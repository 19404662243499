import {IHistoryItem, IRequestData} from "../interfaces/containers";

export const mergeHistoryWithCancellation = (data: IRequestData) => {
  const {history = [], cancellationHistory = [], user, createdByUser, createdAt, _id} = data;
  const created = {
    _id,
    uid: createdByUser || user,
    createdAt,
    comment: '',
    isCreation: true
  };
  const _h = [...history, created];

  if (!cancellationHistory || !cancellationHistory.length) {
    return _h
  }

  const cancellationFlatMap = cancellationHistory.reduceRight((acc, request) => {
    const {history = [], createdAt, _id} = request;
    const cancellationRequest: IHistoryItem = {
      _id: _id as string,
      createdAt: createdAt as Date,
      comment: '',
      uid: user,
      isCancellationRequest: true
    };
    const cancellationRequestHistory = history.map(i => ({...i, isCancellationRequestHistory: true}));
    return [...acc, cancellationRequest, ...cancellationRequestHistory];
  }, [] as Array<IHistoryItem>);

  return _h
    .concat(cancellationFlatMap)
    .sort((a, b) => {
      return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt));
    });
};