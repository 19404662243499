import React from "react";
import * as validate from "validate.js";
import { generatePath } from "react-router-dom";
import { Button, Row } from "antd";
import routesInventories from "../../../../routes/inventories";
import NavLink from "../../../../components/NavLink";
import Table from "../../../../components/Table";
import columns from "./columns";
import "./styles.scss";
import * as moment from "moment";

interface Props {
  id: string;
  data: any;
}

interface DataEl {
  key: string;
  name: string;
  value: string | number;
}

interface State {
  loading: boolean;
  data: DataEl[];
}

export default class Details extends React.Component<Props, State> {
  state = {
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { data } = this.props;

    const parsedData = this.normalizeData(data);
    this.setState({ loading: false, data: parsedData });
  };

  normalizeData = (data: any) => {
    const { group } = data;
    const { fields, name } = group;
    const parsed: DataEl[] = [
      { name: "Group", value: name, key: "group" },
      {
        name: "Status",
        value:
          data.status === "public"
            ? "Test device"
            : validate.capitalize(data.status),
        key: "status",
      },
    ];
    fields.forEach((item: { key: string; name: string }) => {
      const el = {
        key: item.key,
        name: item.name,
        value: data[item.key],
      };

      if (item.key === "boughtAt" && data[item.key]) {
        el.value = moment.utc(data[item.key]).format("DD/MM/YYYY");
      } else if (item.key === "id") {
        const idChanged = `000${String(data[item.key] || 0)}`.slice(-4);
        el.value = `${group?.prefix || ""}${idChanged}`;
      }

      parsed.push(el);
    });
    return parsed;
  };

  render() {
    const { id } = this.props;
    const { loading, data } = this.state;
    return (
      <>
        <Row type="flex" justify="space-between" className="mb-20">
          <h2 className="page-header">Details</h2>
          <NavLink to={generatePath(routesInventories.edit.path, { id })}>
            <Button className="ant-btn-primary ml-15">Edit</Button>
          </NavLink>
        </Row>
        <Table
          dataSource={data}
          loading={loading}
          showHeader={false}
          rowKey="key"
          size="small"
          columns={columns}
          pagination={{ hideOnSinglePage: true, pageSize: 100 }}
        />
      </>
    );
  }
}
