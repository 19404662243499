import {AnyAction} from "redux";
import {SET_APPROVAL_COUNT} from "./actions";
import initialState from "./initialState";
import {IStore} from "../interfaces/store";

const reducer = (state = initialState.approval, action: AnyAction): IStore["approval"] => {
    const {type, payload} = action;
    switch (type) {
        case SET_APPROVAL_COUNT:
            return {count: payload};
        default:
            return initialState.approval;
    }
};

export default reducer;
