import * as React from 'react';
import {
  Col,
  Dropdown,
  Row,
  Menu,
  Icon
} from 'antd';
import NavLink from '../../components/NavLink';

export interface IAction {
  name: string,
  onClick?: any,
  link?: string,
  item?: any
}

let clickTimer: any;

const getMenu = (actions: IAction[]): JSX.Element => {
  const onClickMenu = ({item, key}: any) => {
    if (actions[key] && actions[key].onClick) {
      if (clickTimer) {
        clearTimeout(clickTimer);
      }
      clickTimer = setTimeout(() => {
        actions[key].onClick(actions[key]);
      }, 50);
    }
  };

  const items: JSX.Element[] = actions.map((action: IAction, index: number): JSX.Element => {
    let inner: any = action.name;
    if (action.link) {
      inner = <NavLink to={action.link}>{action.name}</NavLink>
    }
    return <Menu.Item key={index}>{inner}</Menu.Item>
  });
  return <Menu onClick={onClickMenu}>{items}</Menu>
};


export default (props: { actions: IAction[] }) => {
  const menu = getMenu(props.actions);
  return <Row type="flex"
              justify="end">
    <Col>
      {

      }
      <Dropdown overlay={menu}
                trigger={['click']}
                placement="bottomRight">
        <Icon type="ellipsis"/>
      </Dropdown>
    </Col>
  </Row>
}