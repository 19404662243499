import React from 'react'
import {Col, Select} from "antd";
import Field from "../../ui/Field";
interface IProps {
  name?: string
  label?: string
  defaultValue?: string[]
  col?: {
    [key: string]: any
  }
}

export default (props: IProps) => {
  const {
    defaultValue,
    name = 'gender',
    label = 'Search for gender',
    col
  } = props;
  const genders = ["Male", "Female"];



  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
      defaultValue={defaultValue}
    >
      <Select
        maxTagCount={1}
        showSearch={true}
        notFoundContent="No found"
        optionFilterProp="children"
      >
        {
          genders.map((item: any, i: any) =>
            <Select.Option
              value={item}
              key={i}>{item}</Select.Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  }

  return genders && genders.length ? getItem() : null
}