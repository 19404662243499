import * as React from 'react';
import {FormContext} from '../../noui/Form';
import Tmp from './Tmp';
import {DragEvent} from "react";

interface IChildren {
  triggerChange(): any,
  onDragOver(e: any): any,
  onDrop(e: DragEvent<HTMLDivElement>): any
}

export interface IProps {
  multiple?: boolean
  dragAndDrop?: boolean
  size?: number
  mime?: string[]
  className?: string
  isImage?: boolean
  customDrag?: boolean
  children? (props: IChildren): any
  onChange? (files: any, image?: string): void
  onError? (error: string[]): void
  onClear? (): void
}

export default class ImageUpload extends React.Component<IProps, any> {
  public render() {
    return <FormContext.Consumer>{
      context => <Tmp form={context.form}
                      {...this.props} />
    }
    </FormContext.Consumer>
  }
}