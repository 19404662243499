import * as React from 'react';
import moment from "moment";
import {getGeneralStatus} from '../../../utils/requestStatus'
import DotMenu, {TActions} from '../../../components/DotMenu';

export default (actions: TActions) => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: ['weekend.name'],
    sorter: true,
    render(txt: string, {weekend}: any) {
      return weekend.name
    }
  },
  {
    title: 'Date',
    key: 'from',
    dataIndex: 'from',
    sorter: true,
    render(txt: any, {from, to}: any) {
      const mFrom = moment.utc(from);
      const mTo = moment.utc(to);
      if (mTo.diff(mFrom, 'days')) {
        return `${mFrom.format('DD/MM/YYYY')} - ${mTo.format('DD/MM/YYYY')}`
      }
      return mFrom.format('DD/MM/YYYY')
    }
  },
  {
    title: 'Status',
    key: 'status',
    render(record: any) {
      return getGeneralStatus(record)
    }
  },
  {
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: any) =>
      <DotMenu actions={actions} record={record} />
  }
];