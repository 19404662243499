import React from "react";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import columnLog from "./columnLog";
import apiWeekends from '../../../API/weekends';
import Settings from "../../../layouts/Settings";
import TablePagination from "../../../services/tablePagination";

export default class RequestHistory extends React.Component<any, any> {
  private readonly paginationLog: TablePagination;

  constructor(props: any) {
    super(props);

    this.paginationLog = new TablePagination({
      pageSize: 10,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getHistoryLog
    });

    this.state = {
      listLogs: [],
      pageTitle: '',
      loadingHistory: true,
    };
  }

  componentDidMount() {
    this.getHistoryLog();
  }

  getHistoryLog = async () => {
    try {
      this.setState({loadingHistory: true});
      const {userWeekendId} = this.props.match.params;
      const {data} = await apiWeekends.getHistory({
        params: {id: userWeekendId},
        query: this.paginationLog.requestParams()
      });
      const {list, weekend, user, pagination} = data;

      this.paginationLog.value = pagination;
      const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
      this.setState({
        loadingHistory: false,
        listLogs: list,
        pageTitle: `${weekend.name} - ${fullName}`
      })
    } catch (e) {
      this.setState({loadingHistory: false})
    }
  };


  render() {
    const {
      listLogs,
      loadingHistory,
      pageTitle,
    } = this.state;
    return <Settings
      location={this.props.location}
      title={pageTitle}>
      <h2 className="page-header mb-10">Absence log</h2>
      <Table dataSource={listLogs}
             rowKey="_id"
             columns={columnLog}
             loading={loadingHistory}
             pagination={this.paginationLog.tableConfig} />
      <Pagination {...this.paginationLog.config} />
    </Settings>
  }
}