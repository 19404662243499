import * as React from 'react';
import moment from 'moment';
import {
  Button,
  Col,
  DatePicker,
  Row,
  Icon,
} from 'antd';
import Field, {FormItem} from '../../ui/Field';
import dateConfig from "../../configs/date";
import errors from '../../API/error';
import {IRequestError} from "../../interfaces/error";

interface IKid {
  name?: string
  birthday?: string
  _id: number
  isNew?: boolean
}

interface IState {
  kids: IKid[],
  loaded: boolean
}

export default class EmployeeChildren extends React.Component<any, IState> {
  state = {
    kids: [],
    loaded: false,
  };

  static getDerivedStateFromProps (props: any, state: IState) {
    if (props.kids && !state.loaded) {
      return {
        ...state,
        kids: props.kids,
        loaded: true
      }
    }
    return state;
  }

  static parseFormValues = (values: any = {}) => {
    const data = Object
      .keys(values)
      .reduce((acc, key) => {
        if (/^(name-|birthday-)/.test(key)) {
          const split = key.split('-');
          const id: string = split.pop() || '';
          const prop: 'name' | 'birthday' = split[0] as 'name' | 'birthday';

          acc[id] = acc[id] ? acc[id] : {name: '', birthday: ''};
          acc[id][prop] = prop === 'birthday'
            ? moment(values[key]).format('YYYY-MM-DD')
            : values[key];
          return acc;
        }
        return acc;
      }, {} as {[key: string]: {name: string, birthday: string}});

    return Object.values(data);
  };

  static isValid = (values: any = {}, form: any) => {
    let _errors: {[key: string]: string} = {};

    for (let key in values) {
      if (values.hasOwnProperty(key) && /^birthday-/.test(key)) {
        if (moment(new Date(values[key])).isAfter(new Date())) {
          _errors[key] = 'Can\'t be future date'
        }
      }
    }

    const isInvalid = Boolean(Object.keys(_errors).length);

    if (isInvalid) {
      const e: IRequestError = {
        name: '',
        message: '',
        response: {status: 400, data: {errors: _errors}}
      };
      errors.handle(e, form, {priority: 'form'})
    }

    return !isInvalid;
  };

  add = () => {
    const _id = parseInt((Math.random()*10e10).toFixed(), 10);
    this.setState({
      kids: [...this.state.kids, {
        name: undefined,
        birthday: undefined,
        isNew: true,
        _id
      }]
    });
  };

  remove = (id: number) => {
    const {kids} = this.state;
    this.setState({
      kids: kids.filter((k: IKid) => k._id !== id)
    })
  };

  render() {
    const {kids, loaded} = this.state;

    return <FormItem label="Children">
      {loaded && Boolean(kids && kids.length) &&
        kids.map((kid: IKid) => {
          const {_id: id, name, birthday, isNew} = kid;
          const key = `${isNew ? 'new-' : ''}${id}`;

          return <Row gutter={20} key={id}>
            <Col md={{span: 12}}>
              <Field name={`name-${key}`}
                     defaultValue={name}
                     placeholder="Name"
                     validation="required" />
            </Col>
            <Col md={{span: 8}}>
              <Field name={`birthday-${key}`}
                     placeholder="Birthday"
                     defaultValue={moment(birthday)}>
                <DatePicker format={dateConfig.formatHolidayDatePicker} />
              </Field>
            </Col>
            <Col md={{span: 4}}>
            <span className="cursor" onClick={this.remove.bind(null, id)}>
              <Icon type="close-circle" />
            </span>
            </Col>
          </Row>
        })}
      <Row type="flex">
        <Button onClick={this.add}><Icon type="plus" />Add</Button>
      </Row>
    </FormItem>
  }
}
