import React from "react";
import invitationsAPI from "../../../API/invitations";
import Settings from "../../../layouts/Settings";
import { Button, Row } from "antd";
import Table from "../../../components/Table";
import columns from "./columns";

class InvitationsItem extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      item: [],
      loading: true,
    };
  }

  getData = async () => {
    try {
      const { id } = this.props.match.params;
      const res: any = await invitationsAPI.getItem({ params: { id } });
      const parsedData = this.normalizeData(res.data.records);
      this.setState({
        item: parsedData,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  deleteItem = async () => {
    try {
      const { id } = this.props.match.params;
      console.log(id);
      await invitationsAPI.remove(null, { params: { id } });
      this.props.history.push(`/settings/invitations`);
    } catch (e) {
      console.log(e);
    }
  };

  normalizeData = (data: any) => {
    delete data["_id"];
    delete data["companyId"];
    const dataKeys = Object.keys(data);
    return dataKeys.map((item: any) => {
      return { name: item, key: item, value: data[item] };
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { item, loading } = this.state;

    return (
      <Settings location={this.props.location}>
        <Row type="flex" justify="space-between" className="mb-20">
          <h2 className="page-header">Details</h2>
          <Button
            onClick={() => this.deleteItem()}
            className="ant-btn-primary ml-15"
          >
            Delete
          </Button>
        </Row>
        <Table
          dataSource={item}
          loading={loading}
          showHeader={false}
          rowKey="key"
          size="small"
          columns={columns}
          pagination={{ hideOnSinglePage: true, pageSize: 100 }}
        />
      </Settings>
    );
  }
}

export default InvitationsItem;
