import React from "react";
import {connect} from "react-redux";
import columns from "./columns";
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'
import TablePagination from "../../../../services/tablePagination";
import apiInventories from "../../../../API/inventories";
import errors from "../../../../API/error";
import modalInventoryAssignUsers from "../../../Modals/InventoryAssignUsers";
import './styles.scss'

export default connect(({user}: any) => ({user}))(class MyDevices extends React.Component<any, any> {
  pagination: TablePagination;
  unlisten: any = null;
  id = '';

  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
    });
  }

  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    modalInventoryAssignUsers.init({
      onSubmit: this.handleSubmitMembers
    });
    this.getData()
  }

  componentWillUnmount() {
    modalInventoryAssignUsers.remove()
  }

  getData = async () => {
    try {
      const {data} = await apiInventories.getPublic({query: this.pagination.requestParams()});
      this.pagination.value = data.pagination;
      this.setState({
        loading: false,
        data: data.list
      })
    } catch (e) {
      return
    }
  };

  handleAssignMe = async (id: string) => {
    try {
      await apiInventories.postUserAssign({}, {params: {id}});
      this.getData()
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  handleOpenMembersModal = ({_id, users = []}: any) => {
    const selected = users.filter(({isResponsible}: any) =>
      !isResponsible);

    this.id = _id;
    modalInventoryAssignUsers.open({
      users: selected
    })
  };

  handleSubmitMembers = async (props: any) => {
    try {
      await apiInventories.postResponsibleAssignUsers(props.values, {
        params: {id: this.id}
      });
      this.getData();
      props.onCancel();
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  render() {
    const {data, loading} = this.state;
    const {user} = this.props;
    const inResponsiblePerson = data.some(({users}: any) =>
      users.some(({isResponsible, _id}: any) =>
        isResponsible && _id === user._id));

    return <>
      <h2 className="page-header mt-30 mb-10">Test Devices</h2>
      <div className="overflow-auto table-min-width">
        <Table dataSource={data}
               loading={loading}
               rowKey="_id"
               size="small"
               columns={columns(
                 user,
                 this.handleAssignMe,
                 inResponsiblePerson ? this.handleOpenMembersModal : null
               )}
               pagination={this.pagination.tableConfig}
               onChange={this.pagination.tableChange.bind(this.pagination)} />
      </div>

      <Pagination {...this.pagination.config} />
    </>
  }
})