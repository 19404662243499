import React from "react";
import {Col, Row} from "antd";
import {Groups, Users, Buttons, Teams} from "../../components/Filters";

export default () => <Row gutter={[10, 8]} className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col md={{span: 20}}>
    <Row gutter={[8, 8]}>
      <Users col={{md: {span: 6}}} />
      <Groups col={{md: {span: 6}}} />
      <Teams col={{md: {span: 6}}} />
    </Row>
  </Col>
  <Col md={{span: 4}}>
    <Buttons />
  </Col>
</Row>