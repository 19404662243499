import * as React from 'react';
import {notification, Spin} from 'antd';
import holidaysAPI from "../../../API/holidays";
import appConfig from "../../../configs/app";
import moment from "moment";
import eventTitle from "../../../services/eventChecker";


class SingleEvent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      list: []
    }
  }

  componentDidMount() {
    this.getData()
  };


  getData = async () => {
    try {
      const {id} = this.props.match.params;
      const res = await holidaysAPI.getItem({params: {id}});

      this.setState({list: res.data, loading: false})
    } catch (e) {
      notification.error({message: "failed"})
    }
  };

  render() {
    const {loading, list} = this.state;

    const {name, image, isRestDay, isHoliday, description, from, to} = list;

    const fromDate = moment.utc(from);
    const oneDay = fromDate.format('dddd, DD/MM/YYYY');
    const toDate = moment.utc(to);
    const date = fromDate.diff(toDate, 'days') === 0 ? oneDay : `${fromDate.format('DD/MM/YYYY')} - ${toDate.format('DD/MM/YYYY')}`;

    return <React.Fragment>
      <Spin tip="Loading..." spinning={loading}>
        <div className="event-cards">
          <h2 className="page-header">{name}</h2>
          {
            <div className="event-card single mt-10">
              <div className="event-card-row">

                {image ? <div className="card-background"
                              style={{background: `${image ? `url('${appConfig.staticFolder}${image}?width=500') no-repeat center center / cover` : ''}`}}>
                </div> : null}

                <div className="event-card-right">
                  <div className="event-card-right-top mb-35">
                    <div className="events-label">
                      <h3 className="events-title">{name}</h3>
                      <span
                        className="sub-title" style={isRestDay ? {color: '#C30B2F'} : undefined}>{eventTitle(isHoliday, isRestDay)}</span>
                    </div>
                  </div>

                  <div className="event-desc mb-45">
                    {description}
                  </div>

                  <span className="event-date">{date}</span>

                </div>
              </div>
            </div>

          }
        </div>
      </Spin>
    </React.Fragment>
  }
}

export default SingleEvent