import React from "react";
import {Row} from "antd";
import apiInventories from "../../../../API/inventories";
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'
import columns from './columns';
import TablePagination from "../../../../services/tablePagination";

interface Props {
  id: string
  v: number
}

interface State {
  loading: boolean
  data: any[]
}

export default class Details extends React.Component<Props, State> {
  pagination: TablePagination;
  state = {
    loading: true,
    data: []
  };

  constructor(props: Props) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true
    });
  }


  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.v !== prevProps.v) {
      this.getData()
    }
  }

  getData = async () => {
    try {
      const {id} = this.props;
      const {data} = await apiInventories.getHistory({params: {id}, query: this.pagination.requestParams()});
      const {list, pagination} = data;
      this.pagination.value = pagination;
      this.setState({loading: false, data: list})
    } catch (e) {

    }
  };

  render() {
    const {loading, data} = this.state;
    return <>
      <Row type="flex" justify="space-between" className="mt-30 mb-20">
        <h2 className="page-header">History</h2>
      </Row>
      <Table dataSource={data}
             loading={loading}
             rowKey="_id"
             columns={columns}
             pagination={this.pagination.tableConfig}
             onChange={this.pagination.tableChange.bind(this.pagination)}
      />
      <Pagination {...this.pagination.config} />
    </>
  }
}