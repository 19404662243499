import React, { ReactElement } from "react";

interface IProps {
  title: string;
  text: ReactElement;
  lineColor: string;
  backgroundColor: string;
}

const EntitlementItem = (props: IProps) => {
  const { title, text, backgroundColor, lineColor } = props;
  return (
    <div
      className={`entitlement_item__container background-${backgroundColor} line-${lineColor}`}
    >
      <div className="entitlement_item__tittle">{title}</div>
      <div className="entitlement_item__text-box">{text}</div>
    </div>
  );
};

export default EntitlementItem;
