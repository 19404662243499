import * as React from 'react';
import {
  Select,
  Icon,
  Row,
  Button,
  Spin
} from 'antd';
import Field from '../../ui/Field';
import Form, {ISubmitProps} from '../../noui/Form';
import usersAPI from '../../API/users';
import {connect} from "react-redux";
import './styles/confirmationGroup.scss';
import confirmations from "../../API/confirmations";
import errors from '../../API/error';
import {IStore} from "../../interfaces/store";

const Option = Select.Option;

const linesCount = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth'];

class ConfirmationGroup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const id = props.modalProps && props.modalProps._id;
    this.state = {
      id,
      lines: [this.getRowTmp()],
      list: [],
      loaded: !id,
      loading: !!id
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const {id} = this.state;
    try {
      if (!id) {
        return;
      }

      const {data} = await confirmations.getItem({params: {id}});
      const {lists, name} = data;
      const lines: any = [];
      const list: any = [];
      const usersSet = new Set();

      lists.forEach(({users}: any) => {
        const defaultValue = users.map((u: any) => {
          if (!usersSet.has(u._id)) {
            list.push(u);
            usersSet.add(u._id);
          }

          return u._id
        });
        lines.push({
          ...this.getRowTmp(),
          defaultValue
        })
      });

      this.setState({
        loading: false,
        loaded: true,
        name,
        lines,
        list
      });
    } catch (e) {
      errors.handle(e);
      this.props.onCancel();
    }
  }

  getRowTmp = () => ({
    key: (Math.random()*10e10).toFixed(0)
  });

  loadUsers = async (search: string = '') => {
    try {
      const {data} = await usersAPI.get({query: {limit: 10, search}});
      this.setState({list: data.list})
    } catch (e) {
      console.log(e)
    }
  };

  handleClearData = () => {
    this.loadUsers('');
  };

  handleAddList = () =>
    this.setState({lines: [...this.state.lines, this.getRowTmp()]});

  handleRemove = (key: number) => () => {
    const {lines} = this.state;
    this.setState({lines: lines.filter((line: any) => line.key !== key)});
  };

  submit = async ({values, form}: ISubmitProps) => {
    this.setState({loading: true});
    try {
      const {onCancel, onSubmit} = this.props;
      const {name, ...rest} = values;
      const {lines, id} = this.state;
      const reqParams: any = {name, lines: []};
      lines.forEach(({key}: {key: string}) => {
        if (rest.hasOwnProperty(`_${key}`)) {
          reqParams.lines.push(rest[`_${key}`])
        }
      });
      let callbackParams: any = {};
      if (id) {
        await confirmations.update(reqParams, {params: {id}})
      } else {
        const res = await confirmations.create(reqParams);
        callbackParams = res.data.data
      }

      onSubmit && onSubmit({values: callbackParams, form});

      onCancel();
    } catch (e) {
      console.log(e);
      errors.handle(e);
      this.setState({loading: false})
    }
  };

  render() {
    const {list, lines, loading, loaded, name} = this.state;
    const {onCancel} = this.props;
    return <Form onSubmit={this.submit}>
      <Spin tip="Loading..." spinning={loading}>
        {
          loaded ? <>
            <div>
              <Field name="name"
                     validation="required"
                     defaultValue={name}
                     placeholder="Type here"
                     label="Name" />
              {
                lines.map((line: any, i: number) =>
                  <div className="relative confirmation-line ant-form-item"
                       key={i}>
                    {
                      lines.length > 1
                        ? <span className="delete"
                                onClick={this.handleRemove(line.key)}>
                            <Icon type="delete" />
                          </span>
                        : null
                    }
                    <Field name={`_${line.key}`}
                           label={`${linesCount[i] || i + 1} line`}
                           placeholder="Users list"
                           validation="canNotBlank"
                           defaultValue={line.defaultValue}
                           onFocus={this.handleClearData}
                           onSearch={this.loadUsers}
                           onChange={this.handleClearData}>
                      <Select mode="multiple"
                              notFoundContent={null}
                              defaultActiveFirstOption={false}
                              showArrow={false}
                              filterOption={false}
                              showSearch={true}>
                        {
                          list.map((data: any) =>
                            <Option key={data._id}>{data.firstName} {data.lastName}</Option>)
                        }
                      </Select>
                    </Field>
                  </div>)
              }

            </div>
            <Row type="flex" justify="center" className={'mt-15'}>
              <button className="clear ml-10"
                      type="button"
                      onClick={this.handleAddList}>
                <Icon type="plus-circle" style={{fontSize: '24px'}} />
              </button>
            </Row>
            <Row type="flex" justify="end" className={'mt-45'}>
              <Button htmlType="button" onClick={onCancel}>Cancel</Button>
              <Button type="primary"
                      htmlType="submit"
                      className="ml-15">Submit</Button>
            </Row>
          </> : null
        }
      </Spin>
    </Form>
  }
}

export default connect(({user}: Partial<IStore>) => ({user}))(ConfirmationGroup)