import React from "react";
import { Col, Select } from "antd";
import Field from "../../ui/Field";
import { languageLevel } from "src/containers/Employee/CvMaker/languageList";

interface IProps {
  name?: string;
  label?: string;
  defaultValue?: string[];
  col?: {
    [key: string]: any;
  };
}

export default (props: IProps) => {
  const {
    defaultValue,
    name = "languageLevel",
    label = "Search language level",
    col,
  } = props;




  const getItem = () => {
    const itemComponent = (
      <Field name={name} label={label} defaultValue={defaultValue}>
        <Select
          mode="multiple"
          maxTagCount={1}
          showSearch={true}
          notFoundContent="No found"
          optionFilterProp="children"
        >
          {languageLevel.map((item: any, i: any) => (
            <Select.Option value={item.code} key={i}>
              {item.name + " " + item.code}
            </Select.Option>
          ))}
        </Select>
      </Field>
    );
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent;
  };

  return languageLevel && languageLevel.length ? getItem() : null;
};
