import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import NotFound from '../containers/NotFound';


const CustomRoute = ({
                       user,
                       path,
                       component,
                       roles,
                       isVisible,
                       ...rest
}: any) => {
  const {role: userRole} = user;
  path = path.replace(/\([\w\W\d_+[\]]+\)/gm, '');
  if ((roles instanceof Array && roles.length && roles.indexOf(userRole) === -1)
    || (typeof isVisible === 'function' && !isVisible(user))) {
    return <Route path={path}
                  component={NotFound}
                  {...rest} />
  }
  return <Route path={path}
                component={component}
                {...rest} />
};


export default connect((state: any) => ({
  user: state.user
}), null, null, { pure: false })(CustomRoute)