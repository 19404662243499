import * as React from 'react';
import moment from 'moment';
import Avatar from "../Avatar";
import Table from '../Table';
import {IRequestData, IHistoryItem} from "../../interfaces";
import {getStatus} from "../../utils/requestStatus";

const columns = [
    {
        title: '',
        key: 'avatar',
        width: 100,
        render: (record: IHistoryItem) => {
            const {uid} = record;

            if (uid) {
                return <Avatar user={uid} size={24} />
            }

            return 'None';
        }
    },
    {
        title: 'PERSON',
        key: 'uid',
        render: (record: IHistoryItem) => {
            const {uid} = record;
            return uid ? uid.firstName + ' ' + uid.lastName : 'None';
        }
    },
    {
        title: 'STATUS',
        key: 'status',
        render: (record: IHistoryItem) => {
            const status = getStatus(record);
            if (record.isCancellationRequestHistory) {
                return 'Pending Cancellation / ' + status;
            }
            return status;
        }
    },
    {
        title: 'COMMENT',
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
    },
    {
        title: 'DATE',
        key: 'createdAt',
        render: ({createdAt}: IHistoryItem) => {
            return moment(createdAt).format('DD/MM/YYYY, hh:mm A');
        }
    }
];

interface IProps {
    data: IRequestData["history"],
    loading: boolean
}

export default (props: IProps) => {
    const {data = [], loading = false} = props;
    return <div className="th-large table-min-width">
        <Table columns={columns}
               loading={loading}
               dataSource={data}
               size="small"
               rowKey="_id"
               pagination={{
                   hideOnSinglePage: true,
                   size: 'middle'
               }}/>
    </div>;
}
