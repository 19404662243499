import * as React from 'react';
import {
  Dropdown,
  Row,
  Menu
} from 'antd';
import { connect } from 'react-redux';
import Avatar from '../Avatar';
import apiAuth from '../../API/auth';
import headerConfig from '../../configs/header';
import appConfig from '../../configs/app';
import NavLink from '../NavLink';
import userRoutes from '../../routes/user';
import settingsRoutes from '../../routes/settings';
import * as authService from '../../services/auth';
import approvalCount from '../../services/approvalCount';
import requestsApi from '../../API/requests';
import apiInventories from '../../API/inventories';
import { IStore } from '../../interfaces';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import MobileMenu from './MobileMenu';
import MobileMenuBtn from './MobileMenuBtn';
import './styles.scss';
import errors from '../../API/error';

import IconNotifications from '../../assets/icons/notifications';

const logout = async () => {
  try {
    await apiAuth.logout({});
  } catch (e) {
    console.log(e);
  } finally {
    authService.logout();
  }
};

const menu = ({ companyAccess = {} }: IStore['user'], addProps: any = {}) => {
  const items = [
    <Menu.Item key="1" className="menu-item-profile">
      <NavLink to={ userRoutes.profile.path }>Profile</NavLink>
    </Menu.Item>
  ];
  let path = '/';

  if (companyAccess.settings) {
    path = settingsRoutes.settingsCompany.path;
  } else if (companyAccess.wifiConnections) {
    path = settingsRoutes.wifi.path;
  }

  if (companyAccess.settings || companyAccess.inventories || companyAccess.wifiConnections) {
    items.push(<Menu.Item key="2" className="menu-item-companySettings">
      <NavLink to={ path }>Company settings</NavLink>
    </Menu.Item>);
  }

  items.push(<Menu.Item key="5" className="menu-item-userInventories">
    <NavLink to={ userRoutes.userInventories.path }>Inventories</NavLink>
  </Menu.Item>);

  items.push(<Menu.Item key="4" className="menu-item-logout">
    <NavLink to="/logout" exact onClick={ logout }>Logout</NavLink>
  </Menu.Item>);

  return (<Menu { ...addProps }>
    { items }
  </Menu>);
};

type State = {
  notifications: any[];
  isMobile: null | boolean;
  menuActive: boolean;
};

class Header extends React.Component<any, State> {
  timerResize: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      notifications: [],
      menuActive: false,
      isMobile: null
    };
  }

  componentDidMount(): void {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    this.getData();
    this.getNotifications();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkMobile);
  }

  getNotifications = async () => {
    try {
      const res = await apiInventories.getNotifications({ query: { limit: 100, order: 'desc' } });
      this.setState({
        notifications: res.data.list
      });
    } catch (e) {
      errors.handle(e);
      return false;
    }
  };

  checkMobile = () => {
    if (this.timerResize) {
      clearTimeout(this.timerResize);
    }
    this.timerResize = setTimeout(() => {
      const { width } = document.body.getBoundingClientRect();
      const isMobile = width <= 800;
      if (isMobile !== this.state.isMobile) {
        if (isMobile) {
          document.body.classList.add('fixed-header');
        } else {
          document.body.classList.remove('fixed-header');
        }
        this.setState(() => ({
          isMobile
        }));
      }
    }, 200);
  };

  getData = async () => {
    try {
      const { data } = await requestsApi.getPending({ query: { limit: 10, offset: 0 } });
      approvalCount.set(data.pagination.total);
    } catch (e) {
      approvalCount.set(0);
    }
  };

  handleBurgerTrigger = () => {
    const { menuActive } = this.state;
    document.body.style.overflow = !menuActive ? 'hidden' : '';
    this.setState({
      menuActive: !menuActive
    });
  };

  render(): JSX.Element {
    const { user, approval: { count } } = this.props;
    const { notifications, isMobile, menuActive } = this.state;
    const addProps = isMobile && menuActive ? { onClick: this.handleBurgerTrigger } : {};
    const menuItems = headerConfig.map(({ name, icon, ...item }, index) => (<div key={ index } className="top-menu-item-wrap">
      <NavLink
        className="top-menu-item mr-20 ml-20"
        { ...addProps }
        { ...item }>
        { icon && (user.isManager || user.inFileGate) ?
          <span className="approval">Approval{ count ? <span>{ count }</span> : null }</span>
          : name }
      </NavLink>
    </div>));
    const overlayMenu = menu(user, addProps);
    const logo = (<NavLink
      to={ userRoutes.home.path }
      { ...(isMobile && menuActive ? { onClick: this.handleBurgerTrigger } : {}) }>
      {
        user.company.logo
          ? <img
              className="logo"
              alt={ 'logo' }
              src={ appConfig.staticFolder + user.company.logo } />
          : null
      }

    </NavLink>);

    return (<Row
      type="flex"
      justify="center"
      className="relative">
      {
        isMobile !== null ? <>
          { !menuActive ? logo : null }
          {
            isMobile ? <MobileMenuBtn
              active={ menuActive }
              onClick={ this.handleBurgerTrigger } /> :
            <>
              <div className="nav-links">{
                  menuItems
                }</div>
              <div className="account-trigger">
                {
                    notifications && notifications.length > 0
                      ? <Dropdown
                          key="1233"
                          overlay={ NotificationsMenu(notifications, this.getNotifications) }
                          trigger={ ['click'] }
                          placement="bottomRight">
                        <div className="account-notification cursor">
                          <IconNotifications />
                        </div>
                      </Dropdown> : null
                  }
                <Dropdown
                  overlay={ overlayMenu }
                  trigger={ ['click'] }
                  placement="bottomRight">
                  <Avatar className="cursor" user={ user } size={ 32 } />
                </Dropdown>

              </div>
            </>
          }
        </> : null
      }
      {
        isMobile
          ? <MobileMenu
              active={ menuActive }
              onClick={ this.handleBurgerTrigger }>
            <div>
              <Row type="flex" justify="center" className="top-menu-item-logo">
                { logo }
              </Row>
              { menuItems }
            </div>
            <div>
              { overlayMenu }
            </div>
          </MobileMenu> : null
      }
    </Row>);
  }
}


export default connect(({ user, approval }: Partial<IStore>) =>
  ({ user, approval }))(Header);
