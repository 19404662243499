import * as React from 'react';
import * as modalService from '../services/modal';
import history from '../configs/history';
import Modal from './Modal';


interface IState {
  params: any,
  visible: boolean,
  name: string
}

class ModalHash extends React.Component<any, IState> {
  private unmount = false;

  constructor(props: any) {
    super(props);

    this.state = {
      ...modalService.initConfig
    };
  }

  public componentDidMount() {
    this.historyListener(history.location);
    history.listen(this.historyListener)
  }

  public componentWillUnmount() {
    this.unmount = true;
  }

  public render() {
    const {name, visible, params} = this.state;
    let Component = () => null;
    let additionalParams = {};
    let modalProps: any = {};
    let modalConfig = {};
    if (name && visible) {
      const modalItem = modalService.get(name);
      const {component, propsItem, config = {}, ...rest} = modalItem;
      if (propsItem) {
        modalProps = propsItem;
      }
      if (config) {
        modalConfig = config;
      }
      additionalParams = rest;
      Component = component;
      if (modalItem && modalItem.config && typeof modalItem.config.titleFunc === 'function') {
        modalItem.config.title = modalItem.config.titleFunc(modalProps);
      }
    }
    

    return <Modal {...params}
                  openBtnLabel={false}
                  visible={visible}
                  onCancel={this.onCancel}
                  modalProps={modalProps}
                  {...modalConfig}>
      {
        (props: any) => <Component {...this.props}
                     {...props}
                     {...additionalParams}
                     onCancel={this.onCancel}
                     modalProps={modalProps}/>
      }
    </Modal>
  }

  private historyListener = (location: any) => {
    const {hash, state = {}} = location;
    const nameHash = hash.replace(/^#/, '');
    let modalName = null;
    const modals: any = modalService.getList();
    for (const i in modals) {
      if (!modals.hasOwnProperty(i)) {
        continue;
      }
      if (modals[i].hash === nameHash) {
        modalName = i;
        break;
      }
    }

    if (this.unmount) {
      return false;
    }

    if (!nameHash || !modalName) {
      return this.setState({...modalService.initConfig})
    }
    this.setState({
      params: state,
      visible: true,
      name: modalName
    })
  };

  private onCancel = () => {
    const {params} = this.state;
    history.push({...history.location, hash: ''});
    if (params && typeof params.onCancel === 'function') {
      params.onCancel()
    }
  };
}

export default ModalHash;
