import React, {ReactNode, useState} from "react";
import {Collapse} from "antd";
import './mobile.scss'
import DotMenu, {IActionsList, TActions} from "../DotMenu";

const {Panel} = Collapse;

interface Column<T = any> {
  title?: string
  preventClick?: boolean
  dataIndex?: keyof T

  render?(record: T, index: number): any
}

export interface Props<T = any> {
  hideHeader?: boolean
  columns: Column<T>[]
  subColumn?: Column<T>[]
  dataSource: T[]
  rowKey?: string
  dotActions?: IActionsList | TActions
}


const getRow = <T, >(columns: Column<T>[], record: T, index: number, onClick: (record: T, index: number) => void, isGrey: boolean) => {
  const items: any[] = [];
  columns.forEach((column, index) => {
    const {render, dataIndex} = column;
    const adProps: {onClick?: any} = {};
    if (column.preventClick) {
      adProps.onClick = (e: any) => {
        e.stopPropagation();
      }
    }
    let dataNode: ReactNode = null;
    if (render) {
      dataNode = render(record, index);
    } else if (dataIndex) {
      dataNode = record[dataIndex] || null
    }
    return items.push(<td className="dp-table-row-td" key={index} {...adProps}>{dataNode}</td>)
  });
  return <tr className={`dp-table-row${isGrey ? ' dp-table-row-odd' : ' dp-table-row-eval'}`} key={index} onClick={onClick.bind(null, record, index)}>{
    items
  }</tr>
};

const getHeader = <T, >(columns: Column[]): ReactNode => <tr>
  {
    columns.map((col, index) => <th key={index}>{col.title || ''}</th>)
  }
</tr>;

export default <T, >(props: Props<T>) => {
  const [expanded, setExpanded] = useState<(string|number)[]>([]);
  const {hideHeader, dataSource, columns, subColumn, dotActions} = props;
  const _columns: Column<T>[] = [...columns];

  if (dotActions) {
    _columns.push({
      preventClick: true,
      render(record: T): any {
        return <DotMenu actions={dotActions} record={record}/>
      }
    })
  }

  const onRowClick = (record: T, index: number) => {
    const cloned = [...expanded];
    if (cloned.includes(index)) {
      cloned.splice(cloned.indexOf(index), 1);
    } else {
      cloned.push(index);
    }
    setExpanded(cloned)
  };

  const getData = (data: T[]): ReactNode => {
    if (!data || data.length === 0) {
      return null
    }
    const rows: ReactNode[] = [];
    let count = 0;
    data.forEach((rec: T, index: number) => {
      count += 1;
      rows.push(getRow<T>(_columns, rec, index, onRowClick, !(count % 2)));
      if (subColumn && subColumn.length) {
        const isActive = expanded.includes(index);
        if (isActive) {
          count += 1;
        }
        rows.push(<tr className={`dp-table-subrow${isActive ? !(count % 2) ? ' dp-table-subrow-odd' : ' dp-table-subrow-eval' : ''}`} key={`expanded-${index}`}>
          <td colSpan={_columns.length}>
            <Collapse
              activeKey={isActive ? [index] : ''}
              bordered={false}>
              <Panel header={null} key={index} showArrow={false}>
                <table className="dp-subtable-mobile">
                  <tbody>
                  {
                    subColumn.map((col, index) => {
                      return <tr key={index} className="dp-table-inner-row">
                        <td className="dp-table-inner-td dp-table-inner-label">{col.title || ''}</td>
                        <td className="dp-table-inner-td dp-table-inner-value">{col.render ? col.render(rec, index) : col.dataIndex ? rec[col.dataIndex] : null}</td>
                      </tr>
                    })
                  }
                  </tbody>
                </table>
              </Panel>
            </Collapse>
          </td>
        </tr>)
      }
    });
    return rows
  };

  return <table className="dp-table-mobile">
    {
      !hideHeader ? <thead>
        {
          getHeader<T>(columns)
        }
        </thead>
        : null
    }
    <tbody>
    {
      getData(dataSource)
    }
    </tbody>
  </table>
}