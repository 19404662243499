import * as React from "react";
import {Button} from 'antd';
import NavLink from '../../components/NavLink';
import './style.scss';


export default (): JSX.Element => <div className="not-found-container">
  <div className="not-found">
    <div className="not-found-404">
      <h1>404</h1>
    </div>
    <h2>We are sorry, Page not found!</h2>
    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
    <NavLink to='/'>
      <Button size="large">Back To Homepage</Button>
    </NavLink>
  </div>
</div>