import requestsAPI from "../../API/requests";
import n from "../notification";
import {IApprovalParams} from "./index";

const reject = async (params: IApprovalParams) => {
  try {
    await requestsAPI.reject(params);
    n.success('Rejected');
    return true
  } catch (e) {
    n.error('Failed to reject');
    return false
  }
};

export default reject;