import * as React from 'react';
import {Select, Spin, Input} from 'antd';
import Field from '../../ui/Field';
import confirmationsAPI from "../../API/confirmations";
import usersAPI from "../../API/users";
import errors from '../../API/error';

const Option = Select.Option;
const {TextArea} = Input;

class Confirmations extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const {modalProps: mp = {}} = props;
    const usersDataSource = mp.users || [];

    this.state = {
      confirmations: [],
      usersDataSource,
      defaultUsers: usersDataSource.map((u: any) => u._id) || [],
      loading: true,
      loaded: false
    };
  }
  
  componentDidMount() {
    this.getConfirmations();
  }

  getConfirmations = async () => {
    try {
      const {data} = await confirmationsAPI.get({query: {limit: 100}});
      const {list} = data;
      this.setState({confirmations: list, loaded: true, loading: false});
    } catch (e) {
      this.setState({loading: false});
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
      console.log(e)
    }
  };

  handleChange = async (value: string) => {
    try {
      const res = await usersAPI.get({query: {search: value}});
      this.setState({usersDataSource: res.data.list});
    } catch (e) {
      errors.handle(e, undefined, {
        defaultMessage: 'Failed to load employees'
      });
    }
  };

  handleClearData = () => {
    this.handleChange('');
  };

  render() {
    const {confirmations, loaded, loading, usersDataSource, defaultUsers} = this.state;
    const {modalProps: mp = {}} = this.props;

    return <Spin tip="Loading..." spinning={loading}>
      {
        loaded ? <>
          {/*<h2 className="mb-30">{mp.name ? 'Edit team' : 'New team'}</h2>*/}
          <Field name="name"
                 validation="required"
                 label="Name"
                 defaultValue={mp.name}
          />
          <Field name="description"
                 label="Description"
                 defaultValue={mp.description}
          >
            <TextArea rows={4} />
          </Field>

          <Field name="confirmationId"
                 defaultValue={mp.confirmationId}
                 label="Approval gate">
            <Select>
              {
                confirmations.map(({name, _id}: any, index: number) =>
                  <Option key={index}
                          value={_id}>{name}</Option>)
              }
            </Select>
          </Field>
          <Field name="users"
                 label="Users"
                 defaultValue={defaultUsers}
                 onFocus={this.handleClearData}
                 onSearch={this.handleChange}
                 onChange={this.handleClearData}>
            <Select showSearch={true}
                    mode="multiple"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={null}>
              {
                usersDataSource.map((data: any) =>
                  <Option key={data._id} value={data._id}>{data.firstName || ''} {data.lastName || ''}</Option>)
              }
            </Select>
          </Field>
        </> : <div className="pb-30" />
      }
    </Spin>
  }
}

export default Confirmations;