import * as React from 'react';
import Avatar from "../../components/Avatar";
import Points from '../../components/Points';
import {IUserData} from "../../interfaces";

const getPointActions = (record: any, actions: IActions) => [
  {
    name: 'Edit',
    record,
    onClick: actions.onEdit.bind(null, record)
  },
  {
    name: 'Delete',
    onClick: actions.onRemove.bind(null, record)
  }
];

interface IActions {
  onEdit: any,
  onRemove: any
};

interface IProps {
  actions: IActions
}

export default ({
                  actions
}: IProps) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  }, {
    title: 'Members',
    dataIndex: 'users',
    key: 'users',
    render: (users: Array<IUserData>) => {
      if (!users) {
        return null
      }
      return users.map(u => <div className="flex ai-center" key={u._id}>
        <div className="mr-10">
          <Avatar user={u} size={20} />
        </div>
        {u.firstName} {u.lastName}
      </div>)
    }
  },{
    title: '',
    key: 'actions',
    render: (text: any, record: any) =>
      <Points actions={getPointActions(record, actions)}/>
  }
]