export const chargePeriods = [

  {
    value: 1,
    name: 'Month'
  },
  {
    value: 2,
    name: 'Two month'
  },
  {
    value: 3,
    name: 'Three month'
  },
  {
    value: 4,
    name: 'Four month'
  },
  {
    value: 6,
    name: 'Six month'
  },
  {
    value: 12,
    name: 'Year'
  }
];