import approve from './approve';
import reject from './reject';
import cancel from './cancel';

export interface IApprovalParams {
  requestId: string,
  comment?: string,
  code?: string,
  email?: string,
  reason?: string
}

export default {
  approve,
  reject,
  cancel
}