import React from "react";
import { Button, Row, Card, Spin, Select } from "antd";
import routesTables from "../../../routes/tables";
import apiTable from "../../../API/table";
import Field from "../../../ui/Field";
import Form, { ISubmitProps } from "../../../noui/Form";
import Settings from "../../../layouts/Settings";
import errors from "../../../API/error";
import apiTableSector from "../../../API/tableSector";

const { Option } = Select;

interface State {
  data: {
    [key: string]: any;
  };
  activeGroup: string;
  sectors: any;
  loaded?: boolean;
  defaultValues: any;
}

export default class TableCreateEdit extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: {},
      loaded: false,
      activeGroup: "",
      sectors: [],
      defaultValues: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getTable(id);
    } else {
      this.getSectors(id);
      this.setState({ loaded: true });
    }
  }

  getTable = async (id: string) => {
    try {
      const {
        data: { records },
      } = await apiTable.getItem({ params: { id } });
      this.setState({ loaded: true, data: { ...records } });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
 
  };
  handleChangeSector = (id: string) => {
    this.setState({
      data: { ...this.state.data, sectorId: id },
    });
  };

  getSectors = async (id: string) => {
    try {
      const {
        data: { list },
      } = await apiTableSector.get();
      this.setState({ loaded: true, sectors: list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }

  };

  handleSubmit = async ({ form, values }: ISubmitProps) => {
    try {
      const { id } = this.props.match.params;
      values.sectorId = this.state.data.sectorId;

      if (id) {
        await apiTable.put(values, { params: { id } });
      } else {
        await apiTable.create(values);
      }
      this.props.history.push(routesTables.sectors.path);
    } catch (e) {
      errors.handle(e, form, { defaultMessage: "Failed", priority: "all" });
    }
  };

  render() {
    const { data, loaded, sectors } = this.state;
    const { id } = this.props.match.params;
    const tableStatuses = ["available", "hot", "dedicated"];
    return (
      <Settings
        title={id ? "Edit Inventory" : "Create Inventory"}
        location={this.props.location}
      >
        <Card>
          <Spin tip="Loading..." spinning={false}>
            {loaded ? (
              <Form onSubmit={this.handleSubmit}>
                <Field
                  name="name"
                  label="Name"
                  validation="required"
                  defaultValue={data.name}
                />
                <Field
                  name="status"
                  label="Status"
                  validation="required"
                  defaultValue={data.status}
                >
                  <Select>
                    {tableStatuses.map((data: any) => (
                      <Option key={data}>{data}</Option>
                    ))}
                  </Select>
                </Field>

                {!id && (
                  <Field
                    name="groupId"
                    label="Sector"
                    validation="required"
                    onChange={this.handleChangeSector}
                  >
                    <Select>
                      {sectors.map((data: any) => (
                        <Option key={data._id}>{data.name}</Option>
                      ))}
                    </Select>
                  </Field>
                )}
                <Row type="flex" justify="end">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Row>
              </Form>
            ) : null}
          </Spin>
        </Card>
      </Settings>
    );
  }
}
