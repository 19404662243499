import React from 'react';
import weddings from '../../../../assets/imgs/welcomeBook/unsplash_AbnCRgL2DNs.jpg';
import weddingMobile from '../../../../assets/imgs/welcomeBook/Financial Support pic mobile.jpg';
import './styles.scss';

const FinancialSupport = () => (
  <div className="financial_support">
    <div className="financial_support__img_container">
      <img src={ weddings } alt="wedding" />
    </div>
    <div className="financial_support__img_container_mobile">
      <img src={ weddingMobile } alt="wedding" />
    </div>
    <div className="financial_support__text_container">
      <div className="financial_support__tittle_container">
        <div className="financial_support__tittle super-tittle">
          Financial Support
        </div>
        <div className="financial_support__sub-tittle main-text">
          Life is unpredictable. However, in any case, you can count on the
          help of the company.
        </div>
      </div>
      <ul className="mt-50">
        <li>
          <span>
            Child birth -
            <span className="financial_support__web_price"> $300 </span>as a
            gift
          </span>
          <span className="financial_support__mobile_price"> 300$</span>
        </li>
        <li>
          <span>
            Wedding -
            <span className="financial_support__web_price"> $300</span>
          </span>
          <span className="financial_support__mobile_price"> 300$</span>
        </li>
        <li>
          <span>
            Close relative decease (first line of relatives) -
            <span className="financial_support__web_price"> $300</span>
          </span>
          <span className="financial_support__mobile_price"> 300$</span>
        </li>
      </ul>
    </div>
  </div>
);

export default FinancialSupport;
