import React from "react";
import {Menu} from "antd";
import Item from './Item'
import './styles.scss'

const head = <div className="no-head no-title">Notifications</div>

export default (notifications: any[], updateData?: () => void) => <Menu>
  <Menu.Item key="main" className="disable-hover no-content">
    <div onClick={(e) => e.stopPropagation()}>
      {head}
      {
        notifications.map((item, index: number) =>
          <Item key={index} {...item} updateData={updateData} />)
      }
      <div className="no-footer no-title" />
    </div>
  </Menu.Item>
</Menu>