import React from 'react';
import './styles.scss';
import { Link, generatePath } from 'react-router-dom';
import WelcomeBookHeader from './item/Header/WelcomeBookHeader';
import AboutUs from './item/AboutUs/AboutUs';
import WelcomeBookStory from './item/WelcomeBookStory/WelcomeBookStory';
import Stakeholders from './item/Stakeholders/Stakeholders';
import Statement from './item/Statement/Statement';
import Values from './item/Values/Values';
import Healthcare from './item/Healthcare/Healthcare';
import FinancialSupport from './item/FinanceialSupport/FinancialSupport';
import CorporateGifts from './item/CorporateGifts/CorporateGifts';
import SelfDevelopment from './item/SelfDevelopment/SelfDevelopment';
import OfficeAmenities from './item/OfficeAmenities/OfficeAmenities';
import CorporateEvents from './item/CorporateEvents/CorporateEvents';
import AfterAll from './item/AfterAll/AfterAll';
import ReferFriendItem from './item/ReferFriend/ReferFriend';
import routesUser from '../../routes/user';

function WelcomeBook() {
  const pathToDoc = generatePath(routesUser.companyPolicyDocuments.path);
  const linkTo = (<Link to={ pathToDoc }>Company Policy Documents</Link>);
  return (
    <>
      <h2 className="page-header mb-10">Welcome book</h2>
      <div className="welcome-book">
        <WelcomeBookHeader title="Welcome to #devabitfamily!" sub_title="We are glad you joined us!" />
        <AboutUs />
        <WelcomeBookStory />
        <Stakeholders />
        <Statement />
        <Values />
        <br />
        <WelcomeBookHeader title={ linkTo } />
        <Healthcare />
        <FinancialSupport />
        <CorporateGifts />
        <SelfDevelopment />
        <OfficeAmenities />
        <CorporateEvents />
        <AfterAll />
        <ReferFriendItem />
      </div>
    </>
  );
}

export default WelcomeBook;
