import moment from 'moment';
import { IStore } from '../interfaces';

const initialState: IStore = {
  user: {
    _id: '',
    name: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    avatar: null,
    changedAvatar: null,
    children: [],
    personalEmail: '',
    secondPhone: '',
    skype: '',
    address: '',
    birthday: '',
    companyAccess: {
      users: false,
      personalInformation: false,
      settings: false,
      filters: false,
      inventories: false,
      wifiConnections: false,
      cvMaker: false,
    },
    inFileGate: false,
    isManager: false,
  },
  approval: {
    count: 0,
  },
  bookingTable: {
    date: moment(new Date()),
    table: '',
    sector: '',
    tablesData: [],
    sectorsData: [],
    bookingRequestsData: [],
    bookedDays: [],
    bookedFor: '',
  },
};

export default initialState;
