import React from 'react';
import {Collapse, Row, Col, Button, Typography, Icon} from 'antd';

import Form, {ISubmitProps, FormContext} from "../../noui/Form";
import './styles.scss'

export {default as InventoriesGroups} from './InventoriesGroups';
export {default as Users} from './Users';
export {default as User} from './User';
export {default as Groups} from './Groups';
export {default as Weekends} from './Weekends';
export {default as Gender} from './Gender';
export {default as Teams} from './Teams';
export {default as Positions} from './Positions';
export {default as LanguageLevel} from './LanguageLevel';
export {default as LanguageName} from './LanguageName';
export {default as Project} from './Project';
export {default as ReqStatus} from './ReqStatus';
export {default as Buttons} from './Buttons';

const {Title} = Typography;
const {Panel} = Collapse;

interface IProps {
    children: any
    disableResetBtn?: boolean
    disableBtn?: boolean
    closeBtn?: boolean
    visible?: boolean
    okLabel?: string
    resetLabel?: string
    title?: string
    onSubmit?: (props: ISubmitProps) => any
    onReset?: () => any
    onClose?: (visible: boolean) => any
}

export default ({
                    children,
                    visible,
                    onSubmit,
                    okLabel,
                    resetLabel,
                    title,
                    closeBtn,
                    disableResetBtn,
                    disableBtn,
                    onReset,
                    onClose
                }: IProps) => {
    const close = () => onClose && onClose(false);
    return <Form onSubmit={onSubmit} className="collapse-filters relative">
        <Collapse
          bordered={false}
          activeKey={visible ? '1' : ''}
          onChange={close}>
            <Panel header={null} key="1" showArrow={false}>
                <div>
                    {
                        closeBtn ? <span className="collapse-filters-close cursor" onClick={close}>
            <Icon type="close" />
          </span> : null
                    }
                    {
                        title ? <Title level={3}>{title}</Title> : null
                    }

                    <div>{children}</div>
                  {
                    disableBtn ? <Row type="flex" justify="end">
                      <Col>
                        {
                          !disableResetBtn
                            ? <FormContext.Consumer>{(context: {form: any}) =>
                              <Button htmlType="button"
                                      onClick={() => {
                                        context.form && context.form.resetFields();
                                        onReset && onReset();
                                      }}>{resetLabel || 'Reset'}</Button>
                            }</FormContext.Consumer>
                            : null
                        }
                        <Button type="primary" htmlType="submit" className="ml-10">{okLabel || 'Submit'}</Button>
                      </Col>
                    </Row> : null
                  }
                </div>
            </Panel>
        </Collapse>
    </Form>
}
