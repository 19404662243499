import React, {memo} from "react";
import './styles.scss';

interface IProps {
    header?: React.ReactNode,
    content?: React.ReactNode
}

const CalendarEventCard = memo(({header, content}: IProps) => {
    return (
        <div className={'calendar-event-card'}>
            <div>
                {header}
            </div>
            <div>
                {content}
            </div>
        </div>
    );
});

export default CalendarEventCard;