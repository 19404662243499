import React from "react";
import selfDevelopment from "../../../../assets/imgs/welcomeBook/unsplash_1-aA2Fadydc.jpg";
import selfDevelopmentMobile from "../../../../assets/imgs/welcomeBook/Self-Development_mob.jpg";
import "./styles.scss";

interface IProps {
  tittle: string;
  text: string;
}

function SelfDevelopmentItem(props: IProps) {
  const { text, tittle } = props;
  return (
    <div className="self-development__item">
      <ul>
        <li>{tittle}</li>
      </ul>
      <div className="secondary-text mt-15">{text}</div>
    </div>
  );
}

function SelfDevelopment() {
  const selfDevelopmentData = [
    {
      tittle: "English language (language schools)",
      text: "Our company refunds 50% of the cost after completing the course. Please note that the maximum amount of compensation for one course is UAH 5,000.",
    },
    {
      tittle: "Mentoring",
      text: "Eager to share knowledge, we are ready to strengthen the professional skills of each specialist.",
    },
    {
      tittle: "English language (individual lessons)",
      text: "The company partially refunds the cost of individual program in the recommended language schools. Prior approval is necessary.",
    },
    {
      tittle: "Speaking club between colleagues",
      text: "We organize informal communication in English several times a month.",
    },
    {
      tittle: "Professional courses",
      text: "The company encourages your career growth. We consider each case individually and pay a share of the course cost.",
    },
    {
      tittle: "Tech Talks",
      text: "Get awe-inspiring tech insights provided by the best of the best experts in diversified specializations.",
    },
  ];
  return (
    <div className="self-development">
      <div className="self-development__image-container">
        <img
          src={selfDevelopment}
          alt="Self Development"
          className="self-development__image"
        />
        <img
          src={selfDevelopmentMobile}
          alt="Self Development"
          className="self-development__image_mobile"
        />
        <div className="self-development__tittle-container">
          <div className="self-development__tittle super-tittle">
            Self- Development
          </div>
          <div className="self-development__sub-tittle main-text">
            devabit is a great place to grow, both individually and
            professionally.
          </div>
        </div>
      </div>

      <div className="self-development__text-container">
        <div className="self-development__tittle-mobile">
          <div className="self-development__tittle super-tittle">
            Self- Development
          </div>
          <div className="self-development__sub-tittle main-text">
            devabit is a great place to grow, both individually and
            professionally.
          </div>
        </div>
        {selfDevelopmentData.map(({ text, tittle }, index) => (
          <SelfDevelopmentItem tittle={tittle} text={text} key={index} />
        ))}
      </div>
    </div>
  );
}

export default SelfDevelopment;
