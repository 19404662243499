import * as React from 'react';
import Points from '../../components/Points';

const getPointActions = (record: any, actions: IActions) => [
  {
    name: 'Edit',
    record,
    onClick: () => actions.onEdit(record)
  },
  {
    name: 'Delete',
    onClick: () => actions.onRemove(record._id)
  }
];

interface IActions {
  onEdit: any,
  onRemove: any
}

export default (actions: IActions) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: '',
    key: 'actions',
    render: (text: any, record: any) =>
      <Points actions={getPointActions(record, actions)}/>
  }
]