import React from "react";
import {Button, Row, Card, Spin, Col, Select, DatePicker, Icon} from "antd";
import {FormComponentProps} from "antd/lib/form/Form";
import routesInventories from "../../../routes/inventories";
import apiInventories from "../../../API/inventories";
import Field from '../../../ui/Field'
import Form, {ISubmitProps, FormContext} from '../../../noui/Form'
import Settings from "../../../layouts/Settings";
import dateConfig from "../../../configs/date";
import errors from "../../../API/error";
import moment, {Moment} from "moment";

const {Option} = Select;

interface State {
  groups: {
    [key: string]: any
  }
  activeGroup: string
  loaded?: boolean
  defaultValues: any
}

const NUMB_FIELDS = [
  'qtySsd',
  'qtyHdd',
  'qty',
];

const DATE_FIELDS = [
  'boughtAt',
];

export default class CreateEdit extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      groups: {},
      loaded: false,
      activeGroup: '',
      defaultValues: {}
    };
  }

  componentDidMount() {
    this.getGroups()
  }

  getInventory = async () => {
    const {id} = this.props.match.params;
    const {data} = await apiInventories.getItem({params: {id}});
    return data;
  };

  getDefaultValue = (iv: any) => {
    if (!iv || !iv.group) {
      return {}
    }
    const defVal: any = {
      group: iv.group._id
    };
    iv.group.fields.forEach(({key}: any) => {
      if (iv.hasOwnProperty(key)) {
        defVal[key] = iv[key]
      }
    });
    return defVal
  };

  getGroups = async () => {
    try {
      const {id} = this.props.match.params;
      const {data} = await apiInventories.getGroups({query: {limit: 100}});
      const state: any = {
        loaded: true
      };
      state.groups = data.list.reduce((res: any, el: any) => {
        res[el._id] = el;
        return res
      }, {});

      if (id) {
        const iv = await this.getInventory();
        state.defaultValues = this.getDefaultValue(iv);
        if (state.defaultValues.group) {
          state.activeGroup = state.defaultValues.group
        }
      }

      this.setState(state)
    } catch (e) {
      console.log(e)
    }
  };

  handleChangeGroup = (id: string) => {
    this.setState({
      activeGroup: id
    })
  };

  handleGenerateId = async (form: FormComponentProps['form']) => {
    try {
      const {activeGroup} = this.state;
      const {data} = await apiInventories.getUniqueId({params: {id: activeGroup}})
      form.setFieldsValue({
        id: String(`0000${data.key}`.slice(-4))
      })
    } catch (e) {
      return
    }
  };

  getFieldComponent = (item: any) => {
    const {key, name} = item;
    const {defaultValues, activeGroup, groups} = this.state;
    if (key === 'id') {
      return <div className="field-prefix-50">
        <Field name={key}
               label={name}
               type="number"
               prefix={groups[activeGroup].prefix || undefined}
               suffix={<FormContext.Consumer>{
                 context => <Icon type="redo"
                                  onClick={
                                    this.handleGenerateId.bind(null, (context as any).form)} />
               }</FormContext.Consumer>}
               defaultValue={defaultValues[key] ? `0000${defaultValues[key]}`.slice(-4) : undefined} />
      </div>
    }
    if (DATE_FIELDS.includes(key)) {
      return <Field name={key}
                    defaultValue={moment.utc(defaultValues[key])}
                    label={name}>
        <DatePicker format={dateConfig.formatHolidayDatePicker} />
      </Field>
    } else if (NUMB_FIELDS.includes(key)) {
      return <Field name={key}
                    defaultValue={defaultValues[key]}
                    label={name}
                    type="number" />
    } else {
      return <Field name={key}
                    label={name}
                    defaultValue={defaultValues[key]} />
    }
  };

  handleSubmit = async ({form, values}: ISubmitProps) => {
    try {
      const {id} = this.props.match.params;
      const {groups, activeGroup} = this.state;
      const {fields} = groups[activeGroup];
      if (!fields) {
        return
      }
      const fieldsKeys = fields.map((el: any) => el.key);
      const {groupId} = values;
      const data: any = {groupId};
      Object.entries(values).forEach(([name, value]) => {
        if (value !== undefined && fieldsKeys.includes(name)) {
          if (DATE_FIELDS.includes(name)) {
            data[name] = (value as Moment).format('YYYY-MM-DD')
          } else if (name === 'id') {
            data[name] = +(value as string)
          } else {
            data[name] = value
          }
        }
      });

      if (id) {
        await apiInventories.put(data, {params: {id}})
      } else {
        await apiInventories.post(data)
      }
      this.props.history.push(routesInventories.inventories.path)
    } catch (e) {
      errors.handle(e, form, {defaultMessage: 'Failed', priority: 'all'})
    }
  };

  render() {
    const {groups, activeGroup, loaded, defaultValues} = this.state;
    const {id} = this.props.match.params;
    let fields = [];
    if (activeGroup && groups[activeGroup]) {
      fields = groups[activeGroup].fields
    }

    return <Settings
      title={id ? 'Edit Inventory' : 'Create Inventory'}
      location={this.props.location}>
      <Card>
        <Spin tip="Loading..." spinning={false}>
          {
            loaded ? <Form onSubmit={this.handleSubmit}>
              <Row gutter={20}>
                <Col span={12}>
                  <Field name="groupId"
                         label="Group Name"
                         validation="required"
                         defaultValue={defaultValues.group}
                         onChange={this.handleChangeGroup}>
                    <Select>
                      {
                        Object.values(groups).map((data: any) =>
                          <Option key={data._id}>{data.name}</Option>)
                      }
                    </Select>
                  </Field>
                </Col>
              </Row>

              {
                fields && fields.length > 0 ? fields.map((item: { key: string, name: string }, index: number) =>
                  <Row gutter={20} key={index}>
                    <Col span={12}>
                      {
                        this.getFieldComponent(item)
                      }
                    </Col>
                  </Row>) : null
              }
              <Row type="flex" justify="end">
                <Button type="primary" htmlType="submit">Save</Button>
              </Row>
            </Form> : null
          }
        </Spin>
      </Card>
    </Settings>
  }
}