import CalendarsGroups from "../containers/CalendarsGroups";
import SettingsRedirect from "../containers/SettingsRedirect";
import Teams from "../containers/Teams";
import CompanySettings from "../containers/Settings";
import WeekendsItem from "../containers/Weekends/Item";
import Weekends from "../containers/Weekends";
import Confirmations from "../containers/Confirmations";
import Positions from "../containers/Positions";
import Statistics from "../containers/Statistics";
import Entitlements from "../containers/Entitlements";
import Events from "../containers/Events";
import Inventories from "../containers/Inventories";
import UserOfficeInfo from "../containers/WiFiConnections";
import IUser from "../interfaces/user";
import Invitations from "../containers/Invitations";
import InvitationsItem from "../containers/Invitations/Details/InvitationsItem";

import Languages from "src/containers/Languages";
import Projects from "src/containers/Projects";

import Tables from "../containers/Tables";


const isVisible = (u: IUser) => u.companyAccess && u.companyAccess.settings;
export default {
  settingsCompany: {
    component: CompanySettings,
    isVisible,
    path: `/settings/company`,
  },
  settingsCalendars: {
    component: CalendarsGroups,
    isVisible,
    path: `/settings/calendars`,
  },
  settingsConfirmations: {
    component: Confirmations,
    isVisible,
    path: `/settings/confirmations`,
  },
  settingsTeams: {
    component: Teams,
    isVisible,
    path: `/settings/teams`,
  },
  settingsWeekendsAdd: {
    component: WeekendsItem,
    isVisible,
    path: `/settings/weekends/add`,
  },
  settingsWeekendsEdit: {
    component: WeekendsItem,
    isVisible,
    path: `/settings/weekends/edit/:id`,
  },
  settingsInvitationsItem: {
    component: InvitationsItem,
    exact: false,
    isVisible,
    path: `/settings/invitations/:id`,
  },
  settingsWeekends: {
    component: Weekends,
    isVisible,
    path: `/settings/weekends`,
  },
  settingsPositions: {
    component: Positions,
    isVisible,
    path: `/settings/positions`,
  },
  settingsLanguages: {
    component: Languages,
    isVisible,
    path: `/settings/languages`,
  },
  settingsProjects: {
    component: Projects,
    isVisible,
    path: `/settings/projects`,
  },
  settingsStatistics: {
    exact: false,
    isVisible,
    component: Statistics,
    path: `/settings/statistics`,
  },
  settingsInvitations: {
    exact: false,
    isVisible,
    component: Invitations,
    path: `/settings/invitations`,
  },
  settingsTable: {
    exact: false,
    isVisible,
    component: Tables,
    path: `/settings/tables`,
  },
  settingsEntitlements: {
    component: Entitlements,
    isVisible,
    path: `/settings/entitlements`,
  },
  settingsEvents: {
    component: Events,
    isVisible,
    path: "/settings/events",
  },
  inventories: {
    exact: false,
    component: Inventories,
    isVisible: (u: IUser) => u.companyAccess && u.companyAccess.inventories,
    path: "/settings/inventories",
  },
  wifi: {
    exact: false,
    component: UserOfficeInfo,
    isVisible: (u: IUser) => u.companyAccess && u.companyAccess.wifiConnections,
    path: "/settings/wi-fi",
  },
  settingsRedirect: {
    component: SettingsRedirect,
    path: `/settings`,
  },
};
