import * as React from 'react';
import {Col, Row} from 'antd';
import NavLink from '../components/NavLink';
import {connect} from "react-redux";
import {IStore} from "../interfaces";
import Some from "../noui/Permissions/Some";

const settingsEls: {
  [key: string]: {
    title: string,
    permissions?: string[]
  }
} = {
  '/settings/company': {
    title: 'Company',
  },
  '/settings/weekends': {
    title: 'Absence Types'
  },
  '/settings/calendars': {
    title: 'Business Units'
  },
  '/settings/teams': {
    title: 'Teams'
  },
  '/settings/confirmations': {
    title: 'Approval Gates'
  },
  '/settings/positions': {
    title: 'Positions'
  },
  '/settings/languages': {
    title: 'Languages'
  },
  '/settings/projects': {
    title: 'Projects'
  },
  '/settings/statistics': {
    title: 'Statistics'
  },

  '/settings/Invitations': {
    title: 'Invitations'
  },
  '/settings/tables': {
    title: 'Tables'
  },  
  '/settings/entitlements': {
    title: 'Entitlements'
  },
  '/settings/events': {
    title: 'Events'
  },
  '/settings/inventories': {
    title: 'Inventories',
    permissions: ['inventories']
  },
  '/settings/wi-fi': {
    title: 'Wi-Fi Connections',
    permissions: ['wifiConnections']
  },
};

interface IProps {
  title?: string|any
  filtersComponent?: any,
  children?: any,
  actionsComponent?: any,
  location: { pathname: string }
}

class Settings extends React.Component<IProps, any> {

  getPageHeader = () => {
    const {pathname} = this.props.location;
    if ((settingsEls as any)[pathname]) {
      return (settingsEls as any)[pathname].title
    }

    switch (pathname) {
      case `/settings/weekends/add`:
        return 'Add absence type';
      case `/settings/weekends/edit/:id`:
        return 'Edit absence type';
      default:
        return 'Settings';
    }
  };

  render() {
    const {children, filtersComponent, actionsComponent, title} = this.props;
    return <Row>
      <Row type="flex" justify="space-between" className="mb-20">
        {
          typeof title === 'string' || title === undefined ? <h2 className="page-header">{title || this.getPageHeader()}</h2>
            : title
        }
        {
          actionsComponent ? actionsComponent : null
        }
      </Row>
      {
        filtersComponent ? filtersComponent : null
      }
      <Col md={{span: 5}}>
        {
          Object.entries(settingsEls).map(([path, {title, permissions = ['settings']}], index) => <Some key={index}
                                                                         permissions={permissions}>
            <div>
              <NavLink to={path} className="settings-link">{title}</NavLink>
            </div>
          </Some>)
        }
      </Col>
      <Col md={{span: 19}}>
        {children || null}
      </Col>
    </Row>
  }
}

export default connect(({user}: Partial<IStore>) => ({user}))(Settings)