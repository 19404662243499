import * as React from 'react';
import {Form} from 'antd';

/* tslint:disable:no-unused-variable */
export const FormContext = React.createContext({form: {
    getFieldDecorator: () => null
  }});
/* tslint:enable:no-unused-variable */

export interface ISubmitProps {
  form: any,
  values: any
}

const FormComponent = (props: any) => {
  const {
    onSubmit,
    children = null,
    form,
    className = ''
  } = props;

  const onSubmitInner = (e: any) => {
    e.preventDefault();
    const { validateFields } = form;
    validateFields((errors: object, values: object) => {
      if (!errors) {
        if (typeof onSubmit === 'function') {
          onSubmit({
            form,
            values
          })
        }
      }
    });
  };

  return <Form onSubmit={onSubmitInner} className={className}>
    <FormContext.Provider value={{
      form
    }}>
      {
        children
      }
    </FormContext.Provider>
  </Form>
};

export default Form.create<any>()(FormComponent)