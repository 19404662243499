import * as React from 'react';

interface IRecord {
  id: number
};

interface IProps {
  modalProps: IRecord,
  onCancel: any
}

export default class CalendarRemoveWarning extends React.Component<IProps> {
  async componentDidMount() {
    const {modalProps: {id}, onCancel} = this.props;
    if (!id) {
      return onCancel();
    }
  };

  public render() {
    return <div className="mb-30">
      Do you want to remove this Business Unit?
    </div>;
  }
}