/*
* @description paste parameters to url from routes
* */
export const get = (routePath: string, params: any): string => {
  let to = routePath;
  const keys = to.match(/(:[\da-zA-Z_()[\]+\\]+)/gm);
  if (keys && params) {
    keys.forEach((key: string) => {
      const parsedKey = key.replace(/:|\([\w\d+[\]]+\)/gm, '');
      if (params.hasOwnProperty(parsedKey)) {
        to = to.replace(key, params[parsedKey])
      }
    });
  }
  return to;
};