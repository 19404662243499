import React from "react";
import Settings from "../../../layouts/Settings";

export default class CreateEdit extends React.Component<any> {
  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <Settings
      location={this.props.location}>
      SSS
    </Settings>;
  }
}