import React from "react";
import {Col, Row} from "antd";
import {Users, Buttons} from "../../../components/Filters";

type TValue = string


export default () => <Row gutter={[10, 0]} className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col span={20}>
    <Row gutter={[8, 8]}>
      <Users col={{span: 6}} />
    </Row>
  </Col>
  <Col span={4}>
    <Buttons />
  </Col>
</Row>