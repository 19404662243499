import React from "react";

interface Props {
  color?: string;
}

export default ({ color = "#191F2D" }: Props) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0253909 1.71806L1.3111 0.432348L6.64671 5.76795L7.93242 7.05367L6.64671 8.33938L1.31111 13.675L0.0253906 12.3893L5.361 7.05367L0.0253909 1.71806Z"
      fill={color}
    />
  </svg>
);
