import React, {useState, useEffect} from 'react';
import {Col, Select} from "antd";
import Field from "../../ui/Field";
import apiInventories from "../../API/inventories";
import errors from "../../API/error";

const {Option} = Select;

interface IProps {
  name?: string
  maxTagCount?: number | null
  label?: string
  defaultSelected?: string[]
  col?: {
    [key: string]: any
  }
}

export default (props: IProps) => {
  const {
    name = 'groups',
    label = 'Inventory Groups',
    col,
    defaultSelected = [],
    maxTagCount = 1
  } = props;
  const [data, setData] = useState(defaultSelected);

  const getData = async () => {
    try {
      const res = await apiInventories.getGroups({query: {limit: 100}});
      setData(res.data.list);
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load employees'});
    }
  };

  const componentDidMount = () => {
    getData();
  };

  useEffect(componentDidMount, []);

  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
      defaultValue={defaultSelected}
    >
      <Select
        mode="multiple"
        filterOption={false}
        notFoundContent="Not found"
        optionFilterProp="children"
        defaultActiveFirstOption={false}
        maxTagCount={maxTagCount === null ? undefined : maxTagCount}
      >
        {
          data.map((data: any) =>
            <Option key={data._id}
                    value={data._id}>{data.name}</Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  };
  return getItem()
}