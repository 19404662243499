import React from "react";

export default () => (
  <svg
    width="136"
    height="136"
    viewBox="0 0 136 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.8462 84.4587C92.7463 81.4335 92.339 78.4854 91.6543 75.6442M87.3447 107.229C79.7726 120.807 65.1864 130 48.4352 130C23.8943 130 4 110.268 4 85.9278C4 61.5873 23.8943 41.8555 48.4352 41.8555C50.444 41.8555 52.4217 41.9877 54.3599 42.2438"
      stroke="#191F2D"
    />
    <path
      d="M57.4561 68.0478C54.7737 66.5886 51.6941 65.7588 48.419 65.7588C38.0232 65.7588 29.5957 74.1197 29.5957 84.4335C29.5957 94.7472 38.0232 103.108 48.419 103.108C58.8149 103.108 67.2424 94.7472 67.2424 84.4335C67.2424 80.6978 66.1368 77.2184 64.2325 74.2996"
      stroke="#191F2D"
    />
    <path
      d="M61.9766 97.8789C65.7412 100.12 74.0235 107.59 74.0235 121.036"
      stroke="#191F2D"
    />
    <path
      d="M34.8672 97.8789C30.1083 100.963 21.492 109.315 22.0931 121.036"
      stroke="#191F2D"
    />
    <path
      d="M59.9606 61.6918C59.9455 61.5071 59.8794 61.3303 59.7697 61.181C55.6296 55.5487 53.1875 48.6126 53.1875 41.1084C53.1875 22.2783 68.576 7 87.5754 7C106.575 7 121.963 22.2783 121.963 41.1084C121.963 59.9385 106.575 75.2169 87.5754 75.2169C83.1862 75.2169 78.9914 74.4017 75.1349 72.9167C74.9061 72.8287 74.653 72.8276 74.4236 72.9139C71.1496 74.1449 66.8192 74.6681 62.5951 74.7781C58.4105 74.8871 54.4196 74.5888 51.8299 74.2221C51.8268 74.1965 51.8291 74.1653 51.8391 74.135C51.8496 74.1033 51.8665 74.0782 51.8903 74.0582C53.7498 72.4907 55.789 70.1764 57.3406 67.9297C58.1178 66.8044 58.7896 65.6716 59.2576 64.6355C59.7099 63.634 60.034 62.5901 59.9606 61.6918Z"
      stroke="#191F2D"
    />
    <path
      d="M85.8561 90.5059L86.3184 90.211V89.6628V86.1929C86.3184 84.536 87.6616 83.1929 89.3184 83.1929H128C129.657 83.1929 131 84.536 131 86.1929V106.578C131 108.235 129.657 109.578 128 109.578H89.3184C87.6616 109.578 86.3184 108.235 86.3184 106.578V103.109V102.56L85.8561 102.265L76.637 96.3856L85.8561 90.5059Z"
      stroke="#191F2D"
    />
    <ellipse
      cx="96.6108"
      cy="96.3861"
      rx="2.25926"
      ry="2.24161"
      fill="#191F2D"
    />
    <ellipse
      cx="107.152"
      cy="96.3861"
      rx="2.25926"
      ry="2.24161"
      fill="#191F2D"
    />
    <ellipse
      cx="117.693"
      cy="96.3861"
      rx="2.25926"
      ry="2.24161"
      fill="#191F2D"
    />
    <path
      d="M67.7402 29.4097H78.7989C79.3512 29.4097 79.7989 29.8574 79.7989 30.4097V56.2892C79.7989 56.8415 79.3512 57.2892 78.7989 57.2892H67.7402C67.1879 57.2892 66.7402 56.8415 66.7402 56.2892V30.4097C66.7402 29.8574 67.1879 29.4097 67.7402 29.4097Z"
      stroke="#191F2D"
    />
    <path
      d="M79.293 51.5663L84.8322 55.001C85.1487 55.1972 85.5138 55.3012 85.8862 55.3012H102.399C104.608 55.3012 106.399 53.5103 106.399 51.3012V36.8916C106.399 34.6824 104.608 32.8916 102.399 32.8916H96.1046C95.5523 32.8916 95.1046 32.4439 95.1046 31.8916V19.145C95.1046 17.0239 92.2368 16.3618 91.307 18.2683L84.3584 32.5153C84.0232 33.2026 83.3255 33.6386 82.5608 33.6386H80.7988"
      stroke="#191F2D"
    />
  </svg>
);
