import * as React from 'react';
import DotMenu, {TActions} from "../../components/DotMenu";

export default (actions: TActions) => [
  {
    title: 'Type',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Display name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Color',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) =>
      color ? <div className="color-column" style={{backgroundColor: color}} /> : null
  },
  {
    title: 'Disabled',
    dataIndex: 'isDisabled',
    key: 'isDisabled',
    render: (isDisabled: string) =>
      Boolean(isDisabled) === true  ? "YES" : null
  },
  {
    title: '',
    className: 'prevent-click',
    key: 'actions',
    render: (text: any, record: any) =>
      <DotMenu actions={actions} record={record}/>
  }
]
