import * as React from 'react';
import {Button} from 'antd';
import './styles.scss';

interface IProps {
  url?: string,
  triggerChange: any
  onDragOver: any
  onDrop: any
}

export default ({onDragOver, onDrop, triggerChange, url}: IProps) => {
  return <div className="logo-container flex ai-center jc-center"
              style={url ? {background: `url(${url}) no-repeat center center / contain`} : {}}>
    {
      url
        ? <button type="button"
                  className="clear btn-change-image"
                  onClick={triggerChange}>change</button>
        : <Button onClick={triggerChange}>Add logo</Button>
    }
  </div>
};