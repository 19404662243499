import React from "react";

interface IProps {
  color?: string
  [key: string]: any
}

export const dropdownArrow = ({color = '#5F5F5F'}: IProps) => (
    <svg width="12" height="12" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6.12195 5L11 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default dropdownArrow;