import * as React from 'react';
import {Button, Col, Row, notification} from 'antd';
import errors from '../../API/error';
import From from '../../noui/Form';
import Field from '../../ui/Field';
import userRoutes from '../../routes/user';
import userAPI from '../../API/user';

class ChangePassword extends React.Component<any> {
  public render() {
    return <>
        <h2 className="page-header mb-20">Change Password</h2>
        <Row>
          <Col md={{span: 12, offset: 6}} sm={{span: 24}}>
            <From onSubmit={this.handleSubmit}>
              <Field name="oldPassword"
                     label="Old password"
                     type="password"
                     validation="password" />
              <Field name="password"
                     label="New password"
                     type="password"
                     validation="required" />
              <Field name="confirmPassword"
                     label="Confirm password"
                     type="password"
                     validation="password" />
              <Row type="flex" justify='end'>
                <Button className="ant-btn-primary"
                        htmlType="submit">Change</Button>
              </Row>
            </From>
          </Col>
        </Row>
      </>
  }

  private handleSubmit = async ({values, form}: any) => {
    try {
      const {password, confirmPassword, oldPassword} = values;
      if (password !== confirmPassword) {
        return errors.setFormErrors(form, {confirmPassword: 'Must be the same as password'})
      }
      await userAPI.profile.changePassword({password, oldPassword, confirmPassword});
      notification.success({
        message: 'Success',
        description: 'You changed password.'
      });
      this.props.history.push(userRoutes.profile.path);
    } catch (e) {
      return errors.handle(e, form, {priority: "form"})
    }
  }
}

export default ChangePassword