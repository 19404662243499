import './set/calendarGroup';
import './set/forgotPassword';
import './set/message';
import './set/sendRequest';
import './WiFiConnections';

const init = () => null;

export default {
  init
}