import { IRequestData, IWithStatus, TStatus } from '../interfaces/containers';

export const getStatus = <T extends IWithStatus>(data: T): TStatus => {
  const { isApproved, isCanceled, isRejected, isCancellationRequest, isCreation }  = data;
  switch (true) {
    case isCancellationRequest:
      return 'Pending Cancellation';
    case isApproved:
      return 'Approved';
    case isRejected:
      return 'Rejected';
    case isCanceled:
      return 'Cancelled';
    case isCreation:
      return 'Created';
    default:
      return 'Pending';
  }
};

export const getGeneralStatus = (data: IRequestData): TStatus => {
  const { cancellationHistory } = data;

  if (cancellationHistory && cancellationHistory.length) {
    const lastCancellation = cancellationHistory[0];

    if (lastCancellation.isApproved || lastCancellation.isRejected || lastCancellation.isCanceled) {
      return getStatus(data);
    }
    return 'Pending Cancellation';
  }
  return getStatus(data);
};

export const isCancelOptionVisible = (data: IRequestData): boolean => {
  const status = getGeneralStatus(data);

  return status !== 'Pending Cancellation' && status !== 'Rejected' && status !== 'Cancelled';
};

export const isPending = <T extends IWithStatus>(data: T): boolean => getStatus(data) === 'Pending';

export const isApproved = <T extends IWithStatus>(data: T): boolean => getStatus(data) === 'Approved';

export const isRejected = <T extends IWithStatus>(data: T): boolean => getStatus(data) === 'Rejected';

export const isCanceled = <T extends IWithStatus>(data: T): boolean => getStatus(data) === 'Cancelled';

export const isCancellationRequest = <T extends IWithStatus>(data: T): boolean => getStatus(data) === 'Pending Cancellation';

export const isStatusSet = <T extends IWithStatus>(data: T): boolean => {
  const { isApproved, isCanceled, isRejected, isCancellationRequest } = data;
  return Boolean(
    typeof isApproved !== 'undefined' ||
        typeof isRejected !== 'undefined' ||
        typeof isCanceled !== 'undefined' ||
        typeof isCancellationRequest !== 'undefined'
  );
};
