export default [
  {
    title: "Сandidate",
    dataIndex: "fullName",
    sorter: true,
  },

  {
    title: "Invited by",
    dataIndex: "createdBy",
    sorter: true,
  },
];
