const presence = {allowEmpty: false};

const requiredMsg = (message: string) => ({
  presence: {
    allowEmpty: false,
    message
  }
});

export default {
  numericality: {
    numericality: true
  },
  id: {
    presence,
    numericality: true
  },
  email: {
    email: true,
    presence
  },
  name: {
    presence,
    length: {minimum: 3}
  },
  password: {
    length: {minimum: 6},
    presence
  },
  phone: {
    presence
  },
  confirmPassword: {
    length: {minimum: 6},
    presence,
    equality: 'password'
  },
  required: {
    presence: {
      allowEmpty: false
    }
  },
  canNotBlank: requiredMsg('^This field can\'t be blank'),
  employeeCanNotBlank: requiredMsg('^Employee can\'t be blank'),
  absenceTypeCanNotBlank: requiredMsg('^Absence type can\'t be blank'),
}