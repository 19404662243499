import * as React from 'react';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  Icon,
  notification,
  Switch,
  Upload,
  Form,
} from 'antd';
import { connect } from 'react-redux';
import {
  IEducation,
  ILanguage,
  IPortfolio,
} from 'src/interfaces/containers/CvMaker';
import EvaluationDate from 'src/components/EvaluationDate';
import EmployeeLanguages from 'src/components/EployeeLanguage';
import Avatar from '../../../components/Avatar';
import From, { ISubmitProps } from '../../../noui/Form';
import Field from '../../../ui/Field';
import userRoutes from '../../../routes/user';
import dateConfig from '../../../configs/date';
import usersAPI from '../../../API/users';
import groupsAPI from '../../../API/groups';
import positionsAPI from '../../../API/positions';
import weekendsAPI from '../../../API/weekends';
import teamsAPI from '../../../API/teams';
import EmployeeChildren from '../../../components/EmployeeChildren';
import { getBase64 } from '../../../services/images';
import * as userService from '../../../services/user/profile';
import errors from '../../../API/error';

import appConfig from '../../../configs/app';
import positionsModal from '../../Modals/set/positions';
import { IStore, IUserData } from '../../../interfaces';
import './styles.scss';
import languagesAPI from '../../../API/languages';
import projectsAPI from '../../../API/projects';




interface IUpdateUser {
  loading: boolean;
  loaded: boolean;
  user: any;
  status: string;
  inviteSentAt: string;
  hasInsurance: boolean;
  evaluationDate: string[];
  evaluationDateDesc: string[];
}

interface IState extends IUpdateUser {
  avatar: string;
  fileAvatar: any;
  changedAvatar: any;
  weekends: any[];

  uploadFiles: any[];
  groups: any[];
  positions: any[];
  languages: any[];
  projects: any[];


  status: string;
  inviteSentAt: string;
  sendInvitation: boolean;
  hasInsurance: boolean;
  teams: any[];
  evaluationDate: string[];
  evaluationDateDesc: string[];
}

const { Option } = Select;

const accessKeys = {
  users: {
    name: 'Access to edit users',
  },
  personalInformation: {
    name: 'View personal information',
  },
  settings: {
    name: 'Access to edit company settings',
  },
  filters: {
    name: 'Access to use filters',
  },
  inventories: {
    name: 'Access to inventories',
  },
  wifiConnections: {
    name: 'Access to wi-fi connections',
  },
  cvMaker: {
    name: 'Access to CV',
  },

  bookingAdmin: {
    name: 'Access to booking',
  },

};

const personalInformationKeys = [
  'phone',
  'secondPhone',
  'address',
  'personalEmail',
  'insuranceNumber',
];

class Edit extends React.Component<any, IState> {
  initObject = {
    education: {
      name: '',
      degree: '',
      id: 0,
    } as IEducation,
    language: {
      name: '',
      level: '',
      id: 0,
    } as ILanguage,
    portfolio: {
      name: '',
      country: '',
      about: '',
      responsibility: '',
      position: '',
      technologies: '',
      order: 1000,
      id: 0,
    } as IPortfolio,
  };

  private selectedAllAbsences: boolean = false;

  constructor(props: any) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      status: 'inactive',
      inviteSentAt: '',
      sendInvitation: false,
      fileAvatar: null,


      avatar: '',
      changedAvatar: '',
      user: {},
      loaded: !id,
      loading: !!id,
      weekends: [],
      uploadFiles: [],
      groups: [],
      languages: [],
      projects: [],
      positions: [],
      hasInsurance: false,
      teams: [],
      evaluationDate: [],
      evaluationDateDesc: [],
    };
  }

  componentDidMount() {
    positionsModal.init({ onSubmit: this.createPosition });
    this.getData();
  }

  componentWillUnmount(): void {
    positionsModal.remove();
  }

  getData = async () => {
    const { id } = this.props.match.params;
    const stateData: IUpdateUser = {
      loaded: true,
      loading: false,
      user: {},
      inviteSentAt: '',
      status: 'inactive',
      hasInsurance: false,
      evaluationDate: [],
      evaluationDateDesc: []
    };

    try {
      await this.getWeekends();
      await this.getLanguages();
      await this.getProjects();
      await this.getGroups();
      await this.getPositions();
      await this.getTeams();

      if (id) {
        const { weekends } = this.state;
        const { data } = await usersAPI.getProfile({ params: { id } });

        const {
          groups: dataGroups,
          positions: dataPositions,
          allowAllGroups,
          status = 'inactive',
          inviteSentAt,
          evaluationDate,
          evaluationDateDesc,
          ...rest
        } = data;
        const groups: string[] = [];
        let setWeekends: string[] = [];

        if (rest.weekends && rest.weekends.length === weekends.length) {
          setWeekends.push('all');
          this.selectedAllAbsences = true;
        } else {
          setWeekends = rest.weekends;
        }

        if (allowAllGroups) {
          groups.push('all');
        }

        if (dataGroups && dataGroups.length > 0) {
          dataGroups.forEach((g: { _id: string }) => groups.push(g._id));
        }

        let positions: any = [];
        if (dataPositions && dataPositions.length > 0) {
          positions = dataPositions.map((g: any) => g._id);
        }

        stateData.hasInsurance = rest.insuranceNumber?.length > 0 && true;

        const userInTeams: any = this.state.teams.filter((elm: any) => elm.users.some((user_id: any) => user_id._id === rest._id));
        const userTeamsId = userInTeams.map((team: any) => team._id);

        stateData.user = {
          ...rest,
          positions,
          groups,
          weekends: setWeekends,
          teams: userTeamsId,
        };
        stateData.evaluationDate = evaluationDate;
        stateData.evaluationDateDesc = evaluationDateDesc;
        stateData.status = status;
        stateData.inviteSentAt = inviteSentAt;
      }
      this.setState(stateData);
    } catch (e) {
      this.setState(stateData);
      this.props.history.push(userRoutes.employeeAdd.path);
    }
  };

  getWeekends = async () => {
    try {
      const { data } = await weekendsAPI.get({ query: { limit: 100 } });
      this.setState({ weekends: data.list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getProjects = async () => {
    try {
      this.setState({ loading: true });

      const result = await projectsAPI.get({ query: { limit: 100 } });
      const { list } = result.data;

      this.setState({ projects: list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getLanguages = async () => {
    try {
      this.setState({ loading: true });

      const result = await languagesAPI.get({ query: { limit: 100 } });
      const { list } = result.data;

      this.setState({ languages: list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getGroups = async () => {
    try {
      const { data } = await groupsAPI.get({
        query: { limit: 100, offset: 0 },
      });
      this.setState({ groups: data.list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getPositions = async () => {
    try {
      const { data } = await positionsAPI.get({
        query: { limit: 100, offset: 0 },
      });
      this.setState({ positions: data.list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getTeams = async (): Promise<any> => {
    try {
      const { data } = await teamsAPI.getAll({
        query: { limit: 100, statusFilter: false },
      });
      this.setState({ teams: data.list });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  createPosition = async ({ values, onLoading, onCancel, form }: any) => {
    try {
      onLoading(true);
      await positionsAPI.create(values);
      await this.getPositions();
      onLoading(false);
      onCancel();
      notification.success({ message: 'Success' });
    } catch (e) {
      errors.handle(e, form, { priority: 'form' });
      onLoading(false);
    }
  };

  handleChangeAvatar = async (e: any) => {
    try {
      const { files } = e.target;
      if (files.length === 0) {
        return;
      }
      const avatar: string = await getBase64(files[0]);
      const changedAvatar: string = await getBase64(files[0]);
      this.setState({ avatar, changedAvatar, fileAvatar: files[0] });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed' });
    }
  };

  handleSubmit = async ({ form, values }: ISubmitProps) => {
    const { fileAvatar, weekends, status, sendInvitation, uploadFiles } =
      this.state;
    const { id } = this.props.match.params;
    const { user: { _id: currentUserId } = { _id: '' } } = this.props;

    const isEvaluationDateValid = EvaluationDate.isValid(values, form);
    if (!isEvaluationDateValid) return;

    const isChildrenValid = EmployeeChildren.isValid(values, form);

    if (!isChildrenValid) return;

    const fd = new FormData();

    if (fileAvatar) {
      fd.append('avatar', fileAvatar);
    }
    if (uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        fd.append('passportData', uploadFiles[i]);
      }
    }
    if (sendInvitation) {
      fd.append('sendInvitation', String(sendInvitation));
    }

    fd.append('status', status);

    const groupsList = values.groups.filter((group: string) => {
      if (group === 'all') {
        fd.append('allowAllGroups', String(true));
        return false;
      }
      return true;
    });

    if (groupsList.length > 0) {
      fd.append('groups', JSON.stringify(groupsList));
    }

    if ((values.weekends || []).includes('all')) {
      const allWeekends = weekends.map(({ _id }: { _id: string }) => _id);
      fd.append('weekends', JSON.stringify(allWeekends));
    } else {
      fd.append('weekends', JSON.stringify(values.weekends || []));
    }


    ['positions', 'teams', 'project'].forEach((key) => {
      if (values[key]) {
        fd.append(key, JSON.stringify(values[key]));
      }
    });

    // append static fields
    [
      'firstName',
      'lastName',
      'gender',
      'currentLocation',
      'firstNameNative',
      'lastNameNative',
      'surnameNative',
      'IDcode',
      'skype',
      'email',
      'personalEmail',
      'phone',
      'secondPhone',
      'address',
      'contactFullName',
      'contactPhone',
      'contactDescription',
      'insuranceNumber',
    ].forEach((key) => {
      if (values[key]) {
        fd.append(key, values[key]);
      }
    });

    const companyAccess: any = {};
    let hasAccess = false;
    [
      'settings',
      'personalInformation',
      'inventories',
      'wifiConnections',
      'users',
      'filters',
      'cvMaker',
    ].forEach((key) => {
      if (values[key]) {
        companyAccess[key] = true;
        hasAccess = true;
      }
    });

    if (hasAccess) {
      fd.append('companyAccess', JSON.stringify(companyAccess));
    }


    ['birthday', 'anniversaryDate', 'endDate'].forEach((key) => {
      if (values[key]) {
        fd.append(key, moment(values[key]).format('YYYY-MM-DD'));
      }
    });

    const evaluationDate = EvaluationDate.parseFormValues(values);
    const evaluationDateDesc = EvaluationDate.parseFormValuesDesc(values);
    const children = EmployeeChildren.parseFormValues(values);
    const languagesList = EmployeeLanguages.parseFormValues(values);

    fd.append('evaluationDate', JSON.stringify(evaluationDate));
    fd.append('evaluationDateDesc', JSON.stringify(evaluationDateDesc));

    fd.append('languages', JSON.stringify(languagesList));

    fd.append('children', JSON.stringify(children));

    try {
      if (id) {
        await usersAPI.put(fd, { params: { id } });
      } else {
        await usersAPI.post(fd);
      }

      if (currentUserId === id) {
        await userService.get();
      }

      this.props.history.push(userRoutes.employees.path);

      notification.success({
        message: 'Success',
      });
    } catch (e) {
      errors.handle(e, form, { priority: 'all' });
    }
  };

  hasAccessToField = (name: string) => {
    const { user: loggedUser } = this.props;
    const { companyAccess } = loggedUser;
    return (
      (companyAccess && (companyAccess.users || companyAccess.settings)) ||
      !personalInformationKeys.includes(name)
    );
  };

  handleCancel = () => {
    this.props.history.push(userRoutes.employees.path);
  };

  setNewAbsences = (weekends: string[], form: any) => {
    setTimeout(() => {
      form.setFieldsValue({
        weekends,
      });
    });
  };

  handleChangeAllAbsences = (values: string[], ev: any, form: any) => {
    const { weekends } = this.state;
    const includeAll = values.includes('all');
    if (
      !this.selectedAllAbsences &&
      (includeAll || values.length === weekends.length)
    ) {
      this.selectedAllAbsences = true;
      this.setNewAbsences(['all'], form);
    } else if (this.selectedAllAbsences && values.length > 1) {
      this.selectedAllAbsences = false;
      const v = [...values];
      v.splice(v.indexOf('all'), 1);
      this.setNewAbsences(v, form);
    }
  };

  handleActivateAndSubmit = () => {
    this.setState({
      status: 'active',
      sendInvitation: true,
    });
  };

  handleResendInvite = async () => {
    try {
      const { id } = this.props.match.params;
      await usersAPI.sendInvitation(
        {},
        {
          params: {
            id,
          },
        }
      );
      this.setState({
        inviteSentAt: moment.utc().toISOString(),
      });
    } catch (e) {
      errors.handle(e);
    }
  };

  handleChangeStatus = async () => {
    try {
      const status = this.state.status === 'inactive' ? 'active' : 'inactive';
      const { id } = this.props.match.params;
      await usersAPI.changeStatus(
        {
          status,
        },
        {
          params: {
            id,
          },
        }
      );
      this.setState({
        status,
      });
    } catch (e) {
      errors.handle(e);
    }
  };


  downloadFileUrl = (url: string, fileName: string) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  handleInsuranceChange = (checked: boolean) => {
    this.setState({
      hasInsurance: checked,
      user: {
        ...this.state.user,
        insuranceNumber: checked ? this.state.user.insuranceNumber : '',
      },
    });
  };

  handleInsuranceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      user: {
        ...this.state.user,
        insuranceNumber: e.target.value,
      },
    });
  };

  render() {
    const {
      changedAvatar,
      loaded,
      loading,
      user,
      weekends,
      groups,
      positions,
      status,
      inviteSentAt,
      hasInsurance,
      projects,
      languages,
      teams,
      evaluationDate,
      evaluationDateDesc,

    } = this.state;
    const { user: loggedUser } = this.props;
    const { id } = this.props.match.params;
    const { passportData } = user;

    const handleOnChange = ({ file, fileList, event }: any) => {
      //Using Hooks to update the state to the current filelist
      this.setState({
        uploadFiles: fileList.map((file: any) => file.originFileObj),
      });
      //filelist - [{uid: "-1",url:'Some url to image'}]
    };

    const project = user?.project
      ? user?.project.map((item: any) => item._id)
      : [];
    let canSendInvite = true;
    if (inviteSentAt) {
      canSendInvite = moment.utc().diff(moment.utc(inviteSentAt), 'hours') >= 1;
    }
    return (
      <Spin tip="Loading..." spinning={ loading }>
        <h2 className="page-header mb-20">
          { id ? 'Edit Employee' : 'Add New Employee' }
        </h2>
        <Row>
          <Col md={ { span: 12, offset: 6 } } sm={ { span: 24 } } className="pb-30">
            { loaded ? (
              <From onSubmit={ this.handleSubmit }>
                <Row type="flex" justify="center">
                  <Col className="avatar-wrapp">
                    <label className="relative">
                      <Avatar
                        user={ { ...(user as IUserData), changedAvatar } }
                        size={ 100 }
                      />
                      <span className="change-avatar">
                        <Icon type="edit" />
                      </span>
                      <input
                        type="file"
                        className="hidden"
                        onChange={ this.handleChangeAvatar }
                      />
                    </label>
                  </Col>
                </Row>
                <Field
                  name="firstName"
                  label="First name"
                  validation="required"
                  defaultValue={ user.firstName }
                />
                <Field
                  name="lastName"
                  label="Last name"
                  validation="required"
                  defaultValue={ user.lastName }
                />

                <Field
                  name="firstNameNative"
                  label="Ім'я"
                  defaultValue={ user.firstNameNative }
                />
                <Field
                  name="lastNameNative"
                  label="Прізвище"
                  defaultValue={ user.lastNameNative }
                />
                <Field
                  name="surnameNative"
                  label="По-батькові"
                  defaultValue={ user.surnameNative }
                />

                <Field
                  name="gender"
                  defaultValue={ user.gender || [] }
                  label="Gender"
                >
                  <Select>
                    { ['Male', 'Female'].map((item, index) => (
                      <Option key={ index } value={ item }>
                        { item }
                      </Option>
                    )) }
                  </Select>
                </Field>
                <hr className="mt-20 mb-20" />


                { this.hasAccessToField('email') ? (
                  <Field
                    name="email"
                    label="Email"
                    type="email"
                    validation="email"
                    defaultValue={ user.email }
                  />
                ) : null }
                { this.hasAccessToField('personalEmail') ? (
                  <Field
                    name="personalEmail"
                    label="Personal email"
                    type="email"
                    defaultValue={ user.personalEmail }
                  />
                ) : null }
                { this.hasAccessToField('phone') ? (
                  <Field
                    name="phone"
                    label="Phone number"
                    type="phone"
                    validation="required"
                    defaultValue={ user.phone }
                  />
                ) : null }
                { this.hasAccessToField('secondPhone') ? (
                  <Field
                    name="secondPhone"
                    label="Second phone number"
                    type="phone"
                    defaultValue={ user.secondPhone }
                  />
                ) : null }
                { this.hasAccessToField('email') ? (
                  <Field
                    name="skype"
                    label="Skype"
                    validation="required"
                    defaultValue={ user.skype }
                  />
                ) : null }

                <Field
                  name="positions"
                  defaultValue={ user.positions || [] }
                  label="Positions"
                >
                  <Select mode="multiple">
                    { positions.map(({ name, _id }: any, index: number) => (
                      <Option key={ index } value={ _id }>
                        { name }
                      </Option>
                    )) }
                  </Select>
                </Field>
                <Button onClick={ positionsModal.open }>
                  <Icon type="plus" />
                  Add
                </Button>
                <hr className="mt-20 mb-20" />
                { this.hasAccessToField('address') ? (
                  <Field
                    name="address"
                    label="Home address"
                    defaultValue={ user.address }
                  />
                ) : null }
                <Field
                  name="currentLocation"
                  label="Current location"
                  defaultValue={ user.currentLocation }
                />
                <Row gutter={ 20 }>
                  <Col md={ { span: 12 } }>
                    <Field

                      name="birthday"
                      label="Birthday"
                      validation="canNotBlank"
                      defaultValue={
                        user.birthday ? moment(user.birthday) : undefined
                      }
                    >
                      <DatePicker format={ dateConfig.formatHolidayDatePicker } />
                    </Field>
                  </Col>
                  <Col md={ { span: 12 } }>
                    <Field
                      name="anniversaryDate"
                      label="Employment date"
                      validation="canNotBlank"
                      defaultValue={
                        user.anniversaryDate
                          ? moment(user.anniversaryDate)
                          : undefined
                      }
                    >
                      <DatePicker format={ dateConfig.formatHolidayDatePicker } />
                    </Field>
                  </Col>
                </Row>
                <Row gutter={ 20 }>
                  <Col md={ { span: 12 } }>
                    <Field
                      name="endDate"
                      label="End date"
                      defaultValue={
                        user.endDate ? moment(user.endDate) : undefined
                      }
                    >
                      <DatePicker format={ dateConfig.formatHolidayDatePicker } />
                    </Field>
                  </Col>
                </Row>

                <hr className="mt-20 mb-20" />
                <EvaluationDate
                  edate={ evaluationDate || [] }
                  edateDesc={ evaluationDateDesc || [] }
                  anniversaryDate={ user.anniversaryDate || '' } />
                { /* <EvaluationDate edate={ ['2020-03-27 15:55:07.484', '2021-03-28 15:55:07.484', '2022-03-27 15:55:07.484'] } /> */ }

                <hr className="mt-20 mb-20" />
                { Object.entries(accessKeys).map(
                  ([key, value]: [string, { name: string }], index: number) => {
                    if (
                      !loggedUser.companyAccess ||
                      !loggedUser.companyAccess[key]
                    ) {
                      return null;
                    }
                    return (
                      <Field
                        name={ key }
                        key={ index }
                        defaultValue={
                          !!(user.companyAccess && user.companyAccess[key])
                        }
                        defaultChecked={
                          user.companyAccess && user.companyAccess[key]
                        }
                      >
                        <Checkbox>{ value.name }</Checkbox>
                      </Field>
                    );
                  }
                ) }

                <hr className="mt-20 mb-20" />
                <div className="ant-col ant-form-item-label">
                  <label
                    className="ant-form-item-no-colon"
                    title="Enter insurance number"
                  >
                    Enter insurance number
                  </label>
                </div>
                { /* <Checkbox defaultChecked={hasInsurance}>Do not show in the select list</Checkbox> */ }
                <Switch
                  title="has insurance"
                  onChange={ this.handleInsuranceChange }
                  // checkedChildren='Yes'
                  // unCheckedChildren='No'
                  checked={ hasInsurance }
                />
                { this.state.hasInsurance && (
                  <Field
                    name="insuranceNumber"
                    label=""
                    defaultValue={ user.insuranceNumber }
                    onChange={ this.handleInsuranceNumberChange }
                    className="mt-5"
                  />
                ) }

                <hr className="mt-20 mb-20" />
                <Field
                  name="weekends"
                  onChange={ this.handleChangeAllAbsences }
                  defaultValue={ user.weekends }
                  label="Absence type"
                >
                  <Select mode="multiple">
                    { weekends.length > 0 ? (
                      <Option key={ -1 } value="all">
                        All
                      </Option>
                    ) : null }
                    { weekends.map(({ name, _id }: any, index: number) => (
                      <Option key={ index } value={ _id }>
                        { name }
                      </Option>
                    )) }
                  </Select>
                </Field>
                <Field
                  name="groups"
                  defaultValue={ user.groups || [] }
                  label="Business unit"
                >
                  <Select mode="multiple">
                    <Option key={ -1 } value="all">
                      All
                    </Option>
                    { groups.map(({ name, _id }: any, index: number) => (
                      <Option key={ index } value={ _id }>
                        { name }
                      </Option>
                    )) }
                  </Select>
                </Field>

                <Field
                  name="teams"
                  defaultValue={ user.teams || [] }
                  label="User in teams"
                >
                  <Select mode="multiple">
                    { teams.map(({ name, _id }: any, index: number) => (
                      <Option key={ index } value={ _id }>
                        { name }
                      </Option>
                    )) }
                  </Select>
                </Field>
                <Field
                  name="project"
                  defaultValue={ project || [] }
                  label="Project"
                >
                  <Select mode="multiple">
                    { projects.map(({ name, _id }: any, index: number) => (
                      <Option key={ index } value={ _id }>
                        { name }
                      </Option>
                    )) }
                  </Select>
                </Field>

                <hr className="mt-20 mb-20" />
                <EmployeeChildren kids={ user.children || [] } />

                <Row className="additional-info">
                  <hr className="mt-20 mb-20" />
                  <h3 className="mb-20">Contact Person</h3>
                  <Field
                    name="contactFullName"
                    label="Full name"
                    defaultValue={ user.contactFullName }
                  />
                  <Field
                    name="contactPhone"
                    label="Phone number"
                    defaultValue={ user.contactPhone }
                  />
                  <Field
                    name="contactDescription"
                    label="Who is this person for you?"
                    defaultValue={ user.contactDescription }
                  />
                </Row>
                <hr className="mt-20 mb-20" />


                <EmployeeLanguages
                  userLanguages={ user.languages }
                  languagesData={ languages }
                />
                <hr className="mt-20 mb-20" />
                <h3 className="mb-20">Passport data</h3>
                <Field
                  name="IDcode"
                  label="ID code"
                  defaultValue={ user.IDcode }
                />

                <Form.Item label="Documents files" />
                { id &&
                  passportData.map((file: string, index: number) => {
                    const url = `${appConfig.staticFolder + user._id}/${file}`;
                    return (
                      <Row key={ index } gutter={ 20 } className="mb-15">
                        { ' ' }
                        <Col md={ { span: 6 } }>{ file }</Col>
                        <Col md={ { span: 4 } }>
                          { ' ' }
                          <Button
                            onClick={ () => {
                              this.downloadFileUrl(url, file);
                            } }
                          >
                            <Icon type="download" /> Download
                          </Button>
                        </Col>
                      </Row>
                    );
                  }) }

                <Upload multiple onChange={ handleOnChange }>
                  <Button>
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>

                <Row type="flex" justify="end">
                  { id ? (
                    <>
                      <Button
                        className="ml-10"
                        onClick={ this.handleChangeStatus }
                      >
                        { status === 'active' ? 'Deactivate' : 'Activate' }
                      </Button>
                      { canSendInvite ? (
                        <Button
                          className="ml-10"
                          onClick={ this.handleResendInvite }
                        >
                          { inviteSentAt ? 'Resend' : 'Send' } Invitation
                        </Button>
                      ) : null }
                    </>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ml-10"
                      onClick={ this.handleActivateAndSubmit }
                    >
                      Save and Invite
                    </Button>
                  ) }
                  <div className="em-edit-base-btns">
                    <Button
                      htmlType="button"
                      className="ml-10"
                      onClick={ this.handleCancel }
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" className="ml-10">
                      Save
                    </Button>
                  </div>
                </Row>
              </From>
            ) : null }
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(Edit);
