import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import "./styles.scss";
import MultipleDatePicker from "src/components/MultipleDatePicker/MultipleDatePicker";
import bookingRequest from "../../../API/bookingRequest";
import usersAPI from "../../../API/users";
import {
  IBookingRequest,
  ITable,
} from "../../../interfaces/containers/tableBooking";
import store from "../../../configs/store";
import moment from "moment";
import errors from "../../../API/error";
import { setBookedDays, setBookedFor } from "src/services/bookingTable";
import { IUser } from "../../../interfaces";

const { Option } = Select;
function BookingRequestModal() {
  const {
    tablesData,
    date: currentDate,
    sector: currentSector,
    sectorsData,
    table: currentTable,
    bookingRequestsData,
  } = store.getState().bookingTable;

  const [dates, setDates] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [requestsData, setRequestsData] = useState<IBookingRequest[]>([]);
  const user = store.getState().user;
  const setBookingDates = useCallback(() => {
    const bookedDays = dates.map((date) => moment(date).format("YYYY-MM-DD"));
    setBookedDays(bookedDays);
  }, [dates]);

  const getUserData = async () => {
    try {
      const res = await usersAPI.get({ query: { limit: 999 } });
      return res.data.list;
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  const getRequestsData = useCallback(async () => {
    try {
      const { data } = (await bookingRequest.getTableRequest({
        query: {
          tableId: currentTable,
        },
      })) as unknown as any;
    
      return data.list;
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  }, [currentTable]);

  const currentSectorData =
    sectorsData.filter((sector) => sector._id === currentSector)[0] || {};
  const { name: sectorName } = currentSectorData;
  const availableReservations = 5 - bookingRequestsData.length - dates.length;
  const bookedError = "You cannot book more than five dates";

  const handleDatesChange = (newDates: string[]) => {
    try {
      if (availableReservations > 0 || newDates.length < dates.length) {
        setDates(newDates);
      } else {
        setDates([...dates]);
        throw bookedError;
      }
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: bookedError });
    }
  };

  const handleChangeBookedFor = (value: any) => {
    setBookedFor(value);
  };
  useEffect(() => {
    let cancel = false;

    if (currentSector) {
      getRequestsData().then((value) => {
        if (cancel) return;
        setRequestsData(value);
      });
    }
    return () => {
      cancel = true;
    };
  }, [currentSector, getRequestsData]);
  useEffect(() => {
    let cancel = false;
    if (availableReservations > 0) {
      setDates([
        moment(currentDate).endOf("day").format("D MMMM, YYYY").toString(),
      ]);
    }
    if (user.companyAccess.bookingAdmin)
      getUserData().then((value) => {
        if (cancel) return;
        setUsers(value);
      });
    return () => {
      cancel = true;
    };
  }, [availableReservations, currentDate, user.companyAccess.bookingAdmin]);
  useEffect(() => {
    setBookingDates();
  }, [dates, setBookingDates]);

  return (
    <div className="Booking-modal">
      <Form.Item label="Selected table">
        <Select disabled value={currentTable}>
          {tablesData.map((table: ITable) => (
            <Option key={table._id}>
              <div
                style={{
                  display: "flex",
                  fontSize: "16px",
                  fontWeight: "unset",
                  lineHeight: "20px",
                }}
              >
                <div>
                  {sectorName} {table.name}
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {user.companyAccess.bookingAdmin && (
        <Form.Item label="Booked for">
          <Select showSearch onSelect={handleChangeBookedFor}>
            {users.map((user: IUser) => (
              <Option key={user._id}>
                <div
                  style={{
                    display: "flex",
                    fontSize: "16px",
                    fontWeight: "unset",
                    lineHeight: "20px",
                  }}
                >
                  <div>{user.firstName + " " + user.lastName}</div>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label="Calendar">
        <MultipleDatePicker
          placeholder={"Choose date"}
          bookedRequestData={requestsData}
          setFieldsValue={dates}
          onChange={handleDatesChange}
        />
      </Form.Item>
      <div className="available-reservations">
        You can make {availableReservations} more reservations
      </div>
    </div>
  );
}

export default BookingRequestModal;
