import React from "react";
import {Row, Button} from 'antd'

interface Actions {
  onApprove(id: string): void

  onReject(id: string): void

  onReturn(id: string): void
}

const Btn = ({children, ...rest}: any) => <button type="button" {...rest}>{
  children
}</button>;

export default (user: any, actions: Actions) => [
  {
    title: 'group',
    dataIndex: 'group.name',
    key: 'name',
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render(id: string, {group}: any): any {
      const idChanged = `000${String(id || 0)}`.slice(-4);
      return `${group?.prefix || ''}${idChanged}`
    }
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
  },
  {
    title: 'Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: '',
    key: 'actions',
    render(record: any) {
      const {users} = record;
      const u = users.find(({_id}: any) => _id === user._id);
      if (!u) {
        return null
      }
      return <Row type="flex" justify="end">
        {
          u.status === 'pending' ? <>
            <Btn className="cursor btn-red actions-btn"
                 onClick={actions.onReject.bind(null, record._id)}>Reject</Btn>
            <Btn className="cursor btn-green actions-btn ml-10"
                 onClick={actions.onApprove.bind(null, record._id)}>Approve</Btn>
          </> : null
        }
        {
          u.status === 'assigned' ? <Button type="primary"
                                            onClick={() => actions.onReturn(record._id)}>Return Device</Button>
            : null
        }
      </Row>
    }
  },
];