import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Row, Col, Upload, Icon, Modal } from 'antd';
import requestAPI from '../../API/requests';
import weekendsAPI from '../../API/weekends';
import Has from '../../noui/Permissions/Has';
import RequestHistory from '../../components/RequestHistory';
import RequestDetails, { defaultData } from '../../components/RequestDetails';
import approval from '../../services/approval';
import errors from '../../API/error';
import { IRequestData, IStore, IUserData, WeekendData } from '../../interfaces';
import notification from '../../services/notification';
import { isCancelOptionVisible } from '../../utils/requestStatus';
import { mergeHistoryWithCancellation } from '../../utils/requestParse';
import './styles.scss';
import appConfig from '../../configs/app';


interface IState {
  readonly data: IRequestData;
  readonly loading: boolean;
  readonly id: string;
  uploadFiles: any[];
  uploadFileList: any[];
  visibleModal: boolean;
  imgPreview: string;

}

class ChangePassword extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: defaultData,
      loading: true,
      id: '',
      uploadFiles: [],
      uploadFileList: [],
      visibleModal: false,
      imgPreview: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const { id } = this.props.match.params;
      this.setState({ id });
      const res: any = await requestAPI.getItem({ params: { id } });

      let weekendData: WeekendData[] = [];
      if (res.data.user._id !== '') {
        const weekendRes = await weekendsAPI.getUserWeekends({
          params: { id: res.data.user._id },
        });
        weekendData = weekendRes.data.list.filter(
          (item: WeekendData) => item.weekendId === res.data.weekend._id,
        );
      }

      this.setState({
        data: { ...res.data, weekendData },
        loading: false,
        uploadFileList: []
      });
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.checkAccess()
        ? errors.handle(e, undefined, { defaultMessage: 'Failed to load data' })
        : console.log('Failed to load data');

      this.setState({ loading: false });
    }
  };

  checkAccess = (): boolean => {
    const {
      data: { pendingUsers },
    } = this.state;
    const { user: { _id } = { _id: '' } } = this.props;

    if (pendingUsers && Array.isArray(pendingUsers) && pendingUsers.length) {
      const matchIDs = (user: IUserData) => user._id === _id;
      return Boolean(pendingUsers.some(matchIDs));
    }

    return false;
  };

  checkCancelAccess = (): boolean => {
    const { data } = this.state;
    const { user: { _id: requestUserId } = { _id: '' } } = data;
    const { user: { _id: currentUserId } = { _id: '' } } = this.props;

    if (!currentUserId) return false;

    if (!isCancelOptionVisible(data)) return false;

    return requestUserId === currentUserId;
  };

  isPendingCancellation = () => {
    const {
      data: { cancellationHistory },
    } = this.state;

    return Boolean(
      cancellationHistory &&
        cancellationHistory.length &&
        cancellationHistory[0].isActive,
    );
  };

  isPendingUser = () => {
    const { user: { _id: userId } = { _id: '' } } = this.props;
    const {
      data: { pendingUsers = [] },
    } = this.state;

    return Boolean(pendingUsers.find((u) => u._id === userId));
  };

  isPendingCancellationUser = () => {
    const { user: { _id: userId } = { _id: '' } } = this.props;
    const {
      data: { cancellationHistory },
    } = this.state;

    if (cancellationHistory && cancellationHistory.length) {
      const { pendingUsers = [] } = cancellationHistory[0] || {
        pendingUsers: [],
      };
      return Boolean(pendingUsers.find((u) => u._id === userId));
    }

    return false;
  };

  handleRequest = (action: 'approve' | 'reject' | 'cancel') => async () => {
    const { id: requestId } = this.state;
    const access = this.checkAccess();

    if (!access && action !== 'cancel') {
      notification.error('Forbidden');
      return false;
    }

    const success = await approval[action]({ requestId });

    if (success) {
      this.getData();
    }
  };

  handleForceCancel = async () => {
    try {
      const { id: requestId } = this.state;
      await requestAPI.forceCancel({ requestId });
      await this.getData();
    } catch (e) {
      errors.handle(e);
    }
  };

  handleForceApprove = async () => {
    try {
      const { id: requestId } = this.state;
      await requestAPI.forceApprove({ requestId });
      await this.getData();
    } catch (e) {
      errors.handle(e);
    }
  };

  handleCancellationRequest = (action: 'approve' | 'reject') => async () => {
    const {
      data: { cancellationHistory },
    } = this.state;

    if (
      cancellationHistory &&
      cancellationHistory.length &&
      cancellationHistory[0].isActive
    ) {
      const requestId = cancellationHistory[0]._id as string;
      const success = await approval[action]({ requestId });

      if (success) {
        this.getData();
      }
    }
  };

  downloadFileUrl = (url: string, fileName: string) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', fileName);
    aTag.setAttribute('target', '_blank');
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  deleteFileUrl = async (url: string, fileName: string) => {
    try {
      const { data } = this.state;
      const id = data._id;

      await requestAPI.putMedicalFile({ deleteFile: fileName, userId: data.user._id }, { params: { id } });
      this.getData();
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
      this.setState({ loading: false });
    }
  };

  handleOnChangeMedicalFiles = ({ file, fileList, event }: any) => {
    const arrExt = 'application/pdf,image/jpg,image/jpeg,image/gif,image/png'.split(',');
    let viewMes = true;

    const newFileList = fileList.filter((file: any) => {
      const extension = file.type;
      if (arrExt.indexOf(extension) >= 0) {
        return true;
      }
      if (viewMes) {
        notification.error('You can only upload PDF, JPG, JPEG, GIF, PNG file!');
        viewMes = false;
      }
      return false;
    });

    //Using Hooks to update the state to the current filelist
    this.setState({
      uploadFiles: newFileList.map((file: any) => file.originFileObj),
      // eslint-disable-next-line react/no-unused-state
      uploadFileList: newFileList,
    });
  };

  handleSaveFiles = async () => {
    try {
      const { data, uploadFiles } = this.state;
      const id = data._id;

      const fd = new FormData();

      if (uploadFiles) {
        for (let i = 0; i < uploadFiles.length; i++) {
          fd.append('filesConfirmedList', uploadFiles[i]);
        }
      }

      fd.append('userId', data.user._id);

      await requestAPI.putMedicalFile(fd, { params: { id } });
      this.getData();
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
      this.setState({ loading: false });
    }
  };

  handleRequestApproveFile = async () => {
    const { data } = this.state;
    const requestId = data._id;
    try {
      await requestAPI.approveFile({
        requestId,
        comment: 'Files was approved',
      });
      this.getData();
    } catch (error) {
      notification.error('Forbidden');
    }
  };

  handleRequestRejectFile = async () => {
    const { data } = this.state;
    const requestId = data._id;
    try {
      await requestAPI.rejectFile({
        requestId,
        comment: 'Files was rejected',
      });
      this.getData();
    } catch (error) {
      notification.error('Forbidden');
    }
  };

  closePreview = async () => {
    this.setState({
      imgPreview: '',
      visibleModal: false,
    });
  };

  openPreview = async(url: string, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      imgPreview: url,
      visibleModal: true,
    });
  };

  render() {
    const { loading, data, uploadFileList, visibleModal, imgPreview } = this.state;
    const showControls = this.checkAccess();
    const showPendingCancellationBtns =
      this.isPendingCancellation() && this.isPendingCancellationUser();
    const showCancel = this.checkCancelAccess();
    const requestHistoryData = mergeHistoryWithCancellation(data);
    const showForceCancel =
      data.isApproved || data.isActive || this.isPendingCancellation();
    const showForceApprove = data.isActive || this.isPendingCancellation();
    const { user } = this.props;

    return (
      <Spin tip='Loading...' spinning={ loading }>
        <Row gutter={ [0, 16] } className='rq-header-details'>
          <Col md={ { span: 12 } }>
            <Row type='flex' className='rq-header-right'>
              <h2 className='page-header'>Details</h2>
              <div className='rq-force-btns'>
                <Has permissions={ ['settings'] }>
                  { showForceCancel && (
                    <Button className='ml-20' onClick={ this.handleForceCancel }>
                      Force Cancel
                    </Button>
                  ) }
                </Has>
                <Has permissions={ ['settings'] }>
                  { showForceApprove && (
                    <Button
                      className='ml-10'
                      type='primary'
                      onClick={ this.handleForceApprove }
                    >
                      Force Approve
                    </Button>
                  ) }
                </Has>
              </div>
            </Row>
          </Col>
          <Col md={ { span: 12 } }>
            <div className='flex jc-flex-end ai-center'>
              { showPendingCancellationBtns && !showControls && (
                <>
                  <p className='mb-0 mr-20 pt-5'>Cancellation Request: </p>
                  <Button
                    className='reject'
                    onClick={ this.handleCancellationRequest('reject') }
                  >
                    Reject
                  </Button>
                  <Button
                    type='primary'
                    className='ml-10 approve'
                    onClick={ this.handleCancellationRequest('approve') }
                  >
                    Approve
                  </Button>
                </>
              ) }
              { this.isPendingUser() &&
                showControls &&
                !showPendingCancellationBtns && (
                  <>
                    <Button
                      className='reject'
                      onClick={ this.handleRequest('reject') }
                    >
                      Reject
                    </Button>
                    <Button
                      type='primary'
                      className='ml-10 approve'
                      onClick={ this.handleRequest('approve') }
                    >
                      Approve
                    </Button>
                  </>
              ) }
              { showCancel && (
                <Button
                  className='ml-10 cancel'
                  onClick={ this.handleRequest('cancel') }
                >
                  Cancel
                </Button>
              ) }
              { user.inFileGate && data.weekend.isFilesConfirmation && !data.isFilesConfirmed && data.isActive ? (
                <>
                  <Button
                    type='danger'
                    className='ml-10 reject'
                    onClick={ this.handleRequestRejectFile }
                  >
                    Reject Files
                  </Button>
                  <Button
                    type='primary'
                    className='ml-10 approve'
                    onClick={ this.handleRequestApproveFile }
                    disabled={ !(data.filesConfirmedList && data.filesConfirmedList.length > 0) }
                  >
                    Approve Files
                  </Button>
                </>
              ) : null }
            </div>
          </Col>
        </Row>
        <RequestDetails data={ data } loading={ loading } />
        { data.weekend.isFilesConfirmation ? (
          <div>
            <h2 className='page-header mt-30 mb-20'>Medical Files:</h2>

            { data.filesConfirmedList?.map((file: string, index: number) => {
              const url = `${appConfig.staticFolder}/medical/${data.user._id}/${file}`;
              return (
                <Row key={ index } gutter={ 16 } className='mb-15'>
                  { ' ' }
                  <Col md={ { span: 18 } }><span
                    style={ { cursor: 'pointer' } }
                    onClick={ (e) => {
                      this.openPreview(url, e);
                    } }
                    >{ Buffer.from(file, 'latin1').toString('utf8') }</span></Col>
                  <Col md={ { span: 6 } }>
                    { ' ' }
                    <Button
                      onClick={ () => {
                        this.downloadFileUrl(url, file);
                      } }
                    >
                      <Icon type='download' /> Download
                    </Button>
                    { (user.inFileGate || data.user._id === user._id) && !data.isFilesConfirmed ? (
                      <>
                        { ' ' }
                        <Button
                          onClick={ () => {
                            this.deleteFileUrl(url, file);
                          } }
                        >
                          Delete
                        </Button>
                      </>
                    ) : null }
                  </Col>
                </Row>
              );
            }) }
            { (user.inFileGate || data.user._id === user._id) && !data.isFilesConfirmed ? (
              <Upload
                multiple
                onChange={ this.handleOnChangeMedicalFiles }
                fileList={ this.state.uploadFileList }
                accept='image/*,.pdf'
                // customRequest={ ():boolean => true }
                beforeUpload={ ():boolean => false }
              >
                <Button>
                  <Icon type='upload' /> Upload
                </Button>
              </Upload>
            ) : null }
            { uploadFileList.length > 0 ? (
              <div>
                <br />
                <Button onClick={ this.handleSaveFiles }>Save</Button>
              </div>
            ) : null }
          </div>
        ) : null }

        <h2 className='page-header mt-30 mb-20'>History</h2>
        <RequestHistory data={ requestHistoryData } loading={ loading } />
        <Modal
          title={ '' }
          visible={ visibleModal }
          onOk={ this.closePreview }
          onCancel={ this.closePreview }
          footer={ '' }
          style={
            { display: 'block',
              width: '60%',
              margin: 'auto', } }
        >
          { imgPreview.split('.').pop() === 'pdf'
            ? (<object data={ imgPreview } type="application/pdf" width="100%" height="600px">
              alt : <a href={ imgPreview }>{ imgPreview }</a>
            </object>)
            : (<img src={ imgPreview }  width={ '100%' } alt='img preview' />) }
        </Modal>
      </Spin>
    );
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(
  ChangePassword,
);
