import React from "react";
import {Row, Button} from 'antd'
import IUser from "../../../../interfaces/user";
import DotMenu from "../../../../components/DotMenu";

const mapMembers = ({firstName, lastName}: any, index: number) =>
  <div key={index}>{`${firstName || ''} ${lastName || ''}`.trim()}</div>;

export default (
  user: IUser,
  onAssignMe: (id: string) => void,
  membersAction: ((id: string) => void) | null) => {
  const res: any = [
    {
      title: 'group',
      dataIndex: 'group.name',
      key: 'name',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render(id: string, {group}: any): any {
        const idChanged = `000${String(id || 0)}`.slice(-4);
        return `${group?.prefix || ''}${idChanged}`
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
    },
    {
      title: 'Responsible',
      key: 'responsible',
      render(record: any): any {
        const {users = []} = record;
        const responsiblePersons = users.filter(({isResponsible}: any) => !!isResponsible);
        if (responsiblePersons.length === 0) {
          return null
        }
        return responsiblePersons.map(mapMembers)
      }
    },
    {
      title: 'Used by',
      key: 'usedBy',
      render(record: any): any {
        const {users = []} = record;
        const responsiblePersons = users.filter(({isResponsible}: any) => !isResponsible);
        if (responsiblePersons.length === 0) {
          return null
        }
        return responsiblePersons.map(mapMembers)
      }
    },
    {
      title: '',
      key: 'assignAction',
      render(record: any) {
        const {users = []} = record;
        const inUse = users.some(({isResponsible, _id}: any) =>
          !isResponsible && _id === user._id);
        return <Row type="flex" justify="end">
          {
            inUse ? <button className="btn-grey actions-btn">In My Use</button>
              : <Button type="primary"
                        onClick={() => onAssignMe(record._id)}>Assign On Me</Button>
          }
        </Row>
      }
    },
  ];

  if (membersAction) {
    res.push({
      title: '',
      className: 'col-actions',
      key: 'actions',
      render(record: any) {
        const {users = []} = record;
        const inResponsiblePerson = users.some(({isResponsible, _id}: any) =>
          isResponsible && _id === user._id);
        return <Row type="flex" justify="end">
          {
            inResponsiblePerson ? <div className="ml-15 test-devices-actions">
              <DotMenu actions={[
                {
                  name: 'Members',
                  action: membersAction
                }
              ]} record={record} />
            </div> : null
          }
        </Row>
      }
    });
  }

  return res;
}