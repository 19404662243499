import React from "react";

export default () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1355 49.8129V47.7871C23.1355 46.6825 22.2401 45.7871 21.1355 45.7871H12.5677M23.1355 49.8129L32.1935 40.7548M23.1355 49.8129V72.9613M12.5677 45.7871H4C2.89543 45.7871 2 46.6825 2 47.7871V77C2 78.1046 2.89543 79 4 79H21.1355C22.2401 79 23.1355 78.1046 23.1355 77V72.9613M12.5677 45.7871V65.4129M23.1355 72.9613L29.6189 78.5185C29.9814 78.8292 30.4431 79 30.9205 79H55.8452M48.8 70.9484H60.8774M60.8774 70.9484V70.9484C63.0866 70.9484 64.9032 72.7651 64.9032 74.9742V74.9742C64.9032 77.1833 63.1124 79 60.9032 79H55.8452M60.8774 70.9484H67.1677C69.2522 70.9484 70.9419 69.2586 70.9419 67.1742V67.1742C70.9419 65.0898 69.2522 63.4 67.1677 63.4H66.9161M48.8 63.4H66.9161M66.9161 63.4H70.1871C72.2715 63.4 73.9613 61.7102 73.9613 59.6258V59.6258C73.9613 57.5414 72.2715 55.8516 70.1871 55.8516H48.8M72.4516 56.3548V15.5935C70.6065 15.2581 66.9161 13.5806 66.9161 9.55484H52.3226C52.3226 11.4 51.1148 15.191 46.2839 15.5935V46.8065C46.2839 47.911 45.3884 48.8065 44.2839 48.8065H40.2452M40.2452 6.53548L25.0916 10.7974C24.5651 10.9455 24.2552 11.4892 24.3961 12.0177L33.8105 47.3218C34.044 48.1973 34.8369 48.8065 35.743 48.8065H40.2452M40.2452 48.8065V46.7935M55.8452 79H76.4903C77.5949 79 78.4903 78.1046 78.4903 77V3C78.4903 1.89543 77.5949 1 76.4903 1H42.2452C41.1406 1 40.2452 1.89543 40.2452 3V15.0903M40.2452 46.7935C40.2452 46.391 35.8839 31.1935 33.7032 23.6452C35.5484 23.3097 38.9368 21.3303 37.729 16.0968L40.2452 15.0903M40.2452 46.7935V15.0903"
      stroke="#191F2D"
    />
    <path
      d="M34.207 48.8066H65.9103C67.8557 48.8066 69.4328 50.3838 69.4328 52.3292V52.3292C69.4328 54.2747 67.8557 55.8518 65.9103 55.8518H55.4461"
      stroke="#191F2D"
    />
    <circle cx="59.3674" cy="38.7419" r="7.04516" stroke="#191F2D" />
  </svg>
);
