const eventChecker = (isHoliday:any,isRestDay:any) => {
  if (isHoliday && isRestDay) {
    return "Corporate Holiday/Day Off"
  } else if (isRestDay) {
    return "Public Holiday"
  } else if (isHoliday) {
    return "Corporate Holiday"
  }
   return "Corporate Event"
};

export default eventChecker