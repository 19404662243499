import * as modal from "../../../services/modal";
import Positions from "../Positions";

interface IProps {
  onSubmit: any
};

export const init = ({onSubmit}: IProps) => {
  modal.set('languages', {
    hash: 'languages',
    component: Positions,
    config: {
      title: 'Languages',
      okLabel: 'Save',
      onSubmit
    }
  });
};

export const open = (item: any = {}) => {
  modal.setProps('languages', item);
  modal.open('languages')
};

export const remove = () => {
  modal.remove('languages')
};

export default {
  init,
  open,
  remove
}