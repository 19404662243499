import * as api from './index';

const t = '/requests';

export default {
  getAll: api.get(`${t}/all`),
  getList: api.get(`${t}/list`),
  getPending: api.get(`${t}/pending`),
  getStatistic: api.get(`${t}/statistic`),
  getRequestsHistory: api.get(`${t}/requests-history`),
  getExportRequestsHistory: api.get(`${t}/export-requests-history`, {
    responseType: 'arraybuffer'
  }),
  getItem: api.get(`${t}/item/:id`),
  getItemCode: api.get(`${t}/item-code/:id`),
  create: api.post(t),
  remoteRequest: api.post(`${t}/remote`),
  export: api.get(`${t}/export`, {
    responseType: 'arraybuffer'
  }),
  forceApprove: api.post(`${t}/force-approve`),
  approve: api.post(`${t}/approve`),
  reject: api.post(`${t}/reject`),
  cancel: api.post(`${t}/cancel`),
  forceCancel: api.post(`${t}/force-cancel`),
  putMedicalFile: api.put(`${t}/item/:id`),
  approveFile: api.post(`${t}/approve-file`),
  rejectFile: api.post(`${t}/reject-to-day-off`),
};
