import requestsAPI from "../../API/requests";
import n from "../notification";
import {IApprovalParams} from "./index";

const approve = async (params: IApprovalParams) => {
  try {
    await requestsAPI.approve(params);
    n.success('Approved');
    return true
  } catch (e) {
    n.error('Failed to approve');
    return false;
  }
};

export default approve