import React from "react";
import team from "../../../../assets/imgs/welcomeBook/IMG_6534 (1) 1.jpg";
import teamMobile from "../../../../assets/imgs/welcomeBook/values_pic_mobile.jpg";
import "./styles.scss";
interface IProps {
  tittle: string;
  text: string;
}

function ValuesItem(props: IProps) {
  const { text, tittle } = props;
  return (
    <div className="values__item">
      <div className="sub-tittle">{tittle}</div>
      <div className="secondary-text fs-14 mt-15">{text}</div>
    </div>
  );
}

function Values() {
  const valuesItemsData = [
    {
      text: "Each of us is a part of #devabitfamily. We truly appreciate the buddy-buddy atmosphere, the spirit of unity, and the positive vibe. With this in mind, we do everything in our power to make you feel comfortable, inspired, and happy. At devabit, you can feel at home. All in all, devabit is a family in word and deed.",
      tittle: "One Family ",
    },
    {
      text: "devabit is the starting point of your professional and individual growth. We promote mutual development and support your desire to learn and get better at what you love. Knowledge-sharing activities, multiple courses, and the ability to rely on your colleagues during challenging periods — we help everyone find their way to steady growth. ",
      tittle: "Continuous Growth",
    },
    {
      text: "Your opinion truly matters. More than that, we support each other in daily tasks and promote respect among colleagues. Yet, constructive criticism, sincere dialogues, and a professional approach to cooperation also help us move towards our common goals. ",
      tittle: "Respect, Trust & Support",
    },
    {
      text: "It is undeniably great to gather talented people that excelled at their occupation. However, it is also essential to look for interesting individuals who would complement each other to form a strong team. Fortunately, we succeeded from both perspectives by engaging proactive, open-minded, skilled, respectful, and exceptional team members.",
      tittle: "Inspiring Team",
    },
    {
      text: "It is undeniably great to gather talented people that excelled at their occupation. However, it is also essential to look for interesting individuals who would complement each other to form a strong team. Fortunately, we succeeded from both perspectives by engaging proactive, open-minded, skilled, respectful, and exceptional team members.",
      tittle: "Comfort at Work",
    },
  ];
  return (
    <div className="values">
      <div className="values__image_container">
        <img src={team} alt="team" />

        <div className="values__tittle_container tittle">Values</div>
      </div>
      <div className="values__image_container_mobile">
        <img src={teamMobile} alt="team" />
      </div>
      <div className="values__item_container">
        <div className="values__tittle_mobile tittle">Values</div>
        {valuesItemsData.map(({ text, tittle }, index) => (
          <ValuesItem tittle={tittle} text={text} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Values;
