import { AnyAction } from "redux";
import {
  SET_BOOKED_DAYS,
  SET_DATE_REQUEST_TABLE,
  SET_REQUESTS_BOOKING_DATA,
  SET_SECTOR,
  SET_SECTORS_DATA,
  SET_TABLE,
  SET_TABLES_DATA,
  SET_BOOKED_FOR,
} from "./actions";
import initialState from "./initialState";
import { IStore } from "../interfaces/store";

const reducer = (
  state = initialState.bookingTable,
  action: AnyAction
): IStore["bookingTable"] => {
  const { type, payload } = action;
  switch (type) {
    case SET_DATE_REQUEST_TABLE:
      return { ...state, date: payload };
    case SET_TABLE:
      return { ...state, table: payload };
    case SET_SECTOR:
      return { ...state, sector: payload };
    case SET_TABLES_DATA:
      return { ...state, tablesData: payload };
    case SET_SECTORS_DATA:
      return { ...state, sectorsData: payload };
    case SET_REQUESTS_BOOKING_DATA:
      return { ...state, bookingRequestsData: payload };
    case SET_BOOKED_DAYS:
      return { ...state, bookedDays: payload };
    case SET_BOOKED_FOR:
      return { ...state, bookedFor: payload };
    default:
      return initialState.bookingTable;
  }
};

export default reducer;
