import React, {useState} from 'react';
import {Col, Select} from "antd";
import Field from "../../ui/Field";
import usersAPI from "../../API/users";
import errors from "../../API/error";

const {Option} = Select;

interface IProps {
  statuses?: Array<'active' | 'inactive' | 'archived'>
  name?: string
  label?: string
  onChange?: any
  required?: boolean
  defaultSelected?: {
    _id: string,
    firstName: string
    lastName: string
  }
  col?: {
    [key: string]: any
  }
}

let searchTimer: any = null;
const SEARCH_DURATION = 300;

export default (props: IProps) => {
  const {
    name = 'users',
    label = 'Search user',
    col,
    defaultSelected,
    statuses
  } = props;
  const [data, setData] = useState(defaultSelected ? [defaultSelected] : []);

  const getUser = async (search: string) => {
    const urlParams: any = {
      query: {
        search,
        status: 'active',
        limit: 7
      }
    };
    if (statuses && statuses.length) {
      urlParams.query.statuses = JSON.stringify(statuses)
    }
    const res = await usersAPI.get(urlParams);
    setData(res.data.list);
  };

  const onSearchUser = async (search: string) => {
    try {
      if (searchTimer) {
        clearTimeout(searchTimer)
      }
      searchTimer = setTimeout(() => {
        getUser(search)
      }, SEARCH_DURATION);

    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load employees'});
    }
  };

  const handleClearData = () => {
    onSearchUser('');
  };

  const defaultValue = defaultSelected ? defaultSelected._id : undefined;

  const addProps = props.required ? {
    validation: 'required'
  } : {};

  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
      defaultValue={defaultValue}
      onFocus={handleClearData}
      onSearch={onSearchUser}
      onChange={(...rest: any) => {
        props.onChange && props.onChange(...rest);
        handleClearData();
      }}
      {...addProps}
    >
      <Select
        maxTagCount={1}
        allowClear={true}
        showSearch={true}
        filterOption={false}
        notFoundContent="Not found"
        optionFilterProp="children"
        defaultActiveFirstOption={false}
      >
        {
          data.map((data: any) =>
            <Option key={data._id}
                    value={data._id}>{`${data.firstName || ''} ${data.lastName || ''}`.trim()}</Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  };
  return getItem()
}