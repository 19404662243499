import React from "react";
import WiFiSettings from './WiFiSettings'
import MyDevices from './MyDevices'
import TestDevices from './TestDevices'

export default class UserInventories extends React.Component {
  render() {
    return <>
      <WiFiSettings />
      <MyDevices />
      <TestDevices />
    </>
  }
}