import * as React from "react";
import {Row, Table} from "antd";
import MobileTable, {Props as MobileProps} from './Mobile'
import './style.scss'

interface State {
  isMobile: boolean | null
}

interface Props<T> {
  clickable?: boolean
  disableGreyRow?: boolean
  mobile?: MobileProps<T>
}

const MOBILE_BREAK_POINT = 991;

class TableComponent extends React.Component<Props<any> & {[key: string]: any}, State> {
  mobileTrigger: any = null;

  private static modifyEffects(onActions: any) {
    return (record: any, rowIndex: number) => {
      const {onClick, ...restActions} = onActions(record, rowIndex);
      const response = {
        ...restActions
      };

      if (onClick) {
        response.onClick = (event: any) => {
          let el = event.target;
          let foundTd = false;
          let preventClick = false;
          do {
            if (!el || el.tagName === 'TABLE' || el.tagName === 'BODY') {
              break
            } else if (el.tagName === 'TD') {
              foundTd = true;
              preventClick = el.classList && el.classList.contains('prevent-click');
              break;
            } else {
              el = el.parentNode
            }
          } while (true);
          if (!preventClick && foundTd) {
            onClick(event)
          }
        }
      }
      return response
    }
  };

  state: State = {
    isMobile: false
  };

  componentDidMount() {
    this.setMobile();
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.resize);
    clearTimeout(this.mobileTrigger);
  }

  /**
   * Used to detect which table we need to display
   * */
  resize = () => {
    if (this.mobileTrigger) {
      clearTimeout(this.mobileTrigger)
    }
    this.mobileTrigger = setTimeout(() => {
      this.setMobile()
    }, 100)
  };

  setMobile = () => {
    const {isMobile} = this.state;
    const {width} = document.body.getBoundingClientRect();
    const newIsMobile = width < MOBILE_BREAK_POINT;
    if (newIsMobile !== isMobile) {
      this.setState({
        isMobile: newIsMobile
      })
    }
  };

  render() {
    const {onRow, clickable, disableGreyRow, columns, ...rest} = this.props;
    const params: any = {};

    const {isMobile} = this.state;

    if (isMobile && rest.mobile) {
      return <MobileTable dataSource={rest.dataSource}
                          {...rest.mobile} />
    }

    // Stop propagation by clicking on some cell
    // add className 'prevent-click' to the TD el to stop events
    if (onRow) {
      params.onRow = TableComponent.modifyEffects(onRow)
    }

    const _columns = columns ? columns.map(({onCell, ...column}: any) => {
      const _c = Object.assign({}, column);
      // Stop propagation by clicking on some cell
      // add className 'prevent-click' to the TD el to stop events
      if (onCell) {
        _c.onCell = TableComponent.modifyEffects(onCell)
      }
      return _c;
    }) : [];

    return <Row className={`style-table${clickable ? ' table-clickable' : ''}${disableGreyRow ? ' disable-grey' : ''}`}>
      <Table {...rest} {...params} columns={_columns} />
    </Row>
  }
}

export default TableComponent