import React from "react";
import { Button } from "antd";
import Settings from "../../layouts/Settings";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import usersApi from "../../API/users";
import TablePagination from "../../services/tablePagination";
import weekendsApi from "../../API/weekends";
import errors from "../../API/error";
import notification from "../../services/notification";
import { IEntitlementItem, IUserWeekend, IWeekendData } from "../../interfaces";
import Filters from "../../components/Filters";
import { ISubmitProps } from "../../noui/Form";
import FiltersLayout from "./FiltersLayout";
import columns from "./columns";

interface IState {
  readonly weekends: Array<IWeekendData>;
  readonly list: Array<IEntitlementItem>;
  readonly loading: boolean;
  readonly showFilters: boolean;
}

class Entitlements extends React.Component<any, IState> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);
    this.state = {
      weekends: [],
      list: [],
      loading: true,
      showFilters: true,
    };
    this.pagination = new TablePagination({
      pageSize: 20,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData,
      allowedFiltersKeys: {
        users: true,
        groups: true,
        teams: true,
      },
    });
  }

  componentDidMount(): void {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const {
        data: { list, pagination },
      } = await usersApi.getAllWithWeekends({
        query: this.pagination.requestParams(),
      });
      const { data: { list: weekends } = { list: [] } } = await weekendsApi.get(
        { query: { limit: 100 } }
      );
      this.pagination.value = pagination;

      this.setState({
        weekends,
        list,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      errors.handle(e, undefined, { priority: "all" });
    }
  };

  handleApplyFilters = ({ values }: ISubmitProps) => {
    const query: { [key: string]: string | number } = {};

    ["users", "groups", "teams"].forEach((key) => {
      if (values[key] && values[key].length > 0) {
        query[key] = JSON.stringify(values[key]);
      } else {
        this.pagination.unlink = key;
      }
    });

    this.pagination.value = query;
    this.getData();
  };

  handleColumnSort = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: [] }
  ) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  handleChangeAbsence = async (val: number, weekend: IUserWeekend) => {
    try {
      this.setState({ loading: true });
      const { _id: id, days } = weekend;
      if (Number(val) !== Number(days)) {
        await weekendsApi.employeesAbsences(
          { days: Number(val), userWeekend: weekend, id },
          { params: { id } }
        );
        await this.getData();
        notification.success("Success");
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      errors.handle(e);
    }
  };

  toggleFilters = () => {
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
  };

  render() {
    const { list, loading, weekends, showFilters } = this.state;

    return (
      <Settings
        location={this.props.location}
        actionsComponent={
          <Button
            type="primary"
            className="top-filters-btn mr-10"
            onClick={this.toggleFilters}
          >
            Filters
          </Button>
        }
        filtersComponent={
          <div className="mb-20">
            <Filters onSubmit={this.handleApplyFilters} visible={showFilters}>
              <FiltersLayout />
            </Filters>
          </div>
        }
      >
        <Table
          className="entitlements-table"
          dataSource={list}
          rowKey="_id"
          columns={columns({
            weekends,
            onAbsenceChange: this.handleChangeAbsence,
          })}
          loading={loading}
          onChange={this.handleColumnSort}
          pagination={this.pagination.tableConfig}
        />
        <Pagination {...this.pagination.config} />
      </Settings>
    );
  }
}

export default Entitlements;
