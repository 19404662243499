import * as React from 'react';
import { generatePath } from 'react-router';
import moment from 'moment';
import { Col, Row, Button, Icon } from 'antd';
import Avatar from '../../../components/Avatar';
import usersAPI from '../../../API/users';
import userRoutes from '../../../routes/user';
import appConfig from '../../../configs/app';
import './style.scss';
import Some from '../../../noui/Permissions/Some';
import apiInventories from '../../../API/inventories';
import * as userService from '../../../services/user/profile';

class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      user: {},
      inventories: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await usersAPI.getProfile({ params: { id } });
      const reqParams = { limit: 10, offset: 0, status: 'active', users: `["${id}"]` }; // users: ['63f8e26136da1275cafd6610']

      // check logged user permission
      const accessProperty = ['settings', 'personalInformation', 'inventories'];
      const hasAccess = await userService.somePermission(accessProperty);

      let inventoryData  = [];
      if (hasAccess) {
        const { data: iData } = await apiInventories.get({
          query: reqParams,
        });
        inventoryData = iData.list;
      }
      this.setState({ user: data, loading: false, inventories: inventoryData });
    } catch (e) {
      this.props.history.push('/');
    }
  };

  onCvMaker = () => {
    this.props.history.push(
      generatePath(userRoutes.employeeCvMaker.path, { id: this.state.user._id })
    );
  };

  downloadFileUrl = (url: string, fileName: string) => {
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  render() {
    const { loading, user = {}, inventories = [] } = this.state;
    const {
      firstName,
      positions,
      email,
      personalEmail,
      lastName,
      phone,
      secondPhone,
      address,
      birthday,
      passportData,
      gender,
      currentLocation,
      endDate,
      project,
      languages,
      firstNameNative,
      lastNameNative,
      surnameNative,
      IDcode,
      skype,
      anniversaryDate,
      companyAccess,
      contactFullName = '-',
      contactPhone = '-',
      contactDescription = '-',
      insuranceNumber = '-',
    } = user;

    const projects = project
      ? project.map((item: any, index: number) =>
        index < project.length - 1 ? `${item.name}, ` : item.name
      )
      : '';
    return !loading ? (
      <Row className="page-profile">
        <h2 className="page-header">Employee Profile</h2>
        <Row
          gutter={ 8 }
          type="flex"
          align="middle"
          className="page-profile-card mt-10"
        >
          <Col md={ { span: 10 } } sm={ { span: 24 } }>
            <div className="page-profile-left">
              <div className="mb-60">
                <Avatar user={ user } size={ 150 } />
              </div>

              <div className="user-name mb-10">
                { firstName } { lastName }
              </div>
              { positions ? (
                <div className="user-positions mb-40">
                  { positions.map((items: any, index: any) => (
                    <span key={ index }>
                      { items.name }
                      { index < positions.length - 1 ? ', ' : null }

                    </span>
                  )) }
                </div>
              ) : null }

              <div className="user-since">
                Joined devabit on

                <p>{ moment(anniversaryDate).format('LL') }</p>

              </div>
              { endDate && (
                <Some permissions={ ['settings', 'personalInformation'] }>
                  <div className="user-since">
                    End work on devabit
                    <p>{ moment(endDate).format('LL') }</p>
                  </div>
                </Some>
              ) }
              <Some permissions={ ['cvMaker'] }>
                <div className="user-since">
                  <Button type="primary" onClick={ this.onCvMaker }>
                    CV Maker
                  </Button>
                </div>
              </Some>
            </div>
          </Col>
          <Col md={ { span: 14 } } sm={ { span: 24 } }>
            <div className="user-contacts">
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Ім'я:</strong>
                </Col>
                <Col span={ 16 }>{ firstNameNative || '-' }</Col>
              </Row>
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Прізвище:</strong>
                </Col>
                <Col span={ 16 }>{ lastNameNative || '-' }</Col>
              </Row>
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>По-батькові:</strong>
                </Col>
                <Col span={ 16 }>{ surnameNative || '-' }</Col>
              </Row>
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Email:</strong>
                </Col>

                <Col span={ 16 }>{ email || '-' }</Col>

              </Row>
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Skype:</strong>
                </Col>

                <Col span={ 16 }>{ skype || '-' }</Col>

              </Row>
              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Phone number:</strong>
                </Col>

                <Col span={ 16 }>{ phone || '-' }</Col>

              </Row>
              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Personal email:</strong>
                  </Col>
                  <Col span={ 16 }>{ personalEmail || '-' }</Col>

                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Second Phone:</strong>
                  </Col>

                  <Col span={ 16 }>{ secondPhone || '-' }</Col>

                </Row>
              </Some>
              <Row className={ companyAccess ? 'pb-25' : 'pb-50' }>
                <Col span={ 8 }>
                  <strong>Birthday:</strong>
                </Col>

                <Col span={ 16 }>{ moment(birthday).format('LL') }</Col>

              </Row>
              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Address:</strong>
                  </Col>
                  <Col span={ 16 }>{ address || '-' }</Col>
                </Row>
              </Some>
              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Current location:</strong>
                  </Col>
                  <Col span={ 16 }>{ currentLocation || '-' }</Col>

                </Row>
              </Some>

              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Project:</strong>
                </Col>
                <Col span={ 16 }>{ projects || '-' }</Col>
              </Row>

              <Row className="pb-25">
                <Col span={ 8 }>
                  <strong>Gender:</strong>
                </Col>
                <Col span={ 16 }>{ gender || '-' }</Col>
              </Row>

              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="mb-20">
                  <hr className="mt-20 mb-20" />
                  <h3>Languages</h3>
                </Row>
                { languages.map((languageData: any, index: number) => (
                  <Row key={ index } className="pb-25">
                    <Col span={ 8 }>
                      <strong>{ languageData.language }:</strong>
                    </Col>
                    <Col span={ 16 }>{ languageData.code }</Col>
                  </Row>
                )) }
              </Some>
              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="mb-20">
                  <hr className="mt-20 mb-20" />
                  <h3>Contact Person</h3>
                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Name:</strong>
                  </Col>

                  <Col span={ 16 }>{ contactFullName }</Col>

                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Phone number:</strong>
                  </Col>

                  <Col span={ 16 }>{ contactPhone }</Col>

                </Row>
                <Row>
                  <Col span={ 8 }>
                    <strong>Who is this person for you:</strong>
                  </Col>

                  <Col span={ 16 }>{ contactDescription }</Col>

                </Row>
              </Some>
              <Some permissions={ ['settings', 'personalInformation'] }>
                <Row className="mb-20">
                  <hr className="mt-20 mb-20" />
                  <h3>Insurance</h3>
                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Number:</strong>
                  </Col>

                  <Col span={ 16 }>{ insuranceNumber }</Col>
                </Row>
              </Some>
              <Some permissions={ ['settings', 'users'] }>
                <Row className="mb-20">
                  <hr className="mt-20 mb-20" />
                  <h3>Passport data</h3>
                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>ID code:</strong>
                  </Col>
                  <Col span={ 16 }>{ IDcode }</Col>
                </Row>
                <Row className="pb-25">
                  <Col span={ 8 }>
                    <strong>Documents files:</strong>
                  </Col>
                  <Col span={ 16 }>
                    { ' ' }
                    { passportData.map((file: string, index: number) => {
                      const url =
                        `${appConfig.staticFolder + user._id}/${file}`;
                      return (
                        <Row key={ index } gutter={ 20 } className="mb-15">
                          { ' ' }
                          <Col md={ { span: 8 } }>{ file }</Col>
                          <Col md={ { span: 4 } }>
                            { ' ' }
                            <Button
                              onClick={ () => {
                                this.downloadFileUrl(url, file);
                              } }
                            >
                              <Icon type="download" /> Download
                            </Button>
                          </Col>
                        </Row>
                      );
                    }) }
                  </Col>
                </Row>

              </Some>
              <Some permissions={ ['settings', 'personalInformation', 'inventories'] }>
                <Row className="mb-20">
                  <hr className="mt-20 mb-20" />
                  <h3>Inventories</h3>
                </Row>
                { inventories.map((item: any, index: number) => (
                  <Row key={ index } className="pb-25">
                    <Col span={ 6 }>{ item.group.name }</Col>
                    <Col span={ 6 }><strong>BRAND:</strong><br />{ item.brand }</Col>
                    <Col span={ 6 }><strong>SERIAL NUMBER:</strong><br />{ item.serialNumber }</Col>
                    <Col span={ 6 }><strong>MODEL:</strong><br />{ item.model }</Col>
                  </Row>
                )) }
              </Some>
            </div>
          </Col>
        </Row>
      </Row>
    ) : null;
  }
}

export default Profile;
