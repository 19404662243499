import React from "react";
import officeAmenitiesImg from "../../../../assets/imgs/welcomeBook/IMG_6603 1.jpg";
import officeAmenitiesImgMob from "../../../../assets/imgs/welcomeBook/Office_Amenities_mob.jpg";
import "./styles.scss";

function OfficeAmenities() {
  return (
    <div className="officeAmenities ">
      <div className="officeAmenities__image-container">
        <img
          src={officeAmenitiesImg}
          alt="officeAmenities"
          className="officeAmenities__image"
        />
      </div>
      <div className="office-amenities__image-mobile">
        <img src={officeAmenitiesImgMob} alt="office amenities" />
      </div>
      <div className="officeAmenities__text-container">
        <div className="officeAmenities__tittle-container">
          <div className="officeAmenities__tittle super-tittle">Office Amenities</div>
          <div className="officeAmenities__sub-tittle main-text">
            In general, offices are created for work. Yet, our office may be
            used for different purposes.
          </div>
        </div>
        <div className="officeAmenities__points-container secondary-text">
          <ul>
            <li>Lounge area</li>
            <li>Game area (Xbox, PlayStation, table tennis, table football)</li>
            <li>Coffee, tea, cookies, and sweets</li>
            <li>Parking</li>
            <li>Office library</li>
            <li>Green and pet friendly office</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OfficeAmenities;
