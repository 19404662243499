import user from "../routes/user";

export default [
  {
    name: "Home",
    to: user.home.path,
    exact: true,
  },
  {
    name: "Workspace",
    to: user.workspace.path,
    exact: true,
  },
  {
    name: "Employees",
    to: user.employees.path,
    exact: true,
  },
  {
    name: "Newsfeed",
    to: user.newsfeed.path,
    exact: true,
  },
  {
    name: "Entitlement",
    to: user.userWeekends.path,
    exact: true,
  },
  {
    name: "Information",
    to: user.information.path,
    exact: true,
  },
  {
    name: null,
    to: user.approval.path,
    exact: true,
    icon: true,
  },
];
