import * as modal from "../../../services/modal";
import itemRemoveWarning from "../itemRemoveWarning";

interface IProps {
  onSubmit: any
  name: string
}

export const init = ({onSubmit, name}: IProps) => {
  modal.set('removeItem', {
    hash: 'remove-position',
    component: itemRemoveWarning,
    config: {
      title: `Remove ${name}`,
      okLabel: 'Remove',
      onSubmit
    }
  });
};

export const open = (record: any) => {
  modal.setProps('removeItem', record);
  modal.open('removeItem');
};

export const del = () => {
  modal.remove('removeItem');
};

export default {
  init,
  open,
  del
}