import userAPI from '../API/user';
import * as userService from './user';

export const login = async (token: string) => {
  localStorage.setItem('AUTH_TOKEN', `Bearer ${token}`);
  try {
    const res = await userAPI.profile.get();
    userService.set(res.data);
  } catch (e) {
    throw e;
  }
};

export const logout = () => {
  localStorage.setItem('AUTH_TOKEN', '');
  userService.remove();
};

export default {
  login,
  logout
}