import * as React from 'react';
import {Row, Typography, Col} from 'antd';
import {connect} from "react-redux";
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'

import apiWeekends from '../../../../API/weekends';
import IUser from '../../../../interfaces/user';
import TablePagination from '../../../../services/tablePagination';
import columns from "./columns";

const {Title} = Typography;

interface IProps {
  user: IUser,
  fileAvatar: Blob,
  match: any
};

class Profile extends React.Component<IProps, any> {
  private readonly pagination: TablePagination;
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      pagination: {},
      data: {}
    };

    this.pagination = new TablePagination({
      pageSize: 20,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData
    })
  }

  public componentDidMount() {
    this.getData();
  };

  getData = async () => {
    try {
      const res = await apiWeekends.getHistory({
        query: this.pagination.requestParams(),
        params: {id: this.props.match.params.id}
      });
      const {list, pagination, ...data} = res.data;
      this.pagination.value = pagination;
      this.setState({list, data, pagination, loading: false});
    } catch (e) {
      return this.setState({loading: false})
    }
  };

  public render() {
    const {loading, list, data} = this.state;
    return <>
      <Row type="flex" justify="space-between">
        <Col>
          <h2 className="page-header">Absence log</h2>
        </Col>
        <Col>
          {
            data.weekend ? <Title level={3}>Available {data.weekend.name}: {data.weekend.unlimitedDays ? 'Unlimited' : `${data.days} day${data.days !== 1 ? 's' : ''}`}</Title> : null
          }
        </Col>
      </Row>
      <div className="mt-30">
          <Table dataSource={list}
                 loading={loading}
                 rowKey="_id"
                 columns={columns}
                 pagination={this.pagination.tableConfig} />
        <Pagination {...this.pagination.config} />
      </div>
    </>
  }
}

export default connect(({user}: {user: IUser}) => ({user}))(Profile)