import * as api from "./index";
const t = "/table-sector";

export default {
  get: api.get(t),
  create: api.post(t),
  getItem: api.get(`${t}/item/:id`),
  remove: api.del(`${t}/:id`),
  put: api.put(`${t}/:id`),
};
