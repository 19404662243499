import React from "react";
import moment from "moment";
import Avatar from "../../../../components/Avatar";

export default [
  {
    title: '',
    dataIndex: 'assignedUser',
    key: 'avatar',
    width: 100,
    render: (user: any) => <Avatar user={user} size={24} />
  },
  {
    title: 'Employee',
    key: 'uid',
    dataIndex: 'assignedUser',
    render: ({firstName, lastName}: any) => `${firstName} ${lastName}`.trim()
  },
  {
    title: 'Changed By',
    key: 'changedBy',
    dataIndex: 'changedBy',
    render: ({firstName, lastName}: any) => `${firstName} ${lastName}`.trim()
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status: string) => status
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm')
  },
]