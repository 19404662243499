import * as React from 'react';
import {
  Button,
  notification
} from 'antd';
import positionsModal from '../Modals/set/positions';
import removePositionModal from '../Modals/set/removePosition';
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import positionsAPI from '../../API/positions';
import errors from "../../API/error";
import TablePagination from "../../services/tablePagination";
import Settings from "../../layouts/Settings";
import columns from "./columns";

interface IState {
  list: object[],
  pagination: any,
  id: string
  loading: boolean
}

class Positions extends React.Component<any, IState> {
  pagination = new TablePagination();
  state = {
    list: [],
    pagination: {
      total: 0
    },
    loading: true,
    id: ''
  };

  componentDidMount() {
    positionsModal.init({onSubmit: this.handleSubmit});
    removePositionModal.init({onSubmit: this.handleRemove});
    this.getPositions();
  };

  componentWillUnmount(): void {
    positionsModal.remove();
    removePositionModal.del();
  }

  getPositions = async () => {
    try {
      this.setState({loading: true});
      const query: any = this.state.pagination;
      const result = await positionsAPI.get({query: {...query, ...this.pagination.requestParams()}});
      const {list, pagination} = result.data;
      this.pagination.value = pagination;
      this.setState({list, pagination, loading: false})
    } catch (e) {
      errors.handle(e);
      this.setState({list: [], loading: false})
    }
  };

  addPosition = () => positionsModal.open();

  handleSubmit = async ({values, onLoading, onCancel, form, modalProps: mp = {}}: any) => {
    try {
      onLoading(true);
      if (mp._id) {
        await positionsAPI.update(values, {params: {id: mp._id}});
      } else {
        await positionsAPI.create(values);
      }
      await this.getPositions();
      onLoading(false);
      onCancel();
    } catch (e) {
      errors.handle(e, form, {priority: 'form'});
      onLoading(false);
    }
  };

  handleEdit = (item: any) => {
    this.setState({id: item._id});
    positionsModal.open(item);
  };

  handleRemove = async (params: any) => {
    const {modalProps: {_id: id}, onCancel, onLoading} = params;
    try {
      onLoading(true);
      await positionsAPI.remove(null, {params: {id}});
      await this.getPositions();
      notification.success({message: 'Success'});
      onCancel();
    } catch (e) {
      errors.handle(e);
    } finally {
      onLoading(false);
    }
  };

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getPositions();
  };

  render() {
    const {list, loading} = this.state;
    return <Settings location={this.props.location} actionsComponent={
      <Button className="ant-btn-primary" onClick={this.addPosition}>
        Create Position
      </Button>
    }>
        <Table dataSource={list}
               rowKey="_id"
               loading={loading}
               columns={columns({
                 actions: {
                   onEdit: this.handleEdit,
                   onRemove: removePositionModal.open
                 }
               })}
               pagination={this.pagination.tableConfig} />
      <Pagination {...this.pagination.config} onChange={this.changePage} />
    </Settings>
  }
}

export default Positions;