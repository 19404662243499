import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import Head from './Head';
import './styles.scss';
// eslint-disable-next-line import/no-cycle
import userRoutes from '../../../routes/user';

interface Props {
  [key: string]: any;
}

const trialItem = (props: Props): JSX.Element => {
  const momentTrial = moment.utc(props.anniversaryDate).set({ month: new Date(props.anniversaryDate).getMonth() + 3 });
  const linkToProfile = generatePath(userRoutes.employeeProfile.path, { id: props._id });
  return (<div className="nfi-trial nfi-card js-nfi" data-id={ props.keyItem }>
    <Head { ...props } date={ momentTrial.format('DD/MM/YYYY') } />
    <div className="ant-row-flex ant-row-flex-space-between nfi-event-footer pl-20 pr-20">
      <div className="nfi-date">{ moment.utc(props.anniversaryDate).format('DD/MM/YYYY') } - { momentTrial.format('DD/MM/YYYY') }</div>
      <div className="nfi-date nfi-event-view-more">
        <Link
          to={ linkToProfile }
        >
          View more
        </Link>
      </div>
    </div>
  </div>);
};

export default trialItem;
