import * as React from 'react';
import moment from 'moment';
import {
  Collapse,
  Button,
  Col,
  DatePicker,
  Row,
  Icon,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Field, { FormItem } from '../../ui/Field';
import dateConfig from '../../configs/date';
import errors from '../../API/error';
import { IRequestError } from '../../interfaces/error';
import './styles.scss';

const { Panel } = Collapse;

interface IState {
  edate: string[];
  edateDesc: string[];

  anniversaryDate: string;
  loaded: boolean;

}

export default class EvaluationDate extends React.Component<any, IState> {
  constructor(props:any) {
    super(props);
    this.state = {
      edate: [],
      edateDesc: [],
      anniversaryDate: '',
      loaded: false,
    };
  }

  static getDerivedStateFromProps (props: any, state: IState): IState {
    if (props.edate && !state.loaded) {
      return {
        ...state,
        edate: props.edate,
        edateDesc: props.edateDesc ?? [],
        anniversaryDate: props.anniversaryDate,
        loaded: true
      };
    }
    return state;
  }

  static parseFormValues = (values: any = {}): Array<string> => {
    const data = Object
      .keys(values)
      .reduce((acc: string[], key) => {
        if (/^evaluationDate-/.test(key)) {
          // const split = key.split('-');
          // const id: string = split.pop() || '';
          // const prop: 'evaluationDate' = split[0] as 'evaluationDate';
          acc.push(moment(values[key]).toString());
          return acc;
        }
        return acc;
      }, []);

    return Object.values(data);
  };

  static parseFormValuesDesc = (values: any = {}): Array<string> => {
    const data = Object
      .keys(values)
      .reduce((acc: string[], key) => {
        if (/^evaluationDateDesc-/.test(key)) {
          acc.push(values[key]);
          return acc;
        }
        return acc;
      }, []);

    return Object.values(data);
  };

  static isValid = (values: { [name: string]: any } = {}, form: any): boolean => {
    const _errors: {[key: string]: string} = {};
    let previustDate = values.anniversaryDate;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key) && /^evaluationDate-/.test(key)) {
        if (moment(new Date(values[key])).isBefore(new Date(previustDate))) {
          _errors[key] = 'Can\'t be less than previous date or Employment date';
        }
        previustDate = values[key];
      }
    }

    const isInvalid = Boolean(Object.keys(_errors).length);

    if (isInvalid) {
      const e: IRequestError = {
        name: '',
        message: '',
        response: { status: 400, data: { errors: _errors } }
      };
      errors.handle(e, form, { priority: 'form' });
    }

    return !isInvalid;
  };

  add = (): void => {
    const { edate, anniversaryDate } = this.state;
    let currDate = moment().toString();
    const endTrialPeriod = 3;

    if (anniversaryDate.length > 0) {
      switch (edate.length) {
        case 0:
          currDate = moment(anniversaryDate).add(endTrialPeriod, 'M').toISOString();
          break;
        case 1:
          currDate = moment(anniversaryDate).add(1, 'y').toISOString();
          break;

        default:
          currDate = moment(edate[edate.length - 1]).add(1, 'y').toISOString();
          break;
      }
    }
    this.setState({
      edate: [...edate, currDate]
    });
  };

  remove = (id: number): void => {
    const { edate, edateDesc } = this.state;
    const newVal = edate.filter((v: string, ind: number) => ind !== id);
    const newValDesc = edateDesc.filter((v: string, ind: number) => ind !== id);
    this.setState({
      edate: newVal,
      edateDesc: newValDesc
    });
  };

  handleOnChange = (t: number, val: { toISOString: () => string }): void => {
    const { edate } = this.state;
    if (val) {
      edate[t] = val.toISOString();
    }
    this.setState({
      edate
    });
  };

  handleOnChangeDesc = (t: number, val: { target: { value: string } }): void => {
    const { edateDesc } = this.state;
    if (val) {
      edateDesc[t] = val.target.value;
    }
    this.setState({
      edateDesc
    });
  };


  render() {
    const { edate, edateDesc, loaded } = this.state;
    let title = 'Next Evaluation Date';
    if (edate.length > 0) {
      title += ` at - ${moment(edate[edate.length - 1]).format('DD/MM/YYYY')}`;
    }

    return (

      <Collapse
        bordered={ false }
        defaultActiveKey={ edate.length === 0 ? '1' : '' }
        >
        <Panel header={ title } key="1" showArrow={ true }>

          <FormItem label={ null }>
            { loaded && Boolean(edate && edate.length) &&
              edate.map((item: any, id: number) => {
                // const { isNew } = item;
                const isNew = false;
                const key = `${isNew ? 'new-' : ''}${id}`;

                return (<Row gutter={ 20 } key={ id }>
                  <Col md={ { span: 10 } }>
                    <Field
                      label='Date'
                      name={ `evaluationDate-${key}` }
                      placeholder="evaluation date"
                      defaultValue={ moment(item) }
                      onChange={ this.handleOnChange.bind(this, id) }
                      >
                      <DatePicker format={ dateConfig.formatHolidayDatePicker }  />
                    </Field>
                  </Col>
                  <Col md={ { span: 12 } }>
                    <Field
                      label='Goals'
                      name={ `evaluationDateDesc-${key}` }
                      placeholder="evaluation date description"
                      defaultValue={ edateDesc && edateDesc[id] ? edateDesc[id] : '' }
                      onChange={ this.handleOnChangeDesc.bind(this, id) }
                      >
                      <TextArea rows={ 2 } />
                    </Field>
                  </Col>
                  <Col md={ { span: 2 } } className='mt-30'>
                    <span className="cursor" onClick={ this.remove.bind(null, id) }>
                      <Icon type="close-circle" />
                    </span>
                  </Col>
                </Row>);
              }) }
            <Row type="flex">
              <Button onClick={ this.add }><Icon type="plus" />Add</Button>
            </Row>
          </FormItem>

        </Panel>
      </Collapse>
    );
  }
}
