import React from "react";

interface Props {
  color?: string;
}

export default ({ color = "#191F2D" }: Props) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.97461 12.2819L6.68889 13.5677L1.35329 8.23205L0.0675752 6.94633L1.35329 5.66062L6.68889 0.325012L7.97461 1.61073L2.639 6.94633L7.97461 12.2819Z"
      fill={color}
    />
  </svg>
);
