import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index'


const composeEnhancers =
  (typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === 'development') ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
const enhancer = composeEnhancers(
  applyMiddleware(),
);

export const store = createStore(rootReducer, enhancer);
export default store
