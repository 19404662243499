import React from 'react';
// eslint-disable-next-line import/no-cycle
import BirthdayItem from './BirthdayItem';
// eslint-disable-next-line import/no-cycle
import EmploymentItem from './EmploymentItem';
// eslint-disable-next-line import/no-cycle
import EventItem from './EventItem';
// eslint-disable-next-line import/no-cycle
import TrialItem from './TrialItem';

type DataType = 'event' | 'birthday' | 'employment' | 'trial';

interface Props {
  item: {
    type: DataType;
    height?: number;
    [key: string]: any;
  };
  [key: string]: any;
}

const item = (props: Props): (JSX.Element | null) => {
  const { item, ...rest } = props;
  const { type } = item;
  if (type === 'event') {
    return <EventItem { ...rest } { ...item } />;
  } if (type === 'birthday') {
    return <BirthdayItem { ...rest } { ...item } />;
  } if (type === 'employment') {
    return <EmploymentItem { ...rest } { ...item } />;
  } if (type === 'trial') {
    return <TrialItem { ...rest } { ...item } />;
  }
  return null;
};

export default item;
