import * as React from 'react';
import {
  Button,
  Spin,
  Input
} from 'antd';
import errors from '../../API/error';
import APIlogin from "../../API/auth";
import Form from '../../noui/Form';
import Field from '../../ui/Field';
import NavLink from '../../components/NavLink';
import guest from '../../routes/guest';
import auth from '../../services/auth';
import userRoutes from "../../routes/user";
import "../Login/style.scss"
import * as qs from "query-string";
import LogoImg from "../../assets/imgs/logo.png";

class CreateNewPassword extends React.Component<any, any> {

  private unmount = false;

  constructor(props: any) {
    super(props);
    this.state = {
      code: '',
      email: '',
      loading: false
    };
  }

  componentDidMount() {
    const {search = ''} = this.props.location;
    const q: any = qs.parse(search);
    if (!search || !q.code || !q.email) {
      this.props.history.push(guest.login.path);
    } else {
      this.setState({
          code: decodeURIComponent(q.code),
          email: decodeURIComponent(q.email)
        }
      )
    }
  }

  public componentWillUnmount() {
    this.unmount = true;
  }

  handleSubmit = async ({values, form}: any) => {
    const {password, confirmPassword} = values;
    if (password !== confirmPassword) {
      return errors.setFormErrors(form, {confirmPassword: 'Must be the same as password'})
    }
    try {
      this.loadingTrigger();
      const {code, email} = this.state;
      await APIlogin.newPassword({
        password, code, confirmPassword, email
      });
      const loginRes = await APIlogin.base({email, password});

      await auth.login(loginRes.data.token);
      this.props.history.push(userRoutes.home.path);
      this.loadingTrigger(false)
    } catch (e) {
      errors.handle(e);
      this.loadingTrigger(false)
    }
  };

  loadingTrigger = (loading: boolean = true) => {
    if (!this.unmount) {
      this.setState({loading});
    }
  }

  render() {
    const {loading} = this.state;
    return (
      <div id="register-form">
          <div className="screen-login">
              <a href="/" className="logo-register">
                  <img src={LogoImg} alt="" />
              </a>
              <Spin tip="Loading..." spinning={loading}>
                  <h1>Create your password</h1>
                  <Form onSubmit={this.handleSubmit}>
                      <Field name="password"
                             validation="password"
                             type="password"
                             label="New password">
                          <Input.Password />
                      </Field>
                      <Field name="confirmPassword"
                             validation="password"
                             type="password"
                             label="Confirm new password">
                          <Input.Password />
                      </Field>

                      <Button className="ant-btn btn-register ant-btn-primary" htmlType="submit">Submit</Button>
                      <div className="mt-40"><NavLink className="btn-form-link"
                                                      to={guest.login.path}>Back to Login Page</NavLink>
                      </div>
                  </Form>
              </Spin>
          </div>
      </div>
    );
  }
}

export default CreateNewPassword