import React, { useCallback, useEffect, useRef, useState } from "react";

interface IProps {
  _class: string;
  children: JSX.Element;
  style?: any
}

function Scrollable(props: IProps) {
  const [state, setState] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = ref.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        el.scrollTo({
          left: el.scrollLeft + e.deltaY * 6,
          behavior: "smooth",
        });
      };

      el.addEventListener("wheel", onWheel);

      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  const { _class, children, style } = props;
  const Child = React.Children.only(children);
  const onTouchMove = useCallback(
    (e: any) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        return;
      }
      const { clientX, scrollX, isScrolling } = state;
      if (isScrolling) {
        ref.current!.scrollLeft = scrollX + e.touches[0].clientX - clientX;
        setState({
          ...state,
          scrollX: e.touches[0].clientX,
          clientX: e.touches[0].clientX,
        });
      }
    },
    [state]
  );

  const onTouchEnd = useCallback(
    (e: any) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        return;
      }

      setState({
        ...state,
        isScrolling: false,
      });
    },
    [state]
  );
  const onTouchStart = useCallback(
    (e: any) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        return;
      }

      setState({
        ...state,
        isScrolling: true,
      });
    },
    [state]
  );
  useEffect(() => {
    document.addEventListener("touchstart", onTouchStart);
    document.addEventListener("touchend", onTouchEnd);
    document.addEventListener("touchmove", onTouchMove);
    return () => {
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("touchmove", onTouchMove);
    };
  }, [onTouchEnd, onTouchStart, onTouchMove]);

  return (
    <div
      ref={ref}
      className={_class}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      style={style}
    >
      {Child}
    </div>
  );
}

export default Scrollable;
