import React from 'react';
import { Row, Col, Select } from 'antd';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import Timeline from './Timeline';
// eslint-disable-next-line import/no-cycle
import Today from './Today';
import statisticsAPI from '../../API/statistics';
import holidaysAPI from '../../API/holidays';
// eslint-disable-next-line import/no-cycle
import Item from './Item';
import LoadingItem from './LoadingItem';

import './styles.scss';
import Field from '../../ui/Field';
import { IStore } from '../../interfaces';

type DataType = 'event' | 'birthday' | 'employment' | 'trial';

interface DataItem {
  type: DataType;
  nfDate: string;

  [key: string]: any;
}

interface Data {
  [id: string]: DataItem;
}

interface InfinityLoaderProps {
  innerHeight: number;
  scrollY: number;
  pageHeight: number;
}

interface MaxMinDates {
  events?: {
    max?: string;
    min?: string;
  };
  user?: {
    minAnniversaryDate?: string;
    minBirthday?: string;
  };
}

interface State {
  loading: boolean;
  loaded: boolean;
  loadBlocks: {
    top: boolean;
    bottom: boolean;
  };
  type: string;
  // contains loaded months and ordering items
  dataByMonth: {
    [date: string]: string[];
  };
  timeLineDate: Moment;
}

interface Props {
  [key: string]: any;
}

const MAX_LIMIT = 100;

// If size became less than the number, data would be loaded
const INFINITY_LOAD_TOP = 500;
const INFINITY_LOAD_BOTTOM = 500;

const DURATION_TIMELINE_UPDATE = 100;

const FIXED_HEADER_CLASS = 'nf-fixed-header';
const SHOW_OPTIONS = [
  {
    name: 'All Events',
    v: 'all'
  },
  {
    name: 'Events',
    v: 'event'
  },
  {
    name: 'Birthdays',
    v: 'birthday'
  },
  {
    name: 'Anniversaries',
    v: 'anniversary'
  }
];

const { Option } = Select;

class Newsfeed extends React.Component<Props, State> {
  loadDataTrigger: any = null;

  data: Data = {};

  activeInfinity = true;

  maxMinEventDates: MaxMinDates = {};

  durationDate = Date.now();

  constructor(props: any) {
    super(props);
    this.state = {
      type: 'all',
      // eslint-disable-next-line react/no-unused-state
      loading: true,
      loaded: false,
      timeLineDate: moment.utc().startOf('month'),
      dataByMonth: {},
      loadBlocks: {
        top: true,
        bottom: true
      }
    };
  }

  componentWillMount() {
    this.documentOverflow('hidden');

    const { user: { companyAccess = {} } } = this.props;
    if (companyAccess.personalInformation) {
      const isExist = SHOW_OPTIONS.filter((item) => item.v === 'trial');
      if (isExist.length === 0) {
        SHOW_OPTIONS.push(
          {
            name: 'Trial Period',
            v: 'trial'
          }
        );
      }
    }
  }

  componentDidMount() {
    if (!document.body.classList.contains(FIXED_HEADER_CLASS)) {
      document.body.classList.add(FIXED_HEADER_CLASS);
    }
    window.addEventListener('scroll', this.handleScroll);
    this.getMaxMin();
    this.getData();
  }

  componentWillUnmount(): void {
    this.documentOverflow();
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove(FIXED_HEADER_CLASS);
  }

  getMaxMin = async () => {
    try {
      const { data } = await holidaysAPI.getMinMax();
      this.maxMinEventDates = data;
    } catch (e) {
      return null;
    }
  };

  handleScroll = () => {
    const { innerHeight, scrollY } = window;
    const params = {
      innerHeight,
      scrollY,
      pageHeight: this.getPageHeight()
    };
    this.infinityLoadDataBottom(params);
    this.infinityLoadDataTop(params);
    this.changeActiveTimeline();
  };

  checkNextStep = (date: Readonly<Moment>) => {
    const { type } = this.state;
    const { events = {} } = this.maxMinEventDates;
    const { max, min } = events;

    if (type === 'event') {
      if (min && max) {
        const momentMin = moment.utc(min).startOf('month');
        const momentMax = moment.utc(max).endOf('month');

        return date.diff(momentMin, 'days') >= 0 && momentMax.diff(date, 'days') >= 0;
      }
      return false;
    }
    return true;
  };

  infinityLoadDataBottom = (params: Readonly<InfinityLoaderProps>) => {
    if (params.pageHeight - (params.scrollY + params.innerHeight) <= INFINITY_LOAD_BOTTOM && this.activeInfinity) {
      const sorted = this.getSortedDataKeys();
      const last = sorted[sorted.length - 1];
      const momentLast = moment.utc(last, 'YYYYMMDD')
        .add(1, 'month');
      this.triggerLoadData(momentLast);
    }
  };

  infinityLoadDataTop = (params: Readonly<InfinityLoaderProps>) => {
    if (params.scrollY <= INFINITY_LOAD_TOP && this.activeInfinity) {
      const sorted = this.getSortedDataKeys();
      const first = sorted[0];
      const momentFirst = moment.utc(first, 'YYYYMMDD')
        .subtract(1, 'month');
      this.triggerLoadData(momentFirst, true);
    }
  };

  triggerLoadData = (date: Readonly<Moment>, scrollTrigger?: boolean) => {
    this.loadDataTrigger && clearTimeout(this.loadDataTrigger);
    this.loadDataTrigger = setTimeout(() => {
      this.loadData(date, scrollTrigger);
    }, 100);
  };

  reloadSizes = (params: any) => {
    this.setPostsTopPositions();
    if (params.offsetTop && params.id) {
      const afterItem: HTMLDivElement | null = document.querySelector(`[data-id="${params.id}"]`);
      if (afterItem) {
        const diff2 = window.scrollY - afterItem.offsetTop;
        this.scrollTop(window.scrollY + params.diff - diff2);
      }
    }
    this.activeInfinity = true;
  };

  loadData = async (date: Readonly<Moment>, scrollTrigger?: boolean) => {
    const loadBlocks = this.getStateLoadBoxes(this.state.dataByMonth);
    if ((!loadBlocks.top && scrollTrigger) || (!scrollTrigger && !loadBlocks.bottom)) {
      this.setState({ loadBlocks }, () => {
        this.setPostsTopPositions();
      });
      return;
    }
    this.activeInfinity = false;
    try {
      const items = await this.getMonthData(date);
      const itemsToLoaded: State['dataByMonth'] = this.getItemsToLoadedList(date, items);
      const params = {
        offsetTop: 0,
        scrollY: 0,
        diff: 0,
        id: ''
      };
      const hasItems = items && items.length > 0;
      const stateData = {
        dataByMonth: {
          ...this.state.dataByMonth, ...itemsToLoaded
        }
      };
      if (hasItems) {
        if (scrollTrigger) {
          const el: HTMLDivElement | null = document.querySelector('.js-nfi');
          if (el) {
            const { id } = el.dataset;
            params.offsetTop = el.offsetTop;
            params.scrollY = window.scrollY;
            params.id = id || '';
            params.diff = params.scrollY - params.offsetTop;
          }
        }
        this.setState(stateData, () => {
          this.reloadSizes(params);
        });
      } else {
        this.setState(stateData, () => {
          const result = this.getStateLoadBoxes(stateData.dataByMonth);
          if ((result.top && scrollTrigger) || (!scrollTrigger && result.bottom)) {
            this.loadData(date.clone().add(scrollTrigger ? -1 : 1, 'month'), scrollTrigger);
          } else {
            this.setPostsTopPositions();
          }
        });
      }
    } catch (e) {
      this.activeInfinity = true;
    }
  };

  changeActiveTimeline = () => {
    const date = Date.now();
    if (date - this.durationDate < DURATION_TIMELINE_UPDATE) {
      return;
    }
    this.durationDate = date;
    const { innerHeight, scrollY } = window;

    const centerPoint = scrollY + innerHeight / 2;
    const { timeLineDate } = this.state;
    const item = Object.values(this.data).find((value: any) => {
      const { offsetTop, height, nfDate } = value;

      // Element in the center of the screen
      if (offsetTop < centerPoint && centerPoint < offsetTop + height) {
        const momentNFDate = moment.utc(nfDate);
        const diffDate = momentNFDate.diff(timeLineDate, 'days');
        return diffDate !== 0;
      }
      return false;
    });
    if (item) {
      this.setState({ timeLineDate: moment.utc(item.nfDate) });
    }
  };

  getSortedDataKeys = () => {
    const { dataByMonth } = this.state;
    return Object.keys(dataByMonth).sort((a, b) => +a - +b);
  };

  getQuery = (date: Readonly<Moment>) => {
    const from = date.clone().startOf('month').format('YYYY-MM-DD');
    const to = date.clone().endOf('month').format('YYYY-MM-DD');
    return {
      limit: MAX_LIMIT,
      from,
      to
    };
  };

  decorateRequest = (method: any) => async (date: Readonly<Moment>) => {
    const {
      data: { list }
    } = await method({
      query: this.getQuery(date)
    });
    return list;
  };

  getBirthdays = this.decorateRequest(statisticsAPI.get);

  getEmployments = this.decorateRequest(statisticsAPI.getHired);

  getEvents = this.decorateRequest(holidaysAPI.get);

  getTrial = this.decorateRequest(statisticsAPI.getTrial);

  getMonthData = async (date: Readonly<Moment>) => {
    const { type } = this.state;
    const events = ['all', 'event'].includes(type) ? await this.getEvents(date) : [];
    const birthdays = ['all', 'birthday'].includes(type) ? await this.getBirthdays(date) : [];
    const employments = ['all', 'anniversary'].includes(type) ? await this.getEmployments(date) : [];
    const trial = ['trial'].includes(type) ? await this.getTrial(date) : [];
    return this.normalizeMonthData({
      events, birthdays, trial, employments, date
    });
  };

  setToData = (elements: any[], props: {
    type: DataType;
    nfDate: string;
    year: number;
  }) => elements.map((el: any) => {
    const keyItem = `${props.type}_${el._id}_${props.year}`;
    const item = { ...el, ...props, keyItem };
    if (!this.data[keyItem]) {
      this.data[keyItem] = item;
    }
    return item;
  });

  getCompareDate = (el: any, year: number): Moment => {
    const celebrationDate = el.anniversaryDate || el.birthday;
    const mDate = moment.utc(el.from || celebrationDate).startOf('day');
    if (celebrationDate) {
      mDate.set({ year });
    }
    return mDate;
  };

  sortEventsByDate = (date: Readonly<Moment>) => (a: any, b: any) => {
    const currentYear = date.year();
    const momentA = this.getCompareDate(a, currentYear);
    const momentB = this.getCompareDate(b, currentYear);

    return momentA.diff(momentB, 'days');
  };

  normalizeMonthData = (params: {
    events: any[];
    birthdays: any[];
    trial: any[];
    employments: any[];
    date: Readonly<Moment>;
  }) => {
    const nfDate = params.date.toISOString();
    const year = params.date.year();

    const parsedEvents = this.setToData(params.events, {
      type: 'event',
      nfDate,
      year
    });
    const parsedBirthday = this.setToData(params.birthdays, {
      type: 'birthday',
      nfDate,
      year
    });
    const parsedEmployment = this.setToData(params.employments, {
      type: 'employment',
      nfDate,
      year
    });

    const parsedTrial = this.setToData(params.trial, {
      type: 'trial',
      nfDate,
      year
    });

    const full = [...parsedEvents, ...parsedBirthday, ...parsedEmployment, ...parsedTrial];
    return full.sort(this.sortEventsByDate(params.date));
  };

  getItemsToLoadedList = (date: Readonly<Moment>, list: any[]): State['dataByMonth'] => {
    const key = date.format('YYYYMMDD');
    const result: State['dataByMonth'] = {
      [key]: []
    };
    if (list && list.length) {
      result[key] = list.map((el: any) => el.keyItem);
    }
    return result;
  };

  getPageHeight = () => {
    const { body } = document;
    const html = document.documentElement;

    return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
  };

  setPostsTopPositions = () => {
    // receives all news feed items
    const posts = document.getElementsByClassName('js-nfi');
    for (let i = 0; i < posts.length; i++) {
      const { offsetTop, dataset } = posts[i] as HTMLDivElement;
      const { height } = (posts[i] as HTMLDivElement).getBoundingClientRect();
      const { id } = dataset;

      if (id && this.data[id]) {
        this.data[id].offsetTop = offsetTop;
        this.data[id].height = height;
      }
    }
  };

  /**
   * Loads data with surround elements
   * */
  getData = async () => {
    try {
      const { timeLineDate } = this.state;
      const items = await this.getMonthData(timeLineDate);
      let itemsToLoaded: State['dataByMonth'] = this.getItemsToLoadedList(timeLineDate, items);
      let totalBottom = 0;
      let totalTop = 0;
      let loops = 1;
      let loadBlocksCheck = {
        top: true,
        bottom: true
      };
      let continueLoops = 2;

      do {
        const momentPrevMonth = timeLineDate.clone().subtract(loops, 'month');
        continueLoops = 2;
        loadBlocksCheck = this.getStateLoadBoxes(itemsToLoaded);

        if (loadBlocksCheck.top && totalTop < 3) {
          const prevItems = await this.getMonthData(momentPrevMonth);
          totalTop += prevItems.length;
          itemsToLoaded = {
            ...this.getItemsToLoadedList(momentPrevMonth, prevItems),
            ...itemsToLoaded,
          };
        } else {
          continueLoops -= 1;
        }

        const momentNextMonth = timeLineDate.clone().add(loops, 'month');
        if (loadBlocksCheck.bottom && totalBottom < 5) {
          const nextItems = await this.getMonthData(momentNextMonth);
          totalBottom += nextItems.length;
          itemsToLoaded = {
            ...itemsToLoaded,
            ...this.getItemsToLoadedList(momentNextMonth, nextItems)
          };
        } else {
          continueLoops -= 1;
        }

        loops += 1;
      } while (continueLoops > 0);

      const loadBlocks = this.getStateLoadBoxes(itemsToLoaded);
      this.setState({
        dataByMonth: itemsToLoaded,
        loadBlocks,
        loading: false,
        loaded: true
      }, () => {
        this.setPostsTopPositions();
        this.scrollToDate(moment.utc().startOf('day'), true);
        this.documentOverflow();
      });
    } catch (e) {
      return false;
    }
  };

  /**
   * Execute to get the post offset in the page
   * */
  getOffsetToScroll = (key: string, startDate?: Readonly<Moment>): null | number => {
    const yearStr = key.slice(0, 4);
    const { dataByMonth } : { dataByMonth: State['dataByMonth'] } = this.state;
    let itemId = '';

    if (dataByMonth[key] && dataByMonth[key].length) {
      if (startDate) {
        for (let i = 0; i < dataByMonth[key].length; i++) {
          const elId = dataByMonth[key][i];
          const momentDate = this.getCompareDate(this.data[elId], +yearStr);

          if (momentDate.diff(startDate, 'days') >= 0) {
            itemId = dataByMonth[key][i];
            break;
          }
        }
      } else {
        [itemId] = dataByMonth[key];
      }
    }

    return itemId ? (this.data[itemId].offsetTop - 162) : null;
  };

  /**
   * Scroll to first matched post according to selected date
   * If it doesn't find any post on the target date, it tries to take from the next one.
   * Use strict to scroll to some targeted date
   * */
  scrollToDate = (date: Readonly<Moment>, strict?: boolean) => {
    let loops = 0;

    do {
      const dateKey: string = date.clone()
        .startOf('month')
        .add(loops, 'month')
        .format('YYYYMMDD');
      const offset = this.getOffsetToScroll(dateKey, strict ? date : undefined);

      loops += 1;
      if (offset !== null) {
        return this.scrollTop(offset);
      } if (loops >= 12) {
        return;
      }
    } while (true);
  };

  /**
   * Just to avoid page jumping
   * */
  documentOverflow = (overflow = '') => {
    document.body.style.overflow = overflow;
  };

  scrollTop = (top = 0) => {
    window.scrollTo(0, top);
  };

  getListToRender = () => {
    const { dataByMonth } = this.state;
    const items = Object.entries(dataByMonth);
    return items.sort(([a], [b]) => +a - +b)
      .reduce((res: string[], item: any) => {
        res = [...res, ...item[1]];
        return res;
      }, []);
  };

  /**
   * Executes on timeline date click
   * */
  handleTimelineClick = async (date: Readonly<Moment>) => {
    this.activeInfinity = false;
    const { timeLineDate, dataByMonth, type } = this.state;
    const wayToLoop = date.diff(timeLineDate, 'month') > 0 ? 1 : -1;
    let data: any = {};
    let loops = wayToLoop;
    let loaded = 0;
    let continueLoops = true;
    let targetDateLoaded = false;

    // Loads clicked month or the next months till some items will be loaded
    do {
      const dateCloned = timeLineDate.clone().add(loops, 'month');

      loops += wayToLoop;
      const key = dateCloned.format('YYYYMMDD');

      const isSame = dateCloned.isSame(date);
      if (isSame) {
        targetDateLoaded = true;
      }

      if ((dataByMonth as any)[key]) {
        if (isSame) {
          break;
        }
        continue;
      }

      const items = await this.getMonthData(dateCloned);
      const itemsToLoaded: State['dataByMonth'] = this.getItemsToLoadedList(dateCloned, items);
      data = { ...data, ...itemsToLoaded };

      const loadedBrakes = this.getStateLoadBoxes(data);

      if (targetDateLoaded) {
        loaded += items.length;
        if (loaded >= 5 || (loops > 0 && !loadedBrakes.bottom) || (loops < 0 && !loadedBrakes.top)) {
          continueLoops = false;
        }
      }
    } while (continueLoops);

    const storeDataByMonth = (type === 'trial' ? { ...data } : { ...this.state.dataByMonth, ...data });
    const loadBlocks = this.getStateLoadBoxes(storeDataByMonth);

    this.setState({
      loadBlocks,
      dataByMonth: storeDataByMonth,
      timeLineDate: date.clone()
    }, () => {
      this.setPostsTopPositions();
      this.scrollToDate(date);
      this.activeInfinity = true;
    });
  };

  getStateLoadBoxes = (data: State['dataByMonth']): State['loadBlocks'] => {
    const sorted = Object.keys(data).sort((a, b) => +a - +b);
    const { type } = this.state;
    const { events = {}, user = {} } = this.maxMinEventDates;

    const momentFirst = moment.utc(sorted[0], 'YYYYMMDD').startOf('day');
    const momentLast = moment.utc(sorted[sorted.length - 1], 'YYYYMMDD').startOf('day');

    const momentEvMin = events.min ? moment.utc(events.min).startOf('month') : null;
    const momentEvMax = events.max ? moment.utc(events.max).startOf('month') : null;

    const momentUsAnniversary = user.minAnniversaryDate ? moment.utc(user.minAnniversaryDate).startOf('month') : null;
    const momentUsBirthday = user.minBirthday ? moment.utc(user.minBirthday).startOf('month') : null;

    const result: State['loadBlocks'] = {
      bottom: true,
      top: true
    };

    if (type === 'all') {
      const dates = [
        momentEvMin,
        momentUsAnniversary,
        momentUsBirthday
      ].filter((d) => d !== null);
      const min = moment.min(dates as Moment[]);
      if (min.diff(momentFirst, 'days') >= 0) {
        result.top = false;
      }
    } else if (type === 'event') {
      if (momentEvMin && momentEvMax) {
        if (momentEvMin.diff(momentFirst, 'days') >= 0) {
          result.top = false;
        }
        if (momentLast.diff(momentEvMax, 'days') >= 0) {
          result.bottom = false;
        }
      } else {
        result.top = false;
        result.bottom = false;
      }
    } else if (type === 'birthday' && (!momentUsBirthday || momentUsBirthday.diff(momentFirst, 'days') >= 0)) {
      result.top = false;
    } else if (type === 'anniversary' && (!momentUsAnniversary || momentUsAnniversary.diff(momentFirst, 'days') >= 0)) {
      result.top = false;
    } else if (type === 'trial') {
      result.top = false;
      result.bottom = false;
    }

    return result;
  };

  handleChangeType = (type: string) => {
    this.data = {};
    this.setState({
      timeLineDate: moment.utc().startOf('month'),
      dataByMonth: {},
      loading: true,
      type
    }, this.getData);
  };

  render(): JSX.Element {
    const {
      loaded,
      type,
      timeLineDate,
      loadBlocks
    } = this.state;

    const items = this.getListToRender();

    return (<>
      <div className="nf">
        <Row
          className="nf-title pb-15"
          type="flex"
          justify="space-between">
          <Col span={ 18 }>
            <h2 className="page-header">Upcoming events</h2>
          </Col>
          <Col span={ 6 }>
            <Row type="flex" justify="end">
              <Field
                name="show"
                disableBottomMargin
                onChange={ this.handleChangeType }
                disableDecorator>
                <Select value={ type }>
                  {
                    SHOW_OPTIONS.map(({ name, v }: any, index: number) =>
                      (<Option
                        key={ index }
                        value={ v }>{ name }</Option>))
                  }
                </Select>
              </Field>
            </Row>
          </Col>
        </Row>
        {
          loaded ? <div>
            {
              loadBlocks.top ? <LoadingItem /> : null
            }
            {
              items && items.length > 0
                ? items.map((key: string, index: number) => this.data[key]
                  ? <Item item={ this.data[key] } key={ index } />
                  : null)
                : null
            }
            {
              loadBlocks.bottom ? <LoadingItem /> : null
            }
          </div> : null
        }
      </div>
      <Timeline
        loading={ !loaded }
        onClick={ this.handleTimelineClick }
        activeDate={ timeLineDate } />
      <Today />
    </>);
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(Newsfeed);

