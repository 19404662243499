import * as api from './index';
const P = '/user';

export default {
  profile: {
    get: api.get(`${P}/profile`),
    update: api.post(`${P}/update-profile`, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }),
    changePassword: api.post('/user/change-password'),

  },
  weekends: {
    get: api.get(`${P}/weekends/list`)
  }
}