import * as React from 'react';
import {Button, Icon, Modal, Row, Spin} from 'antd';
import Form, {FormContext} from '../noui/Form';


class ModalContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    
    this.state = {
      visible: false,
      params: {},
      loading: false
    };
  }
  
  public componentDidMount() {
    const {visible, ...params} = this.props;
    this.setState({visible: !!visible, params});
  }

  static getDerivedStateFromProps(newProps: any, state: any) {
    const {visible: newVisible, ...params} = newProps;
    const {visible} = state;
    if (typeof newVisible === "boolean" && visible !== newVisible) {
      return {
        visible: newVisible,
        loading: false,
        params
      }
    }
    return null
  }
  
  public render() {
    const {
      children: Children = null,
      title,
      actions,
      openAction,
      openBtnLabel,
      closeIcon = true,
      okLabel = 'Save',
      disableForm = false,
      cancelLabel = 'Cancel',
      okButtonProps = {},
      openButtonProps = {},
      modalWidth,
      wrapClassName,
      ...rest
    } = this.state.params;
    const {footer} = this.props;
    const {visible, loading} = this.state;
    let modalActions: any = actions;
    const defaultBtns = [
      <Button key="cancel-btn"
              onClick={this.onCancel}>{cancelLabel}</Button>,
      <Button key="submit-btn"
              className="ant-btn-primary"
              disabled={loading}
              htmlType="submit" {...okButtonProps}>{okLabel}</Button>
    ];
    
    if (actions === undefined) {
      modalActions = defaultBtns;
    }
    
    const innerContentFunction = (context: any) => {
      return visible ?
        <>
          {
            title ? typeof title === 'function' ? title()
              : <div className={'title-modal-wrapper'}><h2 className="title-modal">{title}</h2></div>
              : null
          }
          {
              (({form}:{form: any}, children) =>
                <div className={'modal-body-wrapper'}>{children}</div>
              )(context,
                <>
                  {
                    typeof Children === 'function'
                      ? <Children form={context.form} onSubmit={this.props.onSubmit}/>
                      : Children
                  }
                  {
                    visible && footer === undefined && modalActions ?
                      <Row className={'mt-45'} type="flex" justify="end">{
                        modalActions.map((item: any, index: number) => {
                          return <div className="ml-15" key={index}>{
                            typeof item === 'function' ?
                              item({form: context.form, onCancel: this.onCancel})
                              : item
                          }
                          </div>
                        })
                      }</Row>
                      : null
                  }
                </>
              )
          }
          
        </>
        : null
    };
    
    return <React.Fragment>
      {
        !openAction && typeof openBtnLabel === 'string'
          ? <Button {...openButtonProps} onClick={this.onOpen}>{openBtnLabel}</Button>
          : typeof openBtnLabel === 'function'
          ? openBtnLabel(this.onOpen)
          : null
      }
      {
        openAction ? openAction
          : null
      }
      <Modal
        visible={visible}
        onCancel={this.onCancel}
        footer={null}
        closable={null}
        width={modalWidth}
        wrapClassName={wrapClassName}
        {...rest}
      >
        {closeIcon ?
          <button type="button" onClick={this.onCancel} aria-label="Close" className="ant-modal-close">
                      <span className="ant-modal-close-x">
                         <Icon type="close"/>
                      </span>
          </button>
          : null}
        
        <Spin tip="Loading..." spinning={loading}>
          {
            disableForm
              ? innerContentFunction({})
              : <Form onSubmit={this.onSubmit}>
                <FormContext.Consumer>
                  {
                    innerContentFunction
                  }
                </FormContext.Consumer>
              </Form>
          }
        </Spin>
      </Modal>
    </React.Fragment>
  };
  
  private onOpen = () => {
    this.setState({visible: true})
  };
  
  private onClose = () => {
    this.setState({visible: false})
  };
  
  private onCancel = () => {
    this.setState({visible: false}, () => {
      const {onCancel} = this.props;
      if (typeof onCancel === 'function') {
        onCancel()
      }
    });
  };
  
  private handleLoading = (loading = true) => {
    this.setState({loading})
  };
  
  private onSubmit = (data: any) => {
    const {onSubmit, modalProps} = this.props;
    if (typeof onSubmit === 'function') {
      onSubmit({
        ...data,
        modalProps,
        onCancel: this.onCancel,
        onLoading: this.handleLoading
      })
    }
  };
  
}

export default ModalContainer