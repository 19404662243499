import * as api from "./index";
const t = "/booking-table-request";

export default {
  getMyRequest: api.get(`${t}/my-request`),
  create: api.post(t),
  getTableRequest: api.get(`${t}/table`),
  remove: api.del(`${t}/:id`),
  put: api.put(`${t}/item/:id`),
};
