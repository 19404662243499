import React from "react";
import birthdayImage from "../../../../assets/imgs/welcomeBook/loon-image-original.jpg";
import iceCreamImage from "../../../../assets/imgs/welcomeBook/DSC03140.jpg";
import berriesImage from "../../../../assets/imgs/welcomeBook/IMG_2213_Original.jpg";
import halloweenImage from "../../../../assets/imgs/welcomeBook/IMG-3026.jpg";
import competitionImage from "../../../../assets/imgs/welcomeBook/A4 Folder and Paper Mockups 4.jpg";
import teamImage from "../../../../assets/imgs/welcomeBook/DSC09422.jpg";
import Scrollable from "../../../Scrollable";
import "./styles.scss";
function CorporateEvents() {
  const corporateEventsPoits = [
    [
      "Easter holidays",
      "Ice cream day",
      "Smoothie day",
      "Company's birthday",
      "Watermelon day",
      "Programmer's day",
    ],
    [
      "Halloween party",
      "Thanksgiving",
      "Vechornitsy",
      "“Mykolayko” for the children of our colleagues",
      "Charity Nicholas",
    ],
    [
      "Secret Santa (gift exchange)",
      "New Year's corporate party",
      "Table games",
      "English movie nights",
      "Numerous team-building occasions during the year",
    ],
  ];
  return (
    <div className="corporate-events">
      <div className="corporate-events__tittle-container">
        <div className="corporate-events__tittle super-tittle">
          Corporate Events
        </div>
        <div className="corporate-events__text-container main-text">
          At devabit, we party hard. Therefore, your birthday party is not a
          limit but rather a beginning of a long-term festive period.
        </div>
      </div>
      <div className="corporate-events__content">
        <div className="corporate-events__images-container">
          <Scrollable _class="corporate-events__images-items">
            <div className="flex">
              <div className="corporate-events__first-item">
                <img src={birthdayImage} alt="birthday" />
              </div>
              <div className="corporate-events__second-item ">
                <div>
                  <img src={berriesImage} alt="berries" />
                </div>
                <div className="mt-5">
                  <img src={iceCreamImage} alt="ice cream" />
                </div>
              </div>
              <div className="corporate-events__third-item ">
                <div>
                  <img src={halloweenImage} alt="halloween" />
                </div>
              </div>
              <div className="corporate-events__fourth-item">
                <div>
                  <img src={competitionImage} alt="competition" />
                </div>
                <div className="mt-5">
                  <img src={teamImage} alt="team" />
                </div>
              </div>
            </div>
          </Scrollable>
        </div>
        <div className="corporate-events__points-container secondary-text">
          {corporateEventsPoits.map((item, index) => (
            <div className="corporate-events__points-item" key={index}>
              <ul>
                {item.map((point) => (
                  <li key={point}>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CorporateEvents;
