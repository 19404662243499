import React from "react";
import {User} from '../../../components/Filters'
import Field from '../../../ui/Field'
import {Checkbox} from 'antd'

export default (props: any) => {
  const {testDevice = false} = props.modalProps;
  return <>
    <div className="mb-20">
      <User name="userId"
            required
            statuses={['active', 'inactive']} />
    </div>
    {
      testDevice ? <Field name="isResponsible">
        <Checkbox>Responsible person</Checkbox>
      </Field> : null
    }
  </>
}