import React from "react";
import {FormContext} from "../../noui/Form";
import {Button} from "antd";

export default () => <div className="filters-btn">
  <FormContext.Consumer>{(context: {form: any}) =>
    <Button htmlType="button"
            onClick={() => {
              context.form && context.form.resetFields();
            }}>Reset</Button>
  }</FormContext.Consumer>
  <Button className="filters-form-btn filters-form-btn-ok"
          type="primary"
          htmlType="submit">OK</Button>
</div>