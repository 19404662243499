import * as modal from "../../../services/modal";
import Team from "../Team";

interface IProps {
  onSubmit: any
};

const init = ({onSubmit}: IProps) => {
  
  modal.set('teamItem', {
    hash: 'team',
    component: Team,
    config: {
      titleFunc: (modalProps: any) => modalProps.name ? 'Edit Team' : 'Create Team',
      okLabel: 'Save',
      onSubmit
    }
  });
};

const open = (item: any = {}) => {
  modal.setProps('teamItem', item);
  modal.open('teamItem')
};

const del = () => {
  modal.remove('teamItem')
};

export default {
  init,
  open,
  del
}