import {IEntitlementItem} from "../../../interfaces/containers";
import Avatar from "../../../components/Avatar";
import {IUserData} from "../../../interfaces";
import React from "react";

export default [
  {
    title: '',
    key: 'avatar',
    width: '64px',
    render: (record: IEntitlementItem) => {
      return <Avatar style={{padding: 0, margin: 0}} user={record as IUserData} size={32} />
    }
  },
  {
    title: 'Name',
    dataIndex: 'user.firstName',
    render: (text: any, {firstName, lastName}: any) =>
      `${firstName || ''} ${lastName || ''}`.trim(),
    sorter: true,
  }
]