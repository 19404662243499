import React from "react";
import {Col, Row} from "antd";
import {Buttons, Users, InventoriesGroups} from "../../../components/Filters";

type TValue = string

interface Props {
  defaultValue?: {
    [name: string]: TValue[]
  }
}

const changeUsers = (values: string[], e: any, form: any) => {
  const key = 'unassigned';
  const hasUnassigned = values.includes(key);
  if (values.length === 0 || !hasUnassigned || (hasUnassigned && values.length === 1)) {
    return
  }

  let cloned = [...values];

  if (values[values.length - 1] !== key) {
    cloned.splice(cloned.indexOf(key), 1)
  } else if (cloned.length > 1) {
    cloned = [key]
  }
  setTimeout(() => {
    form.setFieldsValue({users: cloned})
  }, 0)
};

export default ({
                  defaultValue = {}
                }: Props) => {

  let users: any = [];
  if (defaultValue.users && defaultValue.users.length) {
    users = defaultValue.users.map((id: string) => ({_id: id}))
  }

  return <Row gutter={[10, 0]} className="table-filters">
    <div className="title-filters mb-20">Filters</div>
    <Col span={20}>
      <Row gutter={[8, 8]}>
        <Users col={{span: 6}}
               onChange={changeUsers}
               defaultSelected={users}
               staticOptions={[
                 {
                   name: 'Unassigned',
                   value: 'unassigned'
                 }
               ]} />
        <InventoriesGroups col={{span: 6}}
                           defaultSelected={defaultValue.groups} />
      </Row>
    </Col>
    <Col span={4}>
      <Buttons />
    </Col>
  </Row>
}