import React from "react";
import {Button, Icon, Row, Spin} from "antd";
import Settings from "../../../layouts/Settings";
import modalWiFiConnections from '../../../containers/Modals/WiFiConnections'
import TablePagination from "../../../services/tablePagination";
import apiWifiConnections from '../../../API/wifiConnections'
import {IActionsList} from "../../../components/DotMenu";
import FiltersComponent from "../../../components/Filters";
import {Filters as FiltersBtn} from "../../../components/Buttons";
import Pagination from '../../../components/Pagination';
import Table from "../../../components/Table";
import Field from "../../../ui/Field";
import columns from "./columns";
import FiltersLayout from "./FiltersLayout";
import errors from "../../../API/error";


export default class UserOfficeInfo extends React.Component<any, any> {
  pagination: TablePagination;
  searchTimer: any = null;

  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
      allowedFiltersKeys: {
        search: true,
        users: true
      }
    });
  }

  state = {
    activeFilters: false,
    loading: false,
    data: []
  };

  componentDidMount(): void {
    modalWiFiConnections.setProps({
      onSubmit: this.getData
    });
    this.getData()
  }

  getData = async () => {
    try {
      const {data} = await apiWifiConnections.get({query: {...this.pagination.requestParams()}});
      const {list, pagination} = data;
      this.pagination.value = pagination;
      this.setState({data: list})
    } catch (e) {
      return
    }
  };

  handleEdit = async (item: any) => {
    try {
      const {data} = await apiWifiConnections.getItem({params: {id: item._id}});
      modalWiFiConnections.open({
        item: data,
        onSubmit: this.getData
      })
    } catch (e) {
      return
    }
  };

  handleDelete = async (item: any) => {
    try {
     await apiWifiConnections.deleteItem(null, {params: {id: item._id}});
     this.getData()
    } catch (e) {
      errors.handle(e);
    }
  };

  actions = (): IActionsList => ([
    {
      name: 'Edit',
      disablePropagation: true,
      action: this.handleEdit
    },
    {
      name: 'Delete',
      confirm: true,
      action: this.handleDelete
    }
  ]);

  handleSearch = (e: any) => {
    const {value: search} = e.target;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
    this.searchTimer = setTimeout(() => {
      this.pagination.valueAndUpdate = {
        offset: 0,
        search
      };
      this.setState({search})
    }, 500);
  };

  toggleFilters = () =>
    this.setState({activeFilters: !this.state.activeFilters});

  handleSubmitFilters = ({values}: any) => {
    this.pagination.valueAndUpdate = values;
  };

  render() {
    const {loading, data, activeFilters} = this.state;
    return <Settings
      location={this.props.location}
      filtersComponent={
        <div className="mb-20">
          <FiltersComponent
            visible={activeFilters}
            onSubmit={this.handleSubmitFilters}>
            <FiltersLayout />
          </FiltersComponent>
        </div>
      }
      actionsComponent={
        <Row type="flex" className="flex-wrap-nowrap" justify="end">
          <Field name="search"
                 disableDecorator
                 disableBottomMargin
                 onChange={this.handleSearch}
                 placeholder="Search"
                 suffix={<Icon type="search" className="certain-category-icon" />} />
          <FiltersBtn className="ml-15"
                      onClick={this.toggleFilters}
                      active={activeFilters} />
          <Button className="ant-btn-primary ml-15" onClick={() => {
            modalWiFiConnections.open({
              onSubmit: this.getData
            })
          }
          }>Create Connection</Button>
        </Row>
      }
    >
      <Spin tip="Loading..."
            spinning={loading}>
        <Table dataSource={data}
               loading={loading}
               rowKey="_id"
               clickable
               columns={columns(this.actions)}
               pagination={this.pagination.tableConfig}
               onChange={this.pagination.tableChange.bind(this.pagination)} />
        <Pagination {...this.pagination.config} />
      </Spin>
    </Settings>
  }
}