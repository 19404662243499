import React from "react";
import doctor from "../../../../assets/imgs/welcomeBook/unsplash_tE7_jvK-_YU.jpg";
import doctorMobile from "../../../../assets/imgs/welcomeBook/doctor_mobile.jpg";
import { useHistory } from "react-router";
import user from "../../../../routes/user";
import "./styles.scss";
function Healthcare() {
  const history = useHistory();
  const toInsuranceHandler = () => {
    history.push(user.insurance.path);
  };
  return (
    <div className="healthcare">
      <div className="healthcare__text_container">
        <div className="healthcare__tittle_container">
          <div className="super-tittle">Healthcare</div>
          <div className="healthcare__sub-tittle main-text mt-30">
            We care about the health of our employees. For this reason, we
            provide the following healthcare benefits.
          </div>
        </div>
        <div className="healthcare__point_container">
          <div className="healthcare__point_item">
            <ul>
              <li onClick={toInsuranceHandler}>Medical insurance</li>
            </ul>
            <div className="secondary-text mt-10">
              The company fully covers the insurance of colleagues. The main
              options our insurance includes are: free medicines, doctor's
              consultation, tests, diagnoses, annual preventive examination,
              dentistry, therapeutic massages, etc.
            </div>
          </div>
          <div className="healthcare__point_item">
            <ul>
              <li onClick={toInsuranceHandler}>Corporate dentist</li>
            </ul>
            <div className="secondary-text">
              The dentistry package includes 2 hygiene procedures and 3 dental
              fillings per year.
            </div>
          </div>
        </div>
      </div>
      <div className="healthcare__image_container">
        <img src={doctor} alt="doctor" />
      </div>
      <div className="healthcare__image-mobile_container">
        <img src={doctorMobile} alt="doctor" />
      </div>
    </div>
  );
}

export default Healthcare;
