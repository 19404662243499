export default [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    className: "table-col-name",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];
