import { SETUP_USER, CHANGE_USER, REMOVE_USER } from './actions';
import initialState from './initialState';
import {AnyAction} from "redux";

const user: any = initialState.user;

const reducer = (state: any = user, { type, payload }: AnyAction) => {
  switch (type) {
    case SETUP_USER:
      return payload;
    case CHANGE_USER:
      return {...state, ...payload};
    case REMOVE_USER:
      return user;
    default:
      return state
  }
};

export default reducer
