import * as React from 'react';
import { Button, Col, Row, Icon, Select, notification } from 'antd';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import moment from 'moment';
import usersAPI from '../../API/users';
import columns from './columnsConfig';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import NavLink from '../../components/NavLink';
import Has from '../../noui/Permissions/Has';
import Field from '../../ui/Field';
import userRoutes from '../../routes/user';
import TablePagination from '../../services/tablePagination';
import errors from '../../API/error';
import { IStore } from '../../interfaces/store';
import Some from '../../noui/Permissions/Some';
import './style.scss';
import { IActionsList } from '../../components/DotMenu';
import * as pathService from '../../services/path';
import { Filters as FiltersBtn } from '../../components/Buttons';
import FiltersComponent from '../../components/Filters';
import FiltersLayout from './FiltersLayout';
import Avatar from '../../components/Avatar';
import exportFile from '../../services/exportFile';


const format = 'YYYY-MM-DD';

class Employees extends React.Component<any, any> {
  private readonly pagination: TablePagination;

  private searchTimer: any = null;

  private unlisten: any;

  constructor(props: any) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
      updateQueryOnChange: true,
      allowedFiltersKeys: {
        teams: true,
        positions: true,
        gender: true,
        project: true,
        languageLevel: true,
        languageName: true,
        groups: true,
        status: true,
        search: true,
        from: true,
        to: true,
      },
      // parameters allowed in the query
      allowedQueryParameters: {
        offset: true,
        status: true,
        limit: true,
        sort: true,
        order: true,
        search: true,
        teams: { type: 'array' },
        positions: { type: 'array' },
        groups: { type: 'array' },
        from: true,
        to: true,
      },
    });

    const {
      user: { companyAccess = {} },
    }: any = this.props;
    let queryPrms;

    if (companyAccess.users) {
      queryPrms = props.location.search;
    } else {
      const params = new URLSearchParams(props.location.search);
      if (params.has('from') || params.has('to')) {
        params.delete('from');
        params.delete('to');
      }

      queryPrms = params.toString();
    }

    this.pagination.query = queryPrms;
    const params = this.pagination.queryParameters;
    this.state = {
      list: [],
      loading: true,
      search: params.search || '',
      status: params.status || 'active',
      activeFilters: !!(
        params.teams ||
        params.groups ||
        params.positions ||
        params.from
      ),
      filters: {
        teams: params.teams || [],
        groups: params.groups || [],
        positions: params.positions || [],
        date: params.from
          ? [
            moment(params.from.toString(), format),
            moment(params.to.toString(), format),
          ]
          : [],
      },
    };
  }

  componentDidMount() {
    // This listener helps to update data by the filters if go back button pressed
    this.unlisten = this.props.history.listen(
      ({ search }: any, action: any) => {
        if (action === 'POP') {
          this.pagination.query =
            search && search.length > 1 ? search : '?limit=10&offset=0';
          this.getData();
        }
      }
    );
    this.getData();
  }

  componentWillUnmount(): void {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const res = await usersAPI.get({
        query: this.pagination.requestParams(),
      });

      const { list, pagination } = res.data;
      this.pagination.value = pagination;

      this.setState({ list, loading: false });
    } catch (e) {
      this.setState({ loading: false, list: [] });
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  exportTable = async () => {
    try {
      const params = JSON.parse(JSON.stringify(this.pagination.requestParams()));
      params.status = params.status || 'active';

      const res = await usersAPI.export({
        query: { ...params, limit: 1000, offset: 0 },
      });
      await exportFile.exportInit(res.data);
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
    }
  };

  exportTableAll = async () => {
    try {
      const params = JSON.parse(JSON.stringify(this.pagination.requestParams()));
      delete params.status;

      const res = await usersAPI.export({
        query: { ...params, limit: 1000, offset: 0 },
      });
      await exportFile.exportInit(res.data);
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
    }
  };

  handleDelete = async (id: number) => {
    try {
      await usersAPI.delete(null, { params: { id } });
      this.getData();
      notification.success({
        message: 'Success',
      });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  search = (e: any) => {
    const { value: search } = e.target;
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.pagination.valueAndUpdate = { offset: 0, search };
      this.setState({ search });
    }, 600);
  };

  handleChangeStatus = (status: string) => {
    this.pagination.valueAndUpdate = { status };
    this.setState({ status });
  };

  handleChangeStatusAction = async (id: number, status: string) => {
    try {
      this.setState({ loading: true });
      await usersAPI.changeStatus({ status }, { params: { id } });
      this.getData();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to change' });
    }
  };

  actions = (record: any): IActionsList => {
    const items: IActionsList = [];
    const {
      user: { companyAccess = {}, _id },
    }: any = this.props;

    if (companyAccess.users) {
      if (!record.isCompanyOwner || _id === record._id) {
        items.push({
          name: 'Edit',
          link: pathService.get(userRoutes.employeeEdit.path, {
            id: record._id,
          }),
        });
      }

      const { status } = record;

      if (!record.isCompanyOwner) {
        if (status !== 'active') {
          items.push({
            name: 'Activate',
            action: this.handleChangeStatusAction.bind(
              null,
              record._id,
              'active'
            ),
          });
        }
        if (status !== 'inactive') {
          items.push({
            name: 'Inactivate',
            action: this.handleChangeStatusAction.bind(
              null,
              record._id,
              'inactive'
            ),
          });
        }
        if (status !== 'archived') {
          items.push({
            name: 'Archive',
            action: this.handleChangeStatusAction.bind(
              null,
              record._id,
              'archived'
            ),
          });
        }
        items.push({
          name: 'Delete',
          confirm: true,
          action: this.handleDelete.bind(null, record._id),
        });
      }
    }

    return items;
  };

  toggleFilters = () =>
    this.setState({ activeFilters: !this.state.activeFilters });

  handleSubmitFilters = ({ values }: any) => {
    const query: { [key: string]: string | number } = {};

    const {
      user: { companyAccess = {} },
    }: any = this.props;
    if (values.gender) query.gender = values.gender;
    if (companyAccess.users && values.date?.length > 0) {
      const [from, to] = values.date;

      query.from = from.format(format);
      query.to = to.format(format);
    } else {
      delete query.from;
      delete query.to;
    }

    ['positions', 'groups', 'teams', 'gender', 'project', 'languageLevel', 'languageName'].forEach((key) => {
      if (values[key] && values[key].length > 0) {
        query[key] = JSON.stringify(values[key]);
      } else {
        this.pagination.unlink = key;
      }
    });

    this.pagination.valueAndUpdate = query;
  };

  onView = (record: any) =>
    this.props.history.push(
      generatePath(userRoutes.employeeProfile.path, { id: record._id })
    );

  render() {
    const { list, loading, activeFilters, filters, status, search } =
      this.state;
    const {
      user: { companyAccess = {} },
    } = this.props;
    const businessUnitsAccess = companyAccess.settings || companyAccess.users;
    return (
      <>
        <Row className="mb-10">
          <Col>
            <Row type="flex" justify="space-between">
              <Col>
                <Row type="flex">
                  <h2 className="page-header">Employees</h2>
                  <Some permissions={ ['settings', 'users'] }>
                    <div className="ml-10 user-status-select">
                      <Field
                        name="status"
                        style={ { margin: 0 } }
                        disableDecorator
                        onChange={ this.handleChangeStatus }
                        placeholder="Status"
                      >
                        <Select defaultValue={ status }>
                          <Select.Option value="active">Active</Select.Option>
                          <Select.Option value="inactive">
                            Inactive
                          </Select.Option>
                          <Select.Option value="archived">
                            Archived
                          </Select.Option>
                        </Select>
                      </Field>
                    </div>
                  </Some>
                </Row>
              </Col>
              <div className="flex search-mb0">
                <Field
                  name="search"
                  style={ { margin: 0 } }
                  disableDecorator
                  defaultValue={ search }
                  onChange={ this.search }
                  placeholder="Search"
                  suffix={
                    <Icon type="search" className="certain-category-icon" />
                  }
                />
                <FiltersBtn
                  className="ml-15 mr-15"
                  onClick={ this.toggleFilters }
                  active={ activeFilters }
                />
                <Has permissions={ ['personalInformation'] }>
                  <Button
                    type="primary"
                    className="top-filters-btn"
                    onClick={ this.exportTable.bind(null, this.state) }
                >
                    Export
                  </Button>
                </Has>
                <Has permissions={ ['personalInformation'] }>
                  <Button
                    type="primary"
                    className="top-filters-btn ml-15"
                    onClick={ this.exportTableAll.bind(null, this.state) }
                >
                    Export with all statuses
                  </Button>
                </Has>
                <Has permissions={ ['users'] }>
                  <NavLink to={ userRoutes.employeeAdd.path } className="ml-15">
                    <Button className="ant-btn-primary">Add Employee</Button>
                  </NavLink>
                </Has>
              </div>
            </Row>
          </Col>
          <FiltersComponent
            visible={ activeFilters }
            onSubmit={ this.handleSubmitFilters }
          >
            <FiltersLayout defaultValue={ filters } />
          </FiltersComponent>
        </Row>
        <Table
          dataSource={ list }
          loading={ loading }
          rowKey="_id"
          clickable
          onRow={ (record: any) => ({
            onClick: this.onView.bind(null, record),
          }) }
          mobile={ {
            dataSource: list,
            hideHeader: true,
            dotActions: (record: any): IActionsList =>
              [
                {
                  name: 'View',
                  action: this.onView,
                },
                ...this.actions(record),
              ] as IActionsList,
            columns: [
              {
                render(record) {
                  return (
                    <div className="flex">
                      <Avatar user={ record } size={ 48 } />
                      <div className="flex fd-column jc-center ml-10">
                        <div className="table-user-name">
                          { `${record.firstName || ''} ${
                            record.lastName || ''
                          }`.trim() }
                        </div>
                        <div className="table-user-email">{ record.email }</div>
                      </div>
                    </div>
                  );
                },
              },
            ],
            subColumn: [
              {
                title: 'Position',
                render(record) {
                  const { positions } = record;
                  return !positions || positions.length === 0
                    ? null
                    : positions.map((position: any, index: number) => (
                      <div key={ index }>{ position.name }</div>
                    ));
                },
              },
              ...(businessUnitsAccess
                ? [
                  {
                    title: 'Business Units',
                    render({ groups, allowAllGroups }: any) {
                      return groups && groups.length > 0 ? (
                        <div>
                          { allowAllGroups ? <div>All</div> : null }
                          { groups.map(
                            (group: { name: string }, index: number) => (
                              <div key={ index }>{ group.name }</div>
                            )
                          ) }
                        </div>
                      ) : null;
                    },
                  },
                ]
                : []),
            ],
          } }
          columns={ columns(this.actions, businessUnitsAccess) }
          pagination={ this.pagination.tableConfig }
          onChange={ this.pagination.tableChange.bind(this.pagination) as any }
        />
        <Pagination { ...this.pagination.config } />
      </>
    );
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(Employees);
