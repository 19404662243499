import * as React from 'react';
import { Button, Select } from 'antd';
import moment from 'moment';
import { generatePath } from 'react-router';
import TablePagination from '../../../services/tablePagination';
import Table from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import Filters from '../../../components/Filters';
import Settings from '../../../layouts/Settings';
import exportFile from '../../../services/exportFile';
import { ISubmitProps } from '../../../noui/Form';
import FiltersLayout from './FiltersLayout';

import errors from '../../../API/error';
import requestsAPI from '../../../API/requests';
import columns from './columns';
import './styles.scss';
import routesStatistics from '../../../routes/statistics';
import Field from '../../../ui/Field';

const format = 'YYYY-MM-DD';

type TDataReturnedWeekend = {
  _id: string;
  count: number;
  name: string;
};

// Data used in statistic request
type TDataReturn = {
  _id: string;
  firstName: string;
  lastName: string;
  weekends: TDataReturnedWeekend[];
};

class Statistics extends React.Component<any, any> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);

    this.state = {
      activeFilters: true,
      list: [],
      // absence types columns
      columns: [],
      status: 'all',
      loading: true,
      filters: {
        users: [],
      }
    };
    this.pagination = new TablePagination({
      pageSize: 20,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData,
      allowedFiltersKeys: {
        users: true,
        weekends: true,
        groups: true,
        users_status: true,
        teams: true,
        from: {
          default: moment.utc().startOf('month').format(format)
        },
        to: {
          default: moment.utc().endOf('month').format(format)
        }
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  handleChangeStatus = (status: string) => {
    const query: {[key: string]: string | number} = {};

    if (status !== 'all') {
      query.users_status = status;
    } else {
      query.users_status = '';
    }

    this.pagination.value = query;
    this.getData();

    this.setState({ status });
  };

  handleSubmit = ({ values, form }: ISubmitProps) => {
    const [from, to] = values.date;
    const query: {[key: string]: string | number} = {
      from: from.format(format),
      to: to.format(format),
    };

    ['users', 'weekends', 'groups', 'teams'].forEach((key) => {
      if (values[key] && values[key].length > 0) {
        query[key] = JSON.stringify(values[key]);
      } else {
        this.pagination.unlink = key;
      }
    });

    this.pagination.value = query;
    this.getData(form);
  };

  normalizeDataList = (list: TDataReturn[]) => {
    const collectTypes = new Map<string, string>();
    const data = list.map((item) => {
      const { weekends, ...rest } = item;
      const el: {[key: string]: any} = { ...rest, weekends };
      weekends.forEach((weekend) => {
        collectTypes.set(weekend._id, weekend.name);
        el[weekend._id] = weekend.count;
      });
      return el;
    });

    const columns: any[] = [];
    [...(collectTypes as any)].forEach(([id, value]) => {
      columns.push({
        title: value,
        dataIndex: id,
        className: 'align-center',
        key: id,
        render: (count?: number) => count || ''
      });
    });

    return {
      data,
      columns
    };
  };

  getData = async (form?: any) => {
    try {
      this.setState({ loading: true });
      const { data: { list, pagination } } = await requestsAPI.getStatistic({
        query: { ...this.pagination.requestParams() }
      });
      this.pagination.value = pagination;

      const { columns, data } = this.normalizeDataList(list);

      this.setState({
        columns,
        list: data,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      errors.handle(e, form || null, { priority: 'all' });
    }
  };


  setActiveFilters = (active = true) => {
    this.setState({ activeFilters: active });
  };

  exportTable = async () => {
    try {
      const res = await requestsAPI.export({
        query: { ...this.pagination.requestParams(), limit: 1000, offset: 0 }
      });
      await exportFile.exportInit(res.data);
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
    }
  };

  handleColumnSort = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: [] }) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  render() {
    const {
      loading,
      activeFilters,
      list,
      status,
      columns: absenceColumns
    } = this.state;

    return (<Settings
      location={ this.props.location }
      actionsComponent={
        <div className="flex">
          <div className="mr-10 user-status-select">
            <Field
              name="status"
              style={ { margin: 0 } }
              disableDecorator
              onChange={ this.handleChangeStatus }>
              <Select defaultValue="all">
                <Select.Option value="all">
                  All
                </Select.Option>
                <Select.Option value="active">
                  Active
                </Select.Option>
                <Select.Option value="archived">
                  Archived
                </Select.Option>
              </Select>
            </Field>
          </div>

          <Button
            type="primary"
            className="top-filters-btn mr-10"
            onClick={ this.setActiveFilters.bind(null, !activeFilters) }>Filters</Button>
          <Button
            type="primary"
            className="top-filters-btn"
            onClick={ this.exportTable.bind(null, this.state) }>Export</Button>
        </div>
                     }
      filtersComponent={
        <div className="mb-20">
          <Filters onSubmit={ this.handleSubmit } visible={ activeFilters }>
            <FiltersLayout status={ status } />
          </Filters>
        </div>
    }>
      <Table
        dataSource={ list }
        rowKey="_id"
        clickable
        onRow={ (record: any) => ({
          onClick: () => {
            const { _id } = record;
            const link = generatePath(routesStatistics.absences.path, { id: _id });
            this.props.history.push(link);
          }
        }) }
        columns={ [...columns, ...absenceColumns] }
        loading={ loading }
        onChange={ this.handleColumnSort }
        pagination={ this.pagination.tableConfig } />
      <Pagination { ...this.pagination.config } />
    </Settings>);
  }
}

export default Statistics;
