import React from "react";

export default () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                          width="80" height="80" viewBox="0 0 81.000000 80.000000"
                          preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
        <path d="M12 788 c-17 -17 -17 -759 0 -776 9 -9 71 -12 220 -12 240 0 228 -5
228 104 l0 65 57 -20 c77 -26 95 -24 169 17 126 71 131 105 41 279 l-62 120
-35 -3 c-19 -1 -48 -2 -65 -2 -16 0 -40 -5 -52 -11 -45 -24 -53 -8 -53 106 0
59 -5 115 -10 126 -10 18 -23 19 -218 19 -149 0 -211 -3 -220 -12z m426 -40
l3 -28 -210 0 -211 0 0 30 0 30 208 -2 207 -3 3 -27z m2 -132 c0 -47 -4 -87
-9 -90 -11 -7 -31 39 -31 74 0 19 -7 33 -19 40 -23 12 -290 13 -309 1 -9 -5
-12 -25 -10 -62 l3 -54 125 -3 125 -3 55 -106 c30 -58 56 -109 58 -114 2 -4
-13 -5 -32 -2 -42 7 -66 -9 -66 -42 0 -27 24 -49 73 -65 20 -6 37 -16 37 -21
0 -5 -88 -9 -210 -9 l-210 0 0 270 0 270 210 0 210 0 0 -84z m-60 -6 c0 -13
-5 -17 -14 -14 -22 8 -56 -14 -56 -36 0 -19 -7 -20 -120 -20 l-120 0 0 38 c0
21 3 42 7 45 3 4 73 7 155 7 141 0 148 -1 148 -20z m36 -115 c46 -85 80 -118
42 -41 -20 42 -21 48 -6 62 20 21 33 13 59 -38 23 -45 38 -44 19 1 -7 17 -10
37 -6 46 9 26 44 17 61 -15 20 -39 26 -38 23 3 -3 28 0 32 22 32 22 0 33 -15
82 -110 57 -110 69 -157 48 -195 -12 -23 -134 -90 -164 -90 -12 0 -72 17 -132
39 -88 31 -110 43 -112 60 -4 28 19 36 68 23 26 -6 40 -6 40 0 0 5 -31 69 -69
141 -56 105 -68 136 -60 150 19 32 42 14 85 -68z m22 -412 l-3 -58 -207 -3
-208 -2 0 60 0 60 210 0 211 0 -3 -57z"/>
        <path d="M175 470 c-67 -27 -94 -119 -53 -180 58 -86 185 -65 218 35 30 92
-73 183 -165 145z m114 -36 c80 -67 18 -199 -85 -180 -41 8 -84 57 -84 97 0
36 23 84 47 98 34 19 90 13 122 -15z"/>
        <path d="M238 363 c-32 -31 -39 -35 -53 -23 -24 20 -28 6 -5 -15 21 -19 22
-18 68 28 26 26 42 47 37 47 -6 0 -27 -17 -47 -37z"/>
        <path d="M164 95 c-13 -33 4 -45 65 -45 61 0 77 12 65 45 -9 21 -122 21 -130
0z m111 -15 c0 -18 -72 -27 -87 -12 -19 19 3 33 45 30 29 -2 42 -8 42 -18z"/>
    </g>
</svg>


