import React from "react";
import Settings from "src/layouts/Settings";
import { Row } from "antd";
import Table from "../../../components/Table";
import { generatePath } from "react-router-dom";
import routesTables from "../../../routes/tables";
import columns from "./columns";
import { IActionsList } from "../../../components/DotMenu";
import apiTable from "../../../API/table";
import errors from "../../../API/error";
import TablePagination from "../../../services/tablePagination";
import moment from "moment";
import Pagination from "../../../components/Pagination";

export default class Tables extends React.Component<any, any> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      loading: true,
    };
    this.pagination = new TablePagination({
      pageSize: 20,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData,
    });
  }
  handleEdit = ({ _id }: any) => {
    this.props.history.push(
      generatePath(routesTables.editTable.path, { id: _id })
    );
  };

  handleDelete = async ({ _id }: any) => {
    try {
      await apiTable.remove(null, { params: { id: _id } });
      await this.getData();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;

      const {
        data: { list },
      } = await apiTable.get({
        query: {
          limit: 999,
          sectorId: id,
          currentDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      });

      this.setState({
        list,
        loading: false,
      });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
      this.setState({ loading: false });
    }
  };
  actions = (record: any): IActionsList => {
    const actions: IActionsList = [];
    actions.push({
      name: "Edit",
      action: this.handleEdit,
    });

    actions.push({
      name: "Delete",
      action: this.handleDelete,
    });

    return actions;
  };
  render() {
    const { loading, list } = this.state;
    return (
      <Settings
        location={this.props.location}
        title={
          <Row type="flex" justify="start">
            <h2 className="page-header">Tables</h2>
          </Row>
        }
      >
        <Table
          dataSource={list}
          loading={loading}
          rowKey="_id"
          clickable
          onRow={(record: any) => {
            return {
              onClick: () => {
                this.props.history.push(
                  generatePath(routesTables.tables.path, {
                    id: record._id,
                  })
                );
              },
            };
          }}
          columns={columns(this.actions)}
        />
        <Pagination {...this.pagination.config} />
      </Settings>
    );
  }
}
