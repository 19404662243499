/**
 * This file used to customize all default error messages in the first step validation by the 'validate.js' module
 * ! By default, the first word of each error is the parameter key received from the Client part.
 * ! To avoid field name at the beginning of the error set '^' symbol at the star
 *
 * If you include %{value} in the error message it will be replaced with the actual value
 * All available variables are above each error
 * */
import * as validate from "validate.js";

validate.validators.datetime.notValid = "must be a valid date";
// keys: %{date}
validate.validators.datetime.tooEarly = "must be no later than %{date}";
// keys: %{date}
validate.validators.datetime.tooLate = "must be no earlier than %{date}";

validate.validators.email.message = "is not a valid email";
// keys: %{attribute}
validate.validators.equality.message = "is not equal to %{attribute}";
// keys: %{value}
validate.validators.exclusion.message = "^%{value} is restricted";

validate.validators.format.message = "is invalid";
// keys: %{value}
validate.validators.inclusion.message = "^%{value} is not included in the list";

validate.validators.length.notValid = "has incorrect length";
// keys: %{count}
validate.validators.length.tooLong = "is too long (maximum is %{count} characters)";
// keys: %{count}
validate.validators.length.tooShort = "is too short (minimum is %{count} characters)";
// keys: %{count}
validate.validators.length.wrongLength = "is wrong length (should be %{count} characters)";

validate.validators.numericality.notValid = "is not a number";
validate.validators.numericality.notInteger = "must be an integer";
// keys: %{count}
validate.validators.numericality.notGreaterThan = "must be greater than %{count}";
// keys: %{count}
validate.validators.numericality.notGreaterThanOrEqualTo = "must be greater than or equal to %{count}";
// keys: %{count}
validate.validators.numericality.notEqualTo = "must be equal to %{count}";
// keys: %{count}
validate.validators.numericality.notLessThan = "must be less than %{count}";
// keys: %{count}
validate.validators.numericality.notLessThanOrEqualTo = "must be less than or equal to %{count}";
validate.validators.numericality.notOdd = "must be odd";
validate.validators.numericality.notEven = "must be even";

validate.validators.presence.message = "can't be blank";

validate.validators.url.message = "is not a valid url";
