import * as React from "react";
import { Button, Col, Row, Icon, Select } from "antd";
import Field, { FormItem } from "../../ui/Field";
import { languageLevel } from "src/containers/Employee/CvMaker/languageList";

interface ILanguage {
  language?: string;
  code?: string;
  name?: string
  _id: number;
  isNew?: boolean;
}

interface IState {
  userLanguages: ILanguage[];
  languagesData: any[];
  loaded: boolean
}
const Option = Select.Option;
export default class EmployeeLanguages extends React.Component<any, IState> {
  state = {
    userLanguages: [],
    languagesData: [],
    loaded: false
  };

  static getDerivedStateFromProps(props: any, state: IState) {
    if ( props.userLanguages && !state.loaded) {
       
      return {
        ...state,
        userLanguages: props.userLanguages,
        loaded: true,
      };
    }

    return state;
  }

  static parseFormValues = (values: any = {}) => {
    const data = Object
      .keys(values)
      .reduce((acc, key) => {
        if (/^(language-|code-)/.test(key)) {
          const split = key.split('-');
          const id: string = split.pop() || '';
          const prop: 'language' | 'code' = split[0] as 'language' | 'code';

          acc[id] = acc[id] ? acc[id] : {language: '', code: ''};
          acc[id][prop] = prop === 'code'
            ? values[key]
            : values[key];
          return acc;
        }
        return acc;
      }, {} as {[key: string]: {language: string, code: string}});
     
    return Object.values(data);
  };


  add = () => {
    const _id = parseInt((Math.random() * 10e10).toFixed(), 10);
    this.setState({
      userLanguages: [
        ...this.state.userLanguages,
        {
          name: undefined,
          code: undefined,
          isNew: true,
          _id,
        },
      ],
    });
  };

  remove = (id: number) => {
    const { userLanguages } = this.state;

    this.setState({
      userLanguages: userLanguages.filter((l: ILanguage) => l._id !== id),
    });
  };

  render() {
    const { userLanguages } = this.state;
    const { languagesData } = this.props
  
    return (
      <FormItem label="Language">
        {Boolean(userLanguages && userLanguages.length) &&
          userLanguages.map((languageData: ILanguage) => {
            const { _id: id, language, isNew, code } = languageData;
            const key = `${isNew ? "new-" : ""}${id}`;

            return (
              <Row gutter={20} key={id}>
                <Col md={{ span: 12 }}>
                  <Field name={`language-${key}`} defaultValue={language || []}>
                    <Select>
                      {languagesData.map(
                        ({ name, _id }: any, index: number) => (
                          <Option key={index} value={name}>
                            {name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Field>        
                </Col>
                <Col md={{ span: 8 }}>
                  <Field name={`code-${key}`} defaultValue={code || []}>
                    <Select>
                      {languageLevel.map(
                        ({ name, code, _id }: any, index: number) => (
                          <Option key={index} value={name + " " + code}>
                            {name + " " + code}
                          </Option>
                        )
                      )}
                    </Select>
                  </Field>
                </Col>
                <Col md={{ span: 4 }}>
                  <span className="cursor" onClick={this.remove.bind(null, id)}>
                    <Icon type="close-circle" />
                  </span>
                </Col>
              </Row>
            );
          })}
        <Row type="flex">
          <Button onClick={this.add}>
            <Icon type="plus" />
            Add
          </Button>
        </Row>
      </FormItem>
    );
  }
}
