import moment, {Moment} from 'moment';

const types = {
  weekend: 0,
  holiday: 1,
  request: 2,
  _names_: ['weekend', 'holiday', 'request']
};

const statuses = {
  pending: 0,
  approved: 1,
  _names_: ['pending', 'approved']
};

const getStatus = (isApproved: boolean) => Boolean(isApproved) ? statuses.approved : statuses.pending;

const mapHolidays = (holiday: any) => {
  holiday.type = types.holiday;
  return holiday;
};

const mapRequests = (request: any) => {
  const {
    isApproved,
    weekend: {color},
    user: {firstName = 'Noname', lastName = 'Noname'} = {firstName: 'Noname', lastName: 'Noname'}
  } = request;

  request.type = types.request;
  request.name = `${firstName} ${lastName}`;
  request.status = getStatus(isApproved);
  request.backgroundColor = color;

  return request;
};

const mapCalendarData = (selectedDate: moment.Moment) => (i: any) => {
  selectedDate = selectedDate || moment();
  let mFrom = moment(i.from).utcOffset(0);
  const mTo = moment(i.to).utcOffset(0);

  return {
    ...i,
    period: mTo.diff(mFrom, 'days'),
    key: mFrom.format('DD_MM_YYYY'),
    momentFrom: mFrom,
    momentTo: mTo
  }
};

const sortCalendarData = (el1: any, el2: any) => el2.period - el1.period;

const buildGrid = (date: moment.Moment) => {
  const grid: any = [];
  const mondays: any = [];
  const endOfMonth = date.clone().endOf('month');
  // set first monday in the month
  const momentStart = date.clone().startOf('month');
  const month = momentStart.month();
  const prevMonth = momentStart.add(-3, 'days').month();
  const nextMonth = endOfMonth.clone().add(3, 'days').month();
  const monday = momentStart.add(momentStart.utcOffset(), 'minutes').utcOffset(0).day("Monday");
  let daysInMonth: any = endOfMonth.date();

  // keys are the date in the MM-DD format
  // value - el or empty or null
  const data: {[key: string]: any[]} = {};

  // generates empty grid
  for (let i = 0; i <= daysInMonth; i++) {
    grid[i] = [];
  }

  // sets all others mondays
  while ([month, prevMonth, nextMonth].includes(monday.month())) {
    mondays.push(monday.clone());
    monday.add(7, 'day');
  }

  return (normalizedCalendarData: Array<any>) => {
    const grouped: any = {};
    normalizedCalendarData.forEach((el: any) => {
      const {momentFrom, momentTo}: any = el;

      // Generates an index where the item will be placed
      let rowIndex2 = 0;
      let empty = false;

      // loop through the dates
      do {
        let endOfRange = false;
        const momentFromRange = momentFrom.clone();

        // loop through the lines and the existing items
        do {
          if (momentFromRange.diff(mondays[0], 'days') < 0) {
            momentFromRange.add(1, 'day');
            continue;
          }

          const dateKey = momentFromRange.format('MM-DD');
          if (data[dateKey] && (data[dateKey][rowIndex2] || data[dateKey][rowIndex2] === null)) {
            rowIndex2++;
            break;
          }

          // empty row found
          if (momentTo.diff(momentFromRange, 'days') <= 0) {
            empty = true;
            endOfRange = true;

          // checks the next day from the range
          } else {
            momentFromRange.add(1, 'day');
          }
        } while (!endOfRange);
      } while (!empty);

      let nextTick = false;
      let momentFromClone: Moment = momentFrom.clone();
      let targetMonday: null | Moment = null;

      grouped[el._id] = el;

      do {
        let key = '';
        /* eslint-disable no-loop-func */
        targetMonday = mondays.find((m: Moment) => {
          const endOfWeek = m.clone().endOf('week')
            .startOf('day');

          const diffFrom = momentFromClone.diff(m, 'days');
          const diffTo = momentTo.diff(m, 'days');

          return (diffFrom >= 0 && endOfWeek.diff(momentFromClone, 'days') >= 0)
            || (diffFrom <= 0 && momentTo.diff(m, 'days') >= 0)
            || (diffFrom >= 0 && diffTo <= 0);
        });
        /* eslint-enable no-loop-func */
        if (!targetMonday) {
          return null
        }
        const endOfWeek = targetMonday.clone().endOf('week')
          .startOf('day');

        const fromDiff = momentFromClone.diff(targetMonday, 'days');
        if (fromDiff <= 0) {
          key = targetMonday.format('MM-DD');
          momentFromClone = targetMonday.clone()
        } else {
          key = momentFromClone.format('MM-DD');
        }

        if (!data[key]) {
          data[key] = []
        }
        const itemToPush: any = {key};

        let momentToClone = momentTo.clone().utcOffset(0);
        const diffEndOfWeek = endOfWeek.diff(momentTo, 'days') < 0;
        if (diffEndOfWeek) {
          momentToClone = endOfWeek.clone().startOf('day').utcOffset(0);
        }

        let dataLength = 1;

        while (momentToClone.diff(momentFromClone, 'days') > 0) {
          const addKey = momentToClone.format('MM-DD');
          if (!data[addKey]) {
            data[addKey] = []
          }
          data[addKey][rowIndex2] = null;
          momentToClone.add(-1, 'day');
          dataLength++;
        }

        if (diffEndOfWeek) {
          nextTick = true;
          momentFromClone.add(7, 'days').startOf('week');
        } else {
          nextTick = false
        }

        itemToPush.dataLength = dataLength;
        itemToPush.id = el._id;

        data[key][rowIndex2] = itemToPush;
      } while (nextTick);
    });
    return {grouped, data};
  }
};

interface IItem {
  from: string
  to: string
  isRestDay?: boolean
}

/**
 * Returns list of dates which are in day-off ranges
 * */
export const getRestDays = (list: any[]) => {
  if (!list || list.length === 0) {
    return []
  }
  const listRestDays = new Set<string>();
  list.forEach(({isRestDay, from, to}: IItem) => {
    if (!isRestDay) {
      return
    }
    const momentFrom = moment.utc(from).startOf('day');
    const momentTo = moment.utc(to).startOf('day');

    let loopNext = false;
    do {
      // Don't change format below
      // It used to check day-offs and render them in the calendar
      listRestDays.add(momentFrom.format('YYYY-MM-DD'));

      momentFrom.add(1, 'day');
      loopNext = momentTo.diff(momentFrom, 'days') >= 0
    } while (loopNext)
  });

  return [...listRestDays as any]
};

export default {
  types,
  statuses,
  getStatus,
  mapHolidays,
  mapRequests,
  mapCalendarData,
  sortCalendarData,
  buildGrid,
  getRestDays
}
