import React from "react";
import {connect} from "react-redux";
import columns from "./columns";
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'
import TablePagination from "../../../../services/tablePagination";
import apiInventories from "../../../../API/inventories";
import './styles.scss'
import errors from "../../../../API/error";

export default connect(({user}: any) => ({user}))(class MyDevices extends React.Component<any, any> {
  pagination: TablePagination;
  unlisten: any = null;

  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
    });
  }
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      const {data} = await apiInventories.getList({query: this.pagination.requestParams()});
      this.pagination.value = data.pagination
      this.setState({
        loading: false,
        data: data.list
      })
    } catch (e) {

    }
  };


  handleApprove = async (id: string) => {
    try {
      await apiInventories.postUserApprove({}, {params: {id}})
      this.getData()
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  handleReject = async (id: string) => {
    try {
      await apiInventories.postUserReject({}, {params: {id}})
      this.getData()
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  handleReturn = async (id: string) => {
    try {
      await apiInventories.postUserReturn({}, {params: {id}})
      this.getData()
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  render() {
    const {data, loading} = this.state;
    const {user} = this.props;
    if (data.length === 0) {
      return null
    }
    return <>
      <h2 className="page-header mt-30 mb-10">My Devices</h2>
      <div className="overflow-auto table-min-width">
        <Table dataSource={data}
               loading={loading}
               rowKey="_id"
               size="small"
               columns={columns(user, {
                 onApprove: this.handleApprove,
                 onReject: this.handleReject,
                 onReturn: this.handleReturn
               })}
               pagination={this.pagination.tableConfig}
               onChange={this.pagination.tableChange.bind(this.pagination)} />
      </div>
      {
        this.pagination.paginationVisible ? <Pagination {...this.pagination.config} />
        : null
      }
    </>
  }
})