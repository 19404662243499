import {
  SET_TABLE,
  SET_TABLES_DATA,
  SET_SECTOR,
  SET_DATE_REQUEST_TABLE,
  SET_SECTORS_DATA,
  SET_REQUESTS_BOOKING_DATA,
  SET_BOOKED_DAYS,
  SET_BOOKED_FOR,
} from "../../reducers/actions";
import store from "../../configs/store";
import {
  IBookingRequest,
  ITable,
  ITableSector,
} from "../../interfaces/containers/tableBooking";
import { Moment } from "moment";

export const setTable = (payload: string) => {
  store.dispatch({
    payload,
    type: SET_TABLE,
  });
};
export const setTablesData = (payload: ITable[]) => {
  store.dispatch({
    payload,
    type: SET_TABLES_DATA,
  });
};
export const setSector = (payload: string) => {
  store.dispatch({
    payload,
    type: SET_SECTOR,
  });
};
export const setDateRequest = (payload: Moment) => {
  store.dispatch({
    payload,
    type: SET_DATE_REQUEST_TABLE,
  });
};
export const setSectorsData = (payload: ITableSector[]) => {
  store.dispatch({
    payload,
    type: SET_SECTORS_DATA,
  });
};
export const setBookingRequests = (payload: IBookingRequest[]) => {
  store.dispatch({
    payload,
    type: SET_REQUESTS_BOOKING_DATA,
  });
};
export const setBookedDays = (payload: string[]) => {
  store.dispatch({
    payload,
    type: SET_BOOKED_DAYS,
  });
};
export const setBookedFor = (payload: string) => {
  store.dispatch({
    payload,
    type: SET_BOOKED_FOR,
  });
};
