import * as React from "react";
import { Input, Form } from "antd";
import validate from "validate.js";
import { FormContext } from "../noui/Form";
import validationSchema from "../configs/validation";
import { Moment } from "moment";

const validSchedule: any = validationSchema;
const FromItemInner = Form.Item;
declare let customError: ICustomError;

enum AntDesignFormItemStatus {
  "success",
  "warning",
  "error",
  "validating",
}

interface ICustomError {
  help?: string;
  validateStatus?: string;
}

const handleValidation = (schedule: any, values: any, callback: any) => {
  const validated = validate(values, schedule);
  if (validated) {
    const errorMessage = Object.values(validated);
    return callback(
      Array.isArray(errorMessage[0])
        ? errorMessage[0].slice(0, 1)
        : errorMessage[0]
    );
  }
  if (typeof callback === "function") {
    callback();
  }
};

interface IFormItem {
  disableWrapper?: boolean;
  disableBottomMargin?: boolean;
  label?: string | any;
  children?: any;
  errorMessage?: string;
  rest?: any;
}

interface IFieldProps extends IFormItem {
  children?: Element | any;
  name: string;
  label?: string;
  defaultValue?: string | boolean | number | Moment | any[];
  placeholder?: string | null;
  formItem?: any;
  validation?: string | boolean;
  disableDecorator?: boolean;
  errorMessage?: string;
  validateStatus?: AntDesignFormItemStatus;
  type?: string;
  rest?: any;
  disabled?: boolean;
  [key: string]: any;
}

export const FormItem = ({
  disableWrapper = false,
  disableBottomMargin = false,
  label,
  children = null,
  errorMessage,
  ...rest
}: IFormItem) => {
  if (disableWrapper) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (errorMessage) {
    customError.help = errorMessage;
    customError.validateStatus = "error";
  }

  return (
    <FromItemInner
      style={{ width: "100%" }}
      className={`fix-label${disableBottomMargin ? " fix-bottom-margin" : ""}`}
      colon={false}
      label={(() => {
        if (!label) {
          return null;
        }
        return typeof label === "string"
          ? label
          : typeof label === "function"
          ? label()
          : null;
      })()}
      {...rest}
    >
      {children}
    </FromItemInner>
  );
};

/*
 * @component Form field
 *
 * */
export default ({
  children,
  name,
  label = "",
  disabled = false,
  disableWrapper,
  disableBottomMargin,
  defaultValue,
  placeholder = "",
  formItem = {},
  validation = false,
  disableDecorator = false,
  errorMessage = "",
  validateStatus,
  type = "text",
  ...rest
}: IFieldProps) => {
  let fieldValidation = {};
  const fieldAdditionalProps: any = { disabled };
  let getFieldDecorator: any = () => (Component: Element) => Component;

  if (typeof validation === "string") {
    if (validSchedule[validation]) {
      fieldValidation = validSchedule[validation];
    } else {
      console.log("Validation schedule doesn't found.");
    }
  } else if (
    typeof validation === "boolean" &&
    validSchedule[name] &&
    validation
  ) {
    fieldValidation = validSchedule[name];
  }

  const additionalParams: any = {
    validateTrigger: "onSubmit",
  };
  if (defaultValue !== undefined) {
    additionalParams.initialValue = defaultValue;
  }

  if (validateStatus) {
    formItem.validateStatus = validateStatus;
  }

  const childrenParams: any = {
    name,
    disabled,
    placeholder: placeholder ? placeholder : placeholder === null ? "" : label,
    style: { width: "100%" },
    ...rest,
  };
  if (disableWrapper) {
    childrenParams.defaultValue = defaultValue;
  }

  return (
    <FormContext.Consumer>
      {(context) => {
        if (
          disableDecorator ||
          !context.form.hasOwnProperty("getFieldDecorator")
        ) {
          if (defaultValue) {
            fieldAdditionalProps.defaultValue = defaultValue;
          }
        } else {
          getFieldDecorator = context.form.getFieldDecorator;
        }
        if (childrenParams.onChange) {
          const changeCallback = childrenParams.onChange;
          childrenParams.onChange = (...rest: any) => {
            changeCallback(...rest, context.form);
          };
        }
        return (
          <FormItem
            style={{ width: "100%" }}
            label={label}
            disableWrapper={disableWrapper}
            disableBottomMargin={disableBottomMargin}
            errorMessage={errorMessage}
            {...formItem}
          >
            {getFieldDecorator(name, {
              ...additionalParams,
              rules: [
                {
                  validator: (
                    rule: any,
                    value: string | number,
                    callback: any
                  ): any => {
                    handleValidation(
                      { [name]: fieldValidation },
                      { [name]: value },
                      callback
                    );
                  },
                },
              ],
            })(
              // Use children to set other field such as Select or Checkbox
              children ? (
                React.cloneElement(children, childrenParams)
              ) : (
                <Input
                  placeholder={
                    placeholder
                      ? placeholder
                      : placeholder === null
                      ? ""
                      : label
                  }
                  name={name}
                  type={type}
                  {...childrenParams}
                  {...fieldAdditionalProps}
                />
              )
            )}
          </FormItem>
        );
      }}
    </FormContext.Consumer>
  );
};
