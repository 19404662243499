import Employees from '../containers/Employees';
import EmployeeEdit from '../containers/Employee/Edit';
import EmployeeProfile from '../containers/Employee/Profile';
import EmployeeCvMaker from '../containers/Employee/CvMaker';
import Calendar from '../containers/Calendar';
import Newsfeed from '../containers/Newsfeed';
import Profile from '../containers/Profile';
import History from '../containers/History';
import Request from '../containers/Request';
import ChangePassword from '../containers/ChangePassword';
import Approval from '../containers/Approval';
import Weekends from '../containers/Profile/Weekends';
import WeekendsHistory from '../containers/Profile/Weekends/History/index';
import Inventories from '../containers/Profile/Inventories';
import AcceptanceByEmail from '../containers/AcceptanceByEmail';
import SingleEvents from '../containers/Events/Item';
import settings from './settings';
import Entitlement from '../components/Entitlement';
import WelcomeBook from '../components/WelcomeBook';
import Workspace from '../containers/Workspace';
import Insurance from '../components/Insurance';
import CompanyPolicyDocuments from '../components/CompanyPolicyDocuments';

export default {
  home: {
    component: Calendar,
    path: '/',
  },
  employeeProfile: {
    component: EmployeeProfile,
    path: '/employee/profile/:id',
  },
  employeeEdit: {
    component: EmployeeEdit,
    path: '/employee/edit/:id',
  },
  employeeAdd: {
    component: EmployeeEdit,
    path: '/employee/create',
  },
  employeeCvMaker: {
    component: EmployeeCvMaker,
    path: '/employee/cv/:id',
  },
  employees: {
    component: Employees,
    path: '/employees',
  },
  ...settings,
  userWeekends: {
    component: Weekends,
    path: '/weekends',
  },
  information: {
    component: WelcomeBook,
    path: '/information',
  },
  workspace: {
    component: Workspace,
    path: '/Workspace',
  },
  entitlement: {
    component: Entitlement,
    path: '/information/entitlement',
  },
  welcomeBook: {
    component: WelcomeBook,
    path: '/information/welcome-book',
  },
  insurance: {
    component: Insurance,
    path: '/information/insurance',
  },
  companyPolicyDocuments: {
    component: CompanyPolicyDocuments,
    path: '/information/company-policy-documents',
  },
  userWeekendsHistory: {
    component: WeekendsHistory,
    path: '/weekends/:id/history',
  },
  userWeekendsRequests: {
    component: History,
    path: '/weekends/:id/requests',
  },
  requestItem: {
    component: Request,
    path: '/request/item/:id',
  },
  singleEvent: {
    component: SingleEvents,
    path: '/events/item/:id',
  },
  profile: {
    component: Profile,
    path: '/profile',
  },
  userInventories: {
    component: Inventories,
    path: '/inventories',
  },
  changePassword: {
    component: ChangePassword,
    path: '/change-password',
  },
  approval: {
    component: Approval,
    exact: true,
    path: '/approval',
  },
  acceptanceByEmail: {
    component: AcceptanceByEmail,
    path: '/view-request',
  },
  newsfeed: {
    component: Newsfeed,
    path: '/newsfeed',
  },
  redirect: {
    isVisible: () => false,
    redirect: '/',
    path: '/*',
  },
};
