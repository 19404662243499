import React from "react";
import "./styles.scss";
import EntitlementItem from "./item/EntitlementItem";

const Entitlement = () => {
  const entitlementData = [
    {
      title: "Day Off",
      text: (
        <ul>
          <li>
            An <b>unpaid</b> leave.
          </li>
          <li>
            There is <b>no upper limit</b> to the number of such days during the
            year.
          </li>
          <li>
            You can create a Day Off request <b>on the same day</b>.
          </li>
        </ul>
      ),
      lineColor: "red",
    },
    {
      title: "Sick Leave",

      text: (
        <ul>
          <li>
            Sick leaves of <b>10 days per year</b> are available (do not require
            a note from a doctor).
          </li>
          <li>
            You can create a Sick Leave request <b>on the same day.</b>
          </li>
          <li>
            The monthly balance of sick leaves (0.83 days) is accrued
            <b> on the first day of the month</b> (10/12 = 0.83 per month).
          </li>
          <li>
            Sick leaves are accrued since the beginning of the <b>new year</b>.
            If you joined the devabit family during the mid-year,
            <br /> you would have fewer available sick leaves (see Future
            Accrual to check your current balance).
          </li>
          <li>
            You can <b>exceed the limit</b> of sick leaves during the year and
            request two extra days.
          </li>
          <li>
            At the end of each year, all accrued sick leaves are
            <b> eliminated</b>.
          </li>
          <li>
            The Sick Leave request option is available
            <b> after the trial period</b> <br /> (3 months) or earlier (must be
            discussed with your manager).
          </li>
        </ul>
      ),
      lineColor: "green",
    },
    {
      title: "Work from Home",
      text: (
        <ul>
          <li>
            The request must <b>include a reason.</b>
          </li>
          <li>
            There is <b>no upper limit</b> to the number of such days during the
            year.
          </li>
          <li>
            You can create a Work from Home request <b>on the same day.</b>
          </li>
        </ul>
      ),
      lineColor: "blue",
    },
    {
      title: "Vacation",
      text: (
        <ul>
          <li>
            <b>15 days</b> of vacation <b>per year</b> are available (only
            working days are counted, without weekends and public holidays).
          </li>
          <li>
            The monthly balance of vacation days (1.25 days) is accrued
            <b> on the first day of the month</b> (15/12 = 1.25 per month).
          </li>
          <li>
            You must create a request <b> no later than 10 working days</b>(two
            weeks) before the actual vacation.
          </li>
          <li>
            At the end of each year, <b>10 vacation days are transferred</b> to
            the next year while the rest are eliminated.
          </li>
          <li>
            The Vacation request option is available
            <b> after the trial period</b> (3 months).
          </li>
        </ul>
      ),
      lineColor: "dark-blue",
    },
  ];
  return (
    <div className="entitlement_container">
      <h2 className="page-header mb-10">Entitlement</h2>
      {entitlementData.map(({ title, text, lineColor }, index) => (
        <EntitlementItem
          title={title}
          text={text}
          lineColor={lineColor}
          backgroundColor={index % 2 === 0 ? "white" : "grey"}
          key={index}
        />
      ))}
      <div className="entitlement_item__advice">
        *** Please note that all options must be agreed upon with the manager.
      </div>
    </div>
  );
};

export default Entitlement;
