import React from "react";
import appConfig from "../../../../../configs/app";
import { Group, Image, Layer, Rect, Text } from "react-konva";
import {
  IBookingRequest,
  ITableSector,
  TStatus,
} from "src/interfaces/containers/tableBooking";
import { open } from "../../../../../services/modal";
import { BOOKED_BY_MODAL_HEIGHT, BOOKED_BY_MODAL_WIDTH, MAP_BASE_HEIGHT, MAP_BASE_WIDTH } from "../const";
import { setTable } from "src/services/bookingTable";
import store from "src/configs/store";
import IUser from "src/interfaces/user";
import { KonvaEventObject } from "konva/lib/Node";
import usersAPI from "../../../../../API/users";
import errors from "../../../../../API/error";
import BookedByModal from "./BookedByModal";
interface ICordinates {
  x: number;
  y: number;
}

interface IProps {
  width: number;
  height: number;
  isFullScreen: Boolean;
  sectorData: ITableSector;
}
interface IState {
  image: HTMLImageElement;
  isBookingByModalOpen: Boolean;
  bookingByModalCoordinates: ICordinates;
  users?: IUser[];
  currentUser?: IUser;
}

class TableMap extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isBookingByModalOpen: false,
      bookingByModalCoordinates: { x: 0, y: 0 },
      image: new window.Image(),
    };
  }
  componentDidMount(): void {
    this.setImage();
    this.getUserData().then((value) => {
      this.setState({ users: value });
    });
  }
  getUserData = async () => {
    try {
      const res = await usersAPI.get({ query: { limit: 999 } });
      return res.data.list;
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.sectorData.name !== this.props.sectorData.name && prevProps) {
      this.setImage();
    }
    if ((prevProps.sectorData.name !== this.props.sectorData.name && prevProps) || (prevProps.isFullScreen !== this.props.isFullScreen)) {
      this.setState({   
        isBookingByModalOpen: false,
      });
    }

  }
  handleOnCloseModal = () => {
    this.setState({
      isBookingByModalOpen: false,
    });
  };
  setImage = () => {
    const {
      sectorData: { mapImage },
    } = this.props;
    const image = new window.Image();
    image.src = appConfig.staticFolder + mapImage;
    image.onload = () => {
      this.setState({
        image: image,
      });
    };
  };
  getCoordinates = (defaultCoordinateX: number, defaultCoordinateY: number) => {
    const { width: currentWidth, height: currentHeight } = this.props;
    const coordinateXInPercent = (defaultCoordinateX * 100) / MAP_BASE_WIDTH;
    const coordinateYInPercent = (defaultCoordinateY * 100) / MAP_BASE_HEIGHT;
    const y = (currentHeight / 100) * coordinateYInPercent;
    const x = (currentWidth / 100) * coordinateXInPercent;
    return { x, y };
  };

  getSize = (defaultWidth: number, defaultHeight: number) => {
    const { width: currentWidth, height: currentHeight } = this.props;
    const widthInPercent = (defaultWidth * 100) / MAP_BASE_WIDTH;
    const hightInPercent = (defaultHeight * 100) / MAP_BASE_HEIGHT;
    const width = (currentWidth / 100) * widthInPercent;
    const height = (currentHeight / 100) * hightInPercent;
    return { width, height };
  };
  onTableHandleClick = (
    event: KonvaEventObject<MouseEvent> | KonvaEventObject<Event>,
    tableId: string,
    requestHistory: IBookingRequest[]
  ) => {
    if (requestHistory.length < 1) {
      setTable(tableId);
      open("bookingRequest");
    } else {
      const stage = event.target.getStage();
      const position = stage?.getPointerPosition();
      const { bookedFor } = requestHistory[0];
      const { users } = this.state;
      const userData = users?.filter((user) => user._id === bookedFor)[0];
    const {width, height, isFullScreen} = this.props
      if (position) {
        if (!isFullScreen) {
          this.setState({
            currentUser: userData,
            isBookingByModalOpen: true,
            bookingByModalCoordinates: {
              x: width/2-BOOKED_BY_MODAL_WIDTH/2,
              y: height/2-BOOKED_BY_MODAL_HEIGHT/2,
            },
          });
        } else {
          this.setState({
            currentUser: userData,
            isBookingByModalOpen: true,
            bookingByModalCoordinates: {
              x: position?.x,
              y: position?.y,
            },
          });
        }
      }
    }
  };

  getFontSize = (s: number) => {
    switch (true) {
      case s > 6000:
        return 16;
      case s < 6000 && s > 4000:
        return 14;
      case s < 4000 && s > 1000:
        return 10;
      case s < 1000 && s > 500:
        return 8;
      case s < 500 && s > 0:
        return 5;
    }
  };

  getTextSize = (text: string, fontSize: number) => {
    const fontSizeInPt = fontSize / 1.33;
    let width = 0,
      height = 0;
    const font = fontSizeInPt + "pt";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      const {
        width: textWidth,
        fontBoundingBoxAscent,
        fontBoundingBoxDescent,
      } = context.measureText(text);
      height = fontBoundingBoxAscent + fontBoundingBoxDescent;
      width = textWidth;
    }
    return { width, height };
  };
  getFillColor = (
    tableStatus: TStatus,
    tableRequestData: IBookingRequest[]
  ): string => {
    if (tableRequestData.length > 0) {
      const { bookedFor } = tableRequestData[0];
      const { _id: userId } = store.getState().user;
      return bookedFor === userId ? "#191F2D" : "#D9D9D9";
    }
    switch (tableStatus) {
      case "available":
        return "#F0F0F0";
      case "hot":
        return "#D9E9F5";
      case "dedicated":
        return "#D8DCF5";
      default:
        return "f0f0f0";
    }
  };
  getTextColor = (
    tableStatus: TStatus,
    tableRequestData: IBookingRequest[],
    tableId: string
  ): string => {
    const { table: currentTable } = store.getState().bookingTable;

    if (tableRequestData.length > 0) {
      const { bookedFor } = tableRequestData[0];
      const { _id: userId } = store.getState().user;
      return bookedFor === userId ? "#FFFFFF" : "#828282";
    }
    if (currentTable === tableId) return "#333333";
    return "#408D2B";
  };

  render() {
    const {
      image,
      currentUser,
      isBookingByModalOpen,
      bookingByModalCoordinates,
    } = this.state;
    const { width, height, sectorData } = this.props;
    const { table: currentTable, tablesData } = store.getState().bookingTable;

    return (
      <Layer>
        {tablesData[0]?.sectorId === sectorData._id && (
          <Image width={width} height={height} image={image} />
        )}
        {tablesData.map((table) => {
          const {
            coordinateX,
            coordinateY,
            name,
            status,
            history,
            rotation,
            _id,
            width: defaultWidth,
            height: defaultHeight,
          } = table;
          if (
            coordinateX &&
            coordinateY &&
            tablesData[0]?.sectorId === sectorData._id
          ) {
            const { width, height } = this.getSize(defaultWidth, defaultHeight);
            const { x, y } = this.getCoordinates(coordinateX, coordinateY);
            const tableLabel = sectorData.name + name;
            const fontSize = this.getFontSize(width * height);
            return (
              <Group
                key={_id}
                onClick={(event) => {
                  if (status === "available")
                    this.onTableHandleClick(event, _id, history);
                }}
                onTap={(event) => {
                  if (status === "available")
                    this.onTableHandleClick(event, _id, history);
                }}
              >
                <Rect
                  width={width}
                  height={height}
                  x={x}
                  fill={this.getFillColor(status, history)}
                  strokeWidth={1}
                  rotation={rotation}
                  y={y}
                  stroke={currentTable === _id ? "#191F2D" : "#828282"}
                />
                <Text
                  x={x}
                  y={y}
                  rotation={rotation}
                  width={width}
                  wrap="char"
                  align="center"
                  verticalAlign="middle"
                  fontSize={fontSize}
                  fontStyle={"bold"}
                  height={height}
                  text={tableLabel}
                  fill={this.getTextColor(status, history, _id)}
                />
              </Group>
            );
          }
          return null;
        })}
        {isBookingByModalOpen && currentUser && (
          <BookedByModal
             width={width}
            userData={currentUser}
            bookingByModalCoordinates={bookingByModalCoordinates}
            handleOnCloseModal={this.handleOnCloseModal}
          />
        )}
      </Layer>
    );
  }
}

export default TableMap;
