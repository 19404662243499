import React from "react";
import {Row, Col} from 'antd';
import Avatar from "../../../components/Avatar";

interface Props {
  date: string
  [key: string]: any
}

export default (props: Props) => {
  const fullName = `${props.firstName || ''} ${props.lastName || ''}`.trim();
  return <Row className="p-20" type="flex" justify="start">
    <Avatar size={50} user={props as any}/>
    <Col className="pl-20">
      <div className="nfi-user-name">
        {
          fullName
        }
      </div>
      <div className="nfi-date">{
        props.date
      }</div>
    </Col>
  </Row>
}