import React from "react";
import {Button, Row, Spin} from "antd";
import {generatePath} from "react-router";
import apiInventories from '../../../API/inventories';
import NavLink from '../../../components/NavLink'
import Table from '../../../components/Table'
import Pagination from '../../../components/Pagination'
import Settings from "../../../layouts/Settings";
import routesInventories from "../../../routes/inventories";
import TablePagination from "../../../services/tablePagination";
import columns from "./columns";
import {IActionsList} from "../../../components/DotMenu";
import errors from "../../../API/error";

interface State {
  loading: boolean
  data: any[]
}

export default class CreateEdit extends React.Component<any, State> {
  pagination: TablePagination;

  constructor(props: any) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
      updateQueryOnChange: true,
    });

    this.state = {
      data: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      const {data} = await apiInventories.getGroups({
        query: this.pagination.requestParams()
      });
      const {list, pagination} = data;
      this.pagination.value = pagination;
      this.setState({data: list, loading: false})
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
      this.setState({loading: false})
    }
  };

  handleRemove = async ({_id}: any) => {
    try {
      this.setState({loading: true});
      await apiInventories.deleteGroup(null, {params: {id: _id}})
      this.setState({loading: false}, this.getData);
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to remove'});
      this.setState({loading: false})
    }
  };

  actions: IActionsList = [
    {
      name: 'Remove',
      confirm: true,
      disablePropagation: true,
      action: this.handleRemove
    }
  ];

  render() {
    const {loading, data} = this.state;
    return <Settings
      location={this.props.location}
      title="Inventories Groups"
      actionsComponent={
        <Row type="flex" justify="end">
          <NavLink to={routesInventories.inventoriesGroupsCreate.path}>
            <Button className="ant-btn-primary ml-15">Create Group</Button>
          </NavLink>
        </Row>
      }
    >
      <Spin tip="Loading..."
            spinning={loading}>
        <Table dataSource={data}
               rowKey="_id"
               clickable
               onRow={(record: any) => {
                 return {
                   onClick: () => {
                     this.props.history.push(generatePath(routesInventories.inventoriesGroupsEdit.path, {id: record._id}))
                   }
                 };
               }}
               columns={columns(this.actions)}
               pagination={this.pagination.tableConfig}
               onChange={this.pagination.tableChange.bind(this.pagination)} />
        <Pagination {...this.pagination.config} />
      </Spin>
    </Settings>;
  }
}