import React from "react";
import {Row, Col, Checkbox} from 'antd'
import Field from '../../../ui/Field';

interface IItem {
  key: string,
  name: string
}

export default (props: any) => {
  const {items = [], selected = []} = props.modalProps;
  if (!items || items.length === 0) {
    props.onCancel(true)
  }
  return <Row>
    {
      items.map((item: IItem, index: number) =>
        <Col span={12}
             key={index} className="mb-15">
          <Field name={item.key}
                 defaultChecked={selected.includes(item.key)}
                 defaultValue={selected.includes(item.key)}>
            <Checkbox>{item.name}</Checkbox>
          </Field>
        </Col>)
    }
  </Row>
}