import * as React from 'react';
import {Spin, Row, Col, Button} from 'antd';
import {generatePath} from 'react-router'
import {connect} from "react-redux";
import Settings from "../../layouts/Settings";
import requestsApi from '../../API/requests';
import apiWeekends from '../../API/weekends';
import errors from "../../API/error";
import IUser from '../../interfaces/user';
import columns from "./columns";
import userRoutes from '../../routes/user';
import statisticsRoutes from '../../routes/statistics';
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import NavLink from "../../components/NavLink";
import AbsenceCard from "../../components/AbsenceCard";
import TablePagination from "../../services/tablePagination";

import {Filters as FiltersBtn} from '../../components/Buttons'
import FiltersComponent from "../../components/Filters";
import FiltersLayout from "./FiltersLayout";
import exportFile from "../../services/exportFile";

interface IProps {
  user: IUser,
  fileAvatar: Blob,
  history: any
  match: any
  location: any
}

class Profile extends React.Component<IProps, any> {
  private readonly pagination: TablePagination;

  constructor(props: IProps) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData
    });

    this.state = {
      loading: true,
      data: [],
      weekends: {user: {}, list: []},
      activeFilters: false,
      filters: {}
    };
  }

  componentDidMount() {
    this.pagination.value = {
      sort: 'from',
      order: 'desc',
    };
    this.getAbsences();
    this.getData();
  };

  getQuery = () => {
    const {filters} = this.state;
    const query: any = {};
    if (filters.status && filters.status.length) {
      query.status = JSON.stringify(filters.status)
    }
    if (filters.range && filters.range.length) {
      const [from, to] = filters.range;
      query.from = from.format('YYYY-MM-DD');
      query.to = to.format('YYYY-MM-DD');
    }
    if (filters.weekends && filters.weekends.length) {
      query.weekends = JSON.stringify(filters.weekends)
    }
    const {id} = this.props.match.params;
    query.users = JSON.stringify([id]);
    return query;
  };

  getData = async () => {
    try {
      const query = this.getQuery();

      const res = await requestsApi.getRequestsHistory({
        query: {...this.pagination.requestParams(), ...query}
      });
      const {data} = res;

      this.pagination.value = data.pagination;

      this.setState({data: data.list, loading: false});
    } catch (e) {
      errors.handle(e, undefined, {priority: 'all'});
      this.setState({loading: false})
    }
  };

  exportData = async () => {
    try {
      const query = this.getQuery();
      const res = await requestsApi.getExportRequestsHistory({
        query: {
          ...this.pagination.requestParams(),
          ...query,
          limit: 1000,
          offset: 0,
          order: 'desc'
        }
      });
      await exportFile.exportInit(res.data)
    } catch (e) {
      errors.handle(e, undefined, {priority: 'all'});
    }
  };

  getAbsences = async () => {
    try {
      const {id} = this.props.match.params;
      const {data} = await apiWeekends.getUserWeekends({params: {id}});
      this.setState({weekends: data})
    } catch (e) {
      errors.handle(e, undefined, {priority: 'all'});
    }
  };

  toggleFilters = () => {
    this.setState({activeFilters: !this.state.activeFilters})
  };

  handleSubmitFilters = ({values}: any) => {
    this.setState({filters: values}, this.getData)
  };

  handleColumnSort = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: [] }) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  render() {
    const {loading, data, weekends, activeFilters} = this.state;
    const {user, list} = weekends;
    const userName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
    return <Settings
      location={this.props.location}
      title={`Absences${userName ? ` - ${userName}` : ''}`.trim()}>

      <Spin tip="Loading..." spinning={loading}>
        {
          list.length ? <>
            <h2 className="page-header mb-10">Entitlement</h2>
            <Row gutter={20}>
              {
                list.map(({
                            color,
                            unlimitedDays,
                            days,
                            nextBreakDays,
                            pendingDays,
                            approvedDays,
                            name,
                            _id
                          }: any, index: number) => <Col
                  md={{span: 12}}
                  className="mb-20"
                  key={index}>
                  <NavLink to={statisticsRoutes.requestsHistory.path} params={{id: user._id, userWeekendId: _id}}>
                    <AbsenceCard
                      title={name}
                      days={days}
                      pending={pendingDays}
                      unlimitedDays={unlimitedDays}
                      accrual={nextBreakDays}
                      approved={approvedDays}
                      color={color} />
                  </NavLink>
                </Col>)
              }
            </Row>
          </> : null
        }
        <Row className="pt-20">
          <Col span={12}>
            <h2 className="page-header">Requests History</h2>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <FiltersBtn
                onClick={this.toggleFilters}
                active={activeFilters} />
              <Button className="v-align-top ml-15" type="primary"
                      onClick={this.exportData}>Export</Button>
            </Row>
          </Col>
        </Row>
        <Row className="mb-10">
          <FiltersComponent
            visible={activeFilters}
            onSubmit={this.handleSubmitFilters}>
            <FiltersLayout list={list} />
          </FiltersComponent>
        </Row>

        <Table dataSource={data}
               rowKey="_id"
               clickable
               onRow={(record: any) => {
                 return {
                   onClick: () => {
                     const url = generatePath(userRoutes.requestItem.path, {id: record._id});
                     this.props.history.push(url)
                   }
                 };
               }}
               onChange={this.handleColumnSort}
               columns={columns()}
               pagination={this.pagination.tableConfig} />
        <Pagination {...this.pagination.config} />
      </Spin>
    </Settings>
  }
}

export default connect(({user}: { user: IUser }) => ({user}))(Profile)