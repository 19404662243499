import * as api from './index';

const P = '/cv';

export default {
  getList: api.get(`${P}/list/:id`), //userId
  getInfo: api.get(`${P}/info/:id`), //cvId
  getPdf: api.get(`${P}/pdf/:id`, { responseType: 'arraybuffer' }), //cvId
  post: api.post(`${P}`),
  put: api.put(`${P}`),
  delete: api.del(`${P}/:id`), //cvId
};
