import React from "react";
import { Image, Layer, Line } from "react-konva";
import officeMap from "../../../../../assets/imgs/bookingTable/Office Map_zones_new colors 1.jpg";
import { ITableSector } from "../../../../../interfaces/containers/tableBooking";
import { MAP_BASE_HEIGHT, MAP_BASE_WIDTH } from "../const";

interface IProps {
  sectorsData: ITableSector[];
  handleSelectSector: (sectorId: string) => void;
  width: number;
  height: number;
}
interface IState {
  image: HTMLImageElement;
}

class SectorMap extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      image: new window.Image(),
    };
  }
  componentDidMount() {
    const image = new window.Image();
    image.src = officeMap;
    image.onload = () => {
      this.setState({
        image: image,
      });
    };
  }

  render() {
    const { image } = this.state;
    const { width, height } = this.props;

    // do calculations for stage properties
    const scaleX = width / MAP_BASE_WIDTH;
    const scaleY = height / MAP_BASE_HEIGHT;

    const { sectorsData, handleSelectSector } = this.props;
    return (
      <Layer>
        <Image width={width} height={height} image={image} />
        {sectorsData.map((sector) => (
          <Line
            key={sector._id}
            points={sector.coordinates}
            closed
            scaleX={scaleX}
            scaleY={scaleY}
            onMouseEnter={(e) => {
              const stage = e.target.getStage();
              if (stage) {
                const container = stage.container();
                container.style.cursor = "pointer";
              }
            }}
            onMouseLeave={(e) => {
              const stage = e.target.getStage();
              if (stage) {
                const container = stage.container();
                container.style.cursor = "default";
              }
            }}
            onTap={() => handleSelectSector(sector._id)}
            onClick={() => handleSelectSector(sector._id)}
          />
        ))}
      </Layer>
    );
  }
}

export default SectorMap;
