import * as React from "react";
import {Col} from "antd";
import Avatar from "../../../components/Avatar";
import NavLink from "../../../components/NavLink";
import './stylesItem.scss'

const CLASS_NAME = 'today-item';

export default ({
                  redirectTo,
                  redirectId,
                  firstName,
                  lastName,
                  avatar,
                  defaultAvatar,
                  bpColor,
                  description,
                  descriptionColor
                }: any) => <NavLink to={redirectTo}
                                    params={{id: redirectId}}
                                    className={CLASS_NAME}>
  <Col className={`${CLASS_NAME}-preview`}>
      <span
        className={`${CLASS_NAME}-point`}
        style={{backgroundColor: bpColor}} />
    <Avatar user={{_id: '', firstName, lastName, avatar}}
            icon={defaultAvatar}
            size={40} />
  </Col>
  <Col className={`${CLASS_NAME}-text`}>
    <div className={`${CLASS_NAME}-name`}>
      {`${firstName || ''} ${lastName || ''}`.trim()}
    </div>
    <div className={`${CLASS_NAME}-description`}
         style={{
           color: descriptionColor || 'initial'
         }}>
      {description || ''}
    </div>
  </Col>
</NavLink>