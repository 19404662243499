import {connect} from "react-redux";

interface IProps {
  user: any,
  children: any,
  permissions: string[],
  groupId?: string
}

const Has = (props: IProps) => {
  const {companyAccess: uPerm = {}} = props.user;
  const {permissions} = props;
  const hasAccess = permissions.every((key: string) => !!uPerm[key]);
  return hasAccess
    ? props.children
    : null
};

export default connect(({
                          user
                        }: any) =>
  ({
    user
  }))(Has);