import * as React from 'react';
import Field from '../../../ui/Field';
import {Users} from '../../../components/Filters';


const WiFiConnections = (props: any) => {
  const {item = {}} = props.modalProps;
  return <>
    <Field name="name"
           defaultValue={item.name}
           validation={false}
           label="Network Name" />
    <Field name="key"
           defaultValue={item.key}
           label="Network Key" />
    <Field name="login"
           defaultValue={item.login}
           label="Login" />
    <Field name="password"
           defaultValue={item.password}
           validation={false}
           label="Password" />
    <Users label="Employees"
           maxTagCount={null}
           statuses={['active', 'inactive']}
           defaultSelected={item.users} />
  </>
};

export default WiFiConnections;