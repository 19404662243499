import React from "react";
import {connect} from "react-redux";
import Table from "../../../../components/Table";
import columns from './columns'

export default connect(({user}: any) => ({data: user.wifi}))((props: any) => {
  if (!props.data || props.data.length === 0) {
    return null
  }
  return <div className="table-min-width">
    <h2 className="page-header mb-10">Wi-fi Settings</h2>
    <Table columns={columns}
           dataSource={props.data}
           size="small"
           rowKey="_id"
           pagination={{
             hideOnSinglePage: true,
           }}/>
  </div>
})