import * as api from './index';

const P = '/inventories';

export default {
  getComponents: api.get(`${P}/components`),

  getGroups: api.get(`${P}/groups`),
  getGroup: api.get(`${P}/group/:id`),
  postGroup: api.post(`${P}/group`),
  putGroup: api.put(`${P}/group/:id`),
  deleteGroup: api.del(`${P}/group/:id`),

  get: api.get(P),
  getItem: api.get(`${P}/item/:id`),
  post: api.post(P),
  put: api.put(`${P}/:id`),

  patchStatus: api.patch(`${P}/status/:id`),
  getHistory: api.get(`${P}/history/:id`),
  getAssignedUsers: api.get(`${P}/assigned-users/:id`),
  postAssignUser: api.post(`${P}/assign-user/:id`),
  putAssignUser: api.put(`${P}/assign-user/:id`),
  postUnassignUser: api.post(`${P}/unassign-user/:id`),
  getList: api.get(`${P}/list`),
  getPublic: api.get(`${P}/public`),
  getNotifications: api.get(`${P}/notifications`),
  postDuplicate: api.post(`${P}/duplicate/:id`),
  getUniqueId: api.get(`${P}/unique-id/:id`),

  postReturningApprove: api.post(`${P}/returning-approve/:id`),
  postReturningReject: api.post(`${P}/returning-reject/:id`),
  postResponsibleAssignUsers: api.post(`${P}/responsible/assign-users/:id`),

  postUserApprove: api.post(`${P}/user/approve/:id`),
  postUserReject: api.post(`${P}/user/reject/:id`),
  postUserReturn: api.post(`${P}/user/return/:id`),
  postUserAssign: api.post(`${P}/user/assign/:id`),

  export: api.get(`${P}/export`, {
    responseType: 'arraybuffer'
  }),
};
