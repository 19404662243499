import * as React from 'react';
import {
  Button,
  notification
} from 'antd';
import groupsAPI from '../../API/groups';
import * as userService from "../../services/user/profile";
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Settings from '../../layouts/Settings'
import columns from "./columns";
import modal from '../../containers/Modals/set/calendarGroup';
import modalRemoveCalendar from '../../containers/Modals/set/removeCalendar';
import TablePagination from "../../services/tablePagination";
import errors from "../../API/error";

interface IState {
  list: object[],
  pagination: any,
  id: number | string,
  loading: boolean
}

class CalendarGroup extends React.Component<any, IState> {
  pagination = new TablePagination();
  state = {
    list: [],
    pagination: {
      total: 0
    },
    id: 0,
    loading: true
  };

  componentDidMount() {
    modal.init({
      onSubmit: this.handleSubmit
    });
    modalRemoveCalendar.init({
      onRemove: this.handleAcceptRemove
    });
    this.getGroups();
  };

  addGroup = () => {
    this.setState({id: 0}, () => {
      modal.open()
    });
  };

  getGroups = async () => {
    try {
      this.setState({loading: true});
      const res = await groupsAPI.get({query: {...this.pagination.requestParams()}});
      const {list, pagination} = res.data;
      this.pagination.value = pagination;
      this.setState({list, pagination, loading: false})
    } catch (e) {
      this.setState({loading: false});
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
    }
  };

  handleSubmit = async ({form, onLoading, values, onCancel}: any) => {
    try {
      onLoading(true);
      const {list, pagination, id} = this.state;
      if (id) {
        const res = await groupsAPI.update(values, {params: {id}});
        const {data} = res;
        const editedList = list.map((item: any) =>
          item.id === data.id ? {...item, ...data} : item);
        this.setState({list: editedList});
      } else {
        const res = await groupsAPI.create(values);
        this.setState({
          list: [res.data, ...list],
          pagination: {...pagination, total: pagination.total + 1}
        });
      }
      await this.getGroups();
      userService.get();
      onLoading(false);
      onCancel();
    } catch (e) {
      errors.handle(e, form, {priority: 'all', defaultMessage: 'Failed'});
      onLoading(false);
    }
  };

  handleEdit = (record: any) => {
    this.setState({id: record._id});
    modal.open(record);
  };

  handleRemoveTrigger = (id: number) => {
    this.setState({id});
    modalRemoveCalendar.open({id})
  };

  handleAcceptRemove = async ({onCancel, onLoading}: any) => {
    try {
      onLoading(true);
      const {id, list, pagination} = this.state;
      await groupsAPI.del(null, {params: {id}});
      const edited = list.filter((item: any) => item.id !== id);
      this.setState({
        list: edited,
        pagination: {...pagination, total: pagination.total - 1}
      });
      notification.success({
        message: 'Success'
      });
      await this.getGroups();
      userService.get();
      onLoading(false);
      onCancel();
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to remove'})
    }
  };

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getGroups();
  };

  render() {
    const {list, loading} = this.state;
    return <Settings location={this.props.location} actionsComponent={
      <Button className="ant-btn-primary"
              onClick={this.addGroup}>Create Business Unit</Button>
    }>
        <Table dataSource={list}
               loading={loading}
               rowKey="_id"
               columns={columns({
                 onRemove: this.handleRemoveTrigger,
                 onEdit: this.handleEdit
               })}
               pagination={this.pagination.tableConfig}/>
      <Pagination {...this.pagination.config} onChange={this.changePage}/>
    </Settings>
  }
}

export default CalendarGroup