import React from 'react'
import {Col, Select} from "antd";
import Field from "../../ui/Field";

interface IProps {
  name?: string
  label?: string
  col?: {
    [key: string]: any
  }
}

const STATIC_OPTIONS = [
  {
    value: 'approved',
    name: 'Approved'
  },
  {
    value: 'rejected',
    name: 'Rejected',
  },
  {
    value: 'cancelled',
    name: 'Cancelled'
  },
  {
    value: 'pending',
    name: 'Pending'
  },
  // {
  //   value: 'pendingCancellation',
  //   name: 'Pending Cancellation'
  // }
];

export default (props: IProps) => {
  const {
    name = 'status',
    label = 'Status',
    col
  } = props;

  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
    >
      <Select
        mode="multiple"
        maxTagCount={1}
        notFoundContent="Not found"
        optionFilterProp="children"
      >
        {
          STATIC_OPTIONS.map((item, i: number) =>
            <Select.Option
              value={item.value}
              key={i}>{item.name}</Select.Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  };
  return getItem()
}