import React from "react";

export default () => <svg width="300" height="167" viewBox="0 0 300 167" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M231 83.258C223.973 83.6185 218.386 89.4301 218.386 96.5466C218.386 102.255 221.981 107.124 227.03 109.013C226.94 109.294 226.849 109.574 226.756 109.854H228.629C235.978 109.854 241.936 115.811 241.936 123.16C241.936 130.509 235.978 136.466 228.629 136.466H212.054C196.74 155.109 173.509 167 147.5 167C109.702 167 77.7705 141.886 67.4809 107.434H56.8552C49.5062 107.434 43.5488 101.476 43.5488 94.1275C43.5488 86.7786 49.5063 80.8211 56.8552 80.8211H64.0423L64.0423 80.8208H70.7259C78.0748 80.8208 84.0323 74.8633 84.0323 67.5144C84.0323 60.1655 78.0748 54.208 70.7259 54.208H69.2824L69.2825 54.2077H68.9525C61.6035 54.2077 55.6461 48.2503 55.6461 40.9013C55.6461 33.5524 61.6036 27.5949 68.9525 27.5949H85.476C100.758 10.6507 122.885 0 147.5 0C184.215 0 215.395 23.6954 226.582 56.6275H260.081C267.43 56.6275 273.388 62.5849 273.388 69.9339C273.388 77.2828 267.43 83.2403 260.081 83.2403H231L231 83.258ZM254.032 123.16C254.032 115.811 259.99 109.854 267.339 109.854H286.694C294.042 109.854 300 115.811 300 123.16C300 130.509 294.042 136.466 286.694 136.466H267.339C259.99 136.466 254.032 130.509 254.032 123.16ZM13.3074 27.5949C5.95845 27.5949 0.000976562 33.5524 0.000976562 40.9013C0.000976562 48.2503 5.95846 54.2077 13.3074 54.2077H32.6621C40.0111 54.2077 45.9685 48.2503 45.9685 40.9013C45.9685 33.5524 40.011 27.5949 32.6621 27.5949H13.3074Z"
        fill="#EEF0F9" />
  <path
    d="M187.448 103.957C195.505 104.356 210.974 102.126 208.396 90.0222C205.173 74.8927 195.908 84.2491 198.124 88.2305V88.2305C202.975 96.9485 212.058 82.7757 212.493 72.8082L212.827 65.1381"
    stroke="#191F2D" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8 2" />
  <path
    d="M111.311 94.9384C104.101 91.0037 88.1206 85.4951 81.8725 94.9384C80.9281 96.3657 80.6306 98.1121 80.8061 100.049C81.94 112.562 106.479 100.069 94.6892 95.7252V95.7252C88.2809 93.3645 72.06 104.578 80.2706 111.857"
    stroke="#191F2D" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="8 2" />
  <rect x="112.5" y="49.5" width="75" height="65" rx="1.5" fill="white" stroke="#191F2D" />
  <path
    d="M112.5 51C112.5 50.1716 113.172 49.5 114 49.5H186C186.828 49.5 187.5 50.1716 187.5 51V63C187.5 63.8284 186.828 64.5 186 64.5H114C113.172 64.5 112.5 63.8284 112.5 63V51Z"
    fill="#EEF0F9" stroke="#191F2D" />
  <path
    d="M113.397 114.81H183.776V118.655C183.776 119.484 183.104 120.155 182.276 120.155H114.897C114.069 120.155 113.397 119.484 113.397 118.655V114.81Z"
    fill="white" stroke="#191F2D" />
  <path
    d="M117.362 120.361H181.396V122.62C181.396 123.448 180.725 124.12 179.896 124.12H118.862C118.034 124.12 117.362 123.448 117.362 122.62V120.361Z"
    fill="white" stroke="#191F2D" />
  <circle cx="125.207" cy="84.1719" r="2.37929" fill="#191F2D" />
  <circle cx="175.965" cy="84.1719" r="2.37929" fill="#191F2D" />
  <rect x="146.621" y="100.034" width="9.51718" height="1.5862" fill="#191F2D" />
  <path opacity="0.5"
        d="M212.193 60.2928C212.193 61.1449 211.447 61.8357 210.527 61.8357C209.607 61.8357 208.862 61.1449 208.862 60.2928C208.862 59.4407 209.607 58.7499 210.527 58.7499C211.447 58.7499 212.193 59.4407 212.193 60.2928Z"
        fill="#191F2D" />
  <path opacity="0.5"
        d="M216 60.2928C216 61.1449 215.254 61.8357 214.334 61.8357C213.415 61.8357 212.669 61.1449 212.669 60.2928C212.669 59.4407 213.415 58.7499 214.334 58.7499C215.254 58.7499 216 59.4407 216 60.2928Z"
        fill="#191F2D" />
  <rect x="211.716" y="58.7499" width="1.42758" height="4.11441" fill="#191F2D" />
  <rect width="0.748427" height="2.04813" transform="matrix(0.933554 -0.358438 0.312261 0.949996 211.241 57.4745)"
        fill="#191F2D" />
  <rect width="0.750269" height="2.04365" transform="matrix(0.915463 0.402403 -0.352195 0.935927 213.124 57.2062)"
        fill="#191F2D" />
  <path opacity="0.5"
        d="M83.0674 113.72C82.5125 113.459 82.3095 112.723 82.614 112.075C82.9185 111.428 83.6151 111.115 84.1701 111.376C84.725 111.637 84.928 112.373 84.6235 113.02C84.319 113.668 83.6223 113.981 83.0674 113.72Z"
        fill="#191F2D" />
  <path opacity="0.5"
        d="M81.8071 116.399C81.2522 116.138 81.0492 115.402 81.3537 114.755C81.6582 114.107 82.3549 113.794 82.9098 114.055C83.4647 114.316 83.6677 115.052 83.3632 115.7C83.0587 116.347 82.362 116.66 81.8071 116.399Z"
        fill="#191F2D" />
  <rect x="84.2295" y="113.857" width="1.11034" height="2.9609" transform="rotate(115.19 84.2295 113.857)"
        fill="#191F2D" />
  <rect x="85.2173" y="113.914" width="0.576703" height="1.48595" transform="rotate(95.632 85.2173 113.914)"
        fill="#191F2D" />
  <rect x="84.7686" y="115.32" width="0.576703" height="1.48595" transform="rotate(137.322 84.7686 115.32)"
        fill="#191F2D" />
  <rect x="120.828" y="45.3104" width="5.55169" height="8.72408" rx="2" fill="#191F2D" />
  <rect x="146.207" y="45.3104" width="5.55169" height="8.72408" rx="2" fill="#191F2D" />
  <rect x="133.517" y="45.3104" width="5.55169" height="8.72408" rx="2" fill="#191F2D" />
  <rect x="158.896" y="45.3104" width="5.55169" height="8.72408" rx="2" fill="#191F2D" />
  <rect x="171.586" y="45.3104" width="5.55169" height="8.72408" rx="2" fill="#191F2D" />
  <path d="M112.897 41.3802V34.207M107.147 44.0943L100.604 37.6967M103.975 50.2981L97.8281 51.6552" stroke="#191F2D"
        strokeLinecap="square" strokeLinejoin="round" strokeDasharray="8 2" />
</svg>
