import React from "react";
import devabitCake from "../../../../assets/imgs/welcomeBook/loon-image-original.jpg";
import "./styles.scss";
function AboutUs() {
  return (
    <>
      <div className="about_us__container">
        <div className="about_us__text_container">
          <div className="tittle mb-25">Who we are</div>
          <div className="main-text mb-25">
            Backed by years of experience delivering innovative solutions,
            devabit is a reliable technology partner for multiple market-leading
            companies across North America and Europe. As a universal soldier
            with multidisciplinary expertise, we have proven our proficiency in
            various competencies, including software engineering, mobile & web
            development, SaaS development, 3D modeling, QA, and BPO.
          </div>
          <div className="secondary-text">
            Serving diversified industry verticals, from eCommerce to
            healthcare, we have acquired the complete skill set needed to
            deliver cutting-edge solutions that truly lead businesses to
            success. Casio, Carlsberg, and Shamballa Jewels, to mention just a
            few, — we earned the trust of multiple leaders that bring meaningful
            changes to society.
          </div>
        </div>

        <div className="about_us__img_container">
          <img src={devabitCake} alt="devabit cake" />
        </div>
        <div className="about_us__grey_block"></div>
      </div>
      <div className="about_us_mean__container">
        <div className="about_us_mean__tittle">
          devabit: What does it <b>really</b> mean?
        </div>
        <div className="secondary-text">
          In such a highly competitive environment, it may be challenging to
          keep pace with emerging trends. Fortunately, devabit easily copes with
          this task. You will probably ask: 'How is this possible?' Yet, the
          answer is pretty obvious. devabit is something more than just a
          catching title or promising slogan. Honestly, devabit is all about
          people. Skilled, talented, creative, and inspiring individuals — here
          is our main secret to success.
        </div>
      </div>
    </>
  );
}

export default AboutUs;
