import userAPI from '../../API/user';
import * as userService from './index';
import requestsApi from '../../API/requests';
import approvalCount from '../approvalCount';

export const get = async () => {
  try {
    const response = await userAPI.profile.get();
    const { data } = await requestsApi.getPending({ query: { limit: 1, offset: 0 } });
    userService.set(response.data);
    approvalCount.set(data.pagination.total);
    return response.data;
  } catch (e) {
    throw e;
  }
};


export const somePermission = async(accessProperty: string[]) => {
  const loggedUser = await get();
  const { companyAccess }: {companyAccess: {[key: string]: boolean} } = loggedUser;

  let hasAccess = false;
  if (accessProperty.length > 0 && companyAccess) {
    accessProperty.forEach((nKey: string) => {
      if (Object.prototype.hasOwnProperty.call(companyAccess, nKey) && companyAccess[nKey] === true) {
        hasAccess = true;
      }
    });
  }
  return hasAccess;
};
