const appHost = window.location.origin;

const isStaging = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const hostConfig = isStaging || !(window as any).HOST_URL ? `${window.location.protocol}//${window.location.hostname}:4040/` : (window as any).HOST_URL;


const defaultEventsColor = '#ff0000';

export default {
  host: `${hostConfig}api/v1`,
  appHost,
  defaultEventsColor,
  staticFolder: `${hostConfig}uploads/`,
  staticPolicy: `${hostConfig}policy/`,
  // Url to create new password
  redirectToNewPassword: `${appHost}/create-password`
};
