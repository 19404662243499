import * as React from "react";
import { Spin, Row, Col, Button } from "antd";
import { generatePath } from "react-router";
import { connect } from "react-redux";
import { open, setProps } from "../../../services/modal";
import requestsApi from "../../../API/requests";
import userApi from "../../../API/user";
import IUser from "../../../interfaces/user";
import columns from "./columns";
import userRoutes from "../../../routes/user";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import NavLink from "../../../components/NavLink";
import AbsenceCard from "../../../components/AbsenceCard";
import * as pathService from "../../../services/path";
import { IActionsList } from "../../../components/DotMenu";
import TablePagination from "../../../services/tablePagination";
import { isCancelOptionVisible } from "../../../utils/requestStatus";
import errors from "../../../API/error";
import { Filters as FiltersBtn } from "../../../components/Buttons";
import FiltersComponent from "../../../components/Filters";
import FiltersLayout from "./FiltersLayout";
import user from "../../../routes/user";

interface IProps {
  user: IUser;
  fileAvatar: Blob;
  history: any;
}

class Profile extends React.Component<IProps, any> {
  private readonly pagination: TablePagination;

  constructor(props: IProps) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData,
    });

    this.state = {
      loading: true,
      data: [],
      weekends: [],
      activeFilters: false,
      filters: {},
    };
  }

  componentDidMount() {
    this.getAbsences();
    this.getData();
  }

  getData = async () => {
    try {
      const { filters } = this.state;
      const query: any = {};
      if (filters.status && filters.status.length) {
        query.status = JSON.stringify(filters.status);
      }
      if (filters.range && filters.range.length) {
        const [from, to] = filters.range;
        query.from = from.format("YYYY-MM-DD");
        query.to = to.format("YYYY-MM-DD");
      }
      if (filters.weekends && filters.weekends.length) {
        query.weekends = JSON.stringify(filters.weekends);
      }

      const res = await requestsApi.getList({
        query: { ...this.pagination.requestParams(), ...query },
      });
      const { data } = res;

      this.pagination.value = data.pagination;

      this.setState({ data: data.list, loading: false });
    } catch (e) {
      errors.handle(e, undefined, { priority: "all" });
      this.setState({ loading: false });
    }
  };

  getAbsences = async () => {
    try {
      const { data } = await userApi.weekends.get();
      this.setState({ weekends: data });
    } catch (e) {
      errors.handle(e, undefined, { priority: "all" });
    }
  };

  handleCancelRequest = async ({ _id }: { _id: string }) => {
    try {
      await requestsApi.cancel({ requestId: _id });
      this.getData();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };

  actions = (record: any) => {
    const items: IActionsList = [
      {
        name: "View Details",
        link: pathService.get(userRoutes.requestItem.path, { id: record._id }),
      },
    ];

    if (isCancelOptionVisible(record)) {
      items.push({
        action: this.handleCancelRequest,
        name: "Cancel Request",
        confirm: true,
      });
    }

    return items;
  };

  sendRequest = () => {
    setProps("sendRequest", { callback: this.getData });
    open("sendRequest");
  };

  toggleFilters = () => {
    this.setState({ activeFilters: !this.state.activeFilters });
  };

  handleSubmitFilters = ({ values }: any) => {
    this.setState({ filters: values }, this.getData);
  };

  handleColumnSort = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: [] }
  ) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  render() {
    const { loading, data, weekends, activeFilters } = this.state;
    const toLeaveTypesHandler = () => {
      this.props.history.push(user.entitlement.path);
    };
  
    return (
      <Spin tip="Loading..." spinning={loading}>
        {weekends.length ? (
          <>
            <Row type="flex" justify="space-between" className="mb-20">
              <h2 className="page-header">Entitlement</h2>
              <Button
                onClick={toLeaveTypesHandler}
                className="ant-btn-primary ml-15"
              >
                Leave types
              </Button>
            </Row>
            <Row gutter={20}>
              {weekends.map(
                (
                  {
                    color,
                    unlimitedDays,
                    days,
                    nextBreakDays,
                    pendingDays,
                    approvedDays,
                    name,
                    _id,
                  }: any,
                  index: number
                ) => (
                  <Col md={{ span: 12 }} className="mb-20" key={index}>
                    <NavLink
                      to={userRoutes.userWeekendsHistory.path}
                      params={{ id: _id }}
                    >
                      <AbsenceCard
                        title={name}
                        days={days}
                        pending={pendingDays}
                        unlimitedDays={unlimitedDays}
                        accrual={nextBreakDays}
                        approved={approvedDays}
                        color={color}
                      />
                    </NavLink>
                  </Col>
                )
              )}
            </Row>
          </>
        ) : null}
        <Row className="pt-20">
          <Col md={{ span: 12 }}>
            <h2 className="page-header">Requests History</h2>
          </Col>
          <Col md={{ span: 12 }}>
            <Row type="flex" justify="end">
              <FiltersBtn onClick={this.toggleFilters} active={activeFilters} />
              <Button
                className="v-align-top ml-15"
                type="primary"
                onClick={this.sendRequest}
              >
                Request New Absence
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className="mb-10">
          <FiltersComponent
            visible={activeFilters}
            onSubmit={this.handleSubmitFilters}
          >
            <FiltersLayout />
          </FiltersComponent>
        </Row>
        <Table
          dataSource={data}
          rowKey="_id"
          clickable
          onRow={(record: any) => {
            return {
              onClick: () => {
                const url = generatePath(userRoutes.requestItem.path, {
                  id: record._id,
                });
                this.props.history.push(url);
              },
            };
          }}
          onChange={this.handleColumnSort}
          columns={columns(this.actions)}
          pagination={this.pagination.tableConfig}
        />
        <Pagination {...this.pagination.config} />
      </Spin>
    );
  }
}

export default connect(({ user }: { user: IUser }) => ({ user }))(Profile);
