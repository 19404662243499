import React from "react";

export default () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.117 50.8049C74.117 55.04 62.5133 63.5102 48.1981 63.5102C33.883 63.5102 22.2793 55.04 22.2793 50.8049C22.2793 46.5698 33.883 38.0996 48.1981 38.0996C62.5133 38.0996 74.117 46.5698 74.117 50.8049Z"
      stroke="#191F2D"
    />
    <path
      d="M60.9028 50.8049C60.9028 54.1746 59.5642 57.4062 57.1815 59.7889C54.7988 62.1716 51.5672 63.5102 48.1975 63.5102C44.8278 63.5102 41.5962 62.1716 39.2135 59.7889C36.8308 57.4062 35.4922 54.1746 35.4922 50.8049C35.4922 47.4353 36.8308 44.2036 39.2135 41.8209C41.5962 39.4382 44.8278 38.0996 48.1975 38.0996C51.5672 38.0996 54.7988 39.4382 57.1815 41.8209C59.5642 44.2036 60.9028 47.4353 60.9028 50.8049V50.8049Z"
      stroke="#191F2D"
    />
    <path
      d="M54.8053 50.805C54.8053 52.5572 54.1093 54.2377 52.8702 55.4767C51.6312 56.7157 49.9508 57.4118 48.1986 57.4118C46.4463 57.4118 44.7659 56.7157 43.5269 55.4767C42.2879 54.2377 41.5918 52.5572 41.5918 50.805C41.5918 49.0528 42.2879 47.3723 43.5269 46.1333C44.7659 44.8943 46.4463 44.1982 48.1986 44.1982C49.9508 44.1982 51.6312 44.8943 52.8702 46.1333C54.1093 47.3723 54.8053 49.0528 54.8053 50.805V50.805Z"
      stroke="#191F2D"
    />
    <path
      d="M62.9362 40.6406V10.115C62.9362 9.0104 62.0408 8.11497 60.9362 8.11497H55.313M62.9362 60.9691V77.2647C62.9362 78.3693 62.0408 79.2647 60.9362 79.2647H25.8367M25.8367 79.2647L17.3801 71.1333M25.8367 79.2647V68.5594C25.8367 67.4548 24.9413 66.5594 23.8367 66.5594H12.6232M12.6232 66.5594V10.115C12.6232 9.0104 13.5186 8.11497 14.6232 8.11497H55.313M12.6232 66.5594L17.3801 71.1333M55.313 8.11497V3C55.313 1.89543 54.4176 1 53.313 1H7C5.89543 1 5 1.89543 5 3V69.1333C5 70.2379 5.89543 71.1333 7 71.1333H17.3801M22.2792 17.2628H51.7555M22.2792 25.9024H43.6241M22.2792 35.0502H32.4435"
      stroke="#191F2D"
    />
  </svg>
);
