import React from "react";
import { IApprovalItem, IRequestData } from "../../interfaces";
import RequestDetails from "../../components/RequestDetails";
import { Col, Row } from "antd";
import Field from "../../ui/Field";
import Form from "../../noui/Form";
import requestAPI from "../../API/requests";
import errors from "../../API/error";

interface IState {
  requestDetails?: IRequestData;
  loading: boolean;
}
interface IProps {
  requestInfo: IApprovalItem;
}
class ExpandedRow extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const { _id: id } = this.props.requestInfo;
      const { data }: any = await requestAPI.getItem({ params: { id } });
      this.setState({ requestDetails: data });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed to load data" });
    } finally {
      this.setState({ loading: false });
    }
  };
  render() {
    const { requestDetails, loading } = this.state;

    return (
      <Form className="expand-table-form">
        <Row gutter={[0, 16]} align="middle" className="mb-20">
          <Col span={36}>
            {requestDetails && (
              <RequestDetails data={requestDetails} loading={loading} />
            )}
          </Col>
        </Row>
        <Row gutter={[0, 16]} align="middle">
          <Col span={3}>
            <span className="expand-title">pm remark:</span>
          </Col>
          <Col span={21}>
            <Field name="comment" placeholder="Text here" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ExpandedRow;
