import * as React from 'react';
import {
    Row,
    Col,
} from 'antd';
import guest from '../routes/guest';
import Routes from '../components/Routes';
import HashModal from '../components/HashModal';
import './style.scss';

class Auth extends React.Component<any, any> {
    render() {
        return (
            <>
                <Row className="container-login-page"
                     type="flex"
                     justify="space-between">
                    <Col lg={16} span={24} className="left-register">
                        <Row className="ml-15 mr-15 guest-screen-content" type="flex">
                            <Routes routes={guest} />
                        </Row>
                    </Col>
                    <Col lg={8}
                         className="right-register">
                        <div className="background-register"/>
                    </Col>
                </Row>
                <HashModal />
            </>
        );
    }
}
export default Auth