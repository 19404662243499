import * as React from 'react';
import {Button, Row, Spin, notification, Icon, DatePicker} from 'antd';
import Avatar from "../../components/Avatar";
import {connect} from "react-redux";
import moment from "moment";
import From from '../../noui/Form';
import Field from '../../ui/Field';
import NavLink from '../../components/NavLink';
import userRoutes from '../../routes/user';
import {getBase64} from '../../services/images';
import * as userService from "../../services/user/profile";
import userAPI from '../../API/user';
import errors from '../../API/error';
import appConfig from '../../configs/app';
import dateConfig from "../../configs/date";
import EmployeeChildren from '../../components/EmployeeChildren';
import {IUserData, IUser} from "../../interfaces";
import './styles.scss';

interface IProps {
  user: IUser,
  fileAvatar: Blob
}

class Profile extends React.Component<IProps, any> {
  state = {
    avatar: '',
    changedAvatar: '',
    fileAvatar: '',
    loading: false
  };

  componentDidMount() {
    const {avatar} = this.props.user;
    if (avatar) {
      this.setState({avatar: appConfig.staticFolder + avatar});
    }
  };

  handleSubmit = async ({values, form}: any) => {
    const {fileAvatar} = this.state;
    const fd: FormData = new FormData();
    fd.append('avatar', fileAvatar);

    const isChildrenValid = EmployeeChildren.isValid(values, form);

    if (!isChildrenValid) return;

    const allowedKeys = [
      'personalEmail',
      // 'firstName',
      // 'lastName',
      'phone',
      'secondPhone',
      'skype',
      'address',
      'contactFullName',
      'contactPhone',
      'contactDescription'
      ];
    Object.entries(values).forEach(([key, value]) => {
      if (allowedKeys.includes(key) && value) {
        fd.append(key, value as any);
      }
    });

    try {
      this.setState({loading: true});

      await userAPI.profile.update(fd);
      await userService.get();

      notification.success({
        message: 'Success'
      });
    } catch (e) {
      errors.handle(e, form, {priority: 'all'});
    }
    this.setState({loading: false})
  };

  handleChangeAvatar = async (e: any) => {
    try {
      const {files} = e.target;
      if (files.length === 0) {
        return;
      }
      const avatar = await getBase64(files[0]);
      const changedAvatar = await getBase64(files[0]);
      this.setState({avatar, changedAvatar, fileAvatar: files[0]});
    } catch (e) {
      errors.handle(e);
    }
  };

  render() {
    const {user}: any = this.props;
    const {avatar, loading,changedAvatar} = this.state;
    return <Spin tip="Loading..." spinning={loading}>
      <h2 className="page-header">Profile</h2>
      <div className="user-profile-content">
        <From onSubmit={this.handleSubmit}>
          <Row type="flex"
               justify="center"
               className="avatar-wrapp">
            <label className="relative">
              <Avatar size={100} user={{...(user as IUserData),avatar,changedAvatar}}  />
              <span className="change-avatar">
                  <Icon type="edit" />
                </span>
              <input type="file" className="hidden" onChange={this.handleChangeAvatar}/>
            </label>
          </Row>
          <Field name="firstName"
                 label="First name"
                 disabled
                 defaultValue={user.firstName} />
          <Field name="lastName"
                 label="Last name"
                 disabled
                 defaultValue={user.lastName} />
          <Field name="email"
                 label="Email"
                 type="email"
                 disabled
                 defaultValue={user.email} />
          <Field name="personalEmail"
                 label="Personal email"
                 type="email"
                 defaultValue={user.personalEmail} />
          <Field name="phone"
                 label="Phone number"
                 type="phone"
                 defaultValue={user.phone} />
          <Field name="secondPhone"
                 label="Second phone number"
                 type="phone"
                 defaultValue={user.secondPhone} />
          <Field name="skype"
                 label="Skype"
                 defaultValue={user.skype} />
          <Field name="address"
                 label="Address"
                 defaultValue={user.address} />
          <Field name="birthday"
                 label="Birthday"
                 defaultValue={user.birthday ? moment(user.birthday) : undefined}
                 disabled>
            <DatePicker format={dateConfig.formatHolidayDatePicker} />
          </Field>
          {/* Disabled by the comments from the company side */}
          {/* Don't remove till the 2022 year :) */}
          {/*<EmployeeChildren kids={user.children || []} />*/}
          <Row className="additional-info">
            <hr className="mt-20 mb-20" />
            <h3 className="mb-20">Contact Person</h3>
            <Field name="contactFullName"
                   label="Full name"
                   defaultValue={user.contactFullName}
            />
            <Field name="contactPhone"
                   label="Phone number"
                   defaultValue={user.contactPhone}
            />
            <Field name="contactDescription"
                   label="Who is this person for you?"
                   defaultValue={user.contactDescription}
            />

          </Row>

          <Row type="flex"
               justify="space-between"
               align="middle">
            <NavLink to={userRoutes.changePassword.path}>Change password</NavLink>
            <Button className="ant-btn-primary" htmlType="submit">Save</Button>
          </Row>
        </From>
      </div>
    </Spin>
  }

}

export default connect(({user}: {user: IUser}) => ({user}))(Profile)