import * as React from 'react';
import {
  Button,
  Row,
  Input
} from 'antd';
import Form from '../../noui/Form';
import Field from '../../ui/Field';
import userRoutes from '../../routes/user';
import APIlogin from "../../API/auth";
import errors from "../../API/error";
import * as google from "../../services/google";
import auth from "../../services/auth";
import googleIco from "../../assets/icons/google"
import modal from '../../containers/Modals/set/forgotPassword';
import LogoImg from "../../assets/imgs/logo.png"
import './style.scss';

class Login extends React.Component<any> {
  componentDidMount() {
    google.googleInit();
    modal.init()
  }

  private addGroup = () => {
    this.setState({id: 0}, () => {
      modal.open()
    });
  };

  handleSubmit = async ({values}: any) => {
    try {
      const res = await APIlogin.base(values);
      await this.handleProfile(res.data.token);
    } catch (e) {
      errors.handle(e);
    }
  };

  handleGoogleLogin = async () => {
    try {
      const googleProps = await google.googleLoginUser();
      const res = await APIlogin.externalLogin(googleProps);
      await this.handleProfile(res.data.token);
    } catch (e) {
      errors.handle(e);
    }
  };

  handleProfile = async (token: string) => {
    try {
      await auth.login(token);

      this.props.history.push(userRoutes.home.path);
    } catch (e) {
      throw e
    }
  };

  render() {
    return (
      <div id="register-form">
        <div className="screen-login">
          <a href="/" className="logo-register">
            <img src={LogoImg} alt="" />
          </a>
          <h1>Welcome to <span>#devabitfamily</span></h1>
          <Form onSubmit={this.handleSubmit}>
            <Field name="email"
                   validation="email"
                   label="Email address" />
            <Field name="password"
                   validation="password"
                   type="password"
                   label="Password">
              <Input.Password />
            </Field>
            <Button className="btn-register ant-btn-primary"
                    htmlType="submit">Login</Button>
          </Form>
          <Row type="flex"
               justify="center"
               className="mb-15 mt-15">
            <h3>or</h3>
          </Row>
          <Row className="mb-10">
            <Button onClick={this.handleGoogleLogin} className="btn-register google-btn">
              <i>{googleIco()}</i>
              {`   Use Google Account`}
            </Button>
          </Row>
          <div className="btn-form-link mt-40"
               onClick={this.addGroup}>Forgot my password
          </div>
        </div>
      </div>
    );
  }
}

export default Login