import React from "react";
import gift from "../../../../assets/imgs/welcomeBook/1531475325087 1.jpg";
import "./styles.scss";
function CorporateGifts() {
  return (
    <div className="corporate_gifts">
      <div className="corporate_gifts__tittle_container">
        <div className="corporate_gifts__tittle super-tittle">
          Corporate Gifts
        </div>
        <div className="corporate_gifts__tittle-text main-text">
          It is always nice to get gifts, especially when it comes to presents
          from the company.
        </div>
      </div>
      <div className="corporate_gifts__content">
        <div className="corporate_gifts__points_container secondary-text">
          <ul>
            <li> Birthday lottery</li>
            <li>
              Anniversary gift (hoodie, thermal mug, shopper, and many more)
            </li>
            <li> Welcome package on the first working day</li>
            <li>Nominal cup after the end of the trial period</li>
            <li>Holiday gifts</li>
            <li>Card of the IT community member (IT Club)</li>
          </ul>
        </div>
        <div className="corporate_gifts__image__container">
          <img src={gift} alt="gift" />
        </div>
      </div>
    </div>
  );
}

export default CorporateGifts;
