import React from 'react';
import moment, { Moment } from 'moment';
import TimelinePoints from '../../../assets/icons/timelinePoints';
import './styles.scss';

interface Props {
  onClick(date: Moment): void;
  loading: boolean;
  activeDate: Moment;
}

interface State {
  visible: boolean;
  dates: DatesRange;
}

interface DatesRange {
  // key in format 'YYYY-MM-DD'
  [key: string]: {
    title: string;
    isActive: boolean;
    opacity: number;
  };
}

const ITEM_HEIGHT = 52; // px
const CLASS_NAME = 'nf-timeline';

class Timeline extends React.Component<Props, State> {
  documentWidth = 0;

  contentRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      dates: {}
    };
  }

  componentDidMount() {
    this.setDocumentWidth();
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    this.updateVisibility(prevProps);
    this.updateActiveDate(prevProps);
  }

  updateVisibility = (prevProps: Readonly<Props>) => {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ visible: !this.props.loading });
    }
  };

  updateActiveDate = (prevProps: Readonly<Props>) => {
    if (prevProps.activeDate.format('YYYY-MM') !== this.props.activeDate.format('YYYY-MM')) {
      this.loadData();
    }
  };

  setDocumentWidth = () => {
    const { width } = document.body.getBoundingClientRect();
    this.documentWidth = width;
  };

  datesRange = (activeDate: Moment): DatesRange => {
    const from = activeDate.clone();
    const to = activeDate.clone();

    const result: DatesRange = {};

    const getResultItem = (d: Moment, diff: number) => {
      const key = d.format('YYYY-MM-DD');
      const isActive = d.isSame(activeDate);
      const opacity = Math.round(2 + (diff < 0 ? (diff * -1) : diff)) / 10;
      result[key] = {
        title: d.format('MMMM, YYYY'),
        isActive,
        opacity: isActive || opacity > 1 ? 1 : opacity
      };
    };

    getResultItem(activeDate, 0);

    let nextStep = 0;
    do {
      nextStep = 0;
      const diffFrom = activeDate.diff(from, 'month');
      if (diffFrom < 12) {
        from.subtract(1, 'month');
        getResultItem(from, diffFrom);
      } else {
        nextStep += 1;
      }
      const diffTo = to.diff(activeDate, 'month');
      if (to.diff(activeDate, 'month') < 12) {
        to.add(1, 'month');
        getResultItem(to, diffTo);
      } else {
        nextStep += 1;
      }
    } while (nextStep !== 2);

    return result;
  };

  loadData = () => {
    const { activeDate } = this.props;
    const dates = this.datesRange(activeDate);
    this.setState({ dates });
  };

  handleClick = (date: string) => {
    const { onClick } = this.props;
    onClick && onClick(moment.utc(date).startOf('month'));
  };

  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { visible, dates } = this.state;
    const items = Object.entries(dates).sort(([key], [key2]) => key > key2 ? 1 : -1);

    const datesHeight = window.innerHeight - 150 - 90;
    let availableItems = Math.ceil(datesHeight / ITEM_HEIGHT);
    if (!(availableItems % 2)) {
      availableItems -= 1;
    }

    const timelineStyles = {
      height: availableItems * ITEM_HEIGHT,
      maxHeight: ITEM_HEIGHT * 13
    };

    return (<div className={ `${CLASS_NAME} pt-50${visible ? ' is-visible' : ''}` }>
      <h2 className="page-header">Timeline</h2>

      { /* Uses to set data by simple js */ }
      <div ref={ this.contentRef } className={ `${CLASS_NAME}-content` }>
        <div
          className={ `${CLASS_NAME}-line` }
          style={ timelineStyles }>
          <div className={ `${CLASS_NAME}-line-center` }>
            <TimelinePoints />
          </div>
        </div>
        <div
          className={ `${CLASS_NAME}-dates` }
          style={ timelineStyles }>
          {
            items.map(([key, value]) => (<div
              style={ {
                opacity: value.opacity
              } }
              onClick={ this.handleClick.bind(null, key) }
              className={ `noselect ${CLASS_NAME}-date${value.isActive ? ' is-active' : ''}` }
              key={ key }>{ value.title }</div>))
          }
        </div>
      </div>
    </div>);
  }
}

export default Timeline;
