import * as React from 'react';
import moment from 'moment';
import {Col, Checkbox, DatePicker, Row, Select, Input, Spin, Button,Tooltip,Icon } from 'antd';
import {getBase64} from '../../../services/images';
import Field from '../../../ui/Field';
import Form, {ISubmitProps} from '../../../noui/Form';
import dateConfig from '../../../configs/date';
import appConfig from '../../../configs/app';
import Image from './Image';
import holidaysApi from '../../../API/holidays'
import './styles.scss';
import {connect} from "react-redux";
import errors from '../../../API/error';
import groupsAPI from "../../../API/groups";

const {TextArea} = Input;
const {Option} = Select;

class Index extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        const {modalProps = {}} = props;

        this.state = {
            image: '',
            data: modalProps,
            file: null,
            loading: false,
            groups: []
        }
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups = async () => {
        try {
            const {data} = await groupsAPI.get({
                query: {
                    limit: 100,
                    offset: 0
                }
            });
            this.setState({groups: data.list})
        } catch (e) {
            errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
        }
    };

    handleChangeImage = async ({file}: any) => {
        const image = await getBase64(file);
        this.setState({image, file});
    };

    submit = async ({values, form}: ISubmitProps) => {
        this.setState({loading: true});
        try {
            const {from, to, ...rest} = values;
            const {file, data} = this.state;
            const {onCancel, onSubmit} = this.props;

            const fd = new FormData();

            if (file) {
                fd.append('image', file);
            }
            if (rest.groups) {
                fd.append('groups', JSON.stringify(rest.groups));
            }

      fd.append('from', from.format('YYYY-MM-DD'));
      fd.append('to', to.format('YYYY-MM-DD'));

            ['name', 'description'].forEach(key => {
                if (rest[key]) {
                    fd.append(key, rest[key])
                }
            });

            ['isHoliday', 'isRestDay'].forEach(key => {
                if (rest[key]) {
                    fd.append(key, 'true')
                }
            });

            if (data._id) {
                await holidaysApi.update(fd, {params: {id: data._id}})
            } else {
                await holidaysApi.create(fd);
            }

            this.setState({loading: false});
            onSubmit && onSubmit({values, form});
            onCancel();
        } catch (e) {
            console.log(e);
            errors.handle(e, form, {priority: 'all', defaultMessage: 'Failed'});
            this.setState({loading: false})
        }
    };

    render() {
        const {loading, image, data, groups} = this.state;
        const {onCancel} = this.props;

        return <Form onSubmit={this.submit}>
            <Spin tip="Loading..." spinning={loading}>
                <Image onChange={this.handleChangeImage}
                       src={image ? image : data.image ? `${appConfig.staticFolder}${data.image}?width=600` : ''}/>
                <Row gutter={20} type="flex" justify="center" className="mb-15">
                    <Col span={10}>
                        <Field name="isRestDay" defaultValue={!!data.isRestDay}>
                            <Checkbox defaultChecked={data.isRestDay}>
                                   <div className="tooltip-helper-wrap"> Public Holiday
                                <Tooltip title="Public Holiday is an official day off in Ukraine.">
                                    <Icon type="question-circle" />
                                </Tooltip>
                                         </div>
                            </Checkbox>
                        </Field>
                    </Col>
                    <Col span={10}>
                        <Field name="isHoliday" defaultValue={!!data.isHoliday}>
                            <Checkbox defaultChecked={data.isHoliday}>
                                <div className="tooltip-helper-wrap"> Сorporate Holiday
                                    <Tooltip title="Corporate Holiday is regular working day with a celebratory event.">
                                        <Icon type="question-circle" />
                                    </Tooltip>
                                </div>
                            </Checkbox>
                        </Field>
                    </Col>
                </Row>
                <Field name="name"
                       defaultValue={data.name}
                       validation="required"
                       label="Event name"
                       className="mb-15"/>
                <Row className="mb-15">
                    <Field name="description"
                           defaultValue={data.description}
                           label="Description">
                        <TextArea autoSize={{minRows: 3, maxRows: 6}}/>
                    </Field>
                </Row>
                <Row gutter={20} className="mb-15">
                    <Col span={12}>
                        <Field name="from"
                               validation="required"
                               defaultValue={data.from ? moment(data.from).add(-1 * moment(data.from).utcOffset(), 'minutes') : undefined}
                               label="From">
                            <DatePicker format={dateConfig.formatHolidayDatePicker}/>
                        </Field>
                    </Col>
                    <Col span={12}>
                        <Field name="to"
                               validation="required"
                               defaultValue={data.to ? moment(data.to).add(-1 * moment(data.to).utcOffset(), 'minutes') : undefined}
                               label="To">
                            <DatePicker format={dateConfig.formatHolidayDatePicker}/>
                        </Field>
                    </Col>
                </Row>
                <Field name="groups"
                       defaultValue={data.groups || []}
                       label="Business unit"
                       className="mb-45">
                    <Select mode="multiple">
                        {
                            groups.map(({name, _id}: any, index: number) =>
                                <Option key={index}
                                        value={_id}>{name}</Option>)
                        }
                    </Select>
                </Field>

                <Row type="flex" justify="end">
                    <Button htmlType="button" onClick={onCancel}>Cancel</Button>
                    <Button type="primary"
                            htmlType="submit"
                            className="ml-15">Submit</Button>
                </Row>
            </Spin>
        </Form>
    }
}

export default connect(({
                            settings
                        }: any) =>
    ({
        settings
    }))(Index);