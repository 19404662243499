import * as React from 'react';
import {
  Button,
  Col,
  Row,
  Spin
} from 'antd';
import requestsAPI from '../../API/requests';
import columns from './columns';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table'
import {IActionsList} from '../../components/DotMenu'
import {open} from "../../services/modal";
import TablePagination from "../../services/tablePagination";
import errors from '../../API/error';
import {generatePath} from "react-router";
import userRoutes from "../../routes/user";
import * as pathService from "../../services/path";
import {isCancelOptionVisible} from "../../utils/requestStatus";

class History extends React.Component<any, any> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true
    })

    const {id} = props.match.params
    this.state = {
      id,
      loading: true,
      loaded: false,
      list: []
    };
  }

  componentDidMount() {
    this.getData();
  };

  getData = async () => {
    try {
      this.setState({loading: true});
      const {id} = this.state;
      // const {search = ''} = this.props.location;
      // const q: any = qs.parse(search);

      const res = await requestsAPI.getList({query: {
          ...this.pagination.requestParams(),
        weekends: JSON.stringify([id]),
          // ...q,
          // ...this.pagination.requestParams()
      }});
      const {list, pagination} = res.data;

      this.pagination.value = pagination;
      this.setState({
        list,
        loading: false,
        loaded: true
      })
    } catch (e) {
      this.setState({loading: false});
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
    }
  };

  sendRequest = () => open('sendRequest');

  handleColumnSort = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: [] }) => {
    this.pagination.tableChange(pagination,filters,sorter,extra);
    this.getData();
  };

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getData();
  };

  handleCancelRequest = async ({_id}: {_id: string}) => {
    try {
      await requestsAPI.cancel({requestId: _id});
      this.getData();
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'})
    }
  };

  actions = (record: any) => {
    const items: IActionsList = [
      {
        name: 'View Details',
        link: pathService.get(userRoutes.requestItem.path, {id: record._id})
      }
    ];

    if (isCancelOptionVisible(record)) {
      items.push({
        action: this.handleCancelRequest,
        name: 'Cancel Request',
        confirm: true
      });
    }

    return items
  };

  render() {
    const {list, loading, loaded} = this.state;
    return <>
      <Row className="mb-10">
        <Col>
          <Row type="flex" justify="space-between">
            <h2 className="page-header">Requests History</h2>
            <Button className="ant-btn-primary"
                    onClick={this.sendRequest}>Request New Absence</Button>
          </Row>
        </Col>
      </Row>
      {
        loaded ? <Spin tip="Loading..." spinning={loading}>

            <Table dataSource={list}
                   rowKey="_id"
                   onChange={this.handleColumnSort}
                   clickable
                   onRow={(record: any) => {
                     return {
                       onClick: () => {
                         this.props.history.push(generatePath(userRoutes.requestItem.path, {id: record._id}))
                       }
                     };
                   }}
                   columns={columns(this.actions)}
                   pagination={this.pagination.tableConfig} />
          <Pagination onChange={this.changePage}
                      {...this.pagination.config} />
        </Spin> : null
      }

    </>
  }
}

export default History;
