import * as modal from "../../../services/modal";
import ConfirmationGroup from "../ConfirmationGroup";

interface IProps {
  onSubmit: any
};

const init = ({onSubmit}: IProps) => {
  modal.set('weekendsConfirmation', {
    hash: 'absence-confirmation',
    component: ConfirmationGroup,
    config: {
      titleFunc: (modalProps: any)=>`${modalProps._id ? 'Edit' : 'Create'} Approval Gate`,
      actions: null,
      disableForm: true,
      okLabel: 'Save',
      onSubmit
    }
  });
};

const open = (item: any = {}) => {
  modal.setProps('weekendsConfirmation', item);
  modal.open('weekendsConfirmation')
};

const del = () => {
  modal.remove('weekendsConfirmation')
};

export default {
  init,
  open,
  del
}