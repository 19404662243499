// User
export const SETUP_USER = "SETUP_USER";
export const CHANGE_USER = "CHANGE_USER";
export const REMOVE_USER = "REMOVE_USER";

// Approval
export const SET_APPROVAL_COUNT = "SET_APPROVAL_COUNT";

//BookingTable

export const SET_TABLE = "SET_TABLE";
export const SET_SECTOR = "SET_SECTOR";
export const SET_DATE_REQUEST_TABLE = "SET_DATE_REQUEST_TABLE";

export const SET_BOOKED_DAYS = "SET_BOOKED_DAYS";
export const SET_TABLES_DATA = "SET_REQUEST_TABLE_DATE";
export const SET_SECTORS_DATA = "SET_SECTOR_DATA";
export const SET_REQUESTS_BOOKING_DATA = "SET_REQUESTS_BOOKING_DATA";
export const SET_BOOKED_FOR = "SET_BOOKED_FOR";
