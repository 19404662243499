import * as React from "react";
import DotMenu, { IActionsList } from "../../../components/DotMenu";

export default (actions: (record: any) => IActionsList) => [
  {
    title: "Sector name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Sector zone",
    dataIndex: "sectorZone",
    sorter: true,
  },
  {
    title: "",
    key: "actions",
    className: "prevent-click",
    render: (text: any, record: any) => (
      <DotMenu actions={actions(record)} record={record} />
    ),
  },
];
