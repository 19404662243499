export interface IBookingLegend {
  tittle: string;
  subTittle: string;
  color: string;
}
export const bookingLegend: IBookingLegend[] = [
  {
    tittle: "Table available for booking",
    subTittle: "If you come to the office irregularly",
    color: "#F0F0F0",
  },
  { tittle: "Booked table", subTittle: "", color: "#D9D9D9" },
  {
    tittle: "Hot desk",
    subTittle: "You can come & take those workplaces without booking",
    color: "#D9E9F5",
  },
  { tittle: "Your booked table", subTittle: "", color: "#191F2D" },
  {
    tittle: "Dedicated",
    subTittle:
      "Permanently booked for those employees 100% working from office",
    color: "#D8DCF5",
  },
];
