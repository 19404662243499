import CalendarRemoveWarning from "../CalendarRemoveWarning";
import * as modal from "../../../services/modal";

interface IProps {
  onRemove: any
}

export const init = ({onRemove}: IProps) => {
  modal.set('removeCalendar', {
    hash: 'remove-calendar-group',
    component: CalendarRemoveWarning,
    config: {
      title: 'Remove Business Unit',
      okLabel: 'Remove',
      onSubmit: onRemove
    }
  });
};

export const open = (item: any = {}) => {
  modal.setProps('removeCalendar', item);
  modal.open('removeCalendar')
};

export const remove = () => {
  modal.remove('removeCalendar')
};

export default {
  init,
  open,
  remove
}