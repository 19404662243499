import React from 'react';

const ProgramTub = () => (
  <>
    <div className="insurance_details__points">
      <ul>
        <li>Free medicines</li>
        <li>Free consultations with doctors</li>
        <li>
          Outpatient analysis and diagnostics (ultrasound, computed tomography
          (CT), magnetic resonance imaging (MRI), gastroscopy, etc.)
        </li>
        <li>Ambulance</li>
        <li>
          Annual preventive checkup (consultations with a physician,
          gynecologist, urologist, ophthalmologist, CBC, and urine analysis,
          electrocardiography, fluorography, etc.)
        </li>
        <li>
          Emergency therapeutic and surgical dentistry (consultation with a
          dentist and X-ray, elimination of acute toothache, anesthesia,
          removal of teeth and roots, opening of root canals, treatment of
          causative tooth (with the use of devitalizing paste, temporary
          filling, emergency prosthesis).
          <b> Limit per one insured person — UAH 1,500 </b>
        </li>
        <li>Calling a doctor at home or in the office</li>
        <li>Physiotherapy</li>
        <li>Therapeutic massages</li>
        <li>PCR and ELISA testing (in case of symptoms)</li>
        <li>
          Treatment of <b>COVID-19 </b>(consultation with a doctor, tests:
          CBC, C-reactive protein, total protein. If necessary, we also
          provide procalcitonin, ALT, AST, d-dimer, and ferritin testing)
        </li>
        <li>
          Emergency medical care/planned inpatient care (round-the-clock
          hospital)
        </li>
      </ul>
    </div>
    <div className="insurance_details__prompt">
      <ul>
        <li>
          Document submission for insurance indemnity has been facilitated.
          Now, if the amount of compensation is
          <b>up to UAH 4,999</b>, you can send scanned copies of the documents
          to <b>mcd@arx.com.ua</b>. You can sign the scanned copy of the
          application or get it certified via a qualified electronic digital
          signature. If the amount of compensation
          <b>exceeds UAH 5,000</b>, you should provide the original documents
          (more information in the appendix).
          <p>&nbsp;</p>
        </li>
        <li>
          The application for insurance takes place after the successful completion of the trial period.
        </li>
      </ul>
    </div>
  </>
);

export default ProgramTub;
