import * as React from 'react';
import { Icon, Row, Button, Col } from 'antd';
import { ExpandIconProps } from 'antd/lib/table';
import { generatePath } from 'react-router';
import requestsAPI from '../../API/requests';
import columns, { expandedRowRender } from './columns';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TablePagination from '../../services/tablePagination';
import errors from '../../API/error';
import * as approvalCount from '../../services/approvalCount';
import { IApprovalItem, IUser } from '../../interfaces';
import dropdownArrow from '../../assets/icons/dropdownArrow';
import user from '../../routes/user';
import Has from '../../noui/Permissions/Has';
import FiltersComponent from '../../components/Filters';
import FiltersLayout from '../Calendar/FiltersLayout';
import { Filters as FiltersBtn } from '../../components/Buttons';
import * as userService from '../../services/user/profile';
import initialState from '../../reducers/initialState';

interface IState {
  readonly data: Array<IApprovalItem>;
  readonly expanded: string[];
  filters: any;
  activeFilters: boolean;
  profile: IUser;
}

class ForApproval extends React.Component<any, IState> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
    });

    this.state = {
      data: [],
      expanded: [],
      activeFilters: false,
      filters: {},
      profile: initialState.user,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (withOutPaginator = 0) => {
    try {
      const profile = await userService.get();
      const { filters } = this.state;

      const query: any = {};
      const paginationConfig = { ...this.pagination.requestParams() };

      Object.entries(filters).forEach(([key, value]: any) => {
        if (value && value.length) {
          query[key] = JSON.stringify(value);
        }
      });
      if (withOutPaginator === 1) {
        paginationConfig.limit = this.pagination.config.total + 1;
        paginationConfig.offset = 0;
      }

      const res = await requestsAPI.getPending({
        query: { ...query, ...paginationConfig },
      });

      if (withOutPaginator === 1) {
        const { list } = res.data;
        return list;
      }
      const { list, pagination } = res.data;
      this.pagination.value = pagination;
      this.setState({ data: list, profile }, this.setColorRows);
      approvalCount.set(res.data.pagination.total);
    } catch (e) {
      errors.handle(e);
      return false;
    }
  };

  handleColumnSort = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: [] },
  ) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  getComment = (form?: any) => {
    let comment = '';
    if (form) {
      const { comment: c = '' } = form.getFieldsValue();
      comment = c.trim();
    }
    return comment;
  };

  handleApprove = async (id: string, form?: any) => {
    try {
      await requestsAPI.approve({
        requestId: id,
        comment: this.getComment(form),
      });
      await this.getData();
    } catch (e) {
      errors.handle(e, form, { priority: 'all' });
    }
  };

  handleApproveAll = async () => {
    try {
      const lists: Array<IApprovalItem> = await this.getData(1);
      const rIds = lists.map(({ _id, cancellationHistory }) => {
        let id = _id;
        if (
          cancellationHistory &&
          cancellationHistory.length &&
          cancellationHistory[0] &&
          cancellationHistory[0].isActive
        ) {
          id = cancellationHistory[0]._id || '';
        }
        return id;
      });
      await requestsAPI.approve({ requestId: rIds, comment: '' });
      this.setState({ data: [] });
      await this.getData();
    } catch (e) {
      errors.handle(e);
    }
  };

  handleView = (id: string) => {
    this.props.history.push(generatePath(user.requestItem.path, { id }));
  };

  handleReject = async (id: string, form?: any) => {
    try {
      await requestsAPI.reject({
        requestId: id,
        comment: this.getComment(form),
      });
      await this.getData();
    } catch (e) {
      errors.handle(e, form, { priority: 'all' });
    }
  };

  handleExpandIcon = ({
    expanded,
    onExpand,
    record,
  }: ExpandIconProps<IApprovalItem>) => (
    <Icon
      component={ dropdownArrow }
      style={ expanded ? { transform: 'rotate(180deg)' } : {} }
      onClick={ (e) => onExpand(record, e) }
    />
  );

  toggleFilters = () => {
    const { activeFilters } = this.state;
    this.setState({ activeFilters: !activeFilters });
  };

  setColorRows = (...rest: any) => {
    setTimeout(() => {
      const rows = document.querySelectorAll('tbody tr');
      if (rows.length) {
        const className = 'bg-grey';
        let grey = false;

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const none = (row as any).style.display === 'none';

          if (none) {
            continue;
          } else if (grey) {
            grey = false;
            row.classList.add(className);
          } else {
            grey = true;
            row.classList.remove(className);
          }
        }
      }
    });
  };

  handleSubmitFilters = ({ values }: any) => {
    this.setState({ filters: values }, this.getData);
  };

  render() {
    const { data, expanded, activeFilters, profile } = this.state;
    const actions = {
      onApprove: this.handleApprove,
      onReject: this.handleReject,
      onView: this.handleView,
    };

    return (
      <Row className='mt-10 approval-page'>
        <Row>
          <Col span={ 12 }>
            <h2 className='page-header mb-10'>For Approval</h2>
          </Col>

          <Col span={ 12 }>
            { profile.isManager ? (
              <div className='ant-row-flex ant-row-flex-end'>
                <FiltersBtn
                  className='ml-15'
                  onClick={ this.toggleFilters }
                  active={ activeFilters }
                />
                <Has permissions={ ['settings'] }>
                  { data.length ? (
                    <Button
                      className='ant-btn ml-10 approve ant-btn-primary'
                      type='primary'
                      onClick={ this.handleApproveAll }
                    >
                      Approve All
                    </Button>
                  ) : null }
                </Has>
              </div>
            ) : null }
          </Col>
        </Row>
        { activeFilters && (
          <Row className='mb-10'>
            <FiltersComponent
              visible={ true }
              onSubmit={ this.handleSubmitFilters }
            >
              <FiltersLayout children />
            </FiltersComponent>
          </Row>
        ) }
        <Table
          columns={ columns(actions) }
          dataSource={ data }
          rowKey='_id'
          clickable
          disableGreyRow
          onChange={ this.handleColumnSort }
          expandIcon={ null }
          expandRowByClick
          expandIconColumnIndex={ false }
          onExpand={ this.setColorRows }
          rowClassName={ ({ _id }: any) =>
            expanded.includes(_id) ? 'row-expanded' : ''
          }
          onExpandedRowsChange={ (expanded: string[]) => {
            this.setState({ expanded });
          } }
          expandedRowRender={ expandedRowRender() }
          pagination={ this.pagination.tableConfig }
        />
        <Pagination { ...this.pagination.config } />
      </Row>
    );
  }
}

export default ForApproval;
