import * as React from 'react';
import Avatar from "../../components/Avatar";
import DotMenu, {IActionsList, TActions} from '../../components/DotMenu';
import {IUserData} from "../../interfaces";


export default (actions: IActionsList | TActions, showGroups = false) => {

  const items: any = [
    {
      title: '',
      key: 'avatar',
      width: '64px',
      render: (text: string, record: IUserData) =>
        <Avatar style={{margin: 0, padding: 0}} user={record} size={32} />,
    },
    {
      title: 'Name',
      dataIndex: ['firstName', 'lastName'],
      key: 'fullName',
      render: (text: any, record: any) =>
        `${record.firstName || ''} ${record.lastName || ''}`.trim(),
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Positions',
      dataIndex: 'positions',
      key: 'positions',
      render: (positions: any) =>
        !positions || positions.length === 0 ? null
          : positions.map((position: any, index: number) => <div key={index}>{position.name}</div>)
    },
  ];

  if (showGroups) {
    items.push({
      title: 'Business Units',
      key: 'businessUnits',
      render: (text: any, {groups, allowAllGroups}: any) => groups && groups.length > 0
        ? <div>
          {
            allowAllGroups ? <div>All</div> : null
          }
          {
            groups.map((group: {name: string}, index: number) => <div key={index}>{group.name}</div>)
          }
        </div> : null
    })
  }

  items.push({
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: any) =>
      <DotMenu actions={actions} record={record}/>
  })
  return items
};


