import React from 'react';
import {connect} from "react-redux";
import qs from 'query-string';
import {Button} from "antd";
import requestsApi from "../../API/requests";
import errors from '../../API/error';
import RequestHistory from '../../components/RequestHistory';
import RequestDetails, {defaultData} from '../../components/RequestDetails';
import {IRequestData} from "../../interfaces/containers";
import approval, {IApprovalParams} from "../../services/approval";
import {IStore} from "../../interfaces";
import {mergeHistoryWithCancellation} from "../../utils/requestParse";
import approvalCount from "../../services/approvalCount";
import LogoImg from "../../assets/imgs/logo.png";

interface IState {
  readonly loading: boolean,
  readonly mounted: boolean,
  readonly data: IRequestData,
  readonly cancellationData?: IRequestData
}

class AcceptanceByEmail extends React.Component<any, IState> {
  state: IState = {
    loading: true,
    mounted: false,
    data: defaultData,
  };

  async componentDidMount() {
    const {user} = this.props
    const {action} = this.getQueries();
    await this.getData();
    const isPending = !this.checkStatus();

    if (action && (isPending || this.isPendingCancellation())) {
      if (action === 'reject' || action === 'approve') {
        await this.handleRequest(action)();
        if (user && user._id) {
          const {data} = await requestsApi.getPending({query: {limit: 1}});
          approvalCount.set(data.pagination.total);
        }
      }
    }

    this.setState({mounted: true});
  }

  getData = async () => {
    try {
      this.setState({loading: true});
      const {code, email} = this.getQueries();
      const {data} = await requestsApi.getItemCode({params: {id: code}, query: {email}});
      this.setState({data});
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'})
    } finally {
      this.setState({loading: false});
    }
  };

  getQueries = () => qs.parse(this.props.location.search);

  checkStatus = () => {
    const {data} = this.state;
    const {isApproved, isCanceled, isRejected} = data;

    return isApproved || isRejected || isCanceled;
  };

  isPendingCancellation = () => {
    const {data: {cancellationHistory}} = this.state;

    return Boolean(cancellationHistory && cancellationHistory.length && cancellationHistory[0].isActive);
  };

  isPendingUser = () => {
    const {user: {_id: userId} = {_id: ''}} = this.props;
    const {data: {pendingUsers = []}} = this.state;

    if (!userId) {
      return true;
    }

    return Boolean(pendingUsers.find(u => u._id === userId));
  };

  isPendingCancellationUser = () => {
    const {user: {_id: userId} = {_id: ''}} = this.props;
    const {data: {cancellationHistory}} = this.state;

    if (!userId) {
      return true;
    }

    if (cancellationHistory && cancellationHistory.length) {
      const {pendingUsers = []} = cancellationHistory[0] || {pendingUsers: []};
      return Boolean(pendingUsers.find(u => u._id === userId))
    }

    return false;
  };

  handleRequest = (action: 'approve' | 'reject') => async () => {
    const {user: {_id: userId} = {_id: ''}} = this.props;
    const {data: {_id: requestId, cancellationHistory} = {_id: '', cancellationHistory: undefined}} = this.state;

    const params: IApprovalParams = {
      requestId,
      comment: ''
    };

    if (!userId) {
      const {code, email} = this.getQueries();
      params.code = code as string;
      params.email = email as string;
    }

    if (this.isPendingCancellation()) {
      params.requestId = (cancellationHistory && cancellationHistory[0]._id) as string
    }

    const success = await approval[action](params);

    if (success) {
      this.getData();
    }
  };

  render() {
    const {data, loading, mounted = false} = this.state;
    const {user} = this.props;
    const showControls = !this.checkStatus() || this.isPendingCancellation();
    const historyData = mergeHistoryWithCancellation(data);

    return <div className={`screen-request${user && user._id ? ' logged-user' : ' pt-80 pb-80'}`}>
        <div className="screen-request-content">
            {
                !user || !user._id ? <a href="/" className="logo-register">
                    <img src={LogoImg} alt="" />
                </a> : null
            }
            {mounted && <div className="email-acceptance">
                <div className="flex jc-space-between ai-center mb-20">
                    <h2 className="page-header">Details</h2>
                    <div className="flex ai-center">
                        {(this.isPendingUser() || this.isPendingCancellationUser()) && showControls && <React.Fragment>
                            {this.isPendingCancellation() && this.isPendingCancellationUser() &&
                            <p className="mb-0 mr-20">Cancellation Request: </p>}
                            <Button className="mr-20 reject"
                                    onClick={this.handleRequest('reject')}>
                                Reject
                            </Button>
                            <Button type="primary"
                                    className="approve"
                                    onClick={this.handleRequest('approve')}
                            >
                                Approve
                            </Button>
                        </React.Fragment>}
                    </div>
                </div>
                <RequestDetails data={data} loading={loading} />
                <h2 className="page-header mb-20 mt-30">History</h2>
                <RequestHistory data={historyData} loading={loading} />
            </div>}
        </div>
    </div>;
  }
}

export default connect(({user}: Partial<IStore>) => ({user}))(AcceptanceByEmail);
