import * as api from './index';
const P = '/users';

export default {
  get: api.get(`${P}`),
  post: api.post(`${P}`),
  export: api.get(`${P}/export`, {
    responseType: 'arraybuffer'
  }),
  put: api.put(`${P}/:id`),
  changeStatus: api.patch(`${P}/change-status/:id`),
  sendInvitation: api.post(`${P}/send-invitation/:id`),
  delete: api.del(`${P}/:id`),
  getProfile: api.get(`${P}/profile/:id`),
  getAllWithWeekends: api.get(`${P}/get-all-with-weekends`)
}