import React from "react";
import story from "../../../../assets/imgs/welcomeBook/Photo.jpg";
import teamMobile from "../../../../assets/imgs/welcomeBook/stakeholders_mobile.jpg";
import "./styles.scss";
function WelcomeBookStory() {
  return (
    <div className="story__container">
      <div className="story__tittle super-tittle ">The Story Behind</div>
      <div className="story__text_container">
        <div className="story__text_item main-text">
          All great things have an interesting story behind them, so devabit is
          not an exception. Built by skilled experts in the IT industry, devabit
          was doomed to success from the very beginning.
        </div>
        <div className="story__text_item secondary-text">
          A bigger team, more complex projects, and new market-leading clients.
          We have undergone multiple transformations since 2014. Yet, we
          perceived each challenge as an opportunity for growth, which helped us
          become the ones we are today — a powerful team of experts passionate
          about designing world-changing solutions.
        </div>
      </div>

      <div className="story__img_container">
        <img src={story} alt="story" />
      </div>
      <div className="story__img_container_mobile">
        <img src={teamMobile} alt="story" />
      </div>
    </div>
  );
}

export default WelcomeBookStory;
