import React from 'react';
import { Button, Row, Select, Spin } from 'antd';
import { generatePath } from 'react-router-dom';
import * as moment from 'moment';
import apiInventories from '../../../API/inventories';
import routesInventories from '../../../routes/inventories';
import Settings from '../../../layouts/Settings';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import NavLink from '../../../components/NavLink';
import columns from './columns';
import TablePagination from '../../../services/tablePagination';
import errors from '../../../API/error';
import { IActionsList } from '../../../components/DotMenu';
import { Filters as FiltersBtn } from '../../../components/Buttons';
import FiltersLayout from './FiltersLayout';
import FiltersComponent from '../../../components/Filters';
import Field from '../../../ui/Field';
import exportFile from '../../../services/exportFile';

interface State {
  data: any[];
  status: string;
  components: any[];
  loading: boolean;
  activeFilters: boolean;
}

export default class Inventories extends React.Component<any, State> {
  pagination: TablePagination;

  unlisten: any = null;

  unassigned = false;

  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true,
      updateQueryOnChange: true,
      allowedFiltersKeys: {
        groups: true,
        users: true,
        status: {
          default: 'active',
        },
      },
      // parameters allowed in the query
      allowedQueryParameters: {
        offset: true,
        status: true,
        limit: true,
        sort: true,
        order: true,
        groups: { type: 'array' },
        users: { type: 'array' },
      },
    });
    this.pagination.query = props.location.search;
    const params = this.pagination.queryParameters;

    this.state = {
      loading: false,
      activeFilters: false,
      data: [],
      status: (params.status as string) || 'active',
      // Used to sort table columns
      components: [],
    };
  }

  componentDidMount() {
    this.getComponents();
    this.getData();

    // This listener helps to update data by the filters if go back button pressed
    this.unlisten = this.props.history.listen(
      ({ search }: any, action: any) => {
        if (action === 'POP') {
          this.pagination.query =
            search && search.length > 1 ? search : '?limit=10&offset=0';
          this.getData();
        }
      }
    );
    this.getData();
  }

  componentWillUnmount(): void {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  getComponents = async () => {
    try {
      const { data: components } = await apiInventories.getComponents();
      this.setState(() => ({ components }));
    } catch (e) {
      errors.handle(e);
    }
  };

  getData = async () => {
    try {
      const reqParams = {
        ...this.pagination.requestParams(),
      };

      if (this.unassigned) {
        reqParams.users = '[]';
      }
      const { data } = await apiInventories.get({
        query: reqParams,
      });
      const { pagination, list } = data;
      this.pagination.value = pagination;
      this.setState({ data: list });
    } catch (e) {
      errors.handle(e);
    }
  };

  handleChangeRecStatus =
  (status: string) =>
    async ({ _id }: any) => {
      try {
        await apiInventories.patchStatus({ status }, { params: { id: _id } });
        this.getData();
      } catch (e) {
        errors.handle(e, undefined, { defaultMessage: 'Failed' });
      }
    };

  handleEdit = ({ _id }: any) => {
    this.props.history.push(
      generatePath(routesInventories.edit.path, { id: _id })
    );
  };

  actions = (record: any): IActionsList => {
    const { status } = record;

    const actions: IActionsList = [];

    if (status !== 'active') {
      actions.push({
        name: 'Activate',
        action: this.handleChangeRecStatus('active'),
      });
    }

    if (status !== 'public') {
      actions.push({
        name: 'Test device',
        action: this.handleChangeRecStatus('public'),
      });
    }

    if (status !== 'archived') {
      actions.push({
        name: 'Archive',
        action: this.handleChangeRecStatus('archived'),
      });
    }

    if (status !== 'repair') {
      actions.push({
        name: 'Repair',
        action: this.handleChangeRecStatus('repair'),
      });
    }

    actions.push({
      name: 'Edit',
      action: this.handleEdit,
    });

    actions.push({
      name: 'Duplicate',
      action: this.handleDuplicate,
    });

    return actions;
  };

  handleDuplicate = async ({ _id }: any) => {
    try {
      const { data } = await apiInventories.postDuplicate(
        {},
        {
          params: {
            id: _id,
          },
        }
      );
      this.props.history.push(
        generatePath(routesInventories.view.path, { id: data._id })
      );
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed' });
    }
  };

  getColumn = (title: string, key: string) => {
    const column: any = {
      title,
      dataIndex: key,
      key,
    };
    if (key === 'boughtAt') {
      column.render = (boughtAt: string) =>
        boughtAt ? moment.utc(boughtAt).format('DD/MM/YYYY') : null;
    }
    if (key === 'id') {
      column.render = (id: string, { group }: any) => {
        const idChanged = `000${String(id || 0)}`.slice(-4);
        return `${group?.prefix || ''}${idChanged}`;
      };
    }
    if (key === 'users') {
      column.render = (id: string, { users }: any) => {
        let res = '';
        if (users.length  > 0) {
          users.forEach((u: any, i: number) => {
            if (i > 0) { res += ' / '; }
            res += `${u.firstName} ${u.lastName}`;
          });
        }
        return res;
      };
    }

    return column;
  };

  groupColumn = {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    render(group: any) {
      return group.name;
    },
  };

  sortedColumns = () => {
    const { components, data } = this.state;
    const { groups = [] } = this.pagination.filters;

    if (!data || data.length === 0) {
      return [];
    }

    const comp = components.reduce((c: any, el: any) => {
      c[el.key] = el;
      return c;
    }, {});

    const columns: any[] = [];
    columns.push(this.getColumn('User', 'users'));
    if (groups.length === 1) {
      const { fields } = (data[0] as any).group;
      fields.forEach((key: string) => {
        columns.push(this.getColumn(comp[key].name, key));
      });
    } else {
      columns.push(this.groupColumn);
      const fieldsSet = new Set<string>();
      data.forEach((el: any) => {
        const { group } = el;
        group.fields.forEach((key: string) => {
          if (el.hasOwnProperty(key)) {
            fieldsSet.add(key);
          }
        });
      });
      [...(fieldsSet as any)].forEach((key: string) => {
        columns.push(this.getColumn(comp[key].name, key));
      });
    }
    return columns;
  };

  toggleFilters = () =>
    this.setState({ activeFilters: !this.state.activeFilters });

  handleSubmitFilters = ({ values }: any) => {
    const { users, ...rest } = values;
    this.unassigned = users.includes('unassigned');
    this.pagination.valueAndUpdate = {
      ...rest,
      users: this.unassigned ? [] : users,
    };
  };

  handleChangeStatus = (status: string) => {
    this.pagination.valueAndUpdate = { status };
    this.setState({ status });
  };

  exportTable = async () => {
    try {
      const res = await apiInventories.export({
        query: { ...this.pagination.requestParams(), limit: 10000, offset: 0 }
      });
      await exportFile.exportInit(res.data);
    } catch (e) {
      errors.handle(e, undefined, { priority: 'all' });
    }
  };

  render() {
    const { loading, data, activeFilters, status } = this.state;

    return (
      <Settings
        location={ this.props.location }
        title={
          <Row type="flex" justify="start">
            <h2 className="page-header">Inventories</h2>
            <div className="ml-10 user-status-select">
              <Field
                name="status"
                style={ { margin: 0 } }
                disableDecorator
                onChange={ this.handleChangeStatus }
                placeholder="Status"
              >
                <Select defaultValue={ status }>
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="public">Test devices</Select.Option>
                  <Select.Option value="archived">Archived</Select.Option>
                  <Select.Option value="repair">Repair</Select.Option>
                </Select>
              </Field>
            </div>
          </Row>
        }
        filtersComponent={
          <FiltersComponent
            visible={ activeFilters }
            onSubmit={ this.handleSubmitFilters }
          >
            <FiltersLayout defaultValue={ this.pagination.filters } />
          </FiltersComponent>
        }
        actionsComponent={
          <Row type="flex" justify="end">
            <FiltersBtn
              className="ml-15"
              onClick={ this.toggleFilters }
              active={ activeFilters }
            />
            <NavLink to={ routesInventories.inventoriesGroups.path }>
              <Button className="ant-btn-primary ml-15">Groups</Button>
            </NavLink>
            <NavLink to={ routesInventories.create.path }>
              <Button className="ant-btn-primary ml-15">
                Create Inventory
              </Button>
            </NavLink>
            <Button
              type="primary"
              className="ant-btn-primary ml-15"
              onClick={ this.exportTable.bind(null, this.state) }>
              Export
            </Button>

          </Row>
        }
      >
        <Spin tip="Loading..." spinning={ loading }>
          <div className="overflow-auto">
            <Table
              dataSource={ data }
              loading={ loading }
              rowKey="_id"
              clickable
              onRow={ (record: any) => ({
                onClick: () => {
                  this.props.history.push(
                    generatePath(routesInventories.view.path, {
                      id: record._id,
                    })
                  );
                },
              }) }
              columns={ columns(this.actions, this.sortedColumns()) }
              pagination={ this.pagination.tableConfig }
              onChange={ this.pagination.tableChange.bind(this.pagination) }
            />
          </div>

          <Pagination { ...this.pagination.config } />
        </Spin>
      </Settings>
    );
  }
}
