import React from "react";
import moment from "moment";
import Avatar from "../../../components/Avatar";
import img1 from '../../../assets/imgs/birthday/Frame_5.png';
import img2 from '../../../assets/imgs/birthday/Frame_6.png';
import img3 from '../../../assets/imgs/birthday/Frame_7.png';
import Head from './Head';
import './styles.scss'

interface Props {
  [key: string]: any
}

const images = [img1, img2, img3];

export default (props: Props) => {
  const charCodeAt = props.firstName.charCodeAt(1);
  const img = charCodeAt % 3;
  const momentBirthday = moment.utc(props.birthday).set({year: moment.utc(props.nfDate).year()});
  return <div className="nfi-birthday nfi-card js-nfi" data-id={props.keyItem}>
    <Head {...props} date={momentBirthday.format('DD/MM/YYYY')} />
    <div className="nfi-birthday-background nfi-card-background" style={{
      backgroundImage: `url(${images[img]})`
    }}>
      <div className="nfi-main-avatar">
        <Avatar user={{...props, avatar: props.avatar ? `${props.avatar}?width=220&height=220` : ''} as any} />
      </div>
    </div>
  </div>
}