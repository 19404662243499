import { Col, Row, Button, Divider, Select, Spin } from 'antd';
import * as React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import ReactQuill from 'react-quill';
import usersAPI from '../../../API/users';
import cvAPI from '../../../API/cv';
import {
  IEducation,
  ILanguage,
  IPortfolio,
  ISkills,
  TSkill,
  IFieldsListArray,
  IFieldsListString,
  IFieldsListFull,
  TFieldListString,
  TVersion,
  IState,
} from '../../../interfaces/containers/CvMaker';
import IUser from '../../../interfaces/user';
import errors from '../../../API/error';
import Form from '../../../noui/Form';
import Field from '../../../ui/Field';
import Some from '../../../noui/Permissions/Some';
import { IRequestError } from '../../../interfaces/error';
// import Avatar from '../../../components/Avatar';

import './style.scss';
import {
  languageListType,
  languageList,
  languageLevelType,
  languageLevel,
} from './languageList';

// import { IUserData } from '../../../interfaces';
import 'react-quill/dist/quill.snow.css';
import { DuplicateField } from '../../../components/DuplicateField';
import { SkillsPreSet } from './SkillsPreSet';

// file name - CV_<NAME>_<POSITION> - CV_ Ivan R. - Front-end_developer
const { Option } = Select;

class CvMaker extends React.Component<any, IState> {
  initObject = {
    education: {
      name: '',
      degree: '',
      id: 0,
    } as IEducation,
    language: {
      name: '',
      level: '',
      id: 0,
    } as ILanguage,
    portfolio: {
      name: '',
      country: '',
      about: '',
      responsibility: '',
      position: '',
      technologies: '',
      order: 1000,
      id: 0,
    } as IPortfolio,
  };

  communicationSkillList = [
    '1 - Very poor',
    '2 - Poor',
    '3 - Average',
    '4 - Good',
    '5 - Excellent',
  ];

  levelList = ['Junior', 'Middle', 'Senior', 'Super Senior'];

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      user: {} as IUser,
      fieldsList: {
        userName: '',
        positions: '',
        primaryStack: '',
        secondaryStack: '',
        experience: '',
        teamLeadExperience: '',
        communicationSkills: '',
        education: [],
        userDescription: '',
        level: '',
        language: [],
        skills: {
          programmingLanguages: { title: 'Programming Languages:', value: '' } as TSkill,
          dataBases: { title: 'RDBMS:', value: '' } as TSkill,
          developmentTools: { title: 'Development Tools:', value: '' } as TSkill,
          operatingSystems: { title: 'Operating Systems:', value: '' } as TSkill,
          frameworksTool: { title: 'Libraries/Frameworks/Tool:', value: '' } as TSkill,
        } as ISkills,
        portfolio: [],
      } as IFieldsListFull,
      versions: [],
    };
  }

  componentDidMount(): void {
    this.getFullData();
  }

  getFullData = async () => {
    this.setState({
      loading: true,
    });
    await this.getProfileData();

    await this.getCvList();

    const { versions } = this.state;
    if (versions.length > 0) {
      const activeCv: TVersion | undefined = versions.find(
        (item: TVersion) => item.active === true,
      );

      if (activeCv !== undefined) {
        await this.getCvData(activeCv._id);
      }
    }
    this.setState({
      loading: false,
    });
  };

  getCvList = async () => {
    try {
      const { id } = this.props.match.params; // id of User
      const queryParams: { params: {} } = {
        params: { id },
      };

      // make API call
      const { data } = await cvAPI.getList(queryParams);

      // get CV data from API
      const { cvList }: { cvList: TVersion[] } = data;
      if (cvList.length > 0) {
        cvList[0].active = true;
      }
      this.setState({
        versions: cvList,
      });
    } catch (e) {
      this.props.history.push('/');
    }
  };

  getCvData = async (cvId: string) => {
    try {
      const queryParams: { params: {} } = {
        params: { id: cvId },
      };

      // make API call
      const { data } = await cvAPI.getInfo(queryParams);

      // get CV data from API
      const { fields, _id }: { fields: IFieldsListFull; _id: string } = data.cv;
      fields.education = fields.education.sort((a, b) => a.id - b.id);
      fields.language = fields.language.sort((a, b) => a.id - b.id);
      const defaultOrder = 1000;
      fields.portfolio = fields.portfolio.sort(
        (a, b) =>
          (a.order || defaultOrder) - (b.order || defaultOrder) || a.id - b.id,
      );

      const fieldList = { ...this.state.fieldsList, ...fields };
      this.setState({
        fieldsList: fieldList,
        cvId: _id,
      });
    } catch (e) {
      this.props.history.push('/');
    }
  };

  getCvPdf = async (cvId: string) => {
    try {
      this.setState({ loading: true });
      const queryParams: { params: {} } = {
        params: { id: cvId },
      };

      // make API call
      const response = await cvAPI.getPdf(queryParams);
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `CV_${this.state.fieldsList.userName}_${this.state.fieldsList.positions}.pdf`;
      link.click();
    } catch (e) {
      this.props.history.push('/');
    } finally {
      this.setState({ loading: false });
    }
  };

  getProfileData = async () => {
    try {
      const { id } = this.props.match.params;
      const { data }: { data: IUser } = await usersAPI.getProfile({
        params: { id },
      });

      this.setState({
        user: data,
      });
    } catch (e) {
      this.props.history.push('/');
    }
  };

  addNewRow = (rowType: keyof IFieldsListArray) => {
    const fieldList: IFieldsListFull = this.state.fieldsList;

    const clonedObj = { ...this.initObject[rowType] };
    fieldList[rowType].push({
      ...clonedObj,
      id: Date.now(),
    });

    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  rmNewRow = (rowType: string, rmId: number) => {
    const fieldList: IFieldsListFull = this.state.fieldsList;
    const rowKey = rowType as keyof IFieldsListArray;
    const newObjectArray = [];
    for (let i = 0; i < fieldList[rowKey].length; i++) {
      if (fieldList[rowKey][i].id !== rmId) {
        newObjectArray.push(fieldList[rowKey][i]);
      }
    }
    fieldList[rowKey] = newObjectArray;

    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  // handleSubmit = async ({ form, values }: ISubmitProps) => {
  handleSubmit = async (isNew = false) => {
    try {
      const { id } = this.props.match.params;

      const formData: {
        userId: string;
        fields: IFieldsListFull;
        _id?: string;
      } = {
        userId: id,
        fields: this.state.fieldsList,
      };

      if (!isNew) {
        formData._id = this.state.cvId; // cvId
        await cvAPI.put(formData);
      } else {
        // make API call
        await cvAPI.post(formData);
      }

      await this.getFullData();
    } catch (e) {
      errors.handle(e as IRequestError, undefined, {
        defaultMessage: 'Failed',
        priority: 'all',
      });
    }
  };

  handleDelete = async () => {
    try {
      const queryParams: { params: {} } = {
        params: { id: this.state.cvId },
      };

      // make API call
      await cvAPI.delete(undefined, queryParams);

      await this.getFullData();
    } catch (e) {
      errors.handle(e as IRequestError, undefined, {
        defaultMessage: 'Failed',
        priority: 'all',
      });
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldList: IFieldsListFull = this.state.fieldsList;

    if (e.target.getAttribute('data-name')) {
      const dataName = e.target.getAttribute(
        'data-name',
      ) as keyof IFieldsListArray;
      const key = Number(e.target.getAttribute('data-key'));
      const fieldName = e.target.getAttribute('data-field-name') as string;
      if (dataName.length > 0 && fieldName?.length > 0) {
        // @ts-ignore
        fieldList[dataName].find(
          (item: IEducation | IPortfolio) => item.id === key,
        )[fieldName] = value;
      }
    } else {
      fieldList[name as keyof IFieldsListString] = value;
    }

    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  handleChangeSelectLanguage = (
    values: string,
    ev: any,
    form: any,
    data: { name: string; key: number; fieldName: string },
  ) => {
    const fieldList: IFieldsListFull = this.state.fieldsList;

    if (typeof values === 'object') {
      // eslint-disable-next-line prefer-destructuring
      values = values[0];
    }

    // @ts-ignore
    fieldList[data.name as keyof IFieldsListArray].find(
      (item: IEducation | ILanguage) => item.id === data.key,
    )[data.fieldName] = values;

    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  handleChangeSelect = (
    values: string,
    ev: any,
    form: any,
    data: { name: string },
  ) => {
    const fieldList: IFieldsListFull = this.state.fieldsList;
    fieldList[data.name as TFieldListString] = values;

    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  handleChangeSkills = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const fieldList: IFieldsListFull = this.state.fieldsList;

    const fieldName = name.split('.');

    fieldList.skills[fieldName[0] as keyof ISkills][fieldName[1] as keyof TSkill] = value;
    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  handleChangeVersions = async (value: string) => {
    const { versions } = this.state;
    versions.map((item) => {
      item.active = item._id === value;
      return item;
    });
    this.setState({
      versions,
    });
    await this.getCvData(value);
  };

  handlePreSetSkills = async (value: string, data: {
    programmingLanguages: string;
    dataBases: string;
    developmentTools: string;
    operatingSystems: string;
    frameworksTool: string;
  }) => {
    const fieldList: IFieldsListFull = this.state.fieldsList;
    Object.entries(data).forEach(([key, value]) => {
      fieldList.skills[key as keyof ISkills].value = value;
    });
    this.setState({
      fieldsList: { ...fieldList },
    });
  };

  render(): JSX.Element {
    const { loading, user, fieldsList, versions, cvId } = this.state;
    const { firstName, positions, lastName } = user;

    if (versions.length === 0) {
      if (positions) {
        fieldsList.positions = positions
          .map((items: any) => items.name)
          .join(', ');
      }
    }

    if (cvId === undefined || fieldsList.userName.length === 0) {
      fieldsList.userName = `${firstName} ${lastName}`;
    }

    return (
      // !loading ? (
      <Row className='page-profile-cv'>
        <Spin tip='Loading...' spinning={ loading }>
          <Some permissions={ ['cvMaker'] }>
            <Row gutter={ 8 } type='flex' align='top'>
              <Col md={ { span: 14 } } sm={ { span: 24 } }>
                <h2 className='page-header'>Employee CV</h2>
              </Col>
              <Col md={ { span: 10 } } sm={ { span: 24 } }>
                { this.state.cvId && (
                  <Col md={ { span: 6 } } sm={ { span: 24 } }>
                    <Button
                      type='primary'
                      htmlType='button'
                      onClick={ () => this.getCvPdf(this.state.cvId ?? '') }
                      className='mr-10'
                    >
                      Export to PDF
                    </Button>
                  </Col>
                ) }
                { /* eslint-disable-next-line no-magic-numbers */ }
                <Col md={ { span: this.state.cvId ? 18 : 24 } } sm={ { span: 24 } }>
                  <Select
                    value={ versions.find((item) => item.active)?._id }
                    onChange={ this.handleChangeVersions }
                  >
                    { versions.map((item) => (
                      <Option key={ item._id }>
                        { `${item.name} - ${item.updatedAt}` }
                      </Option>
                    )) }
                  </Select>
                </Col>
              </Col>
            </Row>
            <Form key={ cvId || '' }>
              <Row
                gutter={ 8 }
                type='flex'
                align='middle'
                className='page-profile-cv-bg mt-10 pb-10 align-center'
              >
                <Col span={ 24 }>
                  { /* <div className='mb-60'>
                    <Avatar user={user as IUserData} size={150} />
                  </div> */ }
                  <div className='user-name mb-10'>
                    <Field
                      name='userName'
                      defaultValue={ fieldsList.userName }
                      label='Name:'
                      onChange={ this.handleChange }
                      validation='required'
                    />
                  </div>
                  { positions ? (
                    <div className='user-positions mb-10'>
                      <Field
                        name='positions'
                        defaultValue={ fieldsList.positions }
                        label='positions:'
                        type='text'
                        onChange={ this.handleChange }
                        validation='required'
                      />
                    </div>
                  ) : null }
                </Col>
              </Row>
              <Row
                gutter={ 8 }
                type='flex'
                align='top'
                className='page-profile-cv-bg mt-0'
              >
                <Col md={ { span: 8 } } sm={ { span: 24 } }>
                  <div className='page-profile-cv-left mr-10'>
                    <div className='mb-10'>
                      <Field
                        name='primaryStack'
                        defaultValue={ fieldsList.primaryStack }
                        label='Primary stack:'
                        onChange={ this.handleChange }
                      >
                        <TextArea rows={ 2 } />
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <Field
                        name='secondaryStack'
                        defaultValue={ fieldsList.secondaryStack }
                        label='Secondary stack:'
                        onChange={ this.handleChange }
                      >
                        <TextArea rows={ 2 } />
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <Field
                        name='experience'
                        defaultValue={ fieldsList.experience }
                        label='Experience (years):'
                        onChange={ (values: string, ev: any, form: any) =>
                          this.handleChangeSelect(values, ev, form, {
                            name: 'experience',
                          })
                        }
                      >
                        <Select>
                          { [...Array(30)].map((item, year) => (
                            <Option key={ `${year} ${year < 2 ? 'year' : 'years'}` }>
                              { `${year} ${year < 2 ? 'year' : 'years'}` }
                            </Option>
                          )) }
                        </Select>
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <Field
                        name='teamLeadExperience'
                        defaultValue={ fieldsList.teamLeadExperience }
                        label='Team Lead Experience:'
                        onChange={ (values: string, ev: any, form: any) =>
                          this.handleChangeSelect(values, ev, form, {
                            name: 'teamLeadExperience',
                          })
                        }
                      >
                        <Select>
                          <Option key='Yes'>Yes</Option>
                          <Option key='No'>No</Option>
                        </Select>
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <Field
                        name='communicationSkills'
                        defaultValue={ fieldsList.communicationSkills }
                        label='Communication Skills:'
                        onChange={ (values: string, ev: any, form: any) =>
                          this.handleChangeSelect(values, ev, form, {
                            name: 'communicationSkills',
                          })
                        }
                      >
                        <Select>
                          { this.communicationSkillList.map((item) => (
                            <Option key={ item }>{ item }</Option>
                          )) }
                        </Select>
                      </Field>
                    </div>
                    <div className='mb-10'>
                      <Field
                        name='level'
                        defaultValue={ fieldsList.level }
                        label='Level:'
                        onChange={ (values: string, ev: any, form: any): void =>
                          this.handleChangeSelect(values, ev, form, {
                            name: 'level',
                          })
                        }
                      >
                        <Select>
                          { this.levelList.map((item) => (
                            <Option key={ item }>{ item }</Option>
                          )) }
                        </Select>
                      </Field>
                    </div>
                    <Divider orientation='left'>
                      <h3 className='section-title'>EDUCATION</h3>
                    </Divider>
                    <DuplicateField
                      key='education-fields'
                      name='education'
                      fields={ [...this.state.fieldsList.education] }
                      fieldsDesc={ [
                        {
                          name: 'name',
                          label: 'University name:',
                          textType: { handleChange: this.handleChange },
                        },
                        {
                          name: 'degree',
                          label: 'Degree:',
                          textType: { handleChange: this.handleChange },
                        },
                      ] }
                      handleAddClick={ (): void => this.addNewRow('education') }
                      handleRmClick={ this.rmNewRow }
                    />

                    <Divider orientation='left'>
                      <h3 className='section-title'>LANGUAGES</h3>
                    </Divider>

                    <DuplicateField
                      key='lenguage-fields'
                      name='language'
                      fields={ [...this.state.fieldsList.language] }
                      fieldsDesc={ [
                        {
                          name: 'name',
                          label: 'Language:',
                          selectType: {
                            handleChange: this.handleChangeSelectLanguage,
                            list: languageList as languageListType[],
                          },
                        },
                        {
                          name: 'level',
                          label: 'Level:',
                          selectType: {
                            handleChange: this.handleChangeSelectLanguage,
                            list: languageLevel as languageLevelType[],
                          },
                        },
                      ] }
                      handleAddClick={ (): void => this.addNewRow('language') }
                      handleRmClick={ this.rmNewRow }
                    />
                  </div>
                </Col>
                <Col md={ { span: 16 } } sm={ { span: 24 } }>
                  <div className='mb-10 ml-10'>
                    <div className='ant-col ant-form-item-label'>
                      <label
                        htmlFor='userDescription'
                        className='ant-form-item-no-colon'
                        title='About person:'
                      >
                        About the person:
                      </label>
                    </div>
                    <ReactQuill
                      onChange={ (value: string): void => {
                        this.handleChangeSelect(value, null, null, {
                          name: 'userDescription',
                        });
                      } }
                      theme='snow'
                      defaultValue={ fieldsList.userDescription }
                    />
                    <Divider orientation='left'>
                      <h3 className='section-title'>SKILLS</h3>
                    </Divider>
                    <SkillsPreSet handleOnChange={ this.handlePreSetSkills } className='mb-10' />
                    <div className='mb-10'>
                      <Field
                        key='programmingLanguages-title'
                        name='programmingLanguages.title'
                        defaultValue={ fieldsList.skills?.programmingLanguages?.title || 'Programming Languages:' }
                        size="small"
                        addonBefore="Title:"
                        onChange={ this.handleChangeSkills }
                      />
                      <Field
                        key='programmingLanguages-value'
                        name='programmingLanguages.value'
                        defaultValue={ fieldsList.skills?.programmingLanguages?.value || '' }
                        onChange={ this.handleChangeSkills }
                      />
                    </div>

                    <div className="mb-10">
                      <Field
                        name='dataBases.title'
                        defaultValue={ fieldsList.skills?.dataBases?.title || 'RDBMS:' }
                        size="small"
                        addonBefore="Title:"
                        onChange={ this.handleChangeSkills }
                      />
                      <Field
                        name='dataBases.value'
                        defaultValue={ fieldsList.skills?.dataBases?.value || '' }
                        onChange={ this.handleChangeSkills }
                      />
                    </div>
                    <div className="mb-10">
                      <Field
                        name='developmentTools.title'
                        defaultValue={ fieldsList.skills?.developmentTools?.title || 'Development Tools:' }
                        size="small"
                        addonBefore="Title:"
                        onChange={ this.handleChangeSkills }
                      />
                      <Field
                        name='developmentTools.value'
                        defaultValue={ fieldsList.skills?.developmentTools?.value || '' }
                        onChange={ this.handleChangeSkills }
                      />
                    </div>
                    <div className="mb-10">
                      <Field
                        name='operatingSystems.title'
                        defaultValue={ fieldsList.skills?.operatingSystems?.title || 'Operating Systems:' }
                        size="small"
                        addonBefore="Title:"
                        onChange={ this.handleChangeSkills }
                      />
                      <Field
                        name='operatingSystems.value'
                        defaultValue={ fieldsList.skills?.operatingSystems?.value || '' }
                        onChange={ this.handleChangeSkills }
                      />
                    </div>
                    <div className="mb-10">
                      <Field
                        name='frameworksTool.title'
                        defaultValue={ fieldsList.skills?.frameworksTool?.title || 'Libraries/Frameworks/Tool:' }
                        size="small"
                        addonBefore="Title:"
                        onChange={ this.handleChangeSkills }
                      />
                      <Field
                        name='frameworksTool.value'
                        defaultValue={ fieldsList.skills?.frameworksTool?.value || '' }
                        onChange={ this.handleChangeSkills }
                      />
                    </div>
                    <Divider orientation='left'>
                      <h3 className='section-title'>PORTFOLIO HIGHLIGHTS</h3>
                    </Divider>

                    <DuplicateField
                      key='portfolio-fields'
                      name='portfolio'
                      fields={ [...this.state.fieldsList.portfolio] }
                      fieldsDesc={ [
                        {
                          name: 'order',
                          label: 'Order position:',
                          type: 'number',
                          textType: {
                            handleChange: this.handleChange,
                          },
                        },
                        {
                          name: 'name',
                          label: 'Project title:',
                          textType: {
                            handleChange: this.handleChange,
                          },
                        },
                        {
                          name: 'country',
                          label: 'Country:',
                          textType: {
                            handleChange: this.handleChange,
                          },
                        },
                        {
                          name: 'about',
                          label: 'About the project:',
                          reachTextType: {
                            handleChange: this.handleChangeSelectLanguage,
                          },
                        },
                        {
                          name: 'responsibility',
                          label: 'The key areas of responsibility:',
                          reachTextType: {
                            handleChange: this.handleChangeSelectLanguage,
                          },
                        },
                        {
                          name: 'position',
                          label: 'Position:',
                          textType: {
                            handleChange: this.handleChange,
                          },
                        },
                        {
                          name: 'technologies',
                          label: 'Technologies Steck:',
                          textType: {
                            handleChange: this.handleChange,
                          },
                        },
                      ] }
                      handleAddClick={ (): void => this.addNewRow('portfolio') }
                      handleRmClick={ this.rmNewRow }
                    />
                  </div>
                </Col>
              </Row>
              <Row type='flex' justify='end'>
                <Col className='mt-10'>
                  <Button
                    type='primary'
                    htmlType='button'
                    onClick={ () => this.handleSubmit() }
                    className='ml-10'
                    disabled={ cvId === undefined }
                  >
                    Update
                  </Button>
                  <Button
                    type='primary'
                    htmlType='button'
                    onClick={ () => this.handleSubmit(true) }
                    className='ml-10'
                  >
                    Save as New
                  </Button>
                  <Button
                    type='danger'
                    htmlType='button'
                    onClick={ this.handleDelete }
                    className='ml-10'
                    disabled={ cvId === undefined }
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Form>
          </Some>
        </Spin>
      </Row>
    ); // : null;
  }
}

export default CvMaker;
