import React from "react";
import "./styles.scss";
import { Button } from "antd";
import ReferIcon from "../../../../assets/icons/referWelcomBook";
import { set as setModal, open } from "../../../../services/modal";
import invitationsAPI from "../../../../API/invitations";
import errors from "../../../../API/error";
import ReferFriend from "../../../../containers/Modals/ReferFriend";
import ReferFriendMobile from "../../../../assets/icons/referWBMobile";

const className = "absence-card";
const ReferFriendItem = () => {
  const submitReferFriend = async ({
    values,
    form,
    onLoading,
    onCancel,
    modalProps,
  }: any) => {
    const { callback = () => {} } = modalProps;
    try {
      onLoading(true);
      await invitationsAPI.create(values);
      onCancel();
    } catch (e) {
      errors.handle(e, form, { priority: "all" });
      onLoading(false);
    } finally {
      callback();
    }
  };

  setModal("referFriend", {
    hash: "refer-friend",
    component: ReferFriend,
    config: {
      title: "Refer a Friend",
      okLabel: "Send",
      onSubmit: submitReferFriend,
    },
  });
  return (
    <div className="refer-friend__container">
      <div className={className}>
        <div className={`${className}-title tittle ml-50`}>Refer a Friend</div>
        <div className={`${className}-content`}>
          <div className="refer-friend__content_container flex jc-center">
            <div className="refer-friend__icon-container">
              <ReferIcon />
            </div>
            <div className="refer-friend__icon-mobile">
              <ReferFriendMobile />
            </div>
            <div className="refer-friend__text-container">
              <div className="refer-friend__main-text ">
                Do you have someone in mind? Someone who possesses the unique
                skills and mindset to join our incredible team?
              </div>
              <div className="refer-friend__sub-text mt-10">
                Let's build #devabitfamily together.
              </div>
              <div className="refer-friend__bonus">
                One-time bonus for the recommendation of a friend who will pass
                the trial period
              </div>
              <div className="flex jc-center mt-20">
                <Button
                  onClick={() => {
                    open("referFriend");
                  }}
                  type="primary"
                >
                  Recommend
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferFriendItem;
