import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import Avatar from '../../../components/Avatar';
// eslint-disable-next-line import/no-cycle
import userRoutes from '../../../routes/user';

interface Props {
  date: string;
  [key: string]: any;
}

const header = (props: Props): JSX.Element => {
  const fullName = `${props.firstName || ''} ${props.lastName || ''}`.trim();
  const linkToProfile = generatePath(userRoutes.employeeProfile.path, { id: props._id });
  return (<Row className="p-20" type="flex" justify="start">
    <Avatar size={ 50 } user={ props as any } />
    <Col className="pl-20">
      <div className="nfi-user-name">
        <Link
          to={ linkToProfile }
        >
          {
            fullName
          }
        </Link>

      </div>
      <div className="nfi-date">{
       props.date
      }</div>
    </Col>
  </Row>);
};

export default header;
