export type languageListType = {
  name: string;
  code: string;
};
export const languageList: languageListType[] = [
  { name: 'Czech', code: 'cs' },
  { name: 'English', code: 'en' },
  { name: 'French', code: 'fr' },
  { name: 'German', code: 'de' },
  { name: 'Polish', code: 'pl' },
  { name: 'Russian', code: 'ru' },
  { name: 'Ukrainian', code: 'uk' },
];

export type languageLevelType = {
  name: string;
  code: string;
};
export const languageLevel: languageLevelType[] = [
  { name: 'Elementary', code: 'A1' },
  { name: 'Pre-intermediate', code: 'A2' },
  { name: 'Intermediate', code: 'B1' },
  { name: 'Upper-intermediate', code: 'B2' },
  { name: 'Advanced', code: 'C1' },
  { name: 'Proficiency', code: 'C2' },
  { name: 'Native', code: 'native' },
  { name: 'Fluent', code: 'fluent' },
];
