import * as modal from "../../../services/modal";
import RemoveWeekend from "../RemoveWeekend";

interface IProps {
  onRemove: any
};

const init = ({onRemove}: IProps) => {
  modal.set('removeWeekend', {
    hash: 'remove-weekend',
    component: RemoveWeekend,
    config: {
      okLabel: 'Delete',
      onSubmit: onRemove
    }
  });
};

const open = () => {
  modal.open('removeWeekend')
};

const del = () => {
  modal.remove('removeWeekend')
};

export default {
  init,
  open,
  del
}