import React from "react";
import {Row, Col} from 'antd'
import {hexAToRGBA} from '../../utils'
import './styles.scss'

interface IProps {
  title: string
  days?: number
  approved?: number
  pending?: number
  accrual?: number
  color: string
  unlimitedDays?: boolean
}

const className = 'absence-card';

const daysTxt = (days = 0) => {
  return days === 1 ? 'Day' : 'Days'
};

const AbsenceCard = (props: IProps) => {
  const {
    days = 0,
    accrual = 0,
    pending = 0,
    approved = 0,
    title,
    unlimitedDays,
    color
  } = props;
  const total = Math.round((days + accrual + pending) * 100) / 100;
  const percAvailable = days * 100 / total;
  const percPending = Math.round((pending * 100 / total + percAvailable) * 100) / 100;
  const pendingBg = hexAToRGBA(color, 0.3);

  return <div className={className}>
    <div className={`${className}-title`}>{title}</div>

    <div className={`${className}-line-title`}>{unlimitedDays ? 'Unlimited' : `${days} ${daysTxt(days)}`}</div>
    <div className={`${className}-line`} style={unlimitedDays ? {backgroundColor: pendingBg} : {}}>
      {
        !unlimitedDays ? <>
        <span className={`${className}-available`} style={{
          width: `${percAvailable}%`,
          backgroundColor: color
        }} />
          <span className={`${className}-pending`} style={{
            width: `${percPending}%`,
            backgroundColor: pendingBg
          }} />
        </> : null
      }
    </div>
    <div className={`${className}-content`}>
      <Row gutter={20} className={`${className}-first`}>
        <Col span={12}>{`Currently Allowed: ${unlimitedDays ? 'Unlimited' : `${days} ${daysTxt(days)}`}`}</Col>
        <Col span={12} className="text-right">{`Pending For Approval: ${pending} ${daysTxt(pending)}`}</Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>{!unlimitedDays ? `Future Accrue: ${accrual} ${daysTxt(accrual)}` : ''}</Col>
        <Col span={12} className="text-right">{`Approved: ${approved} ${daysTxt(approved)}`}</Col>
      </Row>
    </div>
  </div>
};

export default AbsenceCard