import * as modal from "../../../services/modal";
import Holiday from "../Holiday";

interface IProps {
  onSubmit: any,
  onChangeImage: any
}

export const set = ({onSubmit, onChangeImage}: IProps) => {
  modal.set('holiday', {
    hash: 'holiday',
    component: Holiday,
    onChangeImage,
    config: {
      title: 'Add Event or Holiday',
      okLabel: 'Save',
      onSubmit,
      disableForm: true,
      actions: null,
      modalWidth: 420,
      // wrapClassName: 'holiday-modal'
    }
  })
};

export const del = () => {
  modal.remove('holiday')
};