import {CHANGE_USER, REMOVE_USER, SETUP_USER} from "../../reducers/actions";
import store from '../../configs/store';

export const isUserLogged = (storeUser: any): boolean => {
  return !!storeUser._id;
};

export const set = (payload: any) => {
  store.dispatch({
    payload,
    type: SETUP_USER
  })
};

export const change = (payload: any) => {
  store.dispatch({
    payload,
    type: CHANGE_USER
  })
};

export const remove = (payload: any = {}) => {
  store.dispatch({
    payload,
    type: REMOVE_USER
  })
};
