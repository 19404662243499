import * as React from 'react';
import {
    Icon,
    Popconfirm
} from 'antd';
import moment from 'moment';
import appConfig from '../../configs/app';
import Has from '../../noui/Permissions/Has';
import NavLink from '../../components/NavLink';
import eventRoutes from "../../routes/user";
import eventTitle from "../../services/eventChecker";
import './styles.scss';

interface IProps {
    name: string,
    description?: string,
    from: string,
    to: string,
    image?: string,
    onEdit?: () => void,
    onRemove?: () => void,

    [key: string]: any
}

export default ({
                    name,
                    description,
                    from,
                    to,
                    image,
                    onEdit,
                    onRemove,
                    groupId,
                    isHoliday,
                    _id,
                    isRestDay
                }: IProps) => {
    const fromDate = moment.utc(from);
    const oneDay = fromDate.format('dddd, DD/MM/YYYY');
    const toDate = moment.utc(to);
    const date = fromDate.diff(toDate, 'days') === 0 ? oneDay : `${fromDate.format('DD/MM/YYYY')} - ${toDate.format('DD/MM/YYYY')}`;

    return <div className="event-card">
        <div className="event-card-row">

            {image ? <div className="card-background"
                          style={{background: `${image ? `url('${appConfig.staticFolder}${image}?width=500') no-repeat center center / cover` : ''}`}}>
            </div> : null}

            <div className="event-card-right">
                <div className="event-card-right-top mb-35">
                    <div className="events-label">
                        <h3 className="events-title">{name}</h3>
                        <span
                            className="sub-title"
                            style={isRestDay ? {color: '#C30B2F'} : undefined}>{eventTitle(isHoliday,isRestDay)}</span>
                    </div>
                    <div className="events-icon">
                        {
                            onEdit ? <Has permissions={['settings']}>
                                <button className="clear cursor"
                                        onClick={onEdit}>
                                    <Icon type="form"/>
                                </button>
                            </Has> : null
                        }
                        {
                            onRemove ? <Has permissions={['settings']}>
                                <Popconfirm
                                    title="Are you sure delete this?"
                                    onConfirm={onRemove}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <button className="clear ml-10 cursor">
                                        <Icon type="delete"/>
                                    </button>
                                </Popconfirm>
                            </Has> : null
                        }
                    </div>
                </div>

                <div className="event-desc mb-45">
                    {description ? description.length > 250 ? description.slice(0, 250) + "..." : description : null}
                </div>

                <span className="event-date">{date}</span>
                <NavLink to={eventRoutes.singleEvent.path}
                         params={{id: _id}}
                         className="rm">
                    View more
                </NavLink>
            </div>
        </div>
    </div>
};