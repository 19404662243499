import * as React from 'react';
import validate from "../../../services/images/validate";

let inputRef: any = '';

const handleClearValue = () => {
    inputRef.value = '';
};

const handleChangeFiles = (onChange: any, files: any) => {
    if (!files || !files.length) {
        return false
    }
    const file = files[0];
    if (validate(file)) {
        onChange({file, clearValue: handleClearValue})
        return true;
    }
    handleClearValue();
    return false;
};

const handleChangeTrigger = () => {
    inputRef.click()
};

const handleOnChange = (onChange: any) =>
    (e: any) => {
        const {files} = e.target;
        handleChangeFiles(onChange, files);
    };

interface IProps {
    onChange: any,
    src?: string
}

export default ({onChange, src}: IProps) => <div>
    <div className={`image-block mb-15 ${src ? 'active-media' : null}`}>
        <div className="media" style={src
            ? {background: `#F9F9F9 url("${src}") no-repeat center center / cover`}
            : {}}
        />
        <div className="add-btn">
            <div className="add--btn" onClick={handleChangeTrigger}>
                <span className="add-plus-ico">+</span>
                {src ? <span>Change image</span> : <span>Add Image</span> }
            </div>
        </div>
    </div>
    <input type="file"
           className="hidden"
           ref={item => inputRef = item}
           onChange={handleOnChange(onChange)}/>
</div>