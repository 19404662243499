import React, {useState} from 'react';
import {Col, Select} from "antd";
import Field from "../../ui/Field";
import usersAPI from "../../API/users";
import errors from "../../API/error";

const {Option} = Select;

interface IProps {
  statuses?: Array<'active' | 'inactive' | 'archived'>
  name?: string
  required?: boolean
  maxTagCount?: number | null
  label?: string

  onChange?(values: string[], e?: any, form?: any) : void

  staticOptions?: {
    name: string
    value: string
  }[]
  defaultSelected?: {
    _id: string,
    firstName: string
    lastName: string
  }[]
  col?: {
    [key: string]: any
  }
}

export default (props: IProps) => {
  const {
    name = 'users',
    label = 'Search user',
    col,
    defaultSelected = [],
    maxTagCount = 1,
    required,
    staticOptions,
    onChange,
    statuses
  } = props;
  const [data, setData] = useState(defaultSelected);

  const onSearchUser = async (value: string) => {
    try {
      const urlParams: any = {query: {search: value, limit: 10}};
      if (statuses && statuses.length) {
        urlParams.query.statuses = JSON.stringify(statuses)
      }
      const res = await usersAPI.get(urlParams);
      setData(res.data.list);
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load employees'});
    }
  };

  const handleClearData = () => {
    onSearchUser('');
  };

  const defaultValue = defaultSelected.map((el) => el._id);
  const addProps: any = required ? {
    validation: 'required'
  } : {};

  if (onChange) {
    addProps.onChange = onChange
  }

  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
      defaultValue={defaultValue}
      onFocus={handleClearData}
      onSearch={onSearchUser}
      {...addProps}
    >
      <Select
        mode="multiple"
        allowClear={true}
        showSearch={true}
        filterOption={false}
        notFoundContent="Not found"
        optionFilterProp="children"
        defaultActiveFirstOption={false}
        maxTagCount={maxTagCount === null ? undefined : maxTagCount}
      >
        {
          staticOptions && staticOptions.length > 0 ? staticOptions.map((item, index: number) =>
            <Option key={index}
                    value={item.value}>{item.name}</Option>) : null
        }
        {
          data.map((data: any) =>
            <Option key={data._id}
                    value={data._id}>{`${data.firstName || ''} ${data.lastName || ''}`.trim()}</Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  };
  return getItem()
}