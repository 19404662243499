import React, {ReactNode} from "react";
import {IUserData} from "../../interfaces";
import {Avatar as AntAvatar} from "antd";
import {AvatarProps} from "antd/es/avatar";
import appConfig from "../../configs/app";

interface IProps extends AvatarProps {
    user: IUserData,
    width?: number,
    height?: number,
    renderChildren?: (props: IProps) => any,
    icon?: ReactNode
}

const Avatar = (props: IProps) => {
    const {user, width, height, renderChildren, children, size, icon, ...rest} = props;
    const {firstName = '', lastName = '', avatar, changedAvatar} = user;
    const name = `${firstName} ${lastName}`.trim();
    let src = avatar ? appConfig.staticFolder + avatar : '';

    if (typeof size === 'number' && avatar) {
        src += `?width=${size}&height=${size}`
    } else if (avatar && width && height) {
        src += `?width=${width}&height=${height}`
    }

    let iconName = icon ? icon : avatar ? <img src={avatar} alt=""/> : <p>{(firstName.charAt(0) + lastName.charAt(0)).toUpperCase()}</p>

    return <AntAvatar src={changedAvatar ? changedAvatar : src} alt={name} icon={iconName} size={size} {...rest}>
        {children
            ? children
            : renderChildren ? renderChildren(props) : null}
    </AntAvatar>
};

export default Avatar;