import React from "react";
import {Col, DatePicker, Row} from "antd";
import {Weekends, Buttons, ReqStatus} from "../../../components/Filters";
import Field from "../../../ui/Field";
import dateConfig from "../../../configs/date";

const {RangePicker} = DatePicker

export default () => <Row gutter={[10, 8]} className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col md={{span: 20}}>
    <Row gutter={[8, 8]}>
      <Weekends col={{md: {span: 6}}} />
      <ReqStatus col={{md: {span: 6}}} />
      <Col md={{span: 12}}>
        <Field name="range"
               label="From"
               placeholder="From">
          <RangePicker format={dateConfig.formatHolidayDatePicker} />
        </Field>
      </Col>
    </Row>
  </Col>
  <Col md={{span: 4}}>
    <Buttons />
  </Col>
</Row>