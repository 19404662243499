import React from "react";
import afterAllImg from "../../../../assets/imgs/welcomeBook/Copy of Copy of IMG_2115_Original 1.jpg";
import afterAllImgMobile from "../../../../assets/imgs/welcomeBook/Office_party_mob.jpg";
import "./styles.scss";
function AfterAll() {
  return (
    <div className="after-all">
      <div className="after-all__text-container">
        <div className="after-all__tittle tittle">
          After all, what does it mean to be a part of devabit?
        </div>
        <div className="after-all__text secondary-text mb-25">
          It means constantly growing, both individually and professionally. It
          means feeling supported each time you need it. It means being
          surrounded by people that understand you. It means being inspired
          every day. It means unleashing the full potential of your talent and
          capabilities. It means being a part of something that truly matters,
          something that can change the world through tech-powered innovations.
          It means feeling like a real family.
        </div>
        <div className="after-all__main-text main-text">
          All in all, it is time to start your awe-inspiring journey with
          devabit. Just remember that we are always here for you.
        </div>
        <div className="after-all__text secondary-text">
          Kindly,
          <br />
          #devabitfamily!
        </div>
      </div>
      <div className="after-all__image-container">
        <img src={afterAllImg} alt="after all" className="after-all__image" />
        <img
          src={afterAllImgMobile}
          alt="after all"
          className="after-all__image_mobile"
        />
      </div>
    </div>
  );
}

export default AfterAll;
