import React from 'react';
import flag from '../../../../assets/imgs/welcomeBook/flag.png';
import './styles.scss';

const WelcomeBookHeader = (props: any) => {
  return (
    <div className="wb-header__container">
      <div className="wb-header__icon-container">
        <img src={ flag } alt="flag" />
      </div>
      <div className="wb-header__tittle-container">
        <div className="wb-header__tittle">{ props.title || '' }</div>
        <div className="wb-header__sub-tittle">{ props.sub_title || '' }</div>
      </div>
    </div>
  );
};

export default WelcomeBookHeader;
