import requestsAPI from "../../API/requests";
import n from "../notification";
import {IApprovalParams} from "./index";

const cancel = async (params: IApprovalParams) => {
    try {
        params.reason = 'Some reason';
        await requestsAPI.cancel(params);
        n.success('Cancellation request has been sent');
        return true
    } catch (e) {
        n.error('Failed to cancel');
        return false
    }
};

export default cancel;