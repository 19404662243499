import * as modal from "../../../services/modal";
import ForgotPassword from "../ForgotPassword";

export const init = () => {
    modal.set('forgotPassword', {
        hash: 'forgot-password',
        component: ForgotPassword,
        config: {
            disableForm: true,
            actions: null,
            modalWidth:'550px',
            wrapClassName: 'wrap-forgot-password',
        }
    });
};

export const open = (item: any = {}) => {
    modal.setProps('forgotPassword', item);
    modal.open('forgotPassword')
};

export const remove = () => {
    modal.remove('forgotPassword')
};

export default {
    init,
    open,
    remove
}