import { notification } from 'antd';

class Notification {
  public success(msg:string = 'Success') {
    notification.success({
      message: msg
    })
  }

  /**
   * user notification message
   */
  public error(msg: string = 'Failed') {
    notification.error({
      message: msg
    })
  }

  public debug(msg: string) {
    console.log(msg)
  }
  public failed(msg: string) {
    console.error(`::: ${msg}`)
  }
  public warn(msg: string) {
    console.warn(`::: ${msg}`)
  }
}

export default new Notification();
