import * as React from "react";
import { Button, Form, Select, DatePicker } from "antd";
import moment, { Moment } from "moment";
import "./styles.scss";
import {
  ITable,
  ITableSector,
} from "../../../../interfaces/containers/tableBooking";
import { setDateRequest, setTable } from "../../../../services/bookingTable";
import RightOutlined from "src/assets/icons/RightOutlined";
import LeftOutlined from "../../../../assets/icons/LeftOutlined";
import { open } from "../../../../services/modal";
import { connect } from "react-redux";
import { IStore } from "../../../../interfaces";
interface State {
  loaded?: boolean;
}
const { Option } = Select;
class WorkspaceHeader extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  openBookingRequestModal = () => {
    open("bookingRequest");
  };

  handleAddDate = () => {
    const newDate = moment(this.props.bookingTable.date).add(1, "d")

    setDateRequest(newDate);
  };
  handleSubtractDate = () => {
    setDateRequest(moment(this.props.bookingTable.date).subtract(1, "d"));
  };
  handelSelectTabel = (tableId: string) => {
    setTable(tableId);
  };
  handelSelectData = (date: Moment | null) => {
    if (date) setDateRequest(date);
  };

  render() {
    const { disabledSelectSector } = this.props;
    const {
      sectorsData: sectors,
      tablesData: tables,
      sector: currentSector,
      table: currentTable,
      date: currentDate,
    } = this.props.bookingTable;
    const currentSectorData =
      sectors.filter((sector: any) => sector._id === currentSector)[0] || {};
    const { name: sectorName, color: sectorColor } = currentSectorData;

    const currentDateIsToday = currentDate
      .endOf("day")
      .isSame(moment(new Date()).endOf("day"));
  
    const sectorSelect = (
      <Form.Item label={<p className={`workspace__label`}>Zones</p>}>
        {sectors && (
          <div className="flex">
            {sectors.map((sector: ITableSector) => {
              const { name, color, _id } = sector;
              return (
                <div
                  key={_id}
                  style={{ background: color }}
                  className={`sectorSelect ${
                    currentSector === _id ? "selectedSector" : ""
                  } ${disabledSelectSector ? "cursor-disabled" : ""}`}
                  onClick={() => this.props.handleSelectSector(_id)}
                >
                  {name}
                </div>
              );
            })}
          </div>
        )}
      </Form.Item>
    );
    return (
      <div className="workspace__container">
        <div className="workspace__tittle-container">
          <h2 className="workspace__tittle page-header">Workspace</h2>
        </div>
        <div className="workspace__content-container">
          <div className="workspace__selector_desktop">{sectorSelect}</div>
          <Form.Item label={<p className="workspace__label">Selected Date</p>}>
            <DatePicker onChange={this.handelSelectData} value={currentDate} />
            <Button
              disabled={currentDateIsToday}
              onClick={this.handleSubtractDate}
              className="workspace__button"
            >
              <LeftOutlined
                color={currentDateIsToday ? "#BFBFBF" : "#191F2D"}
              />
            </Button>
            <Button className="workspace__button" onClick={this.handleAddDate}>
              <RightOutlined />
            </Button>
          </Form.Item>
          <div className="flex">
            <div className="workspace__selector_mobile">{sectorSelect}</div>
            <Form.Item
              label={
                <p
                  className={`workspace__label ${
                    !tables.length ? "disabled" : ""
                  }`}
                >
                  Table number
                </p>
              }
            >
              <div className="flex">
                <Select
                  disabled={!tables.length}
                  onChange={this.handelSelectTabel}
                  value={currentTable}
                >
                  {tables.map((table: ITable) => (
                    <Option key={table._id}>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "16px",
                          fontWeight: "unset",
                          lineHeight: "20px",
                        }}
                      >
                        <div
                          style={{ background: sectorColor }}
                          className="sector"
                        >
                          {sectorName}
                        </div>
                        {table.name}
                      </div>
                    </Option>
                  ))}
                </Select>
                <Button
                  disabled={!currentTable}
                  onClick={this.openBookingRequestModal}
                  type="primary"
                >
                  Book
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(({ bookingTable }: Partial<IStore>) => ({
  bookingTable,
}))(WorkspaceHeader);
