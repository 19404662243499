import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import Route from './Route';
import NotFound from '../containers/NotFound';


const CustomRoute = ({
                       user,
                       notFound,
                       routes: userRoutes,
}: any): any => {
  const {role: userRole} = user;
  const routes = [];
  const routesKeys = Object.keys(userRoutes);

  routesKeys.forEach((item, index) => {
    const {
      roles,
      isVisible,
      exact = true,
      path,
      redirect,
      component: Component,
      ...rest
    } = userRoutes[item];

    if (Array.isArray(roles) && roles.length && roles.indexOf(userRole) === -1) {
      routes.push(redirect ? <Redirect to={redirect} /> : null)

    } else if ((typeof isVisible === 'function' && !isVisible(user)) || (redirect && !Component)) {
      routes.push(redirect ? <Redirect key={index} from={path} to={redirect} /> : null)

    } else {
      routes.push(<Route exact={exact}
                         key={index}
                         path={path}
                         component={Component}
                         {...rest} />)
    }
  });

  if (notFound) {
    routes.push(<Route key={routesKeys.length} path="/*"
                       component={typeof notFound === 'boolean' ? NotFound : notFound} />)
  }
  return <Switch>{routes}</Switch>;
};


export default connect((state: any) => ({
  user: state.user
}), null, null, { pure: false })(CustomRoute)