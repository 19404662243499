import * as React from 'react';
import {
  Button,
  notification
} from 'antd';
import settingsRoutes from '../../routes/settings';
import weekendsAPI from '../../API/weekends';
import columns from "./columns";
import NavLink from '../../components/NavLink';
import {IActionsList, TActions} from '../../components/DotMenu';
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Settings from '../../layouts/Settings'
import modalRemove from '../../containers/Modals/set/removeWeekend';
import TablePagination from "../../services/tablePagination";
import errors from '../../API/error';
import './styles.scss';
import {generatePath} from "react-router";
import * as path from "../../services/path";

interface IState {
  id: number,
  weekends: any[],
  pagination: any
  loading: boolean
}

class CalendarGroup extends React.Component<any, IState> {
  pagination = new TablePagination();
  state = {
    id: 0,
    weekends: [],
    pagination: {},
    loading: false
  };

  componentDidMount() {
    modalRemove.init({onRemove: this.handleRemove});
    this.getWeekends();
  };


  getWeekends = async () => {
    try {
      const res = await weekendsAPI.get({query: {
        "viewall": true,
        ...this.pagination.requestParams()
      }});
      const {list, pagination} = res.data;
      this.pagination.value = pagination;
      this.setState({weekends: list, pagination, loading: false});
    } catch (e) {
      this.setState({loading: false});
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
    }
  };

  modalRemoveTrigger = async (id: string) => {
    try {
      await weekendsAPI.del(null, {params: {id}});
      const {weekends} = this.state;
      const edited = weekends.filter((item: any) => item._id !== id);
      this.setState({weekends: edited});
      notification.success({
        message: 'Success'
      })
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'})
    }
  };

  handleRemove = async ({onCancel, onLoading}: any) => {
    const {id} = this.state;
    if (id) {
      try {
        onLoading(true);
        await weekendsAPI.del(null, {params: {id}});
        const {weekends} = this.state;
        const edited = weekends.filter((item: any) => item.id !== id);

        this.setState({weekends: edited});
        notification.success({
          message: 'Success'
        });
        onCancel()
      } catch (e) {
        onLoading(false);
        errors.handle(e, undefined, {defaultMessage: 'Failed'});
      }
    }
  };

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getWeekends();
  };

  actions: TActions = (record: any): IActionsList => {
    return [
      {
        name: 'Edit',
        link: path.get(settingsRoutes.settingsWeekendsEdit.path, {id: record._id})
      },
      {
        name: 'Delete',
        action: this.modalRemoveTrigger.bind(null, record._id)
      }
    ]
  };

  render() {
    const {weekends, loading} = this.state;
    return <Settings location={this.props.location} actionsComponent={
      <NavLink to={settingsRoutes.settingsWeekendsAdd.path}>
      <Button className="ant-btn-primary">Create Absence Type</Button>
    </NavLink>}>
        <Table dataSource={weekends}
               loading={loading}
               clickable
               onRow={(record: any) => {
                 return {
                   onClick: () => {
                     this.props.history.push(generatePath(settingsRoutes.settingsWeekendsEdit.path, {id: record._id}))
                   }
                 };
               }}
               columns={columns(this.actions)}
               rowKey="_id"
               pagination={this.pagination.tableConfig} />
      <Pagination {...this.pagination.config} onChange={this.changePage}/>
    </Settings>
  }

}

export default CalendarGroup
