import * as React from 'react';
import { connect } from 'react-redux';
import { Calendar, Card, Col, Row, Select, Popover, Checkbox, Spin } from 'antd';
import moment, { Moment } from 'moment';
import Avatar from '../../components/Avatar';
import FiltersComponent from '../../components/Filters';
import FiltersLayout from './FiltersLayout';
import { open, setProps } from '../../services/modal';
import Filters from '../../services/filters';
import { months } from '../../configs/date';
import configsApp from '../../configs/app';
import holidaysAPI from '../../API/holidays';
import requestsAPI from '../../API/requests';
import calendarHelpers from './helpers';
import { hexAToRGBA } from '../../utils';
import CalendarEventCard from '../../components/CalendarEventCard';
import { IStore } from '../../interfaces';
import './style.scss';
import userRoutes from '../../routes/user';
import NavLink from '../../components/NavLink';
import CellTmp from './Cell';
import { RequestAbsence, Filters as FiltersBtn } from '../../components/Buttons';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

type UserFilter = { _id: string; firstName: string; lastName: string };

const { Option } = Select;
const TYPES = calendarHelpers.types;
const STATUSES = calendarHelpers.statuses;
const initState = {
  activeFilters: false,
  pagination: {},
  list: [],
  listItems: [],
  restDays: [],
  grouped: {},
  boxWidth: 100,
  onlyMyAbsence: true,
  filters: {},
  loading: true,
  filtersUserPreSelect: undefined,
};

class CalendarComponent extends React.Component<any, any> {
  static resizeTimer: any = null;

  boxRef: any = React.createRef();

  filters: any;

  constructor(props: any) {
    super(props);
    const date = moment();
    this.filters = new Filters({
      keys: ['month', 'year'],
      defaultValues: {
        month: moment().format('M'),
        year: moment().format('YYYY'),
      },
    });
    this.filters.setQueryParams();
    const { month, year } = this.filters.all;
    if (month) {
      date.set({ month: parseInt(month as any) - 1 });
    }
    if (year) {
      date.set({ year: parseInt(year as any) });
    }

    this.state = { ...initState, ...(props.location.state || {}), date };
    this.props.location.state = undefined;
  }

  async componentDidMount() {
    this.getData();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  handleOnlyMyAbsence = (event: any) => {
    this.setState(
      {
        onlyMyAbsence: !!event.target.checked,
      },
      this.getData,
    );
  };

  /**
   * Change date in the filters
   */
  handleChange = (name: string) => (value: string) => {
    this.filters.value = { [name]: name === 'month' ? value + 1 : value };
    this.filters.setToQuery();
    const { date } = this.state;
    date.set({ [name]: value });

    this.setState(
      {
        date,
      },
      this.getData,
    );
  };

  handleCalendarChange = (date?: moment.Moment) => {
    if (!date) {
      return;
    }
    const month = date.month();
    const year = date.year();
    this.filters.value = { month: month + 1, year };
    this.filters.setToQuery();

    this.setState(
      {
        date,
      },
      this.getData,
    );
  };

  fetchCalendarData = async () => {
    const { date, onlyMyAbsence, filters } = this.state;
    const query: any = {}; //this.state.pagination;
    const { groupId } = this.props.match.params;
    const defaultData = { list: [], pagination: {} };

    if (groupId) {
      query.groupId = groupId;
    }
    let hasFilters = false;
    Object.entries(filters).forEach(([key, value]: any) => {
      if (value && value.length) {
        hasFilters = true;
        query[key] = JSON.stringify(value);
      }
    });

    if (onlyMyAbsence && !hasFilters) {
      query.users = `["${this.props.user._id}"]`;
    }
    const momentStartWeek = date.clone().startOf('month').startOf('week');

    const holidaysParams = {
      ...query,
      from: momentStartWeek.format('YYYY-MM-DD'),
      to: momentStartWeek.add(5, 'weeks').endOf('week').format('YYYY-MM-DD'),
    };


    const requestsParams = {
      ...holidaysParams,
      limit: 1000,
    };
    // console.log('Calendar requestsParams = ', requestsParams);
    const { data: holidays = defaultData } = await holidaysAPI.get({
      query: requestsParams,
    });
    const { data: requests = defaultData } = await requestsAPI.getAll({
      query: requestsParams,
    });

    const mappedHolidays = holidays.list.map(calendarHelpers.mapHolidays);
    const mappedRequests = requests.list.map(calendarHelpers.mapRequests);

    return {
      hasFilters,
      list: [...mappedHolidays, ...mappedRequests],
      pagination: [holidays.pagination, requests.pagination],
    };
  };

  getData = async () => {
    try {
      this.setState({ loading: true });
      const { date } = this.state;
      const initialGrid = calendarHelpers.buildGrid(date);
      const calendarData = await this.fetchCalendarData();

      const restDays = calendarHelpers.getRestDays(calendarData.list);

      const normalizedCalendarData = calendarData.list
        .map(calendarHelpers.mapCalendarData(date))
        .sort(calendarHelpers.sortCalendarData);
      const finalGrid = initialGrid(normalizedCalendarData);
      const { grouped, data } = finalGrid;

      const { hasFilters } = calendarData;
      const { onlyMyAbsence, filters } = this.state;
      let { filtersUserPreSelect } = this.state;

      if (filters.users?.length === 0) {
        filtersUserPreSelect = undefined;
      }

      this.setState(
        {
          restDays,
          listItems: normalizedCalendarData,
          grouped,
          data,
          onlyMyAbsence: onlyMyAbsence && hasFilters ? false : onlyMyAbsence,
          boxWidth: 0,
          loading: false,
          filtersUserPreSelect,
        },
        () => {
          this.getWidth(true);
        },
      );
      return true;
    } catch (e) {
      this.setState({ loading: false });
      return false;
    }
  };

  getWidth = (withState = false) => {
    this.forceUpdate();
    if (!this.boxRef.current) {
      return 0;
    }
    // default value
    let { width } = this.boxRef.current.getBoundingClientRect();
    width += 2;

    let el = this.boxRef.current;
    do {
      if (!el || el.tagName === 'TABLE' || el.tagName === 'BODY') {
        break;
      } else if (el.tagName === 'TBODY') {
        width = el.getBoundingClientRect().width / 7;
        break;
      } else {
        el = el.parentNode;
      }
    } while (true);

    if (withState) {
      this.setState({ boxWidth: width });
    }
    return width;
  };

  resize = () => {
    if (CalendarComponent.resizeTimer) {
      clearTimeout(CalendarComponent.resizeTimer);
    }
    CalendarComponent.resizeTimer = setTimeout(() => {
      this.getWidth(true);
    }, 50);
  };

  sendRequest = (props: any = {}) => {
    setProps('sendRequest', { callback: this.getData, ...props });
    open('sendRequest');
  };

  renderDate = (date: Moment) => {
    const { grouped, data, boxWidth, restDays }: any = this.state;

    const key = date.format('MM-DD');
    // Don't change format below
    const fullDate = date.format('YYYY-MM-DD');
    const day = date.date();
    const dayToShow = date.format('DD');
    const addRef: any = {};
    const children: any = [];

    if (day === 1) {
      addRef.ref = this.boxRef;
    }

    const isRestDay = restDays.includes(fullDate);

    if (!data || !data[key] || data[key].length === 0) {
      return (
        <CellTmp day={ dayToShow } isRestDay={ isRestDay } date={ date }>
          { day === 1 ? <div className='new-rend' { ...addRef } /> : null }
        </CellTmp>
      );
    }

    for (let i = 0; i < data[key].length; i++) {
      const item = data[key][i];
      const el = item ? grouped[item.id] : null;
      const colItemsLength = item && item.dataLength ? item.dataLength : 1;

      if (el) {
        children.push(
          this.renderCalendarItemBy(el.type)({
            style: {
              top: `${25 * i}px`,
              width: `${colItemsLength * boxWidth}px`,
            },
            key: `${item.key}${i}`,
            data: el,
            date,
          }),
        );
      } else {
        children.push(
          this.renderCalendarItemBy()({
            key: `empty-${day}${i}`,
            style: { top: `${25 * i}px` },
          }),
        );
      }
    }
    return (
      <CellTmp day={ dayToShow } isRestDay={ isRestDay } date={ date }>
        <div
          style={ { minHeight: `${(data[key].length || 1) * 25}px` } }
          { ...addRef }
        >
          { children }
        </div>
      </CellTmp>
    );
  };

  renderCalendarItemBy = (
    type?: number,
  ): ((data: any) => React.ReactElement) => {
    switch (type) {
      case TYPES.holiday:
        return this.renderHolidayCalendarItem;
      case TYPES.request:
        return this.renderRequestCalendarItem;
      default:
        return this.renderEmptyCalendarItem;
    }
  };

  renderHolidayCalendarItem = (data: any) => {
    const { style, key, date, data: itemData } = data;
    const {
      user: { company = {} },
    } = this.props;
    const from = itemData.momentFrom.format('DD/MM/YYYY');
    const to = itemData.momentTo.format('DD/MM/YYYY');

    const className = `calendar-item-inner ${TYPES._names_[itemData.type]}`;
    const color = company.eventsColor || configsApp.defaultEventsColor;
    const backgroundColor = hexAToRGBA(color, 0.2);

    const content = (
      <CalendarEventCard
        header={
          <div
            className='popover-header'
            style={ {
              backgroundColor,
            } }
          >
            { itemData.name }
          </div>
        }
        content={
          <div className={ 'popover-content' }>
            { itemData.description && (
              <p className={ 'ph-10' }>
                { itemData.description
                  ? itemData.description.length > 200
                    ? `${itemData.description.slice(0, 200)}...`
                    : itemData.description
                  : null }
              </p>
            ) }
            <p className={ 'ph-10' }>
              { date.period !== 0 ? `${from} - ${to}` : from }
            </p>
          </div>
        }
      />
    );

    return (
      <div
        className='calendar-item'
        style={ style }
        key={ key }
        title={ `${from}${from !== to ? ` - ${to}` : ''}` }
      >
        <Popover content={ content } overlayClassName={ 'calendar-popover' }>
          <div
            className={ className }
            style={ {
              backgroundColor,
              color,
              borderTop: `2px solid ${color}`,
            } }
          >
            <NavLink
              to={ userRoutes.singleEvent.path }
              style={ { color } }
              target='_blank'
              onClick={ (e: any) => {
                e.stopPropagation();
              } }
              params={ { id: itemData._id } }
            >
              <div
                style={ {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                } }
              >
                { itemData.name }
              </div>
            </NavLink>
          </div>
        </Popover>
      </div>
    );
  };

  renderRequestCalendarItem = (data: any) => {
    const {
      style,
      key,
      date,
      data: { user, ...itemData },
    } = data;
    const { companyAccess = {}, _id } = this.props.user;
    const userLink = user._id === _id || companyAccess.settings;

    const from = itemData.momentFrom.format('DD/MM/YYYY');
    const to = itemData.momentTo.format('DD/MM/YYYY');
    const className = `calendar-item-inner ${TYPES._names_[itemData.type]}`;
    user.positions = Array.isArray(user.positions)
      ? user.positions.map((p: any) => p.name).join(', ')
      : user.positions || '';

    const content = (
      <CalendarEventCard
        header={
          <div
            className='popover-header'
            style={ {
              backgroundColor: hexAToRGBA(itemData.backgroundColor, 0.2),
            } }
          >
            <div className={ 'user-header' }>
              <Avatar user={ user } size={ 32 } />
              <div className={ 'user-full-name-wrapper' }>
                <span>{ user.firstName }</span>
                <br />
                <span>{ user.lastName }</span>
              </div>
            </div>
            <p className='user-position'>{ user.positions }</p>
          </div>
        }
        content={
          <div className={ 'popover-content' }>
            <p
              className={ 'calendar-event-reason ph-10' }
              style={ {
                color: itemData.backgroundColor,
              } }
            >
              { (itemData.weekend || '').name || '-' }
            </p>
            <p className={ 'calendar-event-status ph-10' }>
              { STATUSES._names_[itemData.status] }
            </p>
            <p className={ 'calendar-event-date-range ph-10' }>
              { date.period !== 0 ? `${from} - ${to}` : from }
            </p>
          </div>
        }
      />
    );

    return (
      <div
        className='calendar-item'
        style={ style }
        key={ key }
        title={ `${from}${to ? ` - ${to}` : ''}` }
      >
        <Popover content={ content } overlayClassName={ 'calendar-popover' }>
          <div
            className={ className }
            style={ {
              backgroundColor: hexAToRGBA(itemData.backgroundColor, 0.2),
              color: itemData.backgroundColor,
              borderTop: `2px solid ${itemData.backgroundColor}`,
            } }
          >
            { userLink ? (
              <NavLink
                to={ userRoutes.requestItem.path }
                target='_blank'
                params={ { id: itemData._id } }
                onClick={ (e: any) => {
                  e.stopPropagation();
                } }
              >
                <div
                  style={ {
                    color: itemData.backgroundColor,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  } }
                >
                  { itemData.name }
                </div>
              </NavLink>
            ) : (
              itemData.name
            ) }
          </div>
        </Popover>
      </div>
    );
  };

  renderEmptyCalendarItem = ({
    key,
    style,
  }: {
    key: string;
    style: { [key: string]: string | number };
  }) => <div className='calendar-empty-item' key={ key } style={ style } />;

  toggleFilters = () => {
    const { activeFilters } = this.state;
    this.setState({ activeFilters: !activeFilters });
  };

  handleSubmitFilters = ({ values }: any) => {
    this.setState({ filters: values }, this.getData);
  };

  render() {
    const { date, activeFilters, onlyMyAbsence, loading, filtersUserPreSelect } = this.state;
    const year = moment().year();
    const startYear = year - 2;
    const endYear = year + 5;
    const yearsRange = endYear - startYear;

    const { width } = document.body.getBoundingClientRect();
    const isMobile = width < 767;
    const isSmallMobile = width < 550;

    const SelectYear = (
      <Select
        className={ !isMobile ? 'ml-15' : '' }
        value={ date.year() }
        style={ !isMobile ? { width: 120 } : undefined }
        onChange={ this.handleChange('year') }
      >
        { new Array(yearsRange).fill(null).map((d: null, index: number) => (
          <Option value={ startYear + index } key={ index }>
            { startYear + index }
          </Option>
        )) }
      </Select>
    );
    const SelectMonth = (
      <Select
        className={ !isMobile ? 'ml-15' : '' }
        value={ date.month() }
        style={ !isMobile ? { width: 120 } : undefined }
        onChange={ this.handleChange('month') }
      >
        { months.map((month: string, index: number) => (
          <Option key={ month } value={ index }>
            { month }
          </Option>
        )) }
      </Select>
    );

    const OnlyMy = (
      <Checkbox
        checked={ onlyMyAbsence }
        style={ { alignSelf: 'center' } }
        onChange={ this.handleOnlyMyAbsence }
      >
        Only My Absence
      </Checkbox>
    );

    return (
      <>
        <Row type='flex' justify='space-between'>
          <h2 className='page-header'>Home</h2>
          <div className='flex'>
            { !isSmallMobile ? OnlyMy : null }
            { !isMobile ? (
              <>
                { SelectYear }
                { SelectMonth }
              </>
            ) : null }
            <FiltersBtn
              className='ml-15'
              onClick={ this.toggleFilters }
              active={ activeFilters }
            />
            <div className='ml-15'>
              <RequestAbsence
                mainTitle={ `${!isSmallMobile ? 'Request ' : ''}New Absence` }
                onSend={ this.sendRequest.bind(null, {}) }
                onSendRemote={ this.sendRequest.bind(null, {
                  employeeRequest: true,
                }) }
              />
            </div>
          </div>
        </Row>
        { isSmallMobile ? (
          <Row type='flex' justify='end' className='mt-20'>
            { OnlyMy }
          </Row>
        ) : null }
        <Row className='mb-10'>
          <FiltersComponent
            visible={ activeFilters }
            onSubmit={ this.handleSubmitFilters }
          >
            <FiltersLayout defaultSelected={ filtersUserPreSelect }>
              { isMobile ? (
                <>
                  <Col md={ { span: 6 } }>{ SelectYear }</Col>
                  <Col md={ { span: 6 } }>{ SelectMonth }</Col>
                </>
              ) : null }
            </FiltersLayout>
          </FiltersComponent>
        </Row>
        <Spin tip='Loading...' spinning={ loading }>
          <Card>
            <div className='calendar-edit'>
              <Calendar
                className='disable-header'
                value={ date }
                onChange={ this.handleCalendarChange }
                dateFullCellRender={ this.renderDate }
              />
            </div>
          </Card>
        </Spin>
      </>
    );
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(
  CalendarComponent,
);
