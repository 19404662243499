import React from "react";
import moment from 'moment'
import {Row} from 'antd'
import {generatePath} from 'react-router'
import routesInventories from "../../../routes/inventories";
import userRoutes from "../../../routes/user";
import apiInventories from "../../../API/inventories";
import errors from "../../../API/error";
import NavLink from '../../NavLink';

const getDate = (date: string) => {
  const mDate = moment(date);
  const current = moment();
  const diff = current.diff(mDate, 'seconds');
  if (current.year() !== mDate.year()) {
    return mDate.format('D MMM YYYY')

  } else if (current.date() !== mDate.date()) {
    mDate.format('D MMM')

  } else if (diff <= 59) {
    return `${diff} Sec`

  } else if (diff <= 119) {
    return `1 Minute`

  } else if (diff <= 3599) {
    return `${Math.floor(diff / 60)} Minutes`
  }

  const hours = Math.floor(diff / 3600);
  return `${hours} Hour${hours > 1 ? 's' : ''}`
};

export default ({user, brand, model, serialNumber, id, updateData, ...rest}: any) => {
  const fullName = `${user.firstName || ''} ${user.lastName || ''} `.trim();
  const isReturning = user.status === 'returning';
  const title = isReturning ? 'Device returning' : 'Device Acceptance';
  const changedId = `0000${id || 0}`.slice(-4);
  const description = `${brand || ''} ${model || ''} ${serialNumber || ''} ${id ? `(ID ${rest?.group?.prefix || ''}${changedId})` : ''}`
    .trim()
    .split(' ')
    .filter(str => !!str)
    .join(' ');

  const approveAcceptance = (req: any) => async () => {
    try {
      const body: any = {};
      if (isReturning) {
        body.userId = user._id
      }

      await req(body, {params: {id: rest._id}});
      updateData && updateData();
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  return <div className="mt-10 no-item">
    <NavLink to={
      isReturning
        ? generatePath(routesInventories.view.path, {id: rest._id})
        : generatePath(userRoutes.userInventories.path, {id: user._id})
    }>
      <Row type="flex" justify="space-between" className="no-item-label">
        <div className="no-title">{title}</div>
        <div className="no-title">{getDate(user.updatedAt)}</div>
      </Row>
      <div className="no-item-description">
        {isReturning ? `${fullName} returns ` : ''}
        {description || 'the test device'}
      </div>
    </NavLink>

    <Row type="flex" justify="space-around">
      <button className="cursor btn-red actions-btn"
              onClick={approveAcceptance(
                isReturning
                  ? apiInventories.postReturningReject
                  : apiInventories.postUserReject
              )}>Reject</button>
      <button className="cursor btn-green actions-btn"
              onClick={approveAcceptance(
                isReturning
                  ? apiInventories.postReturningApprove
                  : apiInventories.postUserApprove
              )}>Approve</button>
    </Row>
  </div>
}