import notFound from './notFound';
import {isUserLogged} from '../services/user'
import Auth from '../layouts/Auth';
import User from '../layouts/User';


export default {
  guest: {
    path: '/',
    exact: false,
    component: Auth,
    isVisible: (storeUser: any) => !isUserLogged(storeUser)
  },
  user: {
    path: '/',
    exact: false,
    component: User,
    isVisible: isUserLogged
  },
  notFound
}