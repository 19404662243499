import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import Table from '../Table';
import { IRequestData, WeekendData } from '../../interfaces';
import { getGeneralStatus } from '../../utils/requestStatus';
import './styles.scss';
// eslint-disable-next-line import/no-cycle
import userRoutes from '../../routes/user';
// eslint-disable-next-line import/no-cycle
import routesStatistics from '../../routes/statistics';

interface IDetailsTableItem {
  header: string;
  data: {
    type: string;
    txt: string | React.ReactNode;
  };
}

const columns = [
  {
    title: '',
    dataIndex: 'header',
    key: 'header',
    width: '30%',
    render: (header: string) => (
      <span className='details-header'>{ header.toUpperCase() }</span>
    ),
  },
  {
    title: '',
    dataIndex: 'data',
    key: 'data',
    render: (data: IDetailsTableItem['data']) => {
      let statusClass = '';
      if (data.type === 'status') {
        if (data.txt === 'Approved') statusClass = 'approved';
        if (data.txt === 'Rejected') statusClass = 'rejected';
        if (data.txt === 'Pending') statusClass = 'pending';
      }
      return <span className={ `details-data ${statusClass}` }>{ data.txt }</span>;
    },
  },
];

const defaultTableData = [
  { key: 'name', header: 'Employee', data: { type: 'name', txt: '' } },
  { key: 'status', header: 'Status', data: { type: 'status', txt: '' } },
  { key: 'event', header: 'Absence Type', data: { type: 'event', txt: '' } },
  {
    key: 'availableDays',
    header: 'Available Days',
    data: { type: 'availableDays', txt: '' },
  },
  {
    key: 'working',
    header: 'Business Days/Calendar days',
    data: { type: 'days', txt: '' },
  },
  { key: 'period', header: 'date range', data: { type: 'period', txt: '' } },
  { key: 'reason', header: 'reason', data: { type: 'reason', txt: '' } },
  { key: 'comment', header: 'comment', data: { type: 'comment', txt: '' } },
];

const mapDetails = (data: IRequestData) => {
  const { weekend, workingDays, totalDays, from, to, reason, comment, user } =
    data;
  const userId = user._id;

  const query: any = {
    activeFilters: true,
    onlyMyAbsence: false,
    filters: {
      users: [userId],
    },
    filtersUserPreSelect: [
      { _id: userId, firstName: user.firstName, lastName: user.lastName },
    ],
  };

  let availableDaysTxt = data.weekend?.unlimitedDays
    ? 'Unlimited'
    : data.hasOwnProperty('availableDays')
      ? data.availableDays
      : '';
  if (
    data.weekendData &&
    data.weekendData.length > 0 &&
    availableDaysTxt !== 'Unlimited'
  ) {
    const wData: WeekendData = data.weekendData[0];
    availableDaysTxt = +wData.pendingDays + +wData.days;
  }
  let linkToEntitlement = '#';
  if (userId) {
    linkToEntitlement = generatePath(routesStatistics.absences.path, {
      id: user._id,
    });
  }

  const rows =  defaultTableData.map((d: IDetailsTableItem) => {
    switch (d.data.type) {
      case 'name': {
        d.data.txt = (
          <Link
            to={ {
              pathname: userRoutes.home.path,
              search: '',
              state: { ...query },
            } }
          >
            { user.firstName || '' } { user.lastName || '' }
          </Link>
        );
        // d.data.txt = `${user.firstName || ''} ${user.lastName || ''}`.trim();
        return d;
      }
      case 'status': {
        d.data.txt = getGeneralStatus(data);
        return d;
      }
      case 'event': {
        d.data.txt = weekend.name;
        return d;
      }
      case 'days': {
        d.data.txt = `${workingDays}/${totalDays}`;
        return d;
      }
      case 'period': {
        const format = 'DD/MM/YYYY';
        d.data.txt = `${moment(from).format(format)} - ${moment(to).format(
          format,
        )}`;
        return d;
      }
      case 'reason': {
        d.data.txt = reason;
        return d;
      }
      case 'comment': {
        d.data.txt = comment;
        return d;
      }
      case 'availableDays': {
        // d.data.txt = availableDaysTxt  as string;
        d.data.txt = (
          <Link to={ linkToEntitlement }>{ availableDaysTxt as string }</Link>
        );
        return d;
      }
      default:
        return d;
    }
  });

  if (data.weekend.isFilesConfirmation) {
    const isConfirmed = data.isFilesConfirmed ? 'Yes' : 'No';
    const addRow = { key: 'isFilesConfirmed', header: 'Files Confirmed', data: { type: 'isFilesConfirmed', txt: isConfirmed } };
    // eslint-disable-next-line no-restricted-syntax
    rows.push(addRow);
  }
  return rows;
};

interface IProps {
  data: IRequestData;
  loading: boolean;
}

export const defaultData: IRequestData = {
  _id: '',
  workingDays: 0,
  totalDays: 0,
  from: new Date(),
  to: new Date(),
  reason: '',
  comment: '',
  createdAt: new Date(),
  weekend: { _id: '', color: '#fff', name: 'none' },
  history: [],
  confirmations: [],
  pendingUsers: [],
  user: {
    _id: '',
    firstName: 'Noname',
    lastName: 'Noname',
  },
  isFilesConfirmed: false,
  filesConfirmedList: [],
};

const requestDetails  = (props: IProps): JSX.Element => {
  const { data = defaultData, loading = false } = props;
  return (<Table
    columns={ columns }
    loading={ loading }
    showHeader={ false }
    dataSource={ mapDetails(data) }
    size="small"
    pagination={ { hideOnSinglePage: true } }
    rowClassName={ (record: IDetailsTableItem): string | boolean => record.data.type === 'comment' && 'comment' } />);
};

export default requestDetails;
