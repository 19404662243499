import React from "react";
import {Button, Row} from "antd";
import apiInventories from "../../../../API/inventories";
import modalInventoryAssignUser from '../../../Modals/InventoryAssignUser'
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'
import columns from './columns';
import TablePagination from "../../../../services/tablePagination";
import {IActionsList} from "../../../../components/DotMenu";
import {ISubmitProps} from "../../../../noui/Form";
import errors from "../../../../API/error";

interface Props {
  id: string
  testDevice: boolean

  onUpdate(): void
}


interface State {
  loading: boolean
  data: any[]
}

export default class Details extends React.Component<Props, State> {
  pagination: TablePagination;
  id: string = '';
  state = {
    loading: true,
    data: []
  };

  constructor(props: Props) {
    super(props);

    this.pagination = new TablePagination({
      pageSize: 10,
      callback: this.getData,
      includeOnChange: true,
      updateOnChange: true
    });
  }


  componentDidMount() {
    this.getData();
    modalInventoryAssignUser.init({
      onSubmit: this.handleSubmitUser
    })
  }

  componentWillUnmount(): void {
    modalInventoryAssignUser.remove()
  }

  getData = async () => {
    try {
      const {id} = this.props;
      const {data} = await apiInventories.getAssignedUsers({params: {id}, query: this.pagination.requestParams()});
      const {list, pagination} = data;
      this.pagination.value = pagination;
      this.setState({loading: false, data: list})
    } catch (e) {

    }
  };

  handleSubmitUser = async (props: ISubmitProps | any) => {
    const {values, form, onCancel} = props;
    try {
      const {id, onUpdate} = this.props;
      await apiInventories.postAssignUser(values, {params: {id}})

      this.getData();
      onUpdate && onUpdate();
      onCancel()
    } catch (e) {
      errors.handle(e, form, {priority: 'all'});
    }
  };

  handleUnassign = async ({_id}: any) => {
    try {
      this.setState({loading: true});
      const {onUpdate} = this.props;
      await apiInventories.postUnassignUser({}, {params: {id: _id}});
      onUpdate && onUpdate();
      this.getData();
    } catch (e) {

    }
  };

  handleAssign = () => {
    this.id = '';
    const {testDevice} = this.props;
    modalInventoryAssignUser.open({
      testDevice
    })
  };

  actions: IActionsList = [
    {
      name: 'Unassign',
      disablePropagation: true,
      action: this.handleUnassign
    }
  ];

  handleChangeResponsible = async (inventoryId: string, responsible: boolean) => {
    try {
      await apiInventories.putAssignUser({
        isResponsible: responsible
      }, {
        params: {
          id: inventoryId
        }
      });
      this.getData()
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed'});
    }
  };

  render() {
    const {loading, data} = this.state;
    const {testDevice} = this.props;
    return <>
      <Row type="flex" justify="space-between" className="mt-30 mb-20">
        <h2 className="page-header">Assigned Employees</h2>
        <Button className="ant-btn-primary ml-15"
                onClick={this.handleAssign}>Assign Employee</Button>
      </Row>
      <Table dataSource={data}
             loading={loading}
             rowKey="_id"
             columns={columns(this.actions, testDevice ? this.handleChangeResponsible : undefined)}
             pagination={this.pagination.tableConfig}
             onChange={this.pagination.tableChange.bind(this.pagination)}
      />
      {
        this.pagination.paginationVisible ? <Pagination {...this.pagination.config} /> : null
      }
    </>
  }
}