import React from "react";
import firstImgItem from "../../../../assets/imgs/welcomeBook/IMG_6248 - Copy 1.jpg";
import secondImgItem from "../../../../assets/imgs/welcomeBook/IMG_6374 1.jpg";
import thirdImgItem from "../../../../assets/imgs/welcomeBook/IMG_6214 - Copy 2 (1).jpg";
import "./styles.scss";
interface IProps {
  text: string;
  img: string;
  tittle: string;
}
function StakeholdersItem(props: IProps) {
  const { text, img, tittle } = props;
  return (
    <div className="stakeholders__item">
      <div className="stakeholders_item__img_container mb-15">
        <img src={img} alt={tittle} />
      </div>
      <div className="stakeholders__text-content">
        <div className="stakeholders_item__tittle ">{tittle}</div>
        <div className="stakeholders_item__sub-tittle">{text}</div>
      </div>
    </div>
  );
}

function Stakeholders() {
  const StakeholdersItemData = [
    { tittle: "Andrew Balushka", text: "CEO at devabit", img: firstImgItem },
    { tittle: "Oleg Zakhariya", text: "CTO at devabit", img: secondImgItem },
    { tittle: "Bogdan Tarlupa", text: "CBDO at devabit", img: thirdImgItem },
  ];
  return (
    <div className="stakeholders__container">
      <div className="stakeholders__content">
        <div className="stakeholders__tittle tittle">
          Meet our team stakeholders
        </div>
        <div className="stakeholders__item_container">
          {StakeholdersItemData.map(({ tittle, text, img }, index) => (
            <StakeholdersItem
              text={text}
              img={img}
              tittle={tittle}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stakeholders;
