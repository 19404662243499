import * as React from 'react';
import {
  Button,
  Row
} from 'antd';
import {ColorChangeHandler, ColorResult, SketchPicker} from 'react-color';
import './styles.scss';

interface IProps {
  color?: string
  onChange?(color: string): void
}

interface IState {
  color?: ColorResult
  colorToShow: string
  visible: boolean
}

/*
* Event "path" patch for Firefox and Safari
* It's needed to be able to close color picker on outside click
* */
if (!("path" in Event.prototype)) {
  Object.defineProperty(Event.prototype, "path", {
    get: function() {
      let path = [];
      let currentElem = this.target;
      while (currentElem) {
        path.push(currentElem);
        currentElem = currentElem.parentElement;
      }
      if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
        path.push(document);
      if (path.indexOf(window) === -1)
        path.push(window);
      return path;
    }
  });
}

export default class ColorPicker extends React.Component<IProps, IState> {
  public customIndex = `color-picker-wrapper-${(Math.random()*10e10).toFixed(0)}`;
  public customButtonId = `color-picker-button-${(Math.random()*10e10).toFixed(0)}`;

  constructor(props: IProps) {
    super(props);
    const {color} = props;
    this.state = {
      color: undefined,
      colorToShow: color || '#ffffff',
      visible: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e: any) => {
    if (e.path && e.path.length) {
      if (!e.path.some((i: any) => i.id === this.customIndex || i.id === this.customButtonId)) {
        this.setState({ visible: false });
      }
    }
  };

  handleChangeComplete: ColorChangeHandler = (color: ColorResult) => {
    const {hex, rgb: {a}} = color as ColorResult;
    let opacity = (Math.round((typeof a === "number" ? a : 1) * 255)).toString(16);

    if (opacity.length === 1) {
      opacity = `0${opacity}`
    }
    const colorToShow = `${hex}${opacity}`;
    this.setState({color, colorToShow});

    const {onChange} = this.props;
    if (onChange) {
      onChange(colorToShow)
    }
  };

  trigger = (visible: boolean = true): void => {
    this.setState({visible})
  };

  render() {
    const {visible, color, colorToShow} = this.state;

    return <div className="color-picker">
      <div className="color-picker-area-wrap" id={this.customIndex}>
        {
          visible ? <SketchPicker onChange={this.handleChangeComplete} color={color ? color.rgb : colorToShow} /> : null
        }
      </div>
      <Row type="flex" justify="start">
        <Button id={this.customButtonId}
                className="mr-20"
                onClick={this.trigger.bind(null, true)}>Change</Button>
        <span className="color-block" style={{backgroundColor: colorToShow}} />
      </Row>
    </div>
  }
}