import React from "react";

interface Props {
  active: boolean
  children: any
  onClick(): void
}

export default ({
  onClick,
  active,
  children
                }: Props) =>
  <div className={`flex fd-column jc-space-between m-burger${active ? ' is-active' : ''}`}
       style={
         active ? {
           height: window.screen.availHeight - 60,
           paddingBottom: `${window.screen.availHeight - document.documentElement.clientHeight}px`
         } : {
           height: 0
         }}>{children}</div>