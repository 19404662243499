import * as React from "react";
import DotMenu, {IActionsList} from "../../../components/DotMenu";

export default (actions: IActionsList) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Fields',
    dataIndex: 'fields',
    key: 'fields',
    render(fields: {key: string, name: string}[]) {
      const columns = fields.map((item) => item.name);
      return columns.join(' / ')
    }
  },
  {
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: any) =>
      <DotMenu actions={actions} record={record} />
  },
]