import * as React from 'react';
import moment from 'moment';
import {Row} from 'antd';
import Avatar from "../../components/Avatar";
import {getGeneralStatus} from "../../utils/requestStatus";
import {IApprovalItem} from "../../interfaces/containers";

import './styles.scss';
import AcceptIcon from '../../assets/icons/req_approve'
import RejectIcon from '../../assets/icons/req_cancel'
import ViewIcon from '../../assets/icons/req_view'
import ArrowIcon from '../../assets/icons/req_arrow'
import ExpandedRow from './ExpandedRow';

const dateFormat = 'DD/MM/YYYY';

interface IActions {
  onApprove(id: string, form?: any): void

  onReject(id: string, form?: any): void

  onView(id: string, form?: any): void
}

interface IActionBtn {
  children: any

  onClick?(): void

  type: 'view' | 'accept' | 'reject'
  className?: string
}

const getActionId = (record: any) => {
  const {_id, cancellationHistory} = record;
  let id = _id;
  if (cancellationHistory && cancellationHistory.length && cancellationHistory[0] && cancellationHistory[0].isActive) {
    id = cancellationHistory[0]._id || '';
  }
  return id;
}

const ActionBtn = ({onClick, children, className = ''}: IActionBtn) => {
  return <button
    type="button"
    onClick={onClick}
    className={`approval-btn ${className}`.trim()}>
    {children}
  </button>
};

const ActionsSection = (actions: IActions, baseId: string, id: string, form?: any) => {
  return <Row type="flex" justify="end" className="table-actions" onClick={(e: any) => {
    e.stopPropagation();
  }}>
    <ActionBtn onClick={actions.onView.bind(null, baseId, form)}
               type="view">
      <ViewIcon />
    </ActionBtn>
    <ActionBtn onClick={actions.onReject.bind(null, id, form)}
               className="ml-20" type="reject">
      <RejectIcon />
    </ActionBtn>
    <ActionBtn onClick={actions.onApprove.bind(null, id, form)}
               className="ml-20"
               type="accept">
      <AcceptIcon />
    </ActionBtn>
  </Row>
};

export default (actions: IActions) => [
  {
    title: '',
    key: 'avatar',
    width: '24px',
    render: (record: IApprovalItem) => {
      const {user} = record;
      if (user) {
        return <Avatar user={user} style={{padding: 0}} size={25} />
      }
    }
  },
  {
    title: 'Name',
    sorter: true,
    dataIndex: 'user.firstName',
    key: 'name',
    render: (txt: string, record: IApprovalItem) =>
      `${record.user.firstName} ${record.user.lastName}`.trim()
  },
  {
    title: 'Type',
    dataIndex: 'weekend.name',
    key: 'type',
    sorter: true,
    render: (txt: string, record: IApprovalItem) => {
      const {weekend: {color, name}} = record;
      return <span className="request-type" style={{color}}>
            {name}
          </span>;
    }
  },
  {
    title: 'Date Range',
    dataIndex: 'from',
    key: 'period',
    sorter: true,
    render: (txt: string, record: IApprovalItem) =>
      `${moment(record.from).format(dateFormat)}${record.from !== record.to
        ? ` - ${moment(record.to).format(dateFormat)}`
        : ''}`
  },
  {
    title: 'Status',
    key: 'status',
    render: (record: IApprovalItem) => getGeneralStatus(record)
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason'
  },
  {
    title: '',
    dataIndex: 'open',
    key: 'open',
    render: () => <Row type="flex" justify="end">
      <ActionBtn type="view" className="table-actions-arrow">
        <ArrowIcon />
      </ActionBtn>
    </Row>
  },
  {
    title: '',
    key: 'actions',
    className: 'prevent-click approve-actions',
    render: (record: IApprovalItem) => {
      const id = getActionId(record);
      return ActionsSection(actions, record._id, id)
    }
  }
];

export const expandedRowRender = () => (record: IApprovalItem) => {
  return <ExpandedRow requestInfo={record} />
};