import { combineReducers } from "redux";
import user from "./user";
import approval from "./approval";
import bookingTable from "./bookingTable";

export default combineReducers({
  user,
  approval,
  bookingTable,
});
