import React, { useLayoutEffect, useState } from "react";
import "./styles.scss";
import googleQR from "../../assets/icons/svg/googleQR.svg";
import appQR from "../../assets/icons/svg/appleQR.svg";
import IconStep2 from "../../assets/icons/step2";
import IconStep3 from "../../assets/icons/step3";
import IconStep4 from "../../assets/icons/step4";
import IconStep5 from "../../assets/icons/step5";
import InsuranceCard from "./item/InsuranceCard";
import { Button, Col, Row } from "antd";
import Scrollable from "../Scrollable";
import ProgramTub from "./item/tubs/ProgramTub";
import DocumentsTub from "./item/tubs/DocumentsTub";
import { ANDROID_OS, DOCUMENT_TUB, IOS_OS, PROGRAM_TUB } from "./item/const";

function Insurance() {
  const [selectedOS, setSelectedOS] = useState(IOS_OS);
  const [currentTub, setCurrentTub] = useState(PROGRAM_TUB);

  const insuranceCardData = [
    {
      text: "Sign in via phone number and date of birth",
      icon: <IconStep2 />,
    },
    { text: "Check your insurance policy", icon: <IconStep3 /> },
    {
      text: "Reread the insurance and exclusion agreement",
      icon: <IconStep4 />,
    },
    {
      text: "Go to the insurance case section and view information on refunds",
      icon: <IconStep5 />,
    },
  ];
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h2 className="page-header mb-10">Insurance</h2>
      <Scrollable _class="insurance__card-container">
        <div className=" flex">
          <div className="absence-card insurance_item__container">
            <div className="insurance_item__tittle"> Step 1</div>
            <div className="insurance_item__text">
              Download the app <b>"My ARX"</b>
            </div>
            <div className="insurance_item__qr_container">
              <img
                src={selectedOS === IOS_OS ? appQR : googleQR}
                alt="qr code"
              />
            </div>
            <div className="insurance_item__switcher">
              <span
                onClick={() => {
                  setSelectedOS(IOS_OS);
                }}
                className={selectedOS === IOS_OS ? "selectedOS" : ""}
              >
                iOS
              </span>
              /
              <span
                className={selectedOS === ANDROID_OS ? "selectedOS" : ""}
                onClick={() => {
                  setSelectedOS(ANDROID_OS);
                }}
              >
                Android
              </span>
            </div>
          </div>
          {insuranceCardData.map(({ text, icon }, index) => (
            <InsuranceCard
              text={text}
              icon={icon}
              step={index + 2}
              key={index}
            />
          ))}
        </div>
      </Scrollable>
      <div className="insurance_details__tittle_container">
        <h2 className="page-header mb-10">Insurance Details</h2>
        <div className="insurance_details__button-container">
          <Button
            onClick={() => setCurrentTub(PROGRAM_TUB)}
            className="insurance_details__button mr-15"
            type={currentTub === PROGRAM_TUB ? "default" : "primary"}
          >
            Insurance program
          </Button>
          <Button
            onClick={() => setCurrentTub(DOCUMENT_TUB)}
            className="insurance_details__button"
            type={currentTub === DOCUMENT_TUB ? "default" : "primary"}
          >
            Documents
          </Button>
        </div>
      </div>
      <div className="insurance_details__container">
        <div className="insurance_details__text_container">
          {currentTub === PROGRAM_TUB && <ProgramTub />}
          {currentTub === DOCUMENT_TUB && <DocumentsTub />}
        </div>
      </div>
      <div className="insurance_dental__container">
        <div className="insurance_dental__tittle">
          In addition to insurance, we provide dental help.
        </div>
        <Row className="insurance_dental__item" gutter={16}>
          <Col xs={8} xl={4}>
            <b>Dentist</b>:
          </Col>
          <Col xs={14} xl={6}>
            Orest Kraikivskyi
          </Col>
        </Row>
        <Row className="insurance_dental__item" gutter={16}>
          <Col xs={8} xl={4}>
            <b>LOCATION:</b>
          </Col>
          <Col xs={14} xl={6}>
            Lviv, 6A Symonenka St. (Dental clinic, office №1)
          </Col>
        </Row>
        <Row className="insurance_dental__item" gutter={16}>
          <Col xs={8} xl={4}>
            <b>WORKING SCHEDULE:</b>
          </Col>
          <Col xs={14} xl={6}>
            08:00 - 13:00 (odd days) and 13:00 - 20:00 (even days)
          </Col>
        </Row>
        <Row className="insurance_dental__item" gutter={16}>
          <Col xs={8} xl={4}>
            <b>COVERED:</b>
          </Col>
          <Col xs={14} xl={6}>
            2 hygiene procedures / 3 dental fillings per year
          </Col>
        </Row>
        <div className="mt-20">
          You can book an appointment with our dentist in the “Dentistry”
          section (Information/Dentistry).
        </div>
      </div>
    </>
  );
}

export default Insurance;
