import Inventories from "../containers/Inventories/List";
import InventoriesGroups from "../containers/Inventories/Groups";
import GroupsCreateEdit from "../containers/Inventories/Groups/CreateEdit";
import CreateEdit from "../containers/Inventories/CreateEdit";
import View from "../containers/Inventories/View";

export default {
  inventories: {
    component: Inventories,
    path: '/settings/inventories'
  },
  inventoriesGroups: {
    component: InventoriesGroups,
    path: '/settings/inventories/groups'
  },
  inventoriesGroupsEdit: {
    component: GroupsCreateEdit,
    path: '/settings/inventories/groups/edit/:id'
  },
  inventoriesGroupsCreate: {
    component: GroupsCreateEdit,
    path: '/settings/inventories/groups/create'
  },
  create: {
    component: CreateEdit,
    path: '/settings/inventories/create'
  },
  edit: {
    component: CreateEdit,
    path: '/settings/inventories/edit/:id'
  },
  view: {
    component: View,
    path: '/settings/inventories/item/:id'
  },
}