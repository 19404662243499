import * as React from "react";
import "./styles.scss";
import WideScreen from "src/assets/icons/widescreen";
import NarrowScreen from "src/assets/icons/narrowscreen";
import SectorMap from "./sectorMap";
import TableMap from "./tableMap";
import { ITableSector } from "../../../../interfaces/containers/tableBooking";
import { connect } from "react-redux";
import { IStore } from "../../../../interfaces";
import { MAP_BASE_HEIGHT } from "./const";
import { Stage } from "react-konva";
import Scrollable from "src/components/Scrollable";

class WorkspaceMap extends React.Component<any, any> {
  private container: React.RefObject<HTMLDivElement>;
  private stage: any;
  constructor(props: any) {
    super(props);
    this.container = React.createRef();
    this.stage = React.createRef();
    this.state = {
      stageScale: 1,
      width: 0,
      height: 0,
      isFullScreen: false,
    };
  }

  setSize = () => {
    const container = this.container;
    if (container.current) {
      this.setState({
        width: container.current.clientWidth / 1.05,
        height:
          container.current.clientWidth < MAP_BASE_HEIGHT
            ? container.current.clientWidth * 1.05
            : MAP_BASE_HEIGHT,
      });
    }
  };
  componentDidMount() {
    this.setSize();
    window.addEventListener("resize", this.setSize);
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.setSize);
  }
  handleClickOnFullScreen = () => {
    const { isFullScreen } = this.state;
    if (isFullScreen) {
      this.setSize();
      this.stage.current.position({ x: 0, y: 0 });
      this.setState({
        isFullScreen: false,
        stageScale: 1,
      });
      window.addEventListener("resize", this.setSize);
    } else {
      this.setState({
        isFullScreen: true,
        stageScale: 1.5,
      });
      window.removeEventListener("resize", this.setSize);
    }
  };

  render() {
    const { handleSelectSector } = this.props;
    const { sectorsData: sectors, sector: currentSector } =
      this.props.bookingTable;
    const sector = sectors.filter(
      (sector: ITableSector) => sector._id === currentSector
    )[0];
    const { width, height, isFullScreen } = this.state;
    const isMobile = window.innerWidth < 800;
    const getStageHeight = () => {
      switch (true) {
        case currentSector && !isFullScreen && isMobile:
          return height - 100;
        case currentSector && !isMobile:
          return height - 240;
        case isFullScreen:
          return height * 2;
        default:
          return height;
      }
    };
    return (
      <div
        className="workspace-map"
        style={{ height: isFullScreen ? height * 1.7 + "px" : "" }}
      >
        {Boolean(currentSector) && (
          <div
            className="workspace-map__fullscreen-icon_container"
            onClick={this.handleClickOnFullScreen}
          >
            {isFullScreen ? <WideScreen /> : <NarrowScreen />}
          </div>
        )}
        <Scrollable
          _class={
            isFullScreen ? "map__fullscreen__table-container_wrapper" : ""
          }
          style={{ height: isFullScreen ? height * 1.5 + "px" : "" }}
        >
          <div
            className={currentSector ? "table-container" : "sector-container"}
            style={isFullScreen ? { margin: 0 } : {}}
            ref={this.container}
          >
            <Stage
              width={isFullScreen ? width * 2 : width}
              ref={this.stage}
              height={getStageHeight()}
            >
              {!currentSector && (
                <SectorMap
                  sectorsData={sectors}
                  width={width}
                  height={height}
                  handleSelectSector={handleSelectSector}
                />
              )}

              {Boolean(currentSector) && (
                <TableMap
                  width={isFullScreen ? width * 1.5 : width}
                  isFullScreen={isFullScreen}
                  height={isFullScreen ? height * 1.5 : getStageHeight()}
                  sectorData={sector}
                />
              )}
            </Stage>
          </div>
        </Scrollable>
      </div>
    );
  }
}
export default connect(({ bookingTable }: Partial<IStore>) => ({
  bookingTable,
}))(WorkspaceMap);
