/**
 * @return {string}
 */

export function LightenDarkenColor(col, amt) {
  if (!col) {
    return col;
  }
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  } else if (col[0] === 'r') {
    col = col.replace(/rgb\((.+?)\)/ig, (_, rgb) => {
      const hex = rgb.split(',').map((str) => parseInt(str, 10)
      .toString(16)
      .padStart(2, '0')).join('');
      return `#${hex}`;
    });
  }
  let num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexAToRGBA(h, a = 1) {
  let r = 0, g = 0, b = 0, _a = a;

  if (h.length === 5) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
    // a = "0x" + h[4] + h[4];

  } else if (h.length === 7 || h.length === 9) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
    if (h.length === 9) {
      _a = Math.floor((parseInt('0x' + h[7] + h[8]) / 2.55) * _a) / 100;
    }
  }

  return `rgba(${ +r }, ${ +g }, ${ +b }, ${ _a })`;
}