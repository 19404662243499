import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

interface Props {
  name?: string;
  handleOnChange: (value: string, data: {
    programmingLanguages: string;
    dataBases: string;
    developmentTools: string;
    operatingSystems: string;
    frameworksTool: string;
  }) => void;
  className?: string;
}

export const SkillsPreSet = (props: Props) => {
  const { name, handleOnChange, ...rest } = props;

  const emptyData = {
    name: '',
    data: {
      programmingLanguages: '',
      dataBases: '',
      developmentTools: '',
      operatingSystems: '',
      frameworksTool: ''
    }
  };

  const preSelectData = [
    {
      name: '.NET',
      data: {
        programmingLanguages: 'C#, .NET, Javascript',
        dataBases: 'MS SQL, SQLite, Firebase, Redis',
        developmentTools: 'MS SQL Server, MS Test, Visual Studio, VS Code, ReSharper, TFS/SVN/Git',
        operatingSystems: 'Microsoft Windows XP/ Vista/7/8/8.1/10,',
        frameworksTool: 'ASP.NET MVC, ASP.NET Web API, ADO.NET/Entity Framework, Azure, AngularJS, ReactJS,  Software Architecture Design, UML, Design Patterns, XML, XPath, JSON, JPath'
      }
    },
    {
      name: 'Android',
      data: {
        programmingLanguages: 'Java, Kotlin, C#',
        dataBases: 'SQL, MS SQL, SQLite',
        developmentTools: 'SQL Server, Android Studio, Unity 3D, Visual Studio, GIT',
        operatingSystems: 'Windows, Linux',
        frameworksTool: 'Butterknife, Glide, WPF, Facebook SDK, Android SDK, HTTP, REST, Firebase, Gradle, UML, XML, Retrofit, Algolia, RxJava, Picasso, Material Design'
      }
    },
    {
      name: 'Angular Front-end',
      data: {
        programmingLanguages: 'JavaScript, HTML5, CSS3, PHP',
        dataBases: 'SQL, MySQL',
        developmentTools: 'WebStorm IDE,  Subversion, Git,',
        operatingSystems: 'MS Windows XP /7/8, Linux',
        frameworksTool: 'Bootstrap, Ajax, jQuery, JSON, Angular'
      }
    },
    {
      name: 'Data Science',
      data: {
        programmingLanguages: 'Python',
        dataBases: 'SQL, Postgres',
        developmentTools: '',
        operatingSystems: '',
        frameworksTool: ' Tensorflow, Keras, Pandas, Sklearn, NumPy, Yolo, Django, Flask, Openpyxl, Paramiko, Selenium, BeautifulSoup'
      }
    },
    {
      name: 'Design',
      data: {
        programmingLanguages: 'Figma, Sketch, Principal, Adobe XD, Adobe Illustrator, Adobe Photoshop, Adobe AfterEffects',
        dataBases: '',
        developmentTools: 'Goggle Workspace, Miro, Slack / Notion, Scrum',
        operatingSystems: '',
        frameworksTool: ''
      }
    },
    {
      name: 'iOS Back-end',
      data: {
        programmingLanguages: 'Objective-C, Swift, Java, C#',
        dataBases: '',
        developmentTools: 'Xcode 5-7, AppCode, MS Visual Studio 2012, Unity, Mono Develop, Android Studio, IntelliJ IDEA, git, Subversion',
        operatingSystems: 'Apple Mac OS X, MS Windows XP /7/8, IOS 7/8/9',
        frameworksTool: 'Core Foundation, Core Animation, CoreMotion, CoreLocation, CoreGraphics, ImageIO, AVFoundation, Core Animation, iSGL3D, UIKit, AFNetworking 2.0, ResearchKit, Core Data, ShareKit, SVProgressHUD, MBProgressHUD'
      }
    },
    {
      name: 'Java/Scala',
      data: {
        programmingLanguages: 'Java, C/C++, Objective-c, ROR',
        dataBases: 'SQL, SQLite, MySQL 5x, MS SQL, PostgresSQL, DynamoDB',
        developmentTools: 'Junit 3/4, Maven 2, Ant, Subversion, Perforce, Git, Jira, Eclipse 3.x, Eclipse ADT plugin, SOAP UI',
        operatingSystems: 'MS Windows, Linux',
        frameworksTool: 'Hibernate 3.x, JSP/JSTL, JSF, Spring Framework, Spring-WS, JMS, Google Protocol Buffers, iOS SDK, Android SDK,  RIM Blackberry SDK, Java ME MIDP 2.0, Backbone.js, Jsoup, Mockito, GStreamer, OpenGL, DOM'
      }
    },
    {
      name: 'PHP',
      data: {
        programmingLanguages: 'PHP, JavaScript, HTML5, CSS3',
        dataBases: 'MySQL, PostgreSQL, MongoDB',
        developmentTools: 'PhpStorm, Web-Storm, SQLyog, Git, Subversion',
        operatingSystems: 'Linux (Ubuntu, CentOS, Fedora, Red Hat), Windows 95/98/2000/XP/2003/7, MS-DOS',
        frameworksTool: 'Canvas, XML, XSL, jQuery, jQuery UI, NodeJS, AngularJS, ReactJS, YUI, SproutCore, Konva for canvas, Bootstrap CSS, Yii/Yii2, Phalcon, CodeIgniter, Zend Framework 2'
      }
    },
    {
      name: 'Python Back-end',
      data: {
        programmingLanguages: 'Python, JavaScript, HTML, CSS',
        dataBases: 'MySQL, PostgreSQL, Mongo DB, InfluxDB',
        developmentTools: 'Docker, GIT, OOP',
        operatingSystems: 'Linux (Ubuntu)',
        frameworksTool: 'Flask, Django, FastAPI, REST API, Open API'
      }
    },
    {
      name: 'QA',
      data: {
        programmingLanguages: 'HTML, CSS',
        dataBases: 'SQL, MongoDB, MySQL',
        developmentTools: 'Docker, Git, VisualStudio, DevTools, Jira, Testrail, Trello',
        operatingSystems: 'Windows, Mac, IOS, Android',
        frameworksTool: 'Postman, Swagger, Insomnia, Figma, InVision, Zeplin, Browserstack'
      }
    },
    {
      name: 'React Front-end',
      data: {
        programmingLanguages: 'JavaScript, HTML, CSS',
        dataBases: 'SQL, MySQL, MongoDB, Neo4j',
        developmentTools: 'Subversion, Git, Google Analytics, Bing Webmaster Tools, Piwik(Matomo)',
        operatingSystems: 'MS Windows XP /7/8, Linux',
        frameworksTool: 'ReactJS, AngularJS, SCSS, NodeJS, Redux, Gulp, Web Socket, Ionic 1, Cordova, Type Script, Ionic, Node.JS, Express, CSS3, SCSS, HTML5, REST, IMAP, SMTP'
      }
    },
    {
      name: 'React+Node.JS Front-end',
      data: {
        programmingLanguages: 'JavaScript, HTML, CSS',
        dataBases: 'SQL, MySQL, MongoDB, DynamoDB',
        developmentTools: 'SQLyog, Git, Browsers inspector, Chrome React developer Tools,  Redux Devtools, ColorZilla, WebStorm, Sublime',
        operatingSystems: 'MS Windows XP /7/10, Linux',
        frameworksTool: 'ReactJS, Redux, Angular, Vue.js, Node.JS, JS patterns, jQuery, Bootstrap, SASS/SCSS,  LESS, Gulp, Webpack, Grunt'
      }
    },
  ];

  // const onChange =  (value: string): void => {
  //   console.log(`selected ${value}`);
  // };

  return (
    <Select
      className={ rest.className || '' }
      key={ name || 'preselect-data' }
      showSearch
      style={ { width: 200 } }
      placeholder="Select skills"
      optionFilterProp="children"
      onChange={ (value: string): void => {
        const itemData = preSelectData.find((item) => item.name === value);
        if (itemData) {
          handleOnChange(value, itemData.data);
        } else {
          handleOnChange(value, emptyData.data);
        }
      }  }
      filterOption={ (input: string, option: any): boolean => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

      >
      <Option key={ 0 }>{ '--clear-- ' }</Option>
      { preSelectData.map((item) => (
        <Option key={ item.name }>{ item.name }</Option>
      )) }
    </Select>
  );
};
