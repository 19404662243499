import axios, {AxiosRequestConfig} from 'axios';
import {notification} from 'antd';
import * as qs from 'query-string';
import appConfig from '../configs/app';
import {IParams} from "../interfaces";

/**
 * @description parse additional url parameters
 * @param {String} url
 * @param {Object|null} urlParams
 * @param {Object} urlParams.params // all params in url e.g. ".../:id" === {id : ...}
 * @param {Object| String} urlParams.query
 */
export const parseQueryParams = (url: string, urlParams: IParams | null) => {
  if (!urlParams) {
    return `${appConfig.host}${url}`;
  }
  const {query, params, encode = true, slash = false} = urlParams;
  const urlParamsKeys = url.match(/:[a-z]+/gm);
  let search = slash ? '/' : '';
  if (typeof query === 'object') {
    search = `${search}?${qs.stringify(query, {encode})}`;
  } else if (query && typeof query === 'string') {
    const withSymbol = query.search('?') !== -1;
    search = `${search}${withSymbol ? '' : '?'}${query}`;
  }
  if (urlParamsKeys && params) {
    urlParamsKeys.forEach((key: any) => {
      const paramsKey = key.replace(/^:/gm, '');
      if (params.hasOwnProperty(paramsKey)) {
        url = url.replace(key, params[paramsKey]);
      }
    });
  }
  return `${appConfig.host}${url}${search}`;
};


// Add a request interceptor
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = localStorage.getItem('AUTH_TOKEN');
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(res => res,
  async error => {
    const {response} = error;
    if (response && response.data.hasOwnProperty('_common')) {
      notification.error({
        message: response.data._common
      });
    }
    return Promise.reject(error);
  });

export const get = (url: string, config: AxiosRequestConfig = {}) =>
  (urlParams: any = null) =>
    axios.get(parseQueryParams(url, urlParams), config);
export const post = (url: string, config: AxiosRequestConfig = {}) =>
  (params: any, urlParams: any = null) =>
    axios.post(parseQueryParams(url, urlParams), params, config);
export const put = (url: string, config: AxiosRequestConfig = {}) =>
  (params: any, urlParams: any = null) =>
    axios.put(parseQueryParams(url, urlParams), params, config);
export const patch = (url: string, config: AxiosRequestConfig = {}) =>
  (params: any, urlParams: any = null) =>
    axios.patch(parseQueryParams(url, urlParams), params, config);
export const del = (url: string) =>
  (params: any, urlParams: any = null) =>
    axios.delete(parseQueryParams(url, urlParams));