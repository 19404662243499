import React, {useState, useEffect} from 'react'
import {Col, Select} from "antd";
import Field from "../../ui/Field";
import teamsAPI from "../../API/teams";

interface IProps {
  name?: string
  label?: string
  defaultValue?: string[]
  col?: {
    [key: string]: any
  }
}

export default (props: IProps) => {
  const {
    defaultValue,
    name = 'teams',
    label = 'Search by teams',
    col
  } = props;
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await teamsAPI.getAll({query: {limit: 100}});
      setData(res.data.list);
    } catch (e) {
      return false
    }
  };

  const componentDidMount = () => {
    getData();
  };

  useEffect(componentDidMount, []);

  const getItem = () => {
    const itemComponent = <Field
      name={name}
      label={label}
      defaultValue={defaultValue}
    >
      <Select
        showSearch={true}
        mode="multiple"
        maxTagCount={1}
        notFoundContent="Not found"
        optionFilterProp="children"
      >
        {
          data.map((item: any, i: any) =>
            <Select.Option
              value={item._id}
              key={i}>{item.name}</Select.Option>)
        }
      </Select>
    </Field>;
    return col ? <Col {...col}>{itemComponent}</Col> : itemComponent
  }

  return data && data.length ? getItem() : null
}