import * as React from "react";
import "./assets/styles/styles.scss";
import Routes from "./components/Routes";
import routesConf from "./routes/index";
import * as profileService from "./services/user/profile";
import "./configs/baseErrorMessages";

interface IState {
  loading: boolean;
}

class App extends React.Component<any, IState> {
  public state = {
    loading: true,
  };

  public async componentDidMount() {
    this.initUser();
  }

  public render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {!loading ? <Routes routes={routesConf} /> : null}
      </React.Fragment>
    );
  }

  private initUser = async () => {
    try {
      if (localStorage.getItem("AUTH_TOKEN")) {
        await profileService.get();
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
}

export default App;
