import * as React from 'react';
import {Button} from 'antd';
import {set as setModal} from "../../../services/modal";
import Message from "../Message";

const handleCloseModal = (onCancel: any) => () => onCancel();

setModal('message', {
  hash: 'message',
  component: Message,

  config: {
    actions: [
      ({onCancel}: any) =>
        <Button key="close" onClick={handleCloseModal(onCancel)}>Ok</Button>
    ]
  }
});