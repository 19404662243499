import React from "react";

export default () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.4274 42.7677C68.4274 53.3325 59.8644 61.8967 49.3016 61.8967C38.7389 61.8967 30.1758 53.3325 30.1758 42.7677C30.1758 32.2029 38.7389 23.6387 49.3016 23.6387C59.8644 23.6387 68.4274 32.2029 68.4274 42.7677Z"
      stroke="#191F2D"
    />
    <path
      d="M63.3958 42.7677C63.3958 50.5533 57.0854 56.8644 49.3014 56.8644C41.5174 56.8644 35.207 50.5533 35.207 42.7677C35.207 34.9821 41.5174 28.6709 49.3014 28.6709C57.0854 28.6709 63.3958 34.9821 63.3958 42.7677Z"
      stroke="#191F2D"
    />
    <path
      d="M69.327 58.8203L70.0386 58.1086L76.7983 64.8695C78.5668 66.6382 78.5668 69.5058 76.7983 71.2745V71.2745C75.0299 73.0432 72.1628 73.0432 70.3943 71.2745L63.6346 64.5137L64.3461 63.802M69.327 58.8203L65.0577 54.5503M69.327 58.8203L64.3461 63.802M64.3461 63.802L60.0768 59.532"
      stroke="#191F2D"
    />
    <path
      d="M57.8555 25.1548V15.0903M57.8555 15.0903L43.2642 1M57.8555 15.0903H45.2642C44.1597 15.0903 43.2642 14.1949 43.2642 13.0903V1M43.2642 1H3C1.89543 1 1 1.89543 1 3V77C1 78.1046 1.89543 79 3 79H55.8555C56.96 79 57.8555 78.1046 57.8555 77V60.3806M23.1384 23.6452H49.8051M23.1384 29.1806H35.2139M23.1384 40.2516H30.1824M23.1384 46.2903H30.1824M23.1384 56.8581H36.2202M49.302 61.8903H23.1384"
      stroke="#191F2D"
    />
    <rect
      x="9.04297"
      y="22.6323"
      width="8.06291"
      height="8.06452"
      rx="1"
      stroke="#191F2D"
    />
    <rect
      x="9.04297"
      y="38.7354"
      width="8.06291"
      height="8.06452"
      rx="1"
      stroke="#191F2D"
    />
    <rect
      x="9.04297"
      y="54.8389"
      width="8.06291"
      height="8.06452"
      rx="1"
      stroke="#191F2D"
    />
    <path
      d="M42.2578 44.2477L46.6603 48.8063L57.3522 37.7354"
      stroke="#191F2D"
    />
  </svg>
);
