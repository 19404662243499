import * as React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Checkbox,
  Select,
  Spin,
  DatePicker,
  notification
} from 'antd';
import moment from 'moment';

import { chargePeriods } from '../../../configs/weekends';
import errors from '../../../API/error';
import weekendsAPI from '../../../API/weekends';
import confirmationsAPI from '../../../API/confirmations';
import usersAPI from '../../../API/users';
// eslint-disable-next-line import/no-cycle
import settingsRoutes from '../../../routes/settings';
import Field, { FormItem } from '../../../ui/Field';
import Form, { FormContext } from '../../../noui/Form';
import NavLink from '../../../components/NavLink';
import ColorPicker from '../../../components/ColorPicker';
import weekendsConfirmation from '../../Modals/set/weekendsConfirmation';
import Settings from '../../../layouts/Settings';

const { Option } = Select;

interface IState {
  weekend: any;
  loading: boolean;
  id: number;
  loaded: boolean;

  [key: string]: any;

  list: Array<number>;
}

class CalendarGroup extends React.Component<any, IState> {
  form: any = null;

  constructor(props: any) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      weekend: {
        color: ''
      },
      checkboxes: {},
      id,
      loading: !!id,
      loaded: !id,
      confirmations: [],
      list: []
    };
  }

  componentDidMount() {
    weekendsConfirmation.init({ onSubmit: this.onAddNewConfirmationGroup });
    this.getData();
  }

  componentWillUnmount() {
    weekendsConfirmation.del();
  }

  getData = async () => {
    try {
      const { id } = this.state;
      if (id) {
        const { data } = await weekendsAPI.getItem({ params: { id } });
        const checkboxes = {
          unlimitedDays: !!data.unlimitedDays,
          unlimitedTransfer: !!data.unlimitedTransfer,
          divide: !!data.intervalSteps,
          isfileUsers: !!data.isFilesConfirmation
        };

        const list = data.fileConfirmationUsers?.length > 0 ? data.fileConfirmationUsers : [];

        this.setState({ weekend: data, checkboxes, list });
      }
      await this.getConfirmations();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: 'Failed to load data' });
    }
  };

  getConfirmations = async () => {
    try {
      const { data } = await confirmationsAPI.get({ query: { limit: 100 } });
      const { list } = data;
      this.setState({ confirmations: list, loaded: true, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      errors.handle(e, undefined, { defaultMessage: 'Failed' });
      console.log(e);
    }
  };

  onAddNewConfirmationGroup = ({ values }: any) => {
    const { confirmations } = this.state;
    this.setState({ confirmations: [...confirmations, values] });
    this.form && this.form.setFieldsValue({ defaultConfirmation: values._id });
  };

  handleSubmit = async ({ form, values }: any) => {
    try {
      this.loading(true);
      const { id, weekend: { color }, list } = this.state;
      const params: any = {};
      const {
        unlimitedTransfer,
        breakPoint,
        isfileUsers,
        ...rest
      } = values;

      [
        'displayName',
        'name',
        'unlimitedDays',
        'maxForUserDays',
        'forNewUserDays',
        'accrualDays',
        'interval',
        'creditDays',
        'intervalSteps',
        'includeCurrentStep',
        'transferDays',
        'sendRequestBeforeDays',
        'maxInOneRequestDays',
        'isCommentRequired',
        'isReasonRequired',
        'defaultConfirmationList',
        'useAfterDays',
        'autoConfirmation',
        'isDisabled',
        // 'isFilesConfirmation',
        // 'fileConfirmationUsers'
      ].forEach((key) => {
        if (rest[key]) {
          // check to NaN and then convert to number
          // eslint-disable-next-line no-self-compare
          params[key] = +rest[key] !== +rest[key] ? rest[key] : +rest[key];
        }
      });

      if (unlimitedTransfer) {
        params.unlimitedTransfer = true;
      }

      if (breakPoint) {
        params.breakPoint = breakPoint.format('YYYY-MM-DD');
      }
      if (color) {
        params.color = color;
      }

      if (isfileUsers) {
        params.isFilesConfirmation = isfileUsers;

        if (list) {
          params.fileConfirmationUsers = form.getFieldValue('fileConfirmationUsers');
        }
      }

      if (id) {
        await weekendsAPI.update(params, { params: { id } });
      } else {
        await weekendsAPI.create(params);
      }
      notification.success({
        message: 'Success'
      });
      this.props.history.push(settingsRoutes.settingsWeekends.path);
      return true;
    } catch (e) {
      errors.handle(e, form, { priority: 'all' });
    }
    this.loading(false);
    return false;
  };

  handleCheck = (e: any) => {
    const { name, checked } = e.target;
    this.setState({
      checkboxes: {
        ...this.state.checkboxes,
        [name]: checked
      }
    });
  };

  loading = (loading: boolean = false) => {
    this.setState({ loading });
  };

  handleChangeColor = (color: string) => {
    this.setState((state: any) => ({ weekend: { ...state.weekend, color } }));
  };

  handleChangeConfirmation = (value: any) => {
    if (value === 'new') {
      weekendsConfirmation.open();
    }
  };

  loadUsers = async (search: string = '') => {
    try {
      const { data } = await usersAPI.get({ query: { limit: 10, search } });
      this.setState({ list: data.list });
    } catch (e) {
      console.log(e);
    }
  };

  handleClearData = () => {
    this.loadUsers('');
  };

  render() {
    const {
      weekend,
      confirmations = [],
      loading,
      loaded,
      checkboxes,
      list
    } = this.state;
    return (<Settings location={ this.props.location }>
      <Spin tip="Loading..." spinning={ loading }>
        {
        loaded ? <Card className="mt-30">
          <Form onSubmit={ this.handleSubmit }>
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="displayName"
                  label="Display name"
                  defaultValue={ weekend.displayName } />
              </Col>
            </Row>
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="name"
                  label="Name"
                  validation="required"
                  defaultValue={ weekend.name } />
              </Col>
              <Col md={ { span: 8 } } className="mt-20">
                <Field
                  name="unlimitedDays"
                  type="number"
                  onChange={ this.handleCheck }
                  defaultValue={ checkboxes.unlimitedDays }>
                  <Checkbox defaultChecked={ checkboxes.unlimitedDays }>Unlimited</Checkbox>
                </Field>
              </Col>
            </Row>
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <FormItem label="Color">
                  <ColorPicker
                    onChange={ this.handleChangeColor }
                    color={ weekend.color } />
                </FormItem>
              </Col>
            </Row>
            {
                  !checkboxes.unlimitedDays
                    ? <>
                      <Row gutter={ 20 }>
                        <Col md={ { span: 8 } }>
                          <Field
                            name="maxForUserDays"
                            type="number"
                            defaultValue={ weekend.maxForUserDays }
                            label="Max days for each user" />
                        </Col>
                      </Row>
                      <Row gutter={ 20 }>
                        <Col md={ { span: 8 } }>
                          <Field
                            name="forNewUserDays"
                            type="number"
                            label="Days for new user"
                            defaultValue={ weekend.forNewUserDays } />
                        </Col>
                      </Row>
                    </>
                    : null
                }
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="sendRequestBeforeDays"
                  type="number"
                  label="Send before days"
                  defaultValue={ weekend.sendRequestBeforeDays } />
              </Col>
            </Row>
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="useAfterDays"
                  placeholder="Number of days"
                  type="number"
                  label="The employee can send requests after ... days after hiring"
                  defaultValue={ weekend.useAfterDays } />
              </Col>
            </Row>
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="maxInOneRequestDays"
                  type="number"
                  label="Max days in one request"
                  defaultValue={ weekend.maxInOneRequestDays } />
              </Col>
            </Row>
            {
                  !checkboxes.unlimitedDays ? <>
                    <Row gutter={ 20 }>
                      <Col md={ { span: 8 } }>
                        <Field
                          name="transferDays"
                          label="Transfer to next period"
                          disabled={ !!weekend.unlimitedTransfer }
                          type="number"
                          defaultValue={ weekend.transferDays } />
                      </Col>
                      <Col md={ { span: 8 } } className="mt-20">
                        <Field
                          name="unlimitedTransfer"
                          defaultValue={ checkboxes.unlimitedTransfer }>
                          <Checkbox onChange={ this.handleCheck } defaultChecked={ checkboxes.unlimitedTransfer }>Unlimited
                            transfer</Checkbox>
                        </Field>
                      </Col>
                    </Row>
                    <Row gutter={ 20 }>
                      <Col md={ { span: 8 } }>
                        <Field
                          name="accrualDays"
                          label="Accrual days"
                          type="number"
                          defaultValue={ weekend.accrualDays } />
                      </Col>
                      <Col md={ { span: 8 } }>
                        <Field
                          name="interval"
                          label="Interval"
                          validation="required"
                          defaultValue={ weekend.interval }>
                          <Select>
                            {
                              chargePeriods && chargePeriods.map((period: any, index: number) =>
                                (<Option
                                  key={ index }
                                  value={ period.value }>{ period.name }</Option>))
                            }
                          </Select>
                        </Field>
                      </Col>
                      <Col md={ { span: 8 } } className="mt-20">
                        <Field
                          name="divide"
                          defaultValue={ checkboxes.divide }>
                          <Checkbox onChange={ this.handleCheck } defaultChecked={ checkboxes.divide }>Divide
                            charges</Checkbox>
                        </Field>
                      </Col>
                    </Row>
                    {
                      checkboxes.divide ? <Row gutter={ 20 }>
                        <Col md={ { span: 8 } }>
                          <Field
                            name="intervalSteps"
                            validation="required"
                            defaultValue={ weekend.intervalSteps }
                            label="Interval steps">
                            <Select>
                              {
                                chargePeriods && chargePeriods.map((period: any, index: number) =>
                                  (<Option
                                    key={ index }
                                    value={ period.value }>{ period.name }</Option>))
                              }
                            </Select>
                          </Field>
                        </Col>
                      </Row> : null
                    }
                    <Row gutter={ 20 }>
                      <Col md={ { span: 8 } }>
                        <Field
                          name="creditDays"
                          label="Credit days"
                          type="number"
                          defaultValue={ weekend.creditDays } />
                      </Col>
                    </Row>
                    <Row gutter={ 20 }>
                      <Col md={ { span: 8 } }>
                        <Field
                          name="breakPoint"
                          defaultValue={ weekend.breakPoint
                            ? moment.utc(weekend.breakPoint)
                            : moment.utc().endOf('year') }
                          label="Break point">
                          <DatePicker />
                        </Field>
                      </Col>
                      <Col md={ { span: 8 } } className="mt-20">
                        <Field
                          name="includeCurrentStep"
                          defaultChecked={ !!weekend.includeCurrentStep }
                          defaultValue={ !!weekend.includeCurrentStep }>
                          <Checkbox>Include current step</Checkbox>
                        </Field>
                      </Col>
                    </Row>
                  </> : null
                }
            <Row gutter={ 20 }>
              <Col md={ { span: 8 } }>
                <Field
                  name="defaultConfirmationList"
                  defaultValue={ weekend.defaultConfirmationList
                    ? weekend.defaultConfirmationList._id
                    : undefined }
                  onChange={ this.handleChangeConfirmation }
                  label="Default approval gates">
                  <Select>
                    <Option
                      key="new"
                      value="new">Add new</Option>
                    {
                          confirmations.map(({ name, _id }: any, index: number) =>
                            (<Option
                              key={ index }
                              value={ _id }>{ name }</Option>))
                        }
                  </Select>
                </Field>
              </Col>
              <Col md={ { span: 8 } } className="mt-20">
                <Field
                  name="autoConfirmation"
                  defaultValue={ weekend.autoConfirmation }>
                  <Checkbox
                    onChange={ this.handleCheck }
                    defaultChecked={ weekend.autoConfirmation }>Autocofirmation</Checkbox>
                </Field>
              </Col>
            </Row>
            <Row>
              <Field
                name="isReasonRequired"
                defaultValue={ weekend.isReasonRequired }>
                <Checkbox defaultChecked={ weekend.isReasonRequired }>Reason is required in the requests</Checkbox>
              </Field>
              <Field
                name="isCommentRequired"
                defaultValue={ weekend.isCommentRequired }>
                <Checkbox defaultChecked={ weekend.isCommentRequired }>Comment is required in the requests</Checkbox>
              </Field>
              <Field
                name="isDisabled"
                defaultValue={ weekend.isDisabled }>
                <Checkbox defaultChecked={ weekend.isDisabled }>Do not show in the select list</Checkbox>
              </Field>
            </Row>

            <Row>
              { /* <Field
                name="isFilesConfirmation"
                defaultValue={ weekend.isFilesConfirmation }>
                <Checkbox defaultChecked={ weekend.isFilesConfirmation }>Need a files confirmation</Checkbox>
              </Field> */ }

              <Field
                name="isfileUsers"
                defaultValue={ checkboxes.isfileUsers }>
                <Checkbox onChange={ this.handleCheck } defaultChecked={ checkboxes.isfileUsers }>Need a files confirmation</Checkbox>
              </Field>

              { checkboxes.isfileUsers ? (

                <Field
                  name='fileConfirmationUsers'
                  label='File Confirmation Users list'
                  placeholder="Users list"
                  validation="canNotBlank"
                  defaultValue={ weekend.fileConfirmationUsers.map((u: any) => u._id) }
                  onFocus={ this.handleClearData }
                  onSearch={ this.loadUsers }
                  onChange={ this.handleClearData }
                  >
                  <Select
                    mode="multiple"
                    notFoundContent={ null }
                    defaultActiveFirstOption={ false }
                    showArrow={ false }
                    filterOption={ false }
                    showSearch={ true }>
                    {
                          list.map((data: any) =>
                            <Option key={ data._id }>{ data.firstName } { data.lastName }</Option>)
                        }
                  </Select>
                </Field>
              ) : null }

            </Row>

            <Row type="flex" justify="end">
              <NavLink to={ settingsRoutes.settingsWeekends.path }>
                <Button className="mr-15">
                  Cancel
                </Button>
              </NavLink>
              <Button
                htmlType="submit"
                className="ant-btn-primary"
                disabled={ loading }>Save</Button>
            </Row>
            <FormContext.Consumer>{
                    ({ form }: any) => {
                      if (!this.form && form) {
                        this.form = form;
                      }
                      return null;
                    }
                  }</FormContext.Consumer>
          </Form>
        </Card>
          : null
        }
      </Spin>
    </Settings>);
  }
}

export default CalendarGroup;
