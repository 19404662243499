import * as api from './index';
const t = '/weekends';

export default {
  get: api.get(t),
  create: api.post(t),
  getItem: api.get(`${t}/item/:id`),
  getUserWeekends: api.get(`${t}/user/:id`),
  update: api.put(`${t}/:id`),
  del: api.del(`${t}/:id`),
  employeesAbsences: api.put(`${t}/employees-absences/:id`),
  getHistory: api.get(`${t}/history-log/:id`)
};