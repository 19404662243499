import React from 'react';
import './styles.scss';
import { Button, Col, Icon, Row } from 'antd';
import appConfig from '../../configs/app';

function CompanyPolicyDocuments() {
  function downloadFileUrl(url: string, fileName: string) {
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', fileName);
    aTag.setAttribute('target', '_blank');
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }

  const documentLists = {
    'Professional_courses_compensation_Policy.pdf': 'Professional courses compensation Policy',
    'Leave_and_Sickness_Policy.pdf': 'Leave and Sickness Policy',
    'Healthcare_Policy.pdf': 'Healthcare Policy',
    'Financial_Aid_Policy.pdf': 'Financial Aid Policy',
  };

  return (
    <>
      <h2 className='page-header mb-10'>Company Policy Documents</h2>
      <div className='insurance_details__container'>
        { Object.entries(documentLists).map(([file, subject], index: number) => {
          const url = `${appConfig.staticPolicy + file}`;
          return (
            <Row key={ index } gutter={ 20 } className="mb-15">
              { ' ' }
              <Col md={ { span: 8 } }><strong>{ subject }</strong></Col>
              <Col md={ { span: 4 } }>
                { ' ' }
                <Button
                  onClick={ () => {
                    downloadFileUrl(url, file);
                  } }
                >
                  <Icon type="download" /> Download
                </Button>
              </Col>
            </Row>
          );
        }) }
      </div>
    </>
  );
}

export default CompanyPolicyDocuments;
