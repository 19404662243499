import React from "react";
import {Col, DatePicker, Row, Select} from "antd";
import {Buttons, ReqStatus} from "../../components/Filters";
import Field from "../../ui/Field";
import dateConfig from "../../configs/date";

const {RangePicker} = DatePicker;

interface Props {
  list?: any[]
}

export default (props: Props) => <Row gutter={[10, 0]} className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col span={20}>
    <Row gutter={[8, 8]}>
      {
        props.list && props.list.length > 0 ? <Col span={6}>
          <Field
            name="weekends"
            label="Search absence"
          >
            <Select
              showSearch={true}
              mode="multiple"
              maxTagCount={1}
              notFoundContent
            >
              {
                props.list.map((item: any, i: any) =>
                  <Select.Option
                    value={item.weekendId}
                    key={i}>{item.name}</Select.Option>)
              }
            </Select>
          </Field>
        </Col> : null
      }
      <ReqStatus col={{span: 6}} />
      <Col span={12}>
        <Field name="range"
               label="From"
               placeholder="From">
          <RangePicker format={dateConfig.formatHolidayDatePicker} />
        </Field>
      </Col>
    </Row>
  </Col>
  <Col span={4}>
    <Buttons />
  </Col>
</Row>