import googleConfig from '../configs/google';
const w: any = window;

w.setGoogleApi = () => {
  w.gapi.load('auth2', () => {
    // Retrieve the singleton for the GoogleAuth library and set up the client.
    w.googleAuth2 = w.gapi.auth2.init({
      client_id: googleConfig.googleKey,
      cookiepolicy: 'single_host_origin',
      // Request scopes in addition to 'profile' and 'email'
      // scope: 'userinfo'
    });
  });
};

export const googleInit = () => {
  (((d, s, id) => {
    const fjs: any = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    const js: any = d.createElement(s);
    js.id = id;
    js.src = "https://apis.google.com/js/platform.js?onload=setGoogleApi";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'google-jssdk'))
};

export const googleLoginUser = async () => {
  const googleUser = await w.googleAuth2.signIn();
  const getAuthResponse = googleUser.getAuthResponse();

  const userParams = {
    provider: 'google',
    externalAccessToken: getAuthResponse.id_token
  };
  return userParams;
};