import React from "react";
import moment from "moment";
import Field from "../../../../ui/Field";
import Avatar from "../../../../components/Avatar";
import {IRequestData} from "../../../../interfaces/containers";
import DotMenu, {IActionsList} from "../../../../components/DotMenu";
import {Checkbox, Row} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";

export default (actions: IActionsList, onChangeResponsible?: (inventoryId: string, active: boolean) => void) => {
  const columns: any = [
    {
      title: '',
      dataIndex: 'user',
      key: 'avatar',
      width: 100,
      render: (user: any) => <Avatar user={user} size={24} />
    },
    {
      title: 'Employee',
      key: 'uid',
      dataIndex: 'user',
      render: ({firstName, lastName}: any) => `${firstName} ${lastName}`.trim()
    },
    {
      title: 'Assigned at',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm')
    },
  ];

  if (onChangeResponsible) {
    columns.push({
      title: 'Responsible',
      key: 'isResponsible',
      dataIndex: 'isResponsible',
      render: (isResponsible: boolean, record: any, index: number) => {
        return <Row type="flex" justify="center">
          <Field disableDecorator
                 disableBottomMargin
                 name={`responsible-${index}`}>
            <Checkbox checked={isResponsible}
                      onChange={(checked: CheckboxChangeEvent) => {
                        onChangeResponsible(record._id, checked.target.checked)
                      }} />
          </Field>
        </Row>
      }
    })
  }
  columns.push({
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: IRequestData) =>
      <DotMenu actions={actions} record={record} />
  })
  return columns
}