import React from "react";
import Field from "../../ui/Field";
import TextArea from "antd/lib/input/TextArea";

function ReferFriend() {
  return (
    <div>
      <div className="flex jc-center fs-16">
        Please tell us a little bit about your friend
      </div>
      <Field name="fullName" label="Full Name" placeholder="Type here" />
      <Field name="phoneNumber" label="Phone number" placeholder="Type here" />
      <Field name="position" label="Position" placeholder="Type here" />
      <Field
        name="socialMedia"
        label="Social media (LinkedIn, Facebook, etc.)"
        placeholder="Type here"
      />
      <Field name="comment" label="Comment" placeholder="Type here">
        <TextArea />
      </Field>
    </div>
  );
}

export default ReferFriend;
