import { set as setModal } from '../../../services/modal';
import SendRequest from '../SendRequest';
import requestsApi from '../../../API/requests';
import errors from '../../../API/error';

/**
 * Send weekend request to api
 */
const submitRequest = async ({ values, form, onLoading, onCancel, modalProps }: any) => {
  const { callback = () => {} } = modalProps;
  try {
    const { from, to, comment, userId, ...rest } = values;
    onLoading(true);
    const reqParams = {
      ...rest,
      comment,
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    };

    if (userId) {
      reqParams.userId = userId;
      await requestsApi.remoteRequest(reqParams);
    } else {
      await requestsApi.create(reqParams);
    }
    onCancel();
  } catch (e) {
    errors.handle(e, form, { priority: 'all' });
    onLoading(false);
  } finally {
    callback();
  }
};

setModal('sendRequest', {
  hash: 'send-request',
  component: SendRequest,
  config: {
    title: 'Send Request',
    okLabel: 'Send',
    // wrapClassName: 'send-request-modal',
    onSubmit: submitRequest
  }
});
