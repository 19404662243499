import {FormProps} from "antd/lib/form";
import notification from '../services/notification';
import {IFieldData, IRequestError, IRequestErrorErrors, TOpts, TRequestErrorMessage} from '../interfaces/error';

const setFormErrors = (form: FormProps["form"], errors: IRequestErrorErrors): any => {
  if (form) {
    const {getFieldsValue, setFields} = form;
    const values = getFieldsValue();
    const err: IFieldData = {};

    if (!values) {
      return false;
    }

    const getError = (error: TRequestErrorMessage): Array<Error> | undefined => {
      if (typeof error === 'string') {
        return [new Error(error)];
      }

      if (error instanceof Array) {
        return error.splice(0, 1).map(m => new Error(m));
      }

      return [new Error('error')];
    };

    for (const i in values) {
      if (values.hasOwnProperty(i) && errors.hasOwnProperty(i)) {
        const errMessage = errors[i];
        err[i] = {
          value: values[i],
          errors: getError(errMessage)
        }
      } else {
        err[i] = {value: values[i]}
      }
    }
    setFields(err);
  }
};

function handle(e: IRequestError, form?: FormProps["form"], opts?: TOpts) {
  const {response} = e;
  const status = response && response.status ? response.status : 'none';
  let _opts: TOpts = {priority: 'notification', log: false};

  if (opts && typeof opts === 'object') {
    _opts = {..._opts, ...opts};
  }

  const message = getMessage(response, e.message);


  if (!form) {
    return showNotificationError();
  }

  if (response && response.data) {
    const {errors} = response.data;

    switch (_opts.priority) {
      case 'all':
        return showAllErrors(errors);
      case 'notification':
        return showNotificationError();
      case 'form':
        return showFormErrors(errors);
      default:
        return showNotificationError();
    }
  }

  function showNotificationError () {
    log(message, status);
    return notification.error(message);
  }

  function showFormErrors (errors?: IRequestErrorErrors, hideNotification?: boolean) {
    if (errors && typeof errors === 'object') {
      log(errors, status);
      setFormErrors(form, errors);
    } else {
      !hideNotification && showNotificationError();
    }
  }

  function showAllErrors (errors?: IRequestErrorErrors) {
    showNotificationError();
    showFormErrors(errors, true);
  }

  function log(message: string | object, status: number | string) {
    if (_opts.log) {
      console.error(`ParsedError, status(${status}), message:`, message)
    }
  }

  function getMessage (response: IRequestError["response"], message?: string): string {
    if (response && response.data) {
      const {message: resMessage} = response.data;

      if (resMessage && typeof resMessage === 'string') {
        return resMessage;
      }

      if (resMessage && resMessage instanceof Array) {
        return resMessage.join('. ');
      }
    }
    return _opts.defaultMessage 
      ? _opts.defaultMessage
      : (message || 'Something went wrong. Try later.');
  }
}

const parse = (form: any, errors: any): any => {

  const {getFieldsValue, setFields} = form;
  const values = getFieldsValue();
  if (!values || !errors || !errors.data) {
    return false;
  }
  const err: any = {};
  for (const i in values) {
    if (values.hasOwnProperty(i) && errors.data.hasOwnProperty(i)) {
      err[i] = {
        value: values[i],
        errors: [new Error(errors.data[i])]
      }
    } else {
      err[i] = {value: values[i]}
    }
  }
  setFields(err);
};

export default {
  parse,
  handle,
  setFormErrors
}