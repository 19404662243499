import * as modal from "../../../services/modal";
import Tmp from './Tmp';
import {ISubmitProps} from "../../../noui/Form";

interface IProps {
  testDevice?: boolean
}

const KEY = 'inventory-assign';

export const init = ({onSubmit}: { onSubmit(props: ISubmitProps): void }) => {
  modal.set(KEY, {
    hash: KEY,
    component: Tmp,
    config: {
      title: 'Inventory Assign Employee',
      okLabel: 'Save',
      onSubmit
    }
  });
};

export const open = (props?: IProps) => {
  modal.setProps(KEY, props);
  modal.open(KEY)
};

export const remove = () => {
  modal.remove(KEY)
};

export const setProps = (item: IProps) => {
  modal.setProps(KEY, item);
};

export default {
  setProps,
  open,
  remove,
  init
}