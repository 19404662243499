import * as modal from "../../../services/modal";
import WiFiConnections from './WiFiConnections';
import {ISubmitProps} from "../../../noui/Form";
import errors from "../../../API/error";
import apiWifiConnections from "../../../API/wifiConnections";

interface IProps {
  onSubmit?: any
  item?: any
};

const KEY = 'wifi-connections';

const onSubmit = async ({
  values, form, modalProps, onCancel, onLoading
                  }: ISubmitProps|any) => {
  try {
    onLoading(true);
    if (modalProps && modalProps.item && modalProps.item._id) {
      await apiWifiConnections.put(values, {params: {id: modalProps.item._id}})
    } else {
      await apiWifiConnections.post(values)
    }
    if (modalProps && modalProps.onSubmit) {
      modalProps.onSubmit({values, form})
    }
    onCancel();
    onLoading(false)
  } catch (e) {
    onLoading(false);
    errors.handle(e, form, {priority: 'all'});
  }
};

modal.set(KEY, {
  hash: KEY,
  component: WiFiConnections,
  config: {
    title: 'Wi-Fi Connection',
    okLabel: 'Save',
    onSubmit
  }
});

export const open = (item: IProps = {}) => {
  modal.setProps(KEY, item);
  modal.open(KEY)
};

export const remove = () => {
  modal.remove(KEY)
};

export const setProps = (item: IProps = {}) => {
  modal.setProps(KEY, item);
};

export default {
  setProps,
  open,
  remove
}