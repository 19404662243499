import SectorCreateEdit from "../containers/Tables/SectorCreateEdit";
import Sectors from "../containers/Tables/Sectors";
import Tables from "../containers/Tables/Tables";
import TableCreateEdit from "../containers/Tables/TableCreateEdit";

export default {
  sectors: {
    component: Sectors,
    path: "/settings/tables",
  },
  tables: {
    component: Tables,
    path: "/settings/tables/:id",
  },
  editSector: {
    component: SectorCreateEdit,
    path: "/settings/tables/edit-sector/:id",
  },
  createSector: {
    component: SectorCreateEdit,
    path: "/settings/tables/create/sector",
  },
  tableCreate: {
    component: TableCreateEdit,
    path: "/settings/tables/create/table",
  },
  editTable: {
    component: TableCreateEdit,
    path: "/settings/tables/edit-table/:id",
  },
};
