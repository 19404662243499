import React, { useState } from "react";
import { DatePicker, Select } from "antd";
import moment, { Moment } from "moment";
import "./index.scss";
import store from "../../configs/store";
import { IBookingRequest } from "../../interfaces/containers/tableBooking";

export default function MultipleDatePicker({
  setFieldsValue: selectedDate,
  bookedRequestData,
  onChange,
  format = "D MMMM, YYYY",
  placeholder,
  selectProps = {},
  datePickerProps = {},
}: any) {
  const [open, setOpen] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  const onValueChange = (date: Moment | null) => {
    const currentDate = moment(date).format(format).toString();
    const index = selectedDate.indexOf(currentDate);
    const newSelectedDates = [...selectedDate]
    if (index > -1) {
      newSelectedDates.splice(index, 1);
      onChange(newSelectedDates);
    } else {
      onChange([...newSelectedDates, currentDate]);
    }
  };

  const dateRender = (currentDate: Moment) => {
    const dateStyle = getStyle(currentDate);
    return (
      <div className={"ant-picker-cell-inner"} style={dateStyle}>
        {currentDate.date()}
      </div>
    );
  };
  const getStyle = (currentDate: Moment) => {
    const userId = store.getState().user._id;

    const formatedDate = moment(currentDate).format(format).toString();
    const isSelected = selectedDate.indexOf(formatedDate) > -1;
    const isPastDate = currentDate.isBefore(moment(new Date()).startOf("day"));
    const isMyRequest =
      bookedRequestData.filter((request: IBookingRequest) => {
        return (
          moment(request.dateStart)
            .startOf("day")
            .isSame(moment(currentDate).startOf("day")) &&
          request.bookedFor === userId
        );
      }).length > 0;

    const isRequested =
      bookedRequestData.filter(
        (request: IBookingRequest) =>
          moment(request.dateStart)
            .startOf("day")
            .isSame(moment(currentDate).startOf("day")) &&
          request.bookedFor !== userId
      ).length > 0;
    switch (true) {
      case isMyRequest:
        return {
          zIndex: 2,
          display: "inlineBlock",
          width: "24px",
          height: "22px",
          lineHeight: "22px",
          backgroundColor: "#191F2D",
          color: "#fff",
          margin: "auto",
          borderRadius: "5px",
        };

      case isRequested:
        return {
          textDecorationLine: "line-through",
          color: "#BFBFBF",
        };
      case isSelected:
        return {
          zIndex: 2,
          display: "inlineBlock",
          width: "24px",
          height: "22px",
          lineHeight: "22px",
          border: "1px solid #191F2D",
          margin: "auto",
          borderRadius: "5px",
          transition: "background 0.3s, border 0.3s",
        };
      case isPastDate:
        return {
          color: "#BFBFBF",
        };

      default:
        return {};
    }
  };

  const onDeselect = (value: string) => {
    onChange(
      selectedDate.filter((currentDate: string) => currentDate !== value)
    );
  };
  return (
    <Select
      mode="multiple"
      value={selectedDate}
      onDeselect={onDeselect}
      placeholder={placeholder}
      open={open}
      onFocus={() => {
        setOpen(true);
      }}
      onBlur={() => {
        if (!onFocus) setOpen(false);
      }}
      dropdownClassName={"multipleDropdownClassName"}
      dropdownRender={() => {
        return (
          <DatePicker
            onChange={onValueChange}
            value={null}
            onBlur={() => {
              setOpen(false);
              setOnFocus(false);
            }}
            onFocus={() => {
              setOnFocus(true);
            }}
            dateRender={dateRender}
            open
            showToday={false}
            getCalendarContainer={() =>
              document.getElementsByClassName(
                "multipleDropdownClassName"
              )[0] as unknown as HTMLElement
            }
          />
        );
      }}
    />
  );
}
