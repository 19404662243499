import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { LINK_TO_DOCUMENTS } from "../const";

const DocumentsTub = () => {
  return (
    <div className="insurance_details__points">
      <ul>
        <li>База АRX 2022-2023_Україна</li>
        <li>Заява ДМС</li>
        <li>Пам'ятка амбулаторно-поліклінічні послуги</li>
        <li>Пам'ятка відшкодування стаціонар</li>
        <li>Пам'ятка для застрахованих осіб</li>
        <li>
          Пам'ятка для застрахованих при виклику швидкої медичної допомоги.
        </li>
        <li>Пам'ятка стоматологія</li>
      </ul>
      <Button type="primary" className="ml-20 mt-20">
        <Link
          to={{
            pathname: LINK_TO_DOCUMENTS,
          }}
          target="_blank"
        >
          Download documents
        </Link>
      </Button>
    </div>
  );
};

export default DocumentsTub;
