import * as React from 'react';
import {
  Row,
  Pagination
} from 'antd';
import isMobile from '../../services/isMobile'

export default (props: any): JSX.Element => {
  const {width} = document.body.getBoundingClientRect();
  return <Row className="mt-20 mb-20"
              type="flex"
              justify="center">
    <Pagination size={isMobile || width < 767 ? 'small' : ''} {...props} />
  </Row>
}