import * as React from "react";
import "./styles.scss";
import { bookingLegend } from "./BookingLegend";
import { ITableSector } from "src/interfaces/containers/tableBooking";
import { connect } from "react-redux";
import { IStore } from "../../../../interfaces";

class WorkspaceInfo extends React.Component<any, any> {
  render() {
    const { sectorsData: sectors } = this.props.bookingTable;
    return (
      <div className="workspace-info">
        <div className="workspace-info__legend-container">
          <div className="workspace-info__tittle">Legend</div>
          {bookingLegend.map((item, index) => {
            const { subTittle, tittle, color } = item;
            return (
              <div key={index} className="workspace-info__legend-item">
                <div
                  style={{ background: color }}
                  className="workspace-info__legend-color"
                ></div>
                <div className="workspace-info__legend-text">
                  <div className="workspace-info__legend-tittle">{tittle}</div>
                  {subTittle && (
                    <div className="workspace-info__legend-subtittle">
                      {subTittle}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="workspace-info__request-info-container">
            <div className="workspace-info__tittle">Zones:</div>
            {sectors.map((sector: ITableSector) => {
              const { color, name, sectorZone, _id } = sector;
              return (
                <div key={_id} className="workspace-info__requests-item">
                  <div style={{ background: color }} className="sector">
                    {name}
                  </div>
                  <div className="workspace-info__requests-zone-tittle">
                    {sectorZone}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="workspace-info__subtxet mb-15">
            If you want to book another table in selected date, you need to
            cancel previous booking on that date.
          </div>
          <div className="workspace-info__subtxet">
            You have <span className="green">5 available booking</span> options
            for different dates. Please note that you can still book one table
            for 5 days or choose different tables per each of the available
            days.
          </div>
        </div>
      </div>
    );
  }
}
export default connect(({ bookingTable }: Partial<IStore>) => ({
  bookingTable,
}))(WorkspaceInfo);
