import * as React from 'react';
import Field from '../../ui/Field';

interface IProps {
  onChangePermissions: any,
  modalProps: any
}

const Positions = ({modalProps: {name = '', description = ''}}: IProps) => {
  return <>
    <Field name="name" label="Name" defaultValue={name} />
    <Field name="description" label="Description" defaultValue={description} />
  </>
};

export default Positions;