import React from "react";
import Settings from "../../../../layouts/Settings";
import {Button, Row, Col} from "antd";
import columns from "./columns";
import routesInventories from '../../../../routes/inventories'
import apiInventories from '../../../../API/inventories'
import errors from "../../../../API/error";
import Table from "../../../../components/Table";

import modalInventoriesComponents from '../../../Modals/InventoriesComponents'
import Form, {ISubmitProps} from "../../../../noui/Form";
import Field from "../../../../ui/Field";
import './styles.scss';

interface Component {
  key: string,
  name: string
}

interface State {
  data: string[],
  changed: boolean,
  loaded: boolean,
  defaultValues: {
    [key: string]: string
  },
  components: Component[]
}

export default class CreateEdit extends React.Component<any, State> {
  state: State = {
    data: [],
    defaultValues: {},
    components: [],
    changed: false,
    loaded: false
  };

  componentDidMount() {
    modalInventoriesComponents.init({
      onSubmit: this.handleSubmit
    });
    this.getData();
  }

  componentWillUnmount(): void {
    modalInventoriesComponents.remove();
  }

  handleSubmit = ({values, onCancel}: ISubmitProps | any) => {
    const selected = Object.entries(values)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    this.setState({data: selected, changed: true});
    onCancel();
  };

  handleAddComponents = () => {
    const {components, data} = this.state;
    modalInventoriesComponents.open({
      selected: data,
      items: components
    })
  };

  getData = async () => {
    try {
      const {id} = this.props.match.params;
      const {data} = await apiInventories.getComponents();
      const state: any = {components: data, loaded: true};
      if (id) {
        const {data: {fields, ...rest}} = await apiInventories.getGroup({params: {id}});
        state.defaultValues = rest;
        state.data = fields.map(({key}: any) => key);
      }
      this.setState(state)
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Error happened'});
    }
  };

  handleUp = (index: number) => {
    const {data} = this.state;
    const current = data[index];
    const prev = data[index - 1];
    if (!prev) {
      return
    }
    const cloned = [...data];
    cloned[index] = prev;
    cloned[index - 1] = current;
    this.setState({data: cloned, changed: true})
  };

  handleDown = (index: number) => {
    const {data} = this.state;
    const current = data[index];
    const next = data[index + 1];
    if (!next) {
      return
    }
    const cloned = [...data];
    cloned[index] = next;
    cloned[index + 1] = current;
    this.setState({data: cloned, changed: true})
  };

  handleRemove = (index: number) => {
    const {data} = this.state;
    const cloned = [...data];
    cloned.splice(index, 1);
    this.setState({data: cloned, changed: true})
  };

  handleChange = () => {
    this.setState({changed: true})
  };

  handleSave = async ({values, form}: any) => {
    try {
      const {id} = this.props.match.params;
      const {name, prefix, startIndex} = values;
      const {data} = this.state;
      const params: any = {
        fields: data,
        name,
        prefix,
      };

      if (startIndex) {
        params.startIndex = +startIndex;
      }

      if (id) {
        await apiInventories.putGroup(params, {params: {id}});
      } else {
        await apiInventories.postGroup(params);
      }

      this.props.history.push(routesInventories.inventoriesGroups.path)
    } catch (e) {
      errors.handle(e, form, {priority: 'all'});
    }
  };

  render() {
    const {data, components, changed, loaded, defaultValues} = this.state;
    let hasID = false;
    const dataset = data.map((item: string) => {
      if (item === 'id') {
        hasID = true
      }
      return components.find(({key}: Component) => item === key);
    });

    return <Settings
      location={this.props.location}
      title="Edit/Create Inventories Groups">
      {
        loaded ? <Form onSubmit={this.handleSave}>
          <Row gutter={20}>
            <Col span={8}>
              <Field name="name"
                     label="Name"
                     defaultValue={defaultValues.name}
                     validation="required"
                     onChange={this.handleChange} />
            </Col>
            {
              hasID ?
                <>
                  <Col span={8}>
                    <Field name="prefix"
                           label="ID prefix"
                           defaultValue={defaultValues.prefix}
                           onChange={this.handleChange} />
                  </Col>
                  <Col span={8}>
                    <Field name="startIndex"
                           label="ID start index"
                           type="number"
                           defaultValue={defaultValues.startIndex}
                           onChange={this.handleChange} />
                  </Col>
                </> : null
            }
          </Row>
          <Row type="flex"
               justify="space-between"
               className="mb-15">
            <Col>
              <h2 className="page-header">Components</h2>
            </Col>
            <Col>
              <Button type="primary"
                      onClick={this.handleAddComponents}>Add Components</Button>
            </Col>
          </Row>
          <Table dataSource={dataset}
                 showHeader={false}
                 pagination={{hideOnSinglePage: true, pageSize: 100}}
                 columns={columns({
                   count: dataset.length,
                   onUp: this.handleUp,
                   onDown: this.handleDown,
                   onRemove: this.handleRemove
                 })} />
          {
            changed ? <Row type="flex"
                           justify="end"
                           className="mt-15">
              <Button type="primary"
                      htmlType="submit">Save</Button>
            </Row> : null
          }
        </Form> : null
      }
    </Settings>
  }
}