import React from 'react'
import {Button, Dropdown, Menu} from "antd";
import Has from "../../noui/Permissions/Has";
import DropdownArrow from "../../assets/icons/dropdownArrow";

const ButtonGroup = Button.Group;

interface IProps {
  mainTitle?: string
  onSendRemote?(): void,

  onSend(): void
}

export default ({onSend, onSendRemote, mainTitle = 'Request New Absence'}: IProps) => {
  return <ButtonGroup>
    <Button className="v-align-top" type="primary"
            onClick={onSend}>{mainTitle}</Button>
    {
      onSendRemote ? <Has permissions={['settings']}>
        <Dropdown placement="bottomRight"
                  overlay={<Menu onClick={onSendRemote}>
                    <Menu.Item>On behalf of...</Menu.Item>
                  </Menu>}>
          <Button type="primary"
                  className="pt-5 v-align-top">
                                        <span className="button-arrow">
                                            <DropdownArrow color="#ffffff" />
                                        </span>
          </Button>
        </Dropdown>
      </Has> : null
    }
  </ButtonGroup>
}