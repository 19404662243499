import moment from "moment";

export default [
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: Date) => moment(date).format('DD/MM/YYYY HH:mm')
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Days',
    className: 'align-center',
    dataIndex: 'days',
    key: 'days'
  },
];