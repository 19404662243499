import React from "react";
import { Button, Row, Card, Spin } from "antd";
import routesTables from "../../../routes/tables";
import apiTableSector from "../../../API/tableSector";
import Field from "../../../ui/Field";
import Form, { ISubmitProps } from "../../../noui/Form";
import Settings from "../../../layouts/Settings";
import errors from "../../../API/error";
import { ITableSector } from "../../../interfaces/containers/tableBooking";

interface State {
  data: ITableSector;
  loaded?: boolean;
}

export default class SectorCreateEdit extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: {
        name: "",
        color: "",
        sectorZone: "",
        coordinates: [],
        _id: "",
        mapImage: "",
      },
      loaded: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getSector(id);
    } else {
      this.setState({ loaded: true });
    }
  }

  getSector = async (id: string) => {
    try {
      const {
        data: { records },
      } = await apiTableSector.getItem({ params: { id } });
      this.setState({ loaded: true, data: { ...records } });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };

  handleSubmit = async ({ form, values }: ISubmitProps) => {
    try {
      const { id } = this.props.match.params;

      if (id) {
        await apiTableSector.put(values, { params: { id } });
      } else {
        await apiTableSector.create(values);
      }
      this.props.history.push(routesTables.sectors.path);
    } catch (e) {
      errors.handle(e, form, { defaultMessage: "Failed", priority: "all" });
    }
  };

  render() {
    const { data, loaded } = this.state;
    const { id } = this.props.match.params;

    return (
      <Settings
        title={id ? "Edit Inventory" : "Create Inventory"}
        location={this.props.location}
      >
        <Card>
          <Spin tip="Loading..." spinning={false}>
            {loaded ? (
              <Form onSubmit={this.handleSubmit}>
                <Field
                  name="name"
                  label="Name"
                  validation="required"
                  defaultValue={data.name}
                />
                <Field
                  name="sectorZone"
                  label="Sector zone"
                  validation="required"
                  defaultValue={data.sectorZone}
                />
                <Field
                  name="color"
                  label="Color"
                  validation="required"
                  defaultValue={data.color}
                />
                <Row type="flex" justify="end">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Row>
              </Form>
            ) : null}
          </Spin>
        </Card>
      </Settings>
    );
  }
}
