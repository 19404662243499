import {notification} from "antd";
import filesConfigs from "../../configs/files";

export default (file: any): boolean => {
  if (file.size > filesConfigs.maxUploadImageSize) {
    notification.error({
      message: 'Failed',
      description: `File size must be less than ${filesConfigs.maxUploadImageMsg}`
    });
    return false;
  } else if (filesConfigs.imageMimes.indexOf(file.type) === -1) {
    notification.error({
      message: 'Failed',
      description: `You can upload only image file.`
    });
    return false;
  }
  return true;
}