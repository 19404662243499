import { Button, Card, Select } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import { ISubObject } from '../../interfaces/containers/CvMaker';
import Field from '../../ui/Field';

const { Option } = Select;
interface FieldDesc {
  name: string;
  label: string;
  type?: string;
  textType?: {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  selectType?: {
    handleChange: (
      values: string,
      ev: any,
      form: any,
      data: { name: string; key: number; fieldName: string },
    ) => void;
    list: { name: string; code: string }[];
  };
  reachTextType?: {
    handleChange: (
      values: string,
      ev: any,
      form: any,
      data: { name: string; key: number; fieldName: string },
    ) => void;
  };
}
interface Props {
  name: string;
  fields: ISubObject[];
  fieldsDesc: FieldDesc[];
  handleAddClick: VoidFunction;
  handleRmClick: (name: string, id: number) => void;
  className?: string;
}
export const DuplicateField = (props: Props): JSX.Element => {
  const { name, fields, fieldsDesc, handleAddClick, handleRmClick, ...rest } =
    props;
  const capitalize = (str: string): string =>
    str[0].toUpperCase() + str.slice(1);

  const typeTextInit = (item: ISubObject, field: FieldDesc): JSX.Element => (
    <Field
      data-key={ item.id }
      data-name={ name }
      data-field-name={ field.name }
      key={ `${name}-${field.name}-${item.id}` }
      name={ `${name}[${item.id}][${field.name}]` }
      // @ts-ignore
      defaultValue={ item[field.name] }
      type={ field.type || 'text' }
      label={ field.label }
      onChange={ field.textType?.handleChange }
    />
  );
  const typeSelectInit = (item: ISubObject, field: FieldDesc): JSX.Element => (
    <Field
      key={ `${name}-${field.name}-${item.id}` }
      name={ `${name}[${item.id}][${field.name}]` }
      // @ts-ignore
      defaultValue={ item[field.name] }
      label={ field.label }
      type={ field.type || 'text' }
      onChange={ (values: string, ev: any, form: any): void =>
        field.selectType?.handleChange(values, ev, form, {
          name,
          key: item.id,
          fieldName: field.name,
        })
      }
    >
      <Select mode="tags" maxTagCount={ 1 }>
        { /* <Select> */ }
        { field.selectType?.list.map((lang) => (
          <Option key={ lang.name }>{ `${lang.name} (${lang.code})` }</Option>
        )) }
      </Select>
    </Field>
  );

  const typeReachTextInit = (
    item: ISubObject,
    field: FieldDesc,
  ): JSX.Element => (
    <div
      className='ant-col ant-form-item-control-wrapper'
      key={ `${name}-${field.name}-${item.id}-div` }
    >
      <div className='ant-col ant-form-item-label'>
        <label
          htmlFor={ `${name}-${field.name}-${item.id}` }
          className='ant-form-item-no-colon'
          title={ field.label }
        >
          { field.label }
        </label>
      </div>

      <ReactQuill
        key={ `${name}-${field.name}-${item.id}` }
        onChange={ (values: string, ev: any, form: any): void =>
          field.reachTextType?.handleChange(values, ev, form, {
            name,
            key: item.id,
            fieldName: field.name,
          })
        }
        theme='snow'
        // @ts-ignore
        defaultValue={ item[field.name] }
      />
    </div>
  );

  return (
    <div className={ rest.className }>
      { fields.map((item, index) => {
        const nameCount = index > 0 ? ` (${index})` : '';
        return (
          <Card
            className='mb-10'
            key={ `${name}-card-${item.id}` }
            size='small'
            title={ `${capitalize(name)} ${nameCount}` }
            extra={
              <Button
                key={ `${name}-btn-${item.id}` }
                type='danger'
                onClick={ (): void => handleRmClick(name, item.id) }
              >
                Del-
              </Button>
            }
          >
            { fieldsDesc.map((field: FieldDesc) => {
              let res: any;
              if (field.textType !== undefined) {
                res = typeTextInit(item, field);
              } else if (field.selectType !== undefined) {
                res = typeSelectInit(item, field);
              } else if (field.reachTextType !== undefined) {
                res = typeReachTextInit(item, field);
              }
              return res;
            }) }
          </Card>
        );
      }) }
      <Button key={ `${name}-btn-add` } type='primary' onClick={ handleAddClick }>
        Add+
      </Button>
    </div>
  );
};

export default DuplicateField;
