import * as React from 'react';
import {
  Button,
  Col,
  notification,
  Row,
  Select,
  DatePicker
} from 'antd';
import moment from "moment";
import companyAPI from '../../API/company';
import usersAPI from '../../API/users';
import weekendsAPI from '../../API/weekends';
import From from '../../noui/Form';
import Field, {FormItem} from '../../ui/Field';
import appConfig from '../../configs/app';
import ImageUpload from '../../components/ImageUpload';
import Settings from '../../layouts/Settings';
import Logo from './Logo';
import errors from "../../API/error";
import dateConfig from "../../configs/date";
import ColorPicker from "../../components/ColorPicker";

const Option = Select.Option;

class ChangePassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      logo: '',
      logoFile: '',
      dataSource: [],
      defaultOwners: []
    }
  }

  componentDidMount() {
    this.getData();
  };

  getData = async () => {
    try {
      const {data} = await companyAPI.get();
      const resWeekends = await weekendsAPI.get({query: {limit: 100}});
      const {owners, dashboardWeekends, eventsColor, ...rest} = data;
      const defaultOwners = owners.map((u: any) => u._id);
      const defaultWeekends = dashboardWeekends.map((u: any) => u._id);
      this.setState({
        loading: false,
        data: rest,
        defaultOwners,
        defaultWeekends,
        dataSource: owners,
        weekendsData: resWeekends.data.list,
        eventsColor
      })
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load data'});
      this.setState({loading: false})
    }
  };

  handleChange = async (value: string) => {
    try {
      const res = await usersAPI.get({query: {search: value}});
      this.setState({dataSource: res.data.list});
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to load employees'});
    }
  };

  handleClearData = () => {
    this.handleChange('');
  };

  handleChangeFile = (file: any, image: string) =>
    this.setState({logo: image, logoFile: file});

  handleSubmit = async ({values, form}: any) => {
    try {
      const {birthday, owners, dashboardWeekends = []} = values;
      const {logoFile, eventsColor} = this.state;

      const fd: FormData = new FormData();
      fd.append('logo', logoFile);

      const allowedKeys = [
        'name'
      ];
      Object.entries(values).forEach(([key, value]) => {
        if (allowedKeys.includes(key) && value) {
          fd.append(key, value as any);
        }
      });

      if (moment(birthday).isAfter(new Date())) {
        return errors.setFormErrors(form, {birthday: 'Invalid date. Can\'t be after now'});
      }
      fd.append('eventsColor', eventsColor || '');
      if (birthday) {
        fd.append('birthday', moment(birthday).format('YYYY-MM-DD'))
      }

      if (owners && owners.length > 0) {
        fd.append('owners', JSON.stringify(owners));
      }
      if (dashboardWeekends && dashboardWeekends.length > 0) {
        fd.append('dashboardWeekends', JSON.stringify(dashboardWeekends));
      }
      await companyAPI.update(fd);
      notification.success({
        message: 'Success'
      });
    } catch (e) {
      errors.handle(e, form, {priority: 'all', defaultMessage: 'Failed'});
    }
  };

  handleChangeColor = (eventsColor: string) => {
    this.setState({eventsColor});
  };

  render() {
    const {
      dataSource,
      weekendsData,
      loading,
      data,
      logo,
      eventsColor,
      defaultOwners,
      defaultWeekends
    } = this.state;
    return <Settings location={this.props.location}>
      {
        !loading ? <Row className="mt-80">
            <Col md={{span: 12, offset: 6}} sm={{span: 24}}>
              <From onSubmit={this.handleSubmit}>
                <ImageUpload className="logo-container-wrapper mb-20"
                             isImage={true}
                             dragAndDrop={true}
                             onChange={this.handleChangeFile}>{
                  (propsItem: any) =>
                    <Logo url={logo ? logo : data.logo ? `${appConfig.staticFolder}${data.logo}?width=200` : ``}
                          {...propsItem} />
                }</ImageUpload>
                <Field name="name"
                       label="Company name"
                       validation="required"
                       defaultValue={data.name || undefined} />
                <Field name="owners"
                       label="Company owners"
                       defaultValue={defaultOwners || undefined}
                       onFocus={this.handleClearData}
                       onSearch={this.handleChange}
                       onChange={this.handleClearData}>
                  <Select showSearch={true}
                          mode="tags"
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={null}>
                    {
                      dataSource.map((data: any) =>
                        <Option key={data._id} value={data._id}>{data.firstName || ''} {data.lastName || ''}</Option>)
                    }
                  </Select>
                </Field>
                <Field name="birthday"
                       label="Company establishment date"
                       defaultValue={data.birthday ? moment(data.birthday) : undefined}>
                  <DatePicker format={dateConfig.formatHolidayDatePicker} />
                </Field>
                <Field name="dashboardWeekends"
                       label="Absence types on the dashboard"
                       defaultValue={defaultWeekends || undefined}>
                  <Select mode="tags"
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          notFoundContent={null}>
                    {
                      weekendsData.map((data: any) =>
                        <Option key={data._id} value={data._id}>{data.name}</Option>)
                    }
                  </Select>
                </Field>
                <FormItem label="Calendar events color">
                  <ColorPicker onChange={this.handleChangeColor}
                               color={eventsColor} />
                </FormItem>
                <Row type="flex" justify='end'>
                  <Button className="ant-btn-primary"
                          htmlType="submit">Save</Button>
                </Row>
              </From>
            </Col>
          </Row>
          : null
      }
    </Settings>
  }
}

export default ChangePassword