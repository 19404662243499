import history from '../configs/history';

const modals: any = {};


interface ISet {
  hash: string,
  component: any,
  props?: object,
  config?: object
  onChangeImage?: any
  onChangePermissions?: any
}

/*
 * @description set new modal item
 * @param {String} name - unique modal name by capitalise
 * @param {Object} params
 * @param {Element} params.component
 * @param {String} params.hash
 * */
export const set = (name: string, params: ISet) => {
  if (!name || !params || !params.hash || !params.component) {
    return console.log("You can't set modal. Missing required parameters.")
  } else if (modals[name]) {
    return false;
  }
  if (!params.props) {
    params.props = {};
  }
  if (!params.config) {
    params.config = {};
  }
  modals[name] = params;
};

/*
 * @description Set props to Modal component
 * e.g. onSubmit action to event
 * */
export const setProps = (name: string, props?: object) => {
  if (props) {
    modals[name].propsItem = props;
  } else {
    modals[name].propsItem = {};
  }
};

/*
 * @description Set props to Modal component
 * e.g. onSubmit action to event
 * */
export const changeConfig = (name: string, config: object) => {
  if (modals[name]) {
    modals[name].config = {...modals[name].config, ...config};
  }
};

/*
 * @description Remove modal
 * */
export const remove = (name: string) => {
  if (modals[name]) {
    delete modals[name];
  }
  return true
};

export const open = (name: string) => {
  if (modals[name]) {
    return history.push({...history.location, hash: modals[name].hash})
  }
  return false
};

/*
* @description get modal by name
* */
export const get = (name: string): any => {
  if (modals.hasOwnProperty(name)) {
    return modals[name]
  }
  return false
};

export const change = (name: string, config: any) => {
  if (modals[name]) {
    modals[name].config = {...modals[name].config, ...config};
  }
};

export const getList = (): object => modals;

export const initConfig = {
  // params to Modal component
  params: {},
  visible: false,
  name: '',
};