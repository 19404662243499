import * as React from 'react';
import {Menu, Icon, Dropdown, Popconfirm, Col, Row} from "antd";
import {IActions, IMenuClickParams, IActionItem, IActionsList, TActions} from "./interfaces";
import history from '../../configs/history';
import './styles.scss'

export * from './interfaces';

const MenuItem = Menu.Item;

type TSetActive = (visible: boolean) => any;

const handleDotsClick = ({actions, record}: IActions, setActive: TSetActive) => {
  return ({key, domEvent}: IMenuClickParams) => {
    const idx = Number(key);

    if (typeof actions === 'function') {
      return;
    }
    const item = actions[idx];

    if (item.confirm) {
      return false
    }
    if (item && item.action) {
      const {action, disablePropagation} = actions[idx];
      action && action(record);
      if (disablePropagation) {
        setActive && setActive(false);
        return;
      }

    } else if (item && item.link) {
      history.push(item.link)
    }
    domEvent.stopPropagation();
  };
};

const Dots = (actions: IActionsList | TActions, record: any, setActive: TSetActive) => {
  let acts = actions;
  if (typeof actions === 'function') {
    acts = actions(record);
  }
  return <Menu onClick={handleDotsClick({actions, record}, setActive)}>
    {
      Array.isArray(acts) ? acts.map((i: IActionItem, index: number) =>
        <MenuItem key={String(index)} className={i.confirm ? 'confirm-point-action' : ''}>
          {
            i.confirm
              ? <Popconfirm placement="left"
                            title={typeof i.confirm === 'string' ? i.confirm : "Are you sure?"}
                            onVisibleChange={(visible: boolean) => {
                              if (!visible) {
                                setActive.bind(null, false)
                              }
                            }}
                            onConfirm={i.action || i.link ? () => {
                              if (i.action) {
                                i.action.call(null, record);
                              } else if (i.link) {
                                history.push(i.link)
                              }
                              setActive(false)
                            } : undefined}
                            onCancel={() => setActive(false)}
                            okText={i.confirmLabel || 'Yes'}
                            cancelText={i.cancelLabel || 'No'}>{i.name}</Popconfirm>
              : i.name
          }
        </MenuItem>) : null
    }
  </Menu>
    ;
};

const DotMenu = ({actions, record, children, placement}: IActions) => {
  const {useState} = React;
  const [active, setActive] = useState(false);
  /**
   * Filters actions according the available roles
   * */

  let _actions: IActionsList = [];
  if (typeof actions === 'function') {
    _actions = actions(record);
  } else {
    _actions = actions
  }

  const act: IActionsList = _actions.filter((action: IActionItem) => {
    let valid = true;

    if (action.isVisible && !action.isVisible(record)) {
      valid = false
    }
    return valid
  });
  if (!act || !act.length) return null;

  return <Row type="flex"
              justify="end">
    <Col>
      <Dropdown overlay={Dots(act, record, setActive)}
                visible={active}
                onVisibleChange={(visible: boolean) => setActive(visible)}
                placement={placement || "bottomLeft"}
                trigger={['click']}>
        {
          children ? children : <div className="dot-actions-icon" onClick={() => setActive(true)}>
            <span className="dot-menu-icon cursor">
              <Icon type="ellipsis" />
            </span>
          </div>
        }
      </Dropdown>
    </Col>
  </Row>
};

export default DotMenu;