import * as modal from "../../../services/modal";
import Positions from "../Positions";

interface IProps {
  onSubmit: any
};

export const init = ({onSubmit}: IProps) => {
  modal.set('positions', {
    hash: 'positions',
    component: Positions,
    config: {
      title: 'Positions',
      okLabel: 'Save',
      onSubmit
    }
  });
};

export const open = (item: any = {}) => {
  modal.setProps('positions', item);
  modal.open('positions')
};

export const remove = () => {
  modal.remove('positions')
};

export default {
  init,
  open,
  remove
}