import React, { ReactElement } from "react";

interface IProps {
  text: string;
  icon: ReactElement;
  step: number;
}

const InsuranceCard = (props: IProps) => {
  const { text, icon, step } = props;
  return (
    <div className="absence-card insurance_item__container">
      <div className="insurance_item__tittle"> Step {step} </div>
      <div className="insurance_item__text">{text}</div>
      <div className="insurance_item__icon_container">{icon}</div>
    </div>
  );
};
export default InsuranceCard;
