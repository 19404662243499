import React from "react";
import './styles.scss'
import {Scrollbars} from "react-custom-scrollbars";
import moment, {Moment} from "moment";
import ItemInfo from "./ItemInfo";
import userRoutes from "../../../routes/user";
import configsApp from "../../../configs/app";
import DefaultEventIconSvg from "../../../assets/icons/defaultEventIcon";
import TodayEmpty from "../../../assets/icons/todayEmpty";
import eventTitle from "../../../services/eventChecker";
import statisticsAPI from "../../../API/statistics";
import requestsAPI from "../../../API/requests";
import holidaysAPI from "../../../API/holidays";
import {connect} from "react-redux";

const CLASS_NAME = 'today';

export default connect(({
                          user
                        }: any) =>
  ({
    user
  }))(class Today extends React.Component<any, any> {
  state = {
    loading: true,
    requests: [],
    events: [],
    birthday: [],
    anniversary: [],
    date: moment()
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const {date} = this.state;
      const query: any = {query: {limit: 100, from: date.format('YYYY-MM-DD'), to: date.format('YYYY-MM-DD')}};

      const {
        data: {list: birthday = []}
      } = await statisticsAPI.get(query);
      const {
        data: {list: anniversary = []}
      } = await statisticsAPI.getHired(query);
      const {
        data: {list: requests = []}
      } = await requestsAPI.getAll(query);
      const {
        data: {list: events = []}
      } = await holidaysAPI.get(query);

      this.setState({
        birthday,
        anniversary,
        requests,
        events,
        loading: false
      });
    } catch (e) {
      return false
    }
  };

  parseBirthdays = (birthdays: any[], date: Moment) => {
    const items: any[] = [];
    const arr: Array<any> = [];
    if (birthdays) {
      birthdays.forEach(({_id, firstName, lastName, avatar, birthday}) => {
        const yearsGap = Math.round(
          Math.abs(date.startOf('day').diff(birthday, 'year', true))
        );
        arr.push({
          id: `${_id}_Birthday ${yearsGap}`,
          redirectTo: userRoutes.employeeProfile.path,
          redirectId: _id,
          avatar,
          firstName,
          lastName,
          description: `Birthday`,
          bpColor: '#FFA939',
        })
      });
      items.push(...(arr.sort((a, b) => (
        (a.firstName || '').localeCompare(b.firstName) || (a.lastName || '').localeCompare(b.lastName))
      )));
    }
    return items
  };

  parseRequests = (requests?: any[]) => {
    const items: any[] = [];
    if (Array.isArray(requests)) {
      const arr: Array<any> = [];
      requests.forEach(({
                          _id: id, user: {_id: redirectId, firstName, lastName, avatar},
                          weekend: {color: bpColor, name: description}
                        }) => {
        arr.push({
          id,
          redirectTo: userRoutes.employeeProfile.path,
          redirectId, firstName, lastName, avatar, bpColor, description
        });
      });
      items.push(...(arr.sort((a, b) => (
        (a.firstName || '').localeCompare(b.firstName) || (a.lastName || '').localeCompare(b.lastName))
      )));
    }
    return items;
  };

  parseAnniversary = (anniversary: any[], date: Moment) => {
    const items: any[] = [];
    if (Array.isArray(anniversary)) {
      const arr: Array<any> = [];
      anniversary.forEach(({
                             _id,
                             firstName,
                             lastName,
                             avatar,
                             anniversaryDate
                           }) => {
        const yearsGap = Math.round(Math.abs(
          date.startOf('day').diff(anniversaryDate, 'year', true))
        );
        arr.push({
          id: `${_id}_Anniversary_${yearsGap}`,
          firstName,
          lastName,
          avatar,
          redirectTo: userRoutes.employeeProfile.path,
          redirectId: _id,
          description: yearsGap === 0 ? 'First day at devabit'
            : `Anniversary (${yearsGap} year${Math.abs(yearsGap) === 1 ? '' : 's'} at devabit)`,
          bpColor: '#FFA939',
        })
      });
      items.push(...(arr.sort((a, b) => (
        (a.firstName || '').localeCompare(b.firstName) || (a.lastName || '').localeCompare(b.lastName))
      )));
    }
    return items
  };

  parseEvents = (events: any[]) => {
    const items: any[] = [];
    const {company: {eventsColor = configsApp.defaultEventsColor}} = this.props.user;
    if (Array.isArray(events)) {
      const arr: Array<any> = [];
      events.forEach(({_id, name, image, isHoliday = false, isRestDay = false}:
                        { _id: string, name: string, description: string, image: string, isHoliday: boolean, isRestDay: boolean }) => {
        arr.push({
          id: _id,
          redirectTo: userRoutes.singleEvent.path,
          redirectId: _id,
          bpColor: eventsColor,
          firstName: name,
          avatar: image,
          defaultAvatar: !image && <DefaultEventIconSvg />,
          lastName: '',
          description: eventTitle(isHoliday, isRestDay),
          descriptionColor: isRestDay ? '#C30B2F' : isHoliday ? '#0092D4' : '#0092D4'
        });
      });
      items.push(...(arr.sort((a, b) => (
        (a.firstName || '').localeCompare(b.firstName) || (a.lastName || '').localeCompare(b.lastName))
      )));
    }
    return items
  };

  renderRequests = () => {
    const {
      requests = [],
      events = [],
      birthday = [],
      anniversary = []
    } = this.state;

    const {date} = this.state;
    const dateMoment = moment(date);

    const res: any[] = [
      ...this.parseRequests(requests),
      ...this.parseBirthdays(birthday, dateMoment),
      ...this.parseAnniversary(anniversary, dateMoment),
      ...this.parseEvents(events)
    ];

    return (
      <div>
        <div>
          {res.map((el, idx: number) => <ItemInfo {...el} key={idx} />)}
        </div>
      </div>
    )
  };

  render() {
    const {
      requests,
      events,
      anniversary,
      birthday,
      date,
      loading
    } = this.state;

    const hasData = Boolean((requests && requests.length) || (events && events.length) || (anniversary && anniversary.length) || (birthday && birthday.length));

    return <div className={`${CLASS_NAME}${!loading ? ` is-active` : ''} pt-50`}>
      <h2 className="page-header">{date.format('dddd D MMM, YYYY')}</h2>
      <div className={`${CLASS_NAME}-head`}>

      </div>
      <Scrollbars>
        <div className={`${CLASS_NAME}-content`}>
          {hasData ? this.renderRequests() : <div className={`${CLASS_NAME}-empty`}>
            <TodayEmpty />
            <h3 className="title">Oops!</h3>
            <p className="description">Nothing for the day</p>
          </div>}
        </div>
      </Scrollbars>
    </div>
  }
})