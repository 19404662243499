import * as React from "react";
import TablePagination from "../../services/tablePagination";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Settings from "../../layouts/Settings";
import invitationsAPI from "../../API/invitations";
import columns from "./columns";
import "./styles.scss";

class Invitations extends React.Component<any, any> {
  private readonly pagination: TablePagination;

  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      loading: true,
    };
    this.pagination = new TablePagination({
      pageSize: 20,
      updateOnChange: true,
      includeOnChange: true,
      callback: this.getData,
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const {
        data: { list, pagination },
      } = await invitationsAPI.get({
        query: { ...this.pagination.requestParams() },
      });
      this.pagination.value = pagination;

      this.setState({
        list,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleColumnSort = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: [] }
  ) => {
    this.pagination.tableChange(pagination, filters, sorter, extra);
    this.getData();
  };

  render() {
    const { loading, list } = this.state;
    return (
      <Settings location={this.props.location}>
        <Table
          dataSource={list}
          rowKey="_id"
          clickable
          onRow={(record: any) => ({
            onClick: () => {
              const { _id } = record;
              this.props.history.push(`/settings/invitations/${_id}`);
            },
          })}
          columns={[...columns]}
          loading={loading}
          onChange={this.handleColumnSort}
          pagination={this.pagination.tableConfig}
        />
        <Pagination {...this.pagination.config} />
      </Settings>
    );
  }
}

export default Invitations;
