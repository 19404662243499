import * as React from 'react';
import Points from '../../components/Points';

const getPointActions = (record: any, actions: IActions) => [
  {
    name: 'Edit',
    record,
    onClick: actions.onEdit.bind(null, record)
  },
  {
    name: 'Delete',
    onClick: actions.onRemove.bind(null, record)
  }
];

interface IActions {
  onEdit: any,
  onRemove: any
}

interface IProps {
  actions: IActions
}

export default ({actions}: IProps) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '',
    key: 'actions',
    render: (text: any, record: any) =>
      <Points actions={getPointActions(record, actions)}/>
  }
]