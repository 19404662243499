import * as React from 'react';
import {Redirect} from 'react-router-dom';
import settingsRoutes from '../../routes/settings';
import {connect} from "react-redux";


export default connect(({user}: any) => ({user}))((props: any): JSX.Element => {
  const {companyAccess = {}} = props.user;
  let path = '/';
  if (companyAccess.settings) {
    path = settingsRoutes.settingsCompany.path;
  } else if (companyAccess.wifiConnections) {
    path = settingsRoutes.wifi.path;
  }

  console.log(path)

  return <Redirect to={path} />
})