import history from '../configs/history'
import * as qs from 'query-string';
import * as validate from 'validate.js';
/**
 * Pagination functionality for each antd tables
 */
interface ITableProps {
  limit: number
  total: number
  offset?: number
  order?: string
  sort?: string
  [key: string]: string | number | undefined
}

interface IValueParams {
  limit?: number
  total?: number
  offset?: number
  order?: string
  sort?: string
  [key: string]: string | number | undefined
}

interface ITableConfig {
  pageSize: number
  current: number
  total: number
}

interface IDefaultTableConfig extends ITableConfig {
  hideOnSinglePage: boolean
  defaultCurrent: number
  defaultPageSize: number
  onChange?: ((page: number, pageSize?: number | undefined) => void)
}

interface IReturnedValue extends ITableProps {
  page: number
  pages: number
}

interface IRequest {
  limit: number
  offset: number
  sort?: string
  order?: string,
  [key: string]: string|number|boolean|undefined
}

interface IAllowedQueryParam {
  type: 'array'
}

interface IConstructor {
  pageSize?: number,
  // keys to be used in the request
  allowedFiltersKeys?: {
    [key: string]: true | {
      default?: string|boolean|number
    }
  },
  // function to update data
  callback?: Function
  // generate onChange to the pagination
  includeOnChange?: boolean
  // update data after page has been changed
  updateOnChange?: boolean

  // set parameters to query if value changes
  updateQueryOnChange?: boolean

  // which keys to be synchronised with query parameters
  allowedQueryParameters?: {
    [key: string]: boolean|undefined|IAllowedQueryParam
  }
}

export default class PaginationConfig {
  private readonly getDataCallback?: Function;
  private readonly allowedFiltersKeys?: IConstructor['allowedFiltersKeys'];
  private readonly includeOnChange?: IConstructor['includeOnChange'];
  private readonly updateOnChange?: IConstructor['updateOnChange'];
  private readonly updateQueryOnChange?: IConstructor['updateQueryOnChange'];
  private readonly allowedQueryParameters?: IConstructor['allowedQueryParameters'];
  private firstSet = true;
  private defaultPageSize = 10;
  private paginationConf: ITableProps = {
    limit: this.defaultPageSize,
    total: 0,
    offset: 0,
    order: ''
  };
  private pageCurrent: number = 1;
  private pages: number = 0;

  constructor(props: IConstructor = {}) {
    if (props.pageSize) {
      this.paginationConf.limit = props.pageSize;
    }
    this.getDataCallback = props.callback;
    this.allowedFiltersKeys = props.allowedFiltersKeys || {};
    this.includeOnChange = props.includeOnChange;
    this.updateOnChange = props.updateOnChange
    this.updateQueryOnChange = props.updateQueryOnChange
    this.allowedQueryParameters = props.allowedQueryParameters
  }

  /**
   * change page under the table
   */
  set page(page: number) {
    this.pageCurrent = page;
    this.paginationConf.offset = (page - 1) * this.paginationConf.limit;
    this.updateData();
  }

  private async updateData() {
    if (this.updateOnChange && this.getDataCallback) {
      await this.getDataCallback();
      await this.updateQueryStr()
    }
  }

  private set pageNumb(params: IValueParams) {
    const {offset = 0, limit = this.defaultPageSize} = params;
    if ((params && params.hasOwnProperty && params.hasOwnProperty('offset') && params.hasOwnProperty('limit')) || limit) {
      this.pageCurrent = parseInt((+offset / +limit).toFixed(0), 10) + 1;
    }
  }

  private updateQueryStr() {
    if (this.updateQueryOnChange) {
      let queryParameters = this.getQueryParams();
      if (!validate.isEmpty(queryParameters)) {
        Object.entries(queryParameters).forEach(([key, value]) => {
          if (this.allowedQueryParameters && this.allowedQueryParameters[key]
            && (this.allowedQueryParameters[key] as any).type === 'array') {
            if ((value as any).length > 0) {
              queryParameters[key] = JSON.stringify(value)
            } else {
              delete queryParameters[key]
            }

          }
        });

        history.push({...history.location, search: qs.stringify(queryParameters)})
      }
    }
  };

  private getQueryParams() {
    const filtersKeys = Object.keys(this.allowedQueryParameters || {});
    if (!filtersKeys.length) {
      return {}
    }
    const queryParameters: { [key: string]: string | number | boolean } = {};
    filtersKeys.forEach((key: string) => {
      if (this.allowedQueryParameters && this.allowedQueryParameters[key] && this.paginationConf[key] !== undefined && this.paginationConf[key] !== '') {
        queryParameters[key] = this.paginationConf[key] as string;
      }
    });
    return queryParameters
  }

  get queryParameters() {
    return this.getQueryParams();
  }

  get paginationVisible(): boolean {
    return this.paginationConf.total > this.paginationConf.limit
  }

  /**
   * Set params from query and use in the future requests
   * */
  set query(str: string) {
    const data = qs.parse(str);
    try {
      if (this.allowedQueryParameters) {
        const dataValue = Object.entries(this.allowedQueryParameters);
        for (let i = 0; i < dataValue.length; i++) {
          const [key, value] = dataValue[i];
          if (data[key] && value) {
            if (typeof value === 'object' && value.type === 'array') {
              this.paginationConf[key] = JSON.parse(data[key] as string);
            } else {
              this.paginationConf[key] = /^[\d]+$/.test(data[key] as string) ? parseInt(data[key] as string) : data[key] as string
            }
          } else if (!['offset', 'limit'].includes(key)) {
            delete this.paginationConf[key]
          }
        }
        this.pageNumb = (data || {}) as any;
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Set pagination obj from API
   */
  set value(params: IValueParams) {
    this.pageNumb = params;

    const setData: { [key: string]: string | number } = {};
    Object.entries({
      limit: true,
      total: true,
      offset: true,
      order: true,
      sort: true,
      ...this.allowedFiltersKeys,
    }).forEach(([key]) => {
      if (params && params.hasOwnProperty(key) && params[key] !== undefined) {
        setData[key] = params[key] as string | number
      }
    });

    this.paginationConf = {...this.paginationConf, ...setData};
    this.handleEmptyPageReq();

    this.firstSet = false;
  }

  get filters() {
    if (!this.allowedFiltersKeys) {
      return {}
    }
    const filters: any = {};
    Object.entries(this.allowedFiltersKeys).forEach(([key]) => {
      if (this.paginationConf.hasOwnProperty(key)) {
        filters[key] = this.paginationConf[key]
      }
    });
    return filters
  }

  set valueAndUpdate(params: IValueParams) {
    this.value = params;
    this.updateData();
  }

  private handleEmptyPageReq() {
    if (!this.updateOnChange || !this.getDataCallback) {
      return
    }
    const {total, offset} = this.paginationConf;

    if (offset && total > 0 && offset > 0 && total <= offset) {
      this.paginationConf.offset = 0;
      this.page = 1;
    } else if (offset && offset > 0 && !total) {
      this.paginationConf.offset = 0;
      this.paginationConf.total = 0;
      this.page = 1;
    }
  };

  set unlink(key: string) {
    if (this.paginationConf.hasOwnProperty(key)) {
      delete this.paginationConf[key]
    }
  }

  /**
   * @return parsed pagination config with page and pages
   */
  get pagination(): IReturnedValue {
    return {
      ...this.paginationConf,
      page: this.pageCurrent,
      pages: this.pages
    };
  }

  private get getReturnedConfig() {
    const {
      limit
    } = this.paginationConf;
    return {
      hideOnSinglePage: true,
      defaultCurrent: this.paginationConf.limit,
      current: this.pageCurrent,
      defaultPageSize: this.paginationConf.limit,
      pageSize: limit,
    }
  }

  get tableConfig(): IDefaultTableConfig {
    const {
      limit
    } = this.paginationConf;

    return {
      ...this.getReturnedConfig,
      total: limit
    }
  }

  get config(): IDefaultTableConfig {
    const {
      total
    } = this.paginationConf;

    const params: IDefaultTableConfig = {
      ...this.getReturnedConfig,
      total,
      current: this.pageCurrent
    };

    if (this.includeOnChange) {
      params.onChange = (page: number) =>
        this.page = page;
    }

    return params
  }

  public tableChange(pagination: object,
                     filters: object,
                     sorter: { order: string, field: string | Array<string> },
                     extra: { currentDataSource: [] }) {
    this.paginationConf.order = ((sorter.order || '').match(/(asc)|(desc)/) || [])[0];
    this.paginationConf.sort = this.paginationConf.order
      && ((Array.isArray(sorter.field) ? sorter.field[0] : sorter.field) || '');

    this.updateData();
  };

  /**
   * will execute when page changed page
   * @return requested parameters to get next data
   */
  public requestParams(): IRequest {
    const {limit, sort, order, offset = 0} = this.paginationConf;
    const reqParams: IRequest = {
      limit,
      offset
    };
    if (sort) {
      reqParams.sort = sort;
    }
    if (order) {
      reqParams.order = order;
    }
   
    // sets additional parameters to be used in filters
    if (this.allowedFiltersKeys) {
      Object.entries(this.allowedFiltersKeys).forEach(([key, value]) => {
        if (!this.paginationConf.hasOwnProperty(key)) {
          if (typeof value !== "boolean" && value.default) {
            reqParams[key] = value.default
          }
          return;
        } else if (Array.isArray(this.paginationConf[key]) && (this.paginationConf[key] as any).length > 0) {
          reqParams[key] = JSON.stringify(this.paginationConf[key])
        } else if (typeof value === "boolean" && this.paginationConf[key] !== '') {
          reqParams[key] = this.paginationConf[key]
        } else if (typeof value === "object" && this.paginationConf[key] !== '') {
          reqParams[key] = this.paginationConf[key]
        }
      });
    }
    return reqParams;
  }
}