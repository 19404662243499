import React from "react";
import { Group, Image, Rect, Text } from "react-konva";
import onCloseSvg from "../../../../../assets/icons/svg/Vector.svg";
import appConfig from "../../../../../configs/app";
import IUser from "src/interfaces/user";
import { BOOKED_BY_MODAL_HEIGHT, BOOKED_BY_MODAL_WIDTH } from "../const";

interface ICordinates {
  x: number;
  y: number;
}
interface IProps {
  userData: IUser;
  bookingByModalCoordinates: ICordinates;
  handleOnCloseModal: () => void;
  width: number
}
interface IState {
  avatar: HTMLImageElement;
  closeSvg: HTMLImageElement;
}

class BookedByModal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      avatar: new window.Image(),
      closeSvg: new window.Image(),
    };
  }
  componentDidMount(): void {
    const closeSvg = new window.Image();
    closeSvg.src = onCloseSvg;
    closeSvg.onload = () => {
      this.setState({
        closeSvg: closeSvg,
      });
    };
    const avatar = new window.Image();
    const { avatar: avatarSrc } = this.props.userData;
    avatar.src = appConfig.staticFolder + avatarSrc;

    avatar.onload = () => {
      this.setState({
        avatar: avatar,
      });
    };
  }
  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (prevProps.userData._id !== this.props.userData._id) {
      const avatar = new window.Image();
      const { avatar: avatarSrc } = this.props.userData;
      avatar.src = appConfig.staticFolder + avatarSrc;

      avatar.onload = () => {
        this.setState({
          avatar: avatar,
        });
      };
    }
  }
  render() {
    const { handleOnCloseModal } = this.props;
    const { x, y } = this.props.bookingByModalCoordinates;
    const { closeSvg, avatar } = this.state;
    const { firstName, lastName, positions } = this.props.userData;
   



    function calcClipFunc(
      ctx: any,
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number
    ) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    }

    return (
      <Group x={x} y={y} >
        <Rect
          width={BOOKED_BY_MODAL_WIDTH}
          height={BOOKED_BY_MODAL_HEIGHT}
          shadowColor={"black"}
          fill={"white"}
          cornerRadius={[2, 2, 2, 2]}
          shadowOffsetX={2}
          shadowBlur={10}
          shadowOffsetY={2}
        />
        <Rect
          width={BOOKED_BY_MODAL_WIDTH}
          cornerRadius={[2, 2, 0, 0]}
          height={24}
          fill="#E8E8E8"
        />
        <Text
          x={10}
          height={24}
          fontFamily={"Roboto"}
          fontSize={10}
          fontStyle={"bold"}
          verticalAlign="middle"
          fill="#5F5F5F"
          text="BOOKED BY"
        />

        <Image
          x={140}
          y={5}
          image={closeSvg}
          onClick={handleOnCloseModal}
          onTap={handleOnCloseModal}
        />
        <Group
          clipFunc={(ctx: any) => calcClipFunc(ctx, 10, 35, 32, 32, 16)}
          draggable
        >
          <Image x={10} y={35} width={32} height={32} image={avatar} />
        </Group>
        <Text
          y={43}
          fontStyle={"bold"}
          fontFamily={"Roboto"}
          x={55}
          fontSize={12}
          fill="#5F5F5F"
          text={firstName + " " + lastName}
        />
        {positions && (
          <Text
            y={75}
            x={10}
            fontFamily="Roboto"
            fontSize={12}
            fill="#5F5F5F"
            text={positions[0].name}
          />
        )}
      </Group>
    );
  }
}
export default BookedByModal;
