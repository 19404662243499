import * as React from 'react';
import {connect} from "react-redux";
import {NavLink} from 'react-router-dom';


const CustomNavLink = ({
                         user,
                         params,
                         roles,
                         dispatch,
                         to,
                         isVisible,
                         ...rest
                       }: any): JSX.Element | null => {
  const {role: userRole} = user;
  if ((roles instanceof Array && roles.indexOf(userRole) === -1)
    || (typeof isVisible === 'function' && !isVisible(user))) {
    return null;
  }
  const keys = to.match(/(:[\da-zA-Z_()[\]+\\]+)/gm);
  if (keys && params) {
    keys.forEach((key: string) => {
      const parsedKey = key.replace(/:|\([\w\d+[\]]+\)/gm, '');
      if (params.hasOwnProperty(parsedKey)) {
        to = to.replace(key, params[parsedKey])
      }
    });
  }
  return <NavLink {...rest} to={to} />
};

export default connect(({user}: any) =>
  ({user}), null, null, {pure: false})(CustomNavLink)
