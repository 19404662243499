import store from '../../configs/store';
import {SET_APPROVAL_COUNT} from '../../reducers/actions';

export const set = (payload: number) => {
  store.dispatch({
    payload,
    type: SET_APPROVAL_COUNT
  })
};

export default {
  set,
}