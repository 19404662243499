import * as modal from "../../../services/modal";
import CalendarGroup from "../CalendarGroup";

interface IProps {
  onSubmit: any
}

export const init = ({onSubmit}: IProps) => {
  modal.set('calendarGroup', {
    hash: 'calendar-group',
    component: CalendarGroup,
    config: {
      title: 'Business Unit',
      okLabel: 'Save',
      onSubmit
    }
  });
};

export const open = (item: any = {}) => {
  modal.setProps('calendarGroup', item);
  modal.open('calendarGroup')
};

export const remove = () => {
  modal.remove('calendarGroup')
};

export default {
  init,
  open,
  remove
}