import React from "react";
import {Col, DatePicker, Row} from "antd";
import {Groups, Positions, Buttons, Teams, Gender, Project, LanguageLevel, LanguageName} from "../../components/Filters";
import Some from "../../noui/Permissions/Some";
import Field from "../../ui/Field";
// import moment from "moment";
const {RangePicker} = DatePicker;

type TValue = string

interface Props {
  defaultValue?: {
    [name: string]: TValue[]
  }
}

export default ({
  defaultValue = {}
                }: Props) => <Row gutter={[20, 8]} className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col md={{span: 24}}>
    <Row gutter={[8, 8]}>
      <Positions col={{md: {span: 5}}} defaultValue={defaultValue.positions} />
      <Some permissions={['settings', 'filters']}>
        <Groups col={{md: {span: 5}}} defaultValue={defaultValue.groups}/>
        <Teams col={{md: {span: 5}}} defaultValue={defaultValue.teams} />
        <Col md={{span: 9}}>
          <Field
            name="date"
            label="Date range"
            // defaultValue={defaultValue.date.length > 0 ? defaultValue.date : [moment.utc().startOf('year'), moment.utc().endOf('year')]}
          >
            <RangePicker format={'DD/MM/YYYY'}  />
          </Field>
        </Col>
      </Some>
      <Col span={24}>
        <Row gutter={[8, 8]}>
        <Gender col={{md: {span: 5}}} defaultValue={defaultValue.gender}/>
        <Project col={{md: {span: 5}}} defaultValue={defaultValue.project} />
        <LanguageName col={{md: {span: 5}}} defaultValue={defaultValue.languageName} />
        <LanguageLevel col={{md: {span: 5}}} defaultValue={defaultValue.languageLevel} />
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[10, 0]} type="flex" justify="end">
          <Col span={24}>
            <Buttons />
          </Col>
        </Row>
      </Col>
    </Row>
  </Col>
</Row>
