import React from "react";
import Avatar from "../../components/Avatar";
import {Input} from "antd";
import {IEntitlementItem, IUserData, IUserWeekend, IWeekendData} from "../../interfaces";

interface IParams {
    weekends: Array<IWeekendData>,
    onAbsenceChange: (val: number, weekend: IUserWeekend) => void
}

const columns = (params: IParams) => {
    const {weekends = [], onAbsenceChange = () => {}} = params;
    return [
        {
            title: '',
            key: 'avatar',
            width: '64px',
            render: (record: IEntitlementItem) => {
                return <Avatar style={{padding: 0, margin: 0}} user={record as IUserData} size={32} />
            }
        },
        {
            title: 'Name',
            key: 'firstName',
            sorter: true,
            render: (text:string, record: IEntitlementItem) => `${record.firstName} ${record.lastName}`
        },
        ...weekends.map((w: IWeekendData) => {
            return {
                title: w.name,
                key: w.name,
                render: (record: any) => {
                    const weekend = record.weekends.find((i: any) => {
                        const [we] = i.weekend;
                        return we ? we._id === w._id : false;
                    });
                    const handleChange = (e: any) => {
                        if (e.key === 'Enter' || e.type === 'blur') {
                            onAbsenceChange(e.target.value, weekend);
                        }
                    };

                    if (!weekend) return null;

                    if (weekend.weekend[0].unlimitedDays) return 'Unlimited';

                    return <Input type="number"
                               style={{width: '70px'}}
                               step={0.1}
                               defaultValue={weekend.days}
                               onKeyPress={handleChange}
                               onBlur={handleChange}
                               name={w.name} />;
                }
            }
        })
    ];
};

export default columns