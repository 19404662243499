import * as qs from 'query-string';
import history from '../configs/history';

interface IChangeFilter {
  [key: string]: string|number
}

interface IConstructor {
  keys: string[],
  queryKeys?: string[],
  defaultValues?: IChangeFilter
}

export default class Filters {
  private filtersList:IChangeFilter = {};
  private availableKeys: string[] = [];
  private queryKeys: string[] = [];

  constructor(props: IConstructor) {
    const {keys, defaultValues, queryKeys = []} = props;
    this.queryKeys = queryKeys;
    this.availableKeys = keys;
    if (defaultValues) {
      keys.forEach((key: string) => {
        if (defaultValues[key]) {
          this.filtersList[key] = defaultValues[key];
        }
      })
    }
  }

  public setToQuery() {
    const keysToBeSet = this.queryKeys.length
      ? this.queryKeys
      : this.availableKeys;
    const dataToSet: any = {};
    keysToBeSet.forEach((key: string) => {
      if (this.filtersList[key]) {
        dataToSet[key] = this.filtersList[key];
      }
    });
    const query = qs.stringify(dataToSet);
    history.push({...history.location, search: query});
  }

  /**
   * Set query parameters to instance
   */
  public setQueryParams() {
    const {search} = history.location;
    const list: any = qs.parse(search);
    this.availableKeys.forEach((key: string) => {
      if (list[key]) {
        this.filtersList[key] = list[key];
      }
    });
  }

  /**
   * Set list of filters
   * can be inserted to query and used in requests
   */
  set value(params: IChangeFilter) {
    const filtersToSet: any = {};
    Object.keys(params).forEach(key => {
      if (this.availableKeys.indexOf(key) !== -1) {
        filtersToSet[key] = params[key];
      }
    });
    this.filtersList = {...this.filtersList, ...filtersToSet}
  }

  /**
   * @return all filters list
   */
  get all() {
    return this.filtersList
  }
}