export default [
  {
    title: 'Network name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Network key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
  },
  {
    title: 'Password',
    dataIndex: 'password',
    key: 'password',
  },
];