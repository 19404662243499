import UserOfficeInfo from "../containers/WiFiConnections/List";
import CreateEdit from "../containers/WiFiConnections/CreateEdit";
import View from "../containers/WiFiConnections/View";


const BASE = `/settings/wi-fi`;

export default {
  wiFi: {
    component: UserOfficeInfo,
    path: BASE
  },
  wiFiCreate: {
    component: CreateEdit,
    path: `${BASE}/create`
  },
  wiFiEdit: {
    component: CreateEdit,
    path: `${BASE}/edit/:id`
  },
  wiFiView: {
    component: View,
    path: `${BASE}/edit/:id`
  },
  redirect: {
    redirect: BASE,
    path: `${BASE}/*`
  },
}