import React from "react";
import {Row, Col} from 'antd'
import moment from "moment";
import './styles.scss'
import appConfig from "../../../configs/app";
import eventRoutes from "../../../routes/user";
import NavLink from "../../../components/NavLink";
import eventTitle from "../../../services/eventChecker";

interface Props {
  [key: string]: any
}

export default ({
                  name,
                  from,
                  to,
                  image,
                  description,
                  ...rest
                }: Props) => {
  const momentFrom = moment.utc(from);
  const momentTo = moment.utc(to);
  const fullDate = momentFrom.isSame(momentTo)
    ? momentFrom.format('DD/MM/YYYY')
    : `${momentFrom.format('DD/MM/YYYY')} - ${momentTo.format('DD/MM/YYYY')}`;

  return <div
    className={`nfi-event js-nfi ${image ? 'nfi-event-has-image' : ''}`}
    data-id={rest.keyItem}>
    <Row gutter={20}>
      {
        image
          ? <Col md={{span: 11}}>
            <div className="nfi-event-img" style={{
              background: `url(${appConfig.staticFolder}${image}?width=500&height=300) no-repeat center center / cover`
            }} />
          </Col> : null
      }

      <Col md={{span: image ? 13 : 24}}>
        <div className="nfi-event-content">
          <div>
            <div className="nfi-user-name" title={name}>{name}</div>
            <div className="nfi-event-type"
                 style={rest.isRestDay ? {color: '#C30B2F'} : undefined}>{eventTitle(rest.isHoliday, rest.isRestDay)}</div>
            {
              description ? <div className="event-desc nfi-event-description">
                {description.length > 250 ? description.slice(0, 250) + "..." : description}
              </div> : null
            }
          </div>
          <Row type="flex" justify="space-between" className="nfi-event-footer">
            <div className="nfi-date">{fullDate}</div>
            <div className="nfi-date nfi-event-view-more">
                <NavLink to={eventRoutes.singleEvent.path} params={{id: rest._id}}>
                  View more
                </NavLink>
            </div>
          </Row>
        </div>
      </Col>
    </Row>
  </div>
}