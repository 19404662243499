import React from "react";
import Settings from "src/layouts/Settings";
import { Button, Row } from "antd";
import Table from "../../../components/Table";
import { generatePath } from "react-router-dom";
import routesTables from "../../../routes/tables";
import columns from "./columns";
import { IActionsList } from "../../../components/DotMenu";
import apiTableSector from "../../../API/tableSector";
import errors from "../../../API/error";

export default class Sectors extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      loading: true,
    };
  }
  handleEdit = ({ _id }: any) => {
    this.props.history.push(
      generatePath(routesTables.editSector.path, { id: _id })
    );
  };

  handleDelete = async ({ _id }: any) => {
    try {
      await apiTableSector.remove(null, { params: { id: _id } });
      await this.getData();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const {
        data: { list },
      } = await apiTableSector.get();
      this.setState({
        list,
        loading: false,
      });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
      this.setState({ loading: false });
    }
  };
  actions = (record: any): IActionsList => {
    const actions: IActionsList = [];

    actions.push({
      name: "Edit",
      action: this.handleEdit,
    });

    actions.push({
      name: "Delete",
      action: this.handleDelete,
    });

    return actions;
  };
  render() {
    const { loading, list } = this.state;

    return (
      <Settings
        location={this.props.location}
        title={
          <Row type="flex" justify="start">
            <h2 className="page-header">Sectors</h2>
          </Row>
        }
        actionsComponent={
          <Row type="flex" justify="end">
            <Button
              onClick={() => {
                this.props.history.push(
                  generatePath(routesTables.createSector.path)
                );
              }}
              className="ant-btn-primary ml-15"
            >
              Create Sector
            </Button>
            <Button
              className="ant-btn-primary ml-15"
              onClick={() => {
                this.props.history.push(routesTables.tableCreate.path);
              }}
            >
              Create Table
            </Button>
          </Row>
        }
      >
        <Table
          dataSource={list}
          loading={loading}
          rowKey="_id"
          clickable
          onRow={(record: any) => {
            return {
              onClick: () => {
                this.props.history.push(
                  generatePath(routesTables.tables.path, {
                    id: record._id,
                  })
                );
              },
            };
          }}
          columns={columns(this.actions)}
        />
      </Settings>
    );
  }
}
