import React, {useState, useEffect} from "react";
import FiltersIcon from "../../assets/icons/filters";
import {Button} from "antd";

interface Props {
  onClick(): void
  active?: boolean
  className?: string
}

export default (props: Props) => {
  const {active, className, onClick} = props;
  const [activeBtn, setActive] = useState<boolean>(!!active);

  const handleClick = () => {
    if (active === undefined) {
      setActive(!activeBtn)
    }
    onClick && onClick();
  };

  const componentDidUpdate = () => {
    if (active !== undefined) {
      setActive(active)
    }
  };

  useEffect(componentDidUpdate, [active]);

  return <Button
    type={activeBtn ? 'default' : 'primary'}
                 className={`btn-filters${className ? ` ${className}` : ''}`}
                 onClick={handleClick}>
    <FiltersIcon color={activeBtn ? '#191F2D' : '#FFFFFF'} />
  </Button>
}