import * as React from 'react';
import {
  Button,
  Row,
  Select,
  Icon,
  Spin
} from 'antd';
import moment, {Moment} from 'moment';
import * as qs from 'query-string';
import EventItem from '../../components/EventItem';
import Pagination from '../../components/Pagination';
import {months} from "../../configs/date";
import userRoutes from "../../routes/user";
import NavLink from '../../components/NavLink';
import Has from '../../noui/Permissions/Has';
import holidaysAPI from "../../API/holidays";
import * as modalService from "../../services/modal";
import N from "../../services/notification";
import * as modalHoliday from "../Modals/set/holiday";
import FiltersConf from "../../services/filters";
import TablePagination from "../../services/tablePagination";
import errors from '../../API/error';
import EmptyEvents from '../../assets/icons/emptyEvents';
import './styles.scss';
import Settings from "../../layouts/Settings";

const Option = Select.Option;

class Events extends React.Component<any, any> {
  filters = new FiltersConf({
    keys: ['jkkjjkj']
  });
  pagination = new TablePagination();

  state = {
    loading: true,
    pagination: {},
    list: [],
    year: moment().year(),
    month: null,
  };

  componentDidMount() {
    this.filters.setQueryParams();
    modalHoliday.set({
      onSubmit: this.handleSuccessSaved,
      onChangeImage: this.handleChangeImage
    });
    this.getData()
  };

  componentWillUnmount() {
    modalHoliday.del();
  };


  changePage = (page: number) => {
    this.pagination.page = page;
    this.getData();
  };

  handleEdit = async (id: number) => {
    try {
      this.setState({id});
      const {data} = await holidaysAPI.getItem({params: {id}});
      modalService.setProps('holiday', data);
      modalService.open('holiday')
    } catch (e) {
      console.log(e);
      errors.handle(e, undefined, {defaultMessage: 'Failed to edit'});
    }
  };

  handleSuccessSaved = () =>
    this.getData();

  handleChangeImage = (image: any) =>
    this.setState({image, imageChanged: true});

  handleChange = (name: string) => (value: string) => {
    this.setState({
      [name]: value === 'all' ? null : value
    }, this.getData)
  };

  handleChangeYear = (name: string) => (value: string) => {
    this.setState({
      [name]: value,
    }, this.getData)

  };


  getData = async () => {
    try {
      this.setState({loading: true});
      const {year, month}: any = this.state;
      const {search = ''} = this.props.location;
      const q: any = qs.parse(search);

      let from = '';
      let to = '';
      const reqDateFormat = 'YYYY-MM-DD';

      if (month === null) {
        from = moment().set({year}).startOf('year')
          .format(reqDateFormat);
        to = moment().set({year}).endOf('year')
          .format(reqDateFormat);
      } else {
        const m: Moment = moment().set({year, month});
        from = m.startOf('month').format(reqDateFormat);
        to = m.endOf('month').format(reqDateFormat);
      }

      const res = await holidaysAPI.get({
        query: {
          ...this.pagination.requestParams(),
          from,
          to,
          ...q
        }
      });
      const {list, pagination} = res.data;

      this.pagination.value = pagination;
      this.setState({
        list,
        loading: false
      });
      return true;
    } catch (e) {
      errors.handle(e);
      this.setState({loading: false});
      return false;
    }
  };

  addNew = () => {
    this.setState({image: null, id: 0});
    modalService.setProps('holiday', {});
    modalService.open('holiday')
  };

  /**
   * Remove event
   */
  handleRemove = async (id: number) => {
    try {
      await holidaysAPI.del(null, {params: {id}});
      this.getData();
      N.success();
    } catch (e) {
      errors.handle(e, undefined, {defaultMessage: 'Failed to delete'});
      return false;
    }
  };

  render() {
    const {list, loading, month, year: stateYear}: any = this.state;
    const year = moment().year();
    const startYear = year - 2;
    const endYear = year + 5;
    const yearsRange = endYear - startYear;

    const _m = (month === 'all' || month === null ? moment().month() : month) + 1;

    return <Settings
      location={this.props.location}
      actionsComponent={
        <div className="flex calendar-filter">
          <Select defaultValue={moment().format('YYYY')}
                  style={{width: 120}}
                  onChange={this.handleChangeYear('year')}>
            {
              new Array(yearsRange).fill(null).map((d: null, index: number) =>
                <Option value={startYear + index} key={index}>{startYear + index}</Option>)
            }
          </Select>
          <Select className="ml-15"
                  defaultValue="all"
                  style={{width: 120}}
                  onChange={this.handleChange('month')}>
            <Option value="all">Select month</Option>
            {
              months.map((monthKey: string, index: number) =>
                <Option key={monthKey}
                        value={index}>{monthKey}</Option>)
            }
          </Select>
          <NavLink className="ml-15"
                   to={`${userRoutes.home.path}?month=${_m}&year=${stateYear}&type=1`}>
            <Button className="ant-btn-primary">
              <Icon type="calendar" />
            </Button>
          </NavLink>
          <Has permissions={['settings']}>
            <Button className="ant-btn-primary ml-15"
                    onClick={this.addNew}>Add New Event</Button>
          </Has>
        </div>
      }
    >
      <Spin tip="Loading..." spinning={loading}>
        <div className="event-cards">
          {
            list && list.length
              ? list.map((item: any, index: number) => {
                return <EventItem key={index}
                                  onEdit={this.handleEdit.bind(null, item._id)}
                                  onRemove={this.handleRemove.bind(null, item._id)}
                                  {...item} />
              })
              : <Row type="flex" justify="center" className="width-100 mt-80">
                {<EmptyEvents />}
              </Row>
          }
        </div>
        <Pagination {...this.pagination.config} onChange={this.changePage} />
      </Spin>
    </Settings>
  }
}

export default Events