import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47717 6.47717 2 12 2C17.5228 2 22 6.47717 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37256 24 0 18.6274 0 12C0 5.37256 5.37256 0 12 0C18.6274 0 24 5.37256 24 12ZM11.8286 5.99985H15.8286L17.8286 5.99985V7.99985V11.9998H15.8286V9.41427L12.4142 12.8286L12.4142 12.8286L9.414 15.8288L12 15.8288V17.8288H8L6 17.8288L6 15.8288V11.8288H8V14.4143L11.4143 11L11.4144 11L14.4145 7.99985L11.8286 7.99985V5.99985Z"
      fill="#191F2D"
    />
  </svg>
);
