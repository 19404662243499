import React from 'react';
import { Col, Row } from 'antd';
import { Groups, Users, Weekends, Buttons, Teams } from '../../components/Filters';
import Some from '../../noui/Permissions/Some';

interface Props {
  children: React.ReactNode;
  defaultSelected?: {
    _id: string;
    firstName: string;
    lastName: string;
  }[];
}
export default ({ children, defaultSelected = [] }: Props) => (<Row gutter={ [10, 8] } className="table-filters">
  <div className="title-filters mb-20">Filters</div>
  <Col md={ { span: 20 } }>
    <Row gutter={ [8, 8] }>
      { children || null }
      <Users col={ { md: { span: 6 } } } defaultSelected={ defaultSelected || [] } />
      <Some permissions={ ['settings', 'filters'] }>
        <Groups col={ { md: { span: 6 } } } />
      </Some>
      <Some permissions={ ['settings', 'filters'] }>
        <Teams col={ { md: { span: 6 } } } />
      </Some>
      <Weekends col={ { md: { span: 6 } } } />
    </Row>
  </Col>
  <Col md={ { span: 4 } }>
    <Buttons />
  </Col>
</Row>);
