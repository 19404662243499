import React from "react";
import WorkspaceHeader from "./items/WorkspaceHeader";
import WorkspaceInfo from "./items/WorkspaceInfo";
import "./styles.scss";
import WorkspaceRequestInfo from "./items/WorkspaceRequestInfo";
import WorkspaceMap from "./items/WorkspaceMap";
import apiTable from "../../API/table";
import {
  setBookingRequests,
  setSector,
  setSectorsData,
  setTable,
  setTablesData,
} from "../../services/bookingTable";
import errors from "../../API/error";
import { debounce } from "../../services/debounce";
import apiBookingRequest from "../../API/bookingRequest";
import apiTableSector from "../../API/tableSector";
import { Spin } from "antd";
import { connect } from "react-redux";
import { IStore } from "src/interfaces/store";
import BookingRequestModal from "../Modals/bookingTable/BookingRequestModal";
import store from "src/configs/store";
import { set as setModal } from "../../services/modal";
class Workspace extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      disabledSelectSector: false,
    };
  }
  componentDidMount() {
    this.fetchData();
    setModal("bookingRequest", {
      hash: "booking-request",
      component: BookingRequestModal,
      config: {
        title: "Table Booking",
        okLabel: "Book",
        onSubmit: this.handleBookTable,
      },
    });
  }
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (
      prevProps.bookingTable.date.format("YYYY-MM-DD") !==
      this.props.bookingTable.date.format("YYYY-MM-DD")
    ) {
      if (this.props.bookingTable.sector) this.fetchData();
    }
  }
  handleBookTable = async ({ onLoading, onCancel }: any) => {
    try {
      onLoading(true);
      const {
        table: tableId,
        bookedDays,
        bookedFor,
      } = store.getState().bookingTable;
      const { _id: userId } = store.getState().user;
      const reqParam: any = {
        tableId,
        bookedDays,
        bookedFor: bookedFor ? bookedFor : userId,
      };
      await apiBookingRequest.create(reqParam);
      this.fetchData();
      this.fetchTable();
      onCancel();
    } catch (e) {
      onLoading(false);
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  fetchData = async () => {
    const { sectorsData, sector } = this.props.bookingTable;
    this.setState({
      isLoading: true,
    });
    try {
      Promise.all([
        apiBookingRequest.getMyRequest(),
        sectorsData ? apiTableSector.get() : null,
        sector ? this.fetchTable() : null,
      ]).then((value) => {
        const [requestData, sectorDataResponse] = value;
        setBookingRequests(requestData?.data.list);
        if (sectorDataResponse) setSectorsData(sectorDataResponse.data.list);
      });
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
    this.setState({
      isLoading: false,
    });
  };

  fetchTable = async () => {
    const { sector: currentSector, date: currentDate } =
      this.props.bookingTable;
    try {
      this.setState({
        disabledSelectSector: true,
      });
      const {
        data: { list },
      } = await apiTable.get({
        query: {
          limit: 999,
          sectorId: currentSector,
          currentDate: currentDate.format("YYYY-MM-DD"),
        },
      });
      setTablesData(list);
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
    this.setState({
      disabledSelectSector: false,
    });
  };

  debounceGetTable = debounce(() => {
    this.fetchTable();
  }, 10);

  handleSelectSector = (sectorId: string) => {
    const { disabledSelectSector } = this.state;
    const { sector: currentSector, table: currentTable } =
      this.props.bookingTable;
    if (sectorId !== currentSector && !disabledSelectSector) {
      if (currentTable) setTable("");
      setSector(sectorId);
      this.debounceGetTable();
    }
  };
  render() {
    const { isLoading, disabledSelectSector } = this.state;
    
    return (
      <Spin spinning={isLoading}>
        <WorkspaceHeader
          handleSelectSector={this.handleSelectSector}
          disabledSelectSector={disabledSelectSector}
        />
        <div className="workspace__content">
          <div className="workspace__infobar">
            <WorkspaceInfo />
            <WorkspaceRequestInfo getRequestData={this.fetchData} />
          </div>
          <WorkspaceMap handleSelectSector={this.handleSelectSector} />
        </div>
      </Spin>
    );
  }
}
export default connect(({ bookingTable }: Partial<IStore>) => ({
  bookingTable,
}))(Workspace);
