import * as React from "react";
import Field from "../../ui/Field";
import usersAPI from "../../API/users";
import errors from "../../API/error";
import IUser from "src/interfaces/user";
import { Select } from "antd";

interface IProps {
  onChangePermissions: any;
  modalProps: any;
}
const { Option } = Select;
const Projects = ({
  modalProps: { name = "", description = "", responsiblePerson = "" },
}: IProps) => {
  const [users, setUsers] = React.useState<IUser[]>([]);
  const getUserData = async () => {
    try {
      const res = await usersAPI.get({ query: { limit: 999 } });
      return res.data.list;
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };
  React.useEffect(() => {
    getUserData().then((result) => {
      setUsers(result);
    });
  }, []);
  return (
    <>
      <Field name="name" label="Name" defaultValue={name} />
      <Field
        name="description"
        label="Description"
        defaultValue={description}
      />
      <Field
        name="responsiblePerson"
        label="Responsible person"
        defaultValue={responsiblePerson._id}
      >
        <Select showSearch>
          {users.map((user: IUser) => (
            <Option key={user._id} value={user._id}>
              {user.firstName + " " + user.lastName}
            </Option>
          ))}
        </Select>
      </Field>
    </>
  );
};

export default Projects;
