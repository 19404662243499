import Statistics from "../containers/Statistics/Statistics";
import RequestsHistory from "../containers/Statistics/RequestsHistory";
import UserEntitlementAdmin from "../containers/UserEntitlementAdmin";

export default {
  settingsStatistics: {
    component: Statistics,
    path: `/settings/statistics`
  },
  requestsHistory: {
    component: RequestsHistory,
    path: `/settings/statistics/employee/:id/absence/:userWeekendId`
  },
  absences: {
    component: UserEntitlementAdmin,
    path: `/settings/statistics/employee/:id/absences`
  },
  redirect: {
    redirect: '/settings/statistics',
    path: `/settings/statistics/*`
  },
}