import React from "react";
import "./styles.scss";
import CaledarIcon from "src/assets/icons/calendar";
import moment from "moment";
import {
  IBookingRequest,
} from "../../../../../interfaces/containers/tableBooking";

interface IProps {
  deleteRequest: (tableId: string) => Promise<void>;
  requestData: IBookingRequest;
  sectorColor: string;
  sectorName: string;
}
const WorkspaceRequestItem: React.FC<IProps> = ({
  requestData,
  deleteRequest,
  sectorName,
  sectorColor,
}) => {
  const { dateStart, _id, tableName } = requestData;
  const handleDeleteRequest = () => {
    deleteRequest(_id);
  };

  return (
    <div className="workspace-request-item__container">
      <div className="workspace-request-item__table-label">
        <div className="sector" style={{ background: sectorColor }}>
          {sectorName}
        </div>
        {tableName}
      </div>
      <div className="workspace-request-item__date">
        <div className="workspace-request-item__icon-container">
          <CaledarIcon />{" "}
        </div>{" "}
        {moment(dateStart).format("YYYY-MM-DD")}
      </div>
      <div
        className="workspace-request-item__cancel"
        onClick={handleDeleteRequest}
      >
        Cancel
      </div>
    </div>
  );
};

export default WorkspaceRequestItem;
