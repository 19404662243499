import React from "react";

export default () => <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="12" r="12" fill="white"/>
  <path d="M12 9.75L12.6735 11.8229H14.8532L13.0898 13.1041L13.7634 15.1771L12 13.8959L10.2366 15.1771L10.9102 13.1041L9.14683 11.8229H11.3265L12 9.75Z" fill="#191F2D" stroke="#191F2D" strokeLinecap="round" strokeLinejoin="round"/>
  <rect x="4.5" y="6.75" width="15" height="12" stroke="#191F2D" strokeLinecap="round" strokeLinejoin="round"/>
  <rect x="6" y="5.25" width="0.75" height="3" rx="0.375" fill="#191F2D"/>
  <rect x="15.75" y="5.25" width="0.75" height="3" rx="0.375" fill="#191F2D"/>
  <rect x="7.5" y="5.25" width="0.75" height="3" rx="0.375" fill="#191F2D"/>
  <rect x="17.25" y="5.25" width="0.75" height="3" rx="0.375" fill="#191F2D"/>
</svg>
