import * as React from 'react';
import {
    Button
} from 'antd';
import Form from '../../noui/Form';
import Field from '../../ui/Field';
import appConfig from '../../configs/app';
import restoreAPI from "../../API/auth";
import errors from '../../API/error';
import N from "../../services/notification";
import './styles/forgotPassword.scss';

class ForgotPassword extends React.Component<any> {

    handleSubmit = async ({values,form}: any) => {
        try {
            await restoreAPI.restore({
                ...values,
                redirectUrl: appConfig.redirectToNewPassword
            });
            N.success('Password request sent')
            this.props.onCancel()
        } catch (e) {
            errors.handle(e);
        }
    };

    render() {
        return <div id="forgot-password">
            <h1>Did you forgot your password?</h1>

            <p>Enter your email address you`re using for your account below and we will send you a password reset link</p>

            <Form onSubmit={this.handleSubmit}>
                <Field name="email"
                       validation="email"
                       label="Email address" />
                <Button htmlType="submit" className="ant-btn-primary">Reset Password</Button>
                <div className="btn-form-link" onClick={this.props.onCancel}>
                    Back to Login page
                </div>
            </Form>
        </div>
    }
}

export default ForgotPassword