import * as api from './index';

const P = `/events`;

export default {
  get: api.get(P),
  getItem: api.get(`${P}/item/:id`),
  getMinMax: api.get(`${P}/min-max-dates`),
  create: api.post(`${P}/item`),
  update: api.put(`${P}/item/:id`),
  del: api.del(`${P}/item/:id`)
};