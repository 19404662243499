import * as React from 'react';
import { Col, DatePicker, Input, Row, Select, Spin, Alert } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import Field from '../../ui/Field';
import dateConfig from '../../configs/date';
import { User } from '../../components/Filters';
import './styles/sendRequest.scss';
import { IStore } from '../../interfaces';
import userAPI from '../../API/user';
import weekendsAPI from '../../API/weekends';
import errors from '../../API/error';

const { Option } = Select;
const { TextArea } = Input;

class SendRequest extends React.Component<any, any> {
  private unmounted: boolean | undefined;

  constructor(props: any) {
    super(props);
    const { employeeRequest } = props.modalProps;
    this.state = {
      weekends: [],
      // users list to autocomplete field
      users: [],
      loading: !employeeRequest,
      loaded: !!employeeRequest,
      showWeekendMessage: false,
    };
  }

  componentDidMount() {
    const { employeeRequest } = this.props.modalProps;
    if (!employeeRequest) {
      this.getWeekends();
    }
  }

  componentWillUnmount(): void {
    this.unmounted = true;
  }

  getWeekends = async () => {
    try {
      const { data } = await userAPI.weekends.get();
      if (!this.unmounted) {
        this.setState({
          weekends: data,
          loading: false,
          loaded: true,
        });
      }
    } catch (e) {
      errors.handle(e);
    }
  };

  disabledDate = (current: any): boolean =>
    current && current.diff(moment(), 'days') < 0;

  getUserAbsences = async (id: string) => {
    try {
      const { data } = await weekendsAPI.getUserWeekends({ params: { id } });
      this.setState({ weekends: data.list });
    } catch (e) {
      errors.handle(e);
    }
  };

  handleChange = (value: string, ev: any, form: any) => {
    form.resetFields(['weekendId']);
    this.getUserAbsences(value);
  };

  handleAbsenceTypeChange = (wId: string) => {
    let isMessageShow = false;
    const { weekends } = this.state;
    const weekend = weekends.filter(
      (el: { weekendId: string }) => el.weekendId === wId,
    );
    console.log('Current weekday - ', weekend);

    if (weekend[0].isFilesConfirmation) {
      isMessageShow = true;
      console.log('Current weekday settt - - ', isMessageShow);
    }
    this.setState({ showWeekendMessage: isMessageShow });
  };

  render() {
    const { weekendId, employeeRequest } = this.props.modalProps;
    const { weekends, loading, loaded, showWeekendMessage } = this.state;

    console.log('Message - ', showWeekendMessage);

    return (
      <div>
        <Spin tip='Loading...' spinning={ loading }>
          { loaded ? (
            <>
              { employeeRequest ? (
                <User name='userId' onChange={ this.handleChange } />
              ) : null }
              { showWeekendMessage ? (
                <Alert
                  message={
                    <strong>
                      Please don`t forget add the files to the request for confirmation.
                      To do this, open the request you created
                    </strong> }
                  type='warning'
                  className='mb-15'
                  showIcon
                />
              ) : null }
              <Field
                name='weekendId'
                validation='absenceTypeCanNotBlank'
                defaultValue={ weekendId }
                label='Absence type'
                placeholder='Select'
              >
                <Select onChange={ this.handleAbsenceTypeChange }>
                  { weekends &&
                    weekends.map((weekend: any, index: number) => (
                      <Option key={ index } value={ weekend.weekendId }>
                        { weekend.name }
                      </Option>
                    )) }
                </Select>
              </Field>
              <Row gutter={ 20 } className={ 'ant-form-item mt-15' }>
                <Col span={ 12 }>
                  <Field
                    name='from'
                    validation='required'
                    label='From'
                    placeholder={ 'Select date' }
                  >
                    <DatePicker format={ dateConfig.formatHolidayDatePicker } />
                  </Field>
                </Col>
                <Col span={ 12 }>
                  <Field
                    name='to'
                    validation='required'
                    label='To'
                    placeholder='Select date'
                  >
                    <DatePicker format={ dateConfig.formatHolidayDatePicker } />
                  </Field>
                </Col>
              </Row>
              <Field name='reason' label='Reason' placeholder='Type here' />
              <Field name='comment' label='Comment' placeholder='Type here'>
                <TextArea />
              </Field>
            </>
          ) : null }
        </Spin>
      </div>
    );
  }
}

export default connect(({ user }: Partial<IStore>) => ({ user }))(SendRequest);
