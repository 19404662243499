import * as React from 'react';
import {ChangeEvent, DragEvent} from "react";
import {IProps} from './index'

interface IPropsTmp extends IProps {
  form: any
}

export default class Tmp extends React.Component<IPropsTmp, any> {
  public fileInput: any = null;
  public state = {
    mouseEnter: false
  };

  public render() {
    const {className, children, multiple = false, dragAndDrop, customDrag = false} = this.props;
    const {mouseEnter} = this.state;
    const onDragOver = this.handleDragOver;
    const onDrop = this.handleChangeDrag;
    const drag = !customDrag
      ? {onDragOver, onDrop}
      : {};
    return <div className={`${className ? `${className} ` : ''}${mouseEnter ? 'mouse-enter' : ''}${dragAndDrop && !customDrag ? ` cursor` : ''}`}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                {...drag} >
      {
        children && typeof children === 'function'
          ? children({
            triggerChange: this.handleTriggerChange,
            onDragOver,
            onDrop
          })
          : null
      }
      <input className="hidden"
             type="file"
             multiple={multiple}
             ref={field => {
               this.fileInput = field
             }}
             onChange={this.handleChangeInput} />
    </div>
  }

  private handleMouseEnter = () =>
    this.setState({mouseEnter: true});

  private handleMouseLeave = () =>
    this.setState({mouseEnter: false});

  private handleDragOver = (e: any) =>
    e.preventDefault();

  private handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {dragAndDrop} = this.props;
    if (dragAndDrop) {
      this.handleChange(e.target.files);
    }
  };

  private handleChangeDrag = (e: DragEvent<HTMLDivElement>) => {
    const {dragAndDrop} = this.props;
    if (dragAndDrop) {
      this.handleChange(e.dataTransfer.files);
    }
    e.preventDefault();
  };

  private handleChange = (files: any): any => {
    const {
      mime = ['image/png', 'image/jpg', 'image/jpeg'],
      size = 5,
      isImage = false,
      onChange,
      onError,
      onClear
    } = this.props;

    if (!files || files.length === 0) {
      this.handleClearInputValue();
      if (typeof onError === 'function') {
        onError(['File not found']);
      }
      return false;
    }

    if (typeof onClear === 'function') {
      onClear();
    }

    for (const fileImage of files) {
      const errors = [];
      if (mime.indexOf(fileImage.type) === -1) {
        errors.push("Bad file format");
      }

      if (fileImage.size > 1024 * 1024 * size) { // 5Mb
        errors.push(`You can upload file size less then ${size}MB`);
      }

      if (errors.length) {
        this.handleClearInputValue();
        if (typeof onError === 'function') {
          onError(errors)
        }
        if (fileImage.next) {
          fileImage.next();
        }
      }
      // is it all about showing uploaded image
      if (isImage) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          if (typeof onChange === 'function') {
            onChange(fileImage, event.target.result)
          }
          ;
        };
        reader.readAsDataURL(fileImage);
      } else {
        if (typeof onChange === 'function') {
          onChange(fileImage)
        }
      }
      if (fileImage.next) {
        fileImage.next();
      }
    }
  };

  private handleClearInputValue = () =>
    this.fileInput.value = '';

  private handleTriggerChange = () =>
    this.fileInput.click();
}