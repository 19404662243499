import * as modal from "../../../services/modal";
import Projects from "../Projects";

interface IProps {
  onSubmit: any
};

export const init = ({onSubmit}: IProps) => {
  modal.set('projects', {
    hash: 'projects',
    component: Projects,
    config: {
      title: 'Projects',
      okLabel: 'Save',
      onSubmit
    }
  });
};

export const open = (item: any = {}) => {
  modal.setProps('projects', item);
  modal.open('projects')
};

export const remove = () => {
  modal.remove('projects')
};

export default {
  init,
  open,
  remove
}