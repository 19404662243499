import * as React from 'react';
import {Input} from 'antd';
import Field from '../../ui/Field';

const {TextArea} = Input;

const CalendarGroup = (props: any): JSX.Element => {
  const {modalProps: p} = props;
  return <React.Fragment>
    <Field name="name"
           validation={true}
           defaultValue={p._id ? p.name : undefined}
           label="Name" />
    <Field name="description"
           defaultValue={p._id ? p.description : undefined}
           label="Description">
      <TextArea autoSize={{minRows: 2, maxRows: 6}} />
    </Field>
  </React.Fragment>
}

export default CalendarGroup;