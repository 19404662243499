import * as React from 'react';
import DotMenu, {TActions} from '../../../components/DotMenu';
import {IUserData} from "../../../interfaces";
import {Row} from "antd";
import Avatar from "../../../components/Avatar";

export default (actions: TActions) => [
  {
    title: 'Network Name',
    key: 'name',
    dataIndex: ['name'],
    sorter: true
  },
  {
    title: 'Network Key',
    key: 'key',
    dataIndex: ['key'],
    sorter: true
  },
  {
    title: 'Login',
    key: 'login',
    dataIndex: 'login',
  },
  {
    title: 'Password',
    key: 'password',
    dataIndex: 'password',
  },
  {
    title: 'Users',
    dataIndex: 'users',
    key: 'users',
    render: (users: Array<IUserData>) => {
      if (!users) {
        return null
      }
      return users.map(u => <Row type="flex" className="flex-wrap-nowrap" align="middle" key={u._id}>
        <div className="mr-10">
          <Avatar user={u} size={20} />
        </div>
        <div className="white-space">{u.firstName} {u.lastName}</div>
      </Row>)
    }
  },
  {
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: any) =>
      <DotMenu actions={actions} record={record} />
  }
];