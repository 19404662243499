import * as React from 'react';
import {Row} from 'antd';

export default ({modalProps = {}}: any): JSX.Element => {
  const {text = () => null} = modalProps;
  return <Row type="flex"
              justify="center"
              className="mt-30 mb-30">
    { text() }
  </Row>
}