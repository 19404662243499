import {
  IBookingRequest,
  ITableSector,
} from "../../../../interfaces/containers/tableBooking";
import * as React from "react";
import "./styles.scss";
import apiBookingRequest from "../../../../API/bookingRequest";
import errors from "../../../../API/error";
import WorkspaceRequestItem from "./WorkspaceRequestItem/WorkspaceRequestItem";
import { connect } from "react-redux";
import { IStore } from "../../../../interfaces";

interface State {
  loaded?: boolean;
}

class WorkspaceRequestInfo extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  deleteRequest = async (requestId: string) => {
    try {
      await apiBookingRequest.remove(null, { params: { id: requestId } });
      this.props.getRequestData();
    } catch (e) {
      errors.handle(e, undefined, { defaultMessage: "Failed" });
    }
  };

  render() {
    const { sectorsData, bookingRequestsData: bookingRequest } =
      this.props.bookingTable;

    const availableRequest = 5 - bookingRequest.length;

    return (
      <div className="workspace-request-info">
        <div className="workspace-request-info__tittle mb-10">
          Your booked tables ({availableRequest} available)
        </div>

        {bookingRequest.map((request: IBookingRequest) => {
          const { sectorId, _id } = request;
          const { name: sectorName, color: sectorColor } =
            sectorsData.filter(
              (sector: ITableSector) => sector._id === sectorId
            )[0] || {};
          return (
            <WorkspaceRequestItem
              key={_id}
              sectorName={sectorName}
              sectorColor={sectorColor}
              requestData={request}
              deleteRequest={this.deleteRequest}
            />
          );
        })}
      </div>
    );
  }
}
export default connect(({ bookingTable }: Partial<IStore>) => ({
  bookingTable,
}))(WorkspaceRequestInfo);
