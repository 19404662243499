import React from 'react'
import moment from "moment";
import {Col, Row, DatePicker} from "antd";
import {Buttons, Groups, Users, Weekends, Teams} from "../../../components/Filters";
import Field from "../../../ui/Field";


const {RangePicker} = DatePicker;


export default ({status}: any) => {

  let statuses;

  if( status === "active" ){
    statuses = ['active' as const];
  }else if( status === "archived" ){
    statuses = ['archived' as const];
  }

  return <Row gutter={[20, 8]} className="table-filters">
    <div className="title-filters mb-20">Filters</div>
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Users statuses={statuses} col={{md: {span: 4}}} />
        <Groups col={{md: {span: 4}}} />
        <Weekends col={{md: {span: 4}}} />
        <Teams col={{md: {span: 4}}} />
        <Col md={{span: 8}}>
          <Field
            name="date"
            label="Date range"
            defaultValue={[moment.utc().startOf('month'), moment.utc().endOf('month')]}
          >
            <RangePicker format={'DD/MM/YYYY'}  />
          </Field>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 0]} type="flex" justify="end">
            <Col span={24}>
              <Buttons />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
}