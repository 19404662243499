import React from "react";
import mission from "../../../../assets/imgs/welcomeBook/unsplash_YI_9SivVt_s.jpg";
import vision from "../../../../assets/imgs/welcomeBook/unsplash_xttrPiu_pjw.jpg";
import "./styles.scss";
function Statement() {
  return (
    <div className="statement__container">
      <div className="statement_mission__container">
        <div className="statement_mission__item ">
          <div className="statement_mission__tittle tittle">
            devabit’s Statement
          </div>
          <div className="statement_mission__sub-tittle sub-tittle">
            Mission
          </div>
          <div className="statement_mission__text secondary-text">
            We help companies grow by utilizing the power of digital
            transformation and implementing their creative ideas through
            cutting-edge technologies.
          </div>
        </div>
        <div className="statement_mission__image_container">
          <img src={mission} alt="mission" />
        </div>
      </div>
      <div className="statement_vision___container">
        <div className="statement_vision__image_container ">
          <img src={vision} alt="vision" />
        </div>
        <div className="statement_vision__item">
          <div className="statement_vision__tittle sub-tittle">Vision </div>
          <div className="statement_vision__text secondary-text ">
            Our vision is to enhance and change the way IT solutions are
            delivered while unwavering commitment to excellence, integrity,
            responsiveness, and best management solutions.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statement;
