import React from 'react';
import {Button} from 'antd';
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import confirmations from '../../API/confirmations';
import TablePagination from "../../services/tablePagination";
import weekendsConfirmation from '../Modals/set/weekendsConfirmation'
import columns from './columns';
import errors from '../../API/error';
import Settings from "../../layouts/Settings";

interface IProps {
  [key: string]: any
}

export default class Confirmations extends React.Component<IProps> {
  pagination = new TablePagination();

  state = {
    list: [],
    loading: false
  };

  componentDidMount() {
    weekendsConfirmation.init({onSubmit: this.submit});
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({loading: true});

      const {data} = await confirmations.get();
      const {list, pagination} = data;

      this.pagination.value = pagination;
      this.setState({list, loading: false})
    } catch (e) {
      errors.handle(e);
    }
  };

  submit = async () => {
    this.getData()
  };

  remove = async ({_id}: any) => {
    try {
      await confirmations.del(null, {params: {id: _id}});
      this.getData();
    } catch (e) {
      errors.handle(e);
    }
  };

  actions = [
    {
      name: 'Edit',
      onClick: ({_id}: any) => {
        weekendsConfirmation.open({_id})
      }
    },
    {
      name: 'Delete',
      onClick: this.remove
    }
  ];

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getData();
  };

  render() {
    const {list, loading} = this.state;
    return <Settings location={this.props.location} actionsComponent={
      <Button className="ant-btn-primary" onClick={() => {
        weekendsConfirmation.open()
      }}>Create Approval Gate</Button>
    }>
        <Table dataSource={list}
               loading={loading}
               columns={columns(this.actions)}
               rowKey="_id"
               pagination={this.pagination.tableConfig} />
      <Pagination {...this.pagination.config}
                  onChange={this.changePage}/>
    </Settings>
  }
}