import * as React from 'react';
import moment from 'moment';
import DotMenu, {TActions} from '../../components/DotMenu';
import dateConfig from '../../configs/date';
import {getGeneralStatus} from "../../utils/requestStatus";
import {IRequestData} from "../../interfaces/containers";

export default (actions: TActions) => [
  {
    title: 'Type',
    dataIndex: 'weekend',
    key: 'name',
    render: (text: any) => text.name
  }, {
    title: 'Date',
    sorter: true,
    dataIndex: 'from',
    key: 'date',
    render: (text: any, record: any) =>
      `${moment(record.from).format(dateConfig.formatHolidayDatePicker)}${record.from !== record.to 
        ? ` - ${moment(record.to).format(dateConfig.formatHolidayDatePicker)}` 
        : ''
      }`
  }, {
    title: 'Status',
    dataIndex: 'statusName',
    key: 'statusName',
    render: (txt: any, record: IRequestData) => getGeneralStatus(record)
  }, {
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: IRequestData) =>
      <DotMenu actions={actions} record={record}/>
  }
];