import * as React from 'react';
import {Row} from 'antd'

import RejectIcon from '../../../../assets/icons/req_cancel'
import ArrowIcon from '../../../../assets/icons/req_arrow'

interface Button {
  children: any
  disabled?: boolean
  className?: string

  onClick?(): void
}

const Btn = ({children, className = '', onClick, disabled}: Button) =>
  <button className={`cursor empty-btn ml-15 ${className}`}
          disabled={!!disabled}
          type="button"
          onClick={onClick}>
    {children}
  </button>;

interface Props {
  count: number

  onUp(index: number): void

  onDown(index: number): void

  onRemove(index: number): void
}

export default (props: Props) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    key: 'actions',
    render: (text: any, record: any, index: number) => {
      const {count} = props;
      const hideDown = count - 1 === index;
      const hideUp = 0 === index;
      return <Row type="flex" justify="end">
        <Btn className={`iv-arrow-up${hideUp ? ' iv-arrow-opacity' : ''}`}
             disabled={hideUp}
             onClick={props.onUp.bind(null, index)} >
          <ArrowIcon />
        </Btn>
        <Btn className={`iv-arrow-down${hideDown ? ' iv-arrow-opacity' : ''}`}
             disabled={hideDown}
             onClick={props.onDown.bind(null, index)}>
          <ArrowIcon />
        </Btn>
        <Btn onClick={props.onRemove.bind(null, index)}>
          <RejectIcon />
        </Btn>
      </Row>
    }
  }
]