import React from "react";
import Settings from "../../../layouts/Settings";
import Details from "./Details";
import History from "./History";
import Assigned from "./Assigned";
import apiInventories from "../../../API/inventories";

export default class View extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      v: 1,
      data: null
    };
  }


  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      const {id} = this.props.match.params;
      const {data} = await apiInventories.getItem({params: {id}});
      this.setState({data})
    } catch (e) {

    }
  };

  updateVersion = () => {
    this.setState({v: this.state.v + 1})
  };

  render() {
    const {id} = this.props.match.params;
    const {v, data} = this.state;
    const testDevice = (data && data.status === 'public');
    return <Settings
      location={this.props.location}
      title="Inventory Item">
      {
        data ? <>
            <Details id={id} data={data} />
            <Assigned id={id} testDevice={testDevice} onUpdate={this.updateVersion} />
            <History id={id} v={v} />
        </>
          : null
      }
    </Settings>
  }
}