import * as React from 'react';
import {Layout} from 'antd';
import user from '../routes/user';
import Routes from '../components/Routes';
import HeaderMenu from '../components/Header';
import HashModal from '../components/HashModal';
import modals from '../containers/Modals';
import './style.scss';

modals.init();

const {
  Header, Footer, Content,
} = Layout;

export default (): JSX.Element =>
  <Layout>
    <Header>
      <HeaderMenu />
    </Header>
    <Content className="container pt-50">
      <Routes routes={user}
              notFound={true} />
    </Content>
    <Footer>© {new Date().getFullYear()} Devabit LLC All rights reserved.</Footer>
    <HashModal />
  </Layout>