import React from 'react';
import Points, {IAction} from '../../components/Points';

const getPointActions = (record: any, actions?: IAction[]) => {
  if (!actions) {
    return []
  }

  const menu: IAction[] = actions.map(action => {
    const el: any = {
      ...action
    };
    if (action.onClick) {
      el.onClick = action.onClick.bind(null, record)
    }
    return el;
  });
  return menu;
};

export default (actions: IAction[]) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }, {
    title: '',
    key: 'actions',
    render: (text: any, record: any) => {
      return <Points actions={getPointActions(record, actions)}/>
    }
  }
];