import React from "react";

interface Props {
  active?: boolean
  onClick(): void
}

export default ({active, onClick}: Props) => <div className={`m-menu-burger${active ? ' is-active' : ''}`}
                                                  onClick={onClick}>
  <span className="m-menu-burger-center" />
</div>