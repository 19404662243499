import PositionsRemoveWarning from "../PositionsRemoveWarning";
import * as modal from "../../../services/modal";

interface IProps {
  onSubmit: any
}

export const init = ({onSubmit}: IProps) => {
  modal.set('removePosition', {
    hash: 'remove-position',
    component: PositionsRemoveWarning,
    config: {
      title: 'Remove position',
      okLabel: 'Remove',
      onSubmit
    }
  });
};

export const open = (record: any) => {
  modal.setProps('removePosition', record);
  modal.open('removePosition');
};

export const del = () => {
  modal.remove('removePosition');
};

export default {
  init,
  open,
  del
}