import React from 'react';
import moment from 'moment';
import './styles.scss';
import Head from '../BirthdayItem/Head';

import img0 from '../../../assets/imgs/anniversary/0.png';
import img1 from '../../../assets/imgs/anniversary/1.png';
import img2 from '../../../assets/imgs/anniversary/2.png';
import img3 from '../../../assets/imgs/anniversary/3.png';
import img4 from '../../../assets/imgs/anniversary/4.png';
import img5 from '../../../assets/imgs/anniversary/5.png';
import img6 from '../../../assets/imgs/anniversary/6.png';
import img7 from '../../../assets/imgs/anniversary/7.png';
import img8 from '../../../assets/imgs/anniversary/8.png';
import img9 from '../../../assets/imgs/anniversary/9.png';
import img10 from '../../../assets/imgs/anniversary/10.png';
import img11 from '../../../assets/imgs/anniversary/11.png';


interface Props {
  [key: string]: any;
}

const images = [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, react/display-name
export default (props: Props) => {
  const momentDate = moment.utc(props.anniversaryDate);
  const momentEmployment = momentDate
    .clone()
    .set({ year: moment.utc(props.nfDate).year() });
  const yearsInCompany = momentEmployment.diff(momentDate, 'years');

  return (
    <div className='nfi-employment nfi-card js-nfi' data-id={ props.keyItem }>
      <Head { ...props } date={ momentEmployment.format('DD/MM/YYYY') } />
      <div className='nfi-employment-background'>
        <div
          className='nfi-employment-img'
          style={ {
            backgroundImage: `url(${
              yearsInCompany <= 0 ? images[0] : images[yearsInCompany]
            })`,
          } }
        />
      </div>
    </div>
  );
};
