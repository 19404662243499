import * as React from 'react';
import {
  Button,
  notification
} from 'antd';
import modal from '../Modals/set/teamItem';
import teamsAPI from '../../API/teams';
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import columns from "./columns";
import errors from "../../API/error";
import TablePagination from "../../services/tablePagination";
import Settings from "../../layouts/Settings";

interface IState {
  list: object[],
  pagination: any,
  id: string
  loading: boolean
};

class Teams extends React.Component<any, IState> {

  private readonly pagination: TablePagination;
  constructor(props: any) {
    super(props);
    this.pagination = new TablePagination({
      callback: this.getTeams,
      includeOnChange: true,
      updateOnChange: true,
    });

    this.state = {
      list: [],
      pagination: {
        total: 0,
      },
      loading: true,
      id: '',
    };
  }


  componentDidUpdate(prevProps: any) {
    if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
      this.getTeams();
    }
  }

  componentDidMount() {
    modal.init({ onSubmit: this.handleSubmit });
    this.getTeams();
  }

  getTeams = async () => {
    try {
      const paginationConfig = { ...this.pagination.requestParams() };
      this.setState({ loading: true });
      const queryGroup: any = {};
      const { groupId } = this.props.match.params;
      if (groupId) {
        queryGroup.groupId = groupId;
      }
      const result = await teamsAPI.getAll({
        query: { ...paginationConfig, ...queryGroup },
      });
      const { list, pagination } = result.data;
      this.pagination.value = pagination;
      this.setState({ list, pagination, loading: false });
    } catch (e) {
      errors.handle(e);
      this.setState({ list: [], loading: false });
    }
  };

  addTeam = () => {
    modal.open();
  };

  handleSubmit = async ({
    values,
    onLoading,
    onCancel,
    form,
    modalProps: mp = {},
  }: any) => {
    try {
      onLoading(true);
      if (mp._id) {
        await teamsAPI.update(values, { params: { id: mp._id } });
      } else {
        await teamsAPI.create(values);
      }
      this.getTeams();
      onLoading(false);
      onCancel();
    } catch (e) {
      errors.handle(e, form, { priority: 'form' });
      onLoading(false);
    }
  };

  handleEdit = (item: any) => {
    this.setState({ id: item._id });
    modal.open(item);
  };

  handleRemove = async ({ _id }: any) => {
    try {
      await teamsAPI.del(null, { params: { id: _id } });
      this.getTeams();
      notification.success({
        message: 'Success',
      });
    } catch (e) {
      errors.handle(e);
    }
  };

  changePage = (page: number) => {
    this.pagination.page = page;
    this.getTeams();
  };

  render() {
    const { list, loading } = this.state;
    return (
      <Settings
        location={this.props.location}
        actionsComponent={
          <Button className='ant-btn-primary' onClick={this.addTeam}>
            Create Team
          </Button>
        }
      >
        <Table
          dataSource={list}
          rowKey='_id'
          loading={loading}
          columns={columns({
            actions: {
              onEdit: this.handleEdit,
              onRemove: this.handleRemove,
            },
          })}
          pagination={this.pagination.tableConfig}
        />
        <Pagination {...this.pagination.config} onChange1={this.changePage} />
      </Settings>
    );
  }
}

export default Teams
