import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12C2 6.47717 6.47717 2 12 2C17.5228 2 22 6.47717 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37256 24 0 18.6274 0 12C0 5.37256 5.37256 0 12 0C18.6274 0 24 5.37256 24 12ZM18 11.8288H14L12 11.8288V9.82876V5.82876H14V8.41434L17.4143 5L18.8286 6.41421L15.414 9.82876L18 9.82876V11.8288ZM9.82855 11.9998H5.82855V13.9998L8.41455 13.9998L5 17.4144L6.41421 18.8286L9.82855 15.4143V17.9998L11.8286 17.9998V13.9998V11.9998L9.82855 11.9998Z"
      fill="#191F2D"
    />
  </svg>
);
