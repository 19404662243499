import RedirectToLogin from '../containers/RedirectToLogin';
import Login from '../containers/Login';
import CreateNewPassword from '../containers/CreateNewPassword';
import AcceptanceByEmail from '../containers/AcceptanceByEmail';

export default {
  login: {
    component: Login,
    path: '/login'
  },
  newPassword: {
    component: CreateNewPassword,
    path: '/create-password'
  },
  acceptanceByEmail: {
    component: AcceptanceByEmail,
    path: '/view-request'
  },
  redirect: {
    component: RedirectToLogin,
    path: '/*'
  }
};
