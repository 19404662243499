import * as React from "react";
import DotMenu, {IActionsList} from "../../../components/DotMenu";

export default (actions: (record: any) => IActionsList, columns: any[] = []) => [
  ...columns,
  {
    title: '',
    key: 'actions',
    className: 'prevent-click',
    render: (text: any, record: any) =>
      <DotMenu actions={actions(record)} record={record} />
  },
]